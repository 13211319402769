import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  answers: [],
  comments: [],
  isShowModalRestoreDrafts: false
};

export const selectCommentsIndexDB = createAction('uiIndexDB/selectComments');
export const selectAnswersIndexDB = createAction('uiIndexDB/selectAnswers');
export const clearCommentsIndexDB = createAction('uiIndexDB/clearComments');
export const clearAnswersIndexDB = createAction('uiIndexDB/clearAnswers');
export const toggleIsShowModalRestoreDraftsOpen = createAction(
  'uiIndexDB/toggleIsShowModalRestoreDraftsOpen'
);
export const toggleIsShowModalRestoreDraftsClose = createAction(
  'uiIndexDB/toggleIsShowModalRestoreDraftsClose'
);

export const uiIndexDBReducer = createReducer(defaultState, {
  [selectCommentsIndexDB]: (state, { payload }) => {
    return {
      ...state,
      comments: [...state.comments, ...payload]
    };
  },
  [selectAnswersIndexDB]: (state, { payload }) => {
    return {
      ...state,
      answers: [...state.answers, ...payload]
    };
  },
  [clearCommentsIndexDB]: state => {
    return {
      ...state,
      comments: []
    };
  },
  [clearAnswersIndexDB]: state => {
    return {
      ...state,
      answers: []
    };
  },
  [toggleIsShowModalRestoreDraftsOpen]: state => {
    return {
      ...state,
      isShowModalRestoreDrafts: true
    };
  },
  [toggleIsShowModalRestoreDraftsClose]: state => {
    return {
      ...state,
      isShowModalRestoreDrafts: false
    };
  }
});
