import { PERMISSIONS } from 'core/utils/constants';
import { intersection, head } from 'lodash';

export const conditionalRouting = ({ currentUserPermissions, currentUser }) => {
  const permissionByMenuOrderToRoute = {
    [PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS]: `client-interactions`,
    [PERMISSIONS.CAN_SEE_DASHBOARDS]: `dashboards`,
    [PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS]: `tasks`,
    [PERMISSIONS.CAN_MAKE_REVIEW]: `add-review`,
    [PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS]: `checklists`,
    [PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS]: `settings/triggers`,
    [PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE]: `organization-structure/units`,
    [PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS]: `settings/organization`,
    [PERMISSIONS.CAN_MANAGE_INTEGRATIONS]: `settings/integrations`,
    [PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB]: `user/${currentUser.id}/charts`,
    [PERMISSIONS.CAN_ACCESS_LIBRARY]: 'library'
  };

  return permissionByMenuOrderToRoute[
    head(intersection(Object.keys(permissionByMenuOrderToRoute), currentUserPermissions))
  ];
};
