export default {
  aiSettingsPage: {
    title: 'AI Settings',
    tableColumnsTitle: {
      creationDate: 'Creation Date',
      lastLaunchDate: 'Last Launch Date',
      status: 'Status',
      type: 'Type',
      checks: 'Checks',
      inProgress: 'In Progress',
      successful: 'Successful',
      withError: 'With Error',
      duration: 'Duration',
      direction: 'Direction',
      phoneNumber: 'Phone Number',
      dateTime: 'Date and Time',
      taskId: 'Id'
    },
    taskStatus: {
      readyToLaunch: 'Ready to Launch',
      inProcess: 'In Process',
      validationError: 'Validation Error',
      completed: 'Completed',
      inQueue: 'In Queue',
      transcription: 'Transcription',
      analysis: 'Analysis',
      analysisError: 'Analysis Error',
      transcriptionError: 'Transcription error',
      downloadLogs: 'Download logs',
      analyzeLimitError: 'Limit of analysis exceeded',
      transcriptionLimitError: 'Transcription limit exceeded'
    },
    taskSettings: {
      selectDataset: 'Select Dataset',
      selectCustomFields: 'Select custom fields',
      customField: 'Custom field',
      rating: 'Rating',
      selectAiRatingForm: 'Select AI Rating Form',
      checkStatus: 'Check Status',
      addCondition: 'Add Condition',
      communicationFilter: 'Communication Filter',
      operators: 'Operators',
      contactStatus: 'Contact Status',
      department: 'Department',
      levels: 'Levels',
      period: 'Period',
      regular: 'Regular',
      oneTime: 'One Time',
      immediate: 'Immediate',
      taskType: 'Task Type',
      disableEnable: 'Disable / Enable',
      edit: 'Edit',
      newTask: 'New Task',
      pleaseEnterTaskName: 'Please Enter Task Name',
      enterName: 'Enter Name',
      export: 'Export',
      communicationList: 'Communication List',
      incoming: 'Incoming',
      outgoing: 'Outgoing',
      byStatus: 'By status'
    },
    operations: {
      operationSuccessfully: 'Operation Successfully',
      operationError: 'Operation Error',
      checkAIAssessmentFormOrPromptsValues:
        'You must specify at least one value in the AI or Prompta Evaluation Form field',
      saveAiTask: `After saving the task, it is strongly recommended not to change the prompta and the evaluation form in order to ensure the correct operation of QolioAI. Please note that in order to successfully pass validation, all criteria in the evaluation form and all fields must be associated with prompta.`
    },
    processStatus: {
      completed: 'Completed',
      process: 'In process',
      mistake: 'Mistake',
      ready: 'Ready to launch',
      all: 'All'
    },
    tooltips: {
      validationError: `Possible reasons: \nChange in the evaluation form \nChanging custom fields \nIncorrect prompta`,
      analyzeError: 'Other errors',
      allStatus: 'Within the demo mode, only 100 communications can be processed',
      transcriptionError: 'Transcription error',
      copyTaskId: 'Copy the Task Id',
      analyzeLimitError: 'Communication analysis limit exceeded',
      transcriptionLimitError: 'Time limit for transcription exceeded'
    },
    messages: {
      copyTaskIdSuccess: 'The task Id has been copied'
    }
  }
};
