import api from 'core/api';
import decamelize from 'decamelize-keys-deep';
import { processJsonApiCollection, processJsonApiObject } from 'core/jsonapi';
import * as actions from './reducer';

export const loadLibrary = params => {
  return async dispatch => {
    dispatch(actions.loadingFolders());
    try {
      const response = await api.getReviewLibraries(decamelize(params));
      const libraryFolders = processJsonApiCollection(response.body.data);
      dispatch(actions.foldersLoaded(libraryFolders));
      if (response.body.included) {
        const libraryItems = processJsonApiCollection(response.body.included);
        dispatch(actions.libraryItemsLoaded(libraryItems));
      }
      return libraryFolders;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const createFolder = params => {
  return async dispatch => {
    dispatch(actions.creatingFolder());
    try {
      const response = await api.createReviewLibrary(decamelize(params));
      const newFolder = processJsonApiObject(response.body.data);
      dispatch(actions.folderCreated(newFolder));
      return newFolder;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const updateFolder = ({ id, ...params }) => {
  return async dispatch => {
    dispatch(actions.updatingFolder());
    try {
      const response = await api.updateReviewLibrary({ id }, decamelize(params));
      const newFolder = processJsonApiObject(response.body.data);
      dispatch(actions.folderUpdated(newFolder));
      return newFolder;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const deleteFolder = id => {
  return async dispatch => {
    dispatch(actions.deletingFolder(id));
    try {
      await api.deleteReviewLibraryItem({ id });
      dispatch(actions.folderDeleted(id));
      return true;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

// * calls
export const createLibraryItem = ({ libraryId, reviewId, ...params }) => {
  return async dispatch => {
    dispatch(actions.creatingLibraryItem({ libraryId, reviewId, ...params }));
    try {
      const response = await api.createReviewLibraryItem(
        decamelize({ libraryId, reviewId, ...params })
      );
      const libraryItem = processJsonApiObject(response.body.data);
      dispatch(actions.libraryItemCreated(libraryItem));
      return libraryItem;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const updateLibraryItem = ({ id, ...params }) => {
  return async dispatch => {
    dispatch(actions.updatingLibraryItem({ id, ...params }));
    try {
      const response = await api.updateReviewLibraryItem({ id }, decamelize(params));
      const newItem = processJsonApiObject(response.body.data);
      dispatch(actions.libraryItemUpdated(newItem));
      return newItem;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const deleteLibraryItem = id => {
  return async dispatch => {
    dispatch(actions.deletingLibraryItem(id));
    try {
      await api.deleteReviewLibraryItem({ id });
      dispatch(actions.libraryItemDeleted(id));
      return true;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};
