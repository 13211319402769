import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';

export const questionGroupsResource = createReduxResource({
  name: 'checklistQuestionGroups',
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const checklistDefinition =
        state.checklistDefinitionsResource.byIds[resource.checklistDefinitionId];

      if (checklistDefinition)
        updateResource({
          dispatch,
          type: 'checklistDefinitions',
          resource: {
            ...checklistDefinition,
            questionGroupsIds: [...checklistDefinition.questionGroupsIds, resource.id]
          }
        });
    }
  }
});
