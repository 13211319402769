import React from 'react';
import { Row, Tooltip, Typography } from 'antd';
import { IconButton } from 'components/Buttons';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

export default ({ children, infoTitle }) => (
  <Row type="flex">
    <Tooltip title={children}>
      <Text strong ellipsis>
        {children}
      </Text>
    </Tooltip>
    <IconButton
      tooltip={{ title: infoTitle }}
      button={{
        icon: <InfoCircleOutlined />,
        size: 'icon'
      }}
    />
  </Row>
);
