import React from 'react';
import SRow from 'components/Standard/SRow';
import { Progress } from 'antd';
import styled from 'styled-components';
import { beatifyFloat } from 'core/utils/convertFloat';

const ProgressBar = ({ totalCount, doneCount }) => {
  const percentage = (doneCount / totalCount) * 100;

  const getStrokeColor = percentage => {
    if (percentage <= 33) return 'var(--red_6)';
    if (percentage > 33 && percentage <= 66) return 'var(--orange_8)';
    return 'var(--green_6)';
  };

  return (
    <SRow type="flex">
      <StyledProgress
        percent={!percentage ? 0 : beatifyFloat(percentage)}
        strokeColor={getStrokeColor(percentage)}
      />
    </SRow>
  );
};

const StyledProgress = styled(Progress)`
  &.ant-progress {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .ant-progress-outer {
    margin-right: 0px !important;
    padding-right: 0px !important;
    margin-left: calc(-2em - 8px);
    padding-left: calc(2em + 8px);
  }

  .ant-progress-text {
    margin: 0px 18px 0px 0px;
  }
`;

export default ProgressBar;
