import React from 'react';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { get, isEmpty } from 'lodash';
import { loadAnalyticsWidgets } from 'redux/entities/analytics/widgets/operations';
import ChecklistHistoryModal from 'components/Checklist/ChecklistHistoryModal';
import SRow from 'components/Standard/SRow';
import ChecklistDefinitionAnalytics from './ChecklistDefinitionAnalytics';
import EmptyForUserPage from '../EmptyForUserPage';

const getWidgetData = ({ checklistDefinitionId, widgets }) => {
  const reviewsCount = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_CHECKLIST_DEFINITIONS,
    checklistDefinitionId,
    'value'
  ]);

  const averageScore = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS,
    checklistDefinitionId,
    'value'
  ]);

  const ratingFlags = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_CHECKLIST_DEFINITIONS,
    checklistDefinitionId
  ]);

  const colorZones = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS,
    checklistDefinitionId
  ]);

  return {
    reviewsCount,
    averageScore,
    ratingFlags,
    colorZones
  };
};

export const AllChecklistsAnalytics = ({ checklistDefinitions = [], widgets, loadingWidgets }) => {
  const widgetsReviewsCount = checklistDefinitions
    .map(checklistDefinition => {
      const widgetData = getWidgetData({
        checklistDefinitionId: checklistDefinition.id,
        widgets
      });
      return widgetData?.reviewsCount;
    })
    .filter(reviewCount => reviewCount !== undefined);

  if (isEmpty(widgetsReviewsCount) && !loadingWidgets) {
    return <EmptyForUserPage />;
  }

  return (
    <>
      {checklistDefinitions.reduce((acc, checklistDefinition) => {
        const widgetData = getWidgetData({
          checklistDefinitionId: checklistDefinition.id,
          widgets
        });

        if (!widgetData?.reviewsCount) return acc;

        const component = (
          <SRow key={checklistDefinition.id} padding="16px" gutter={[0, 16]}>
            <ChecklistDefinitionAnalytics
              showName
              checklistDefinition={checklistDefinition}
              reviewsCount={widgetData?.reviewsCount}
              averageScore={widgetData?.averageScore}
              ratingFlags={widgetData?.ratingFlags}
              colorZones={widgetData?.colorZones}
              loading={loadingWidgets}
            />
          </SRow>
        );

        return [...acc, component];
      }, [])}

      <ChecklistHistoryModal widgets={widgets} loadingWidgets={loadAnalyticsWidgets} />
    </>
  );
};
