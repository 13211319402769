import { Button } from 'antd';
import styled from 'styled-components';

export default styled(Button)`
  font-size: 16px;
  border: none !important;
  margin: ${props => props.margin};
  box-shadow: none !important;
  background-color: transparent;
`;
