export default {
  components: {
    colorPicker: {
      name: 'Color',
      namePlaceholder: 'Choose color'
    },
    rateValues: { text: { placeholder: 'Text value' } },
    checklistManager: {
      autoFailModal: {
        title: 'Do you want to complete the evaluation?',
        description:
          'Autofail is activated in a score card. You can finish the evaluation right now with the current results saved or continue the evaluation.',
        ok: 'Finish',
        cancel: 'Continue the evaluation'
      },
      generalCommentModal: {
        title: 'General comment',
        ok: 'Continue',
        cancel: 'Cancel'
      },
      textAreaPlaceholder: 'General comment',
      totalScore: 'Total score:',
      question: {
        autoFailTooltip: { title: 'There was an autofail' },
        showStandardCommentSelector: { placeholder: 'Standart comment' }
      },
      questionDescriptionModal: {
        modal: {
          ok: 'Continue',
          cancel: 'Cancel',
          alert: { title: 'Description' }
        }
      },
      questionDraftModal: {
        modal: {
          title: 'There is a saved draft.',
          content: 'If agreed, the draft will be overwritten'
        }
      },
      checklistManagerHead: {
        emptyChecklist: {
          title: 'To save the assessment, you need to select the assessment form'
        },
        emptyEditingChecklistDefinition: {
          title: 'Evaluation form not established'
        },
        emptyChecklistModal: {
          title: 'You have not chosen the score card for this evaluation',
          description: 'Continue?',
          ok: 'Yes',
          cancel: 'No'
        },
        allQuestionsAnswered: {
          title: 'You have not evaluated one or more items on the score card',
          description: 'All unsent comments will be saved, complete the verification?',
          ok: 'Yes',
          cancel: 'No'
        },
        unsentComments: {
          title: 'All unsent comments will be saved',
          description: 'Complete the verification?',
          ok: 'Yes',
          cancel: 'No'
        },
        confirmChangeChecklist: {
          title: 'Change the score card?',
          description: 'This will reset the current evaluation',
          ok: 'Yes',
          cancel: 'No'
        },
        confirmDeleteEmployee: {
          title: 'Delete the user?',
          description:
            'Are you sure you want to delete the user? This action will remove all data associated with this user (communications, evaluations, dashboards).',
          ok: 'Yes',
          cancel: 'No',
          errors: {
            errorTimeoutTitle: 'Deleting a user',
            errorTimeout:
              "It looks like it's going to take a little longer. You can continue working in the system, the user will be deleted soon."
          }
        },
        confirmDeleteChecklist: {
          title: 'Delete evaluation?',
          description: 'Deleted assessment cannot be restored',
          ok: 'Yes',
          cancel: 'No',
          messages: {
            reviewSuccessfullyDeleted: 'Evaluation deleted',
            deleteReviewFailed: 'Failed to delete the evaluation'
          }
        },
        menu: {
          changeCurrentChecklist: 'Change the score card',
          navigateToClientInteraction: 'New evaluation',
          addToCalibration: 'For calibration',
          deleteReview: 'Delete evaluation'
        },
        buttons: {
          emptyReview: 'No evaluation',
          submitReview: 'Complete evaluation',
          editReview: 'Edit evaluation',
          saveReview: 'Save evaluation',
          updateReview: 'Update evaluation'
        },
        messages: {
          unsavedScore:
            'Not saved score in the score card. If you go to another page, the score will not be saved.'
        }
      },
      selectChecklist: {
        emptyAdminMessage: {
          pt1:
            'The evaluation form has not been configured for this communication or department. Go to the list of assessment forms to set the correct settings.',
          pt2: ' list of assessment forms',
          pt3: ' to set the correct settings.'
        },
        emptyUserMessage:
          'Форма оценки для данной коммуникации или отдела не настроена. Обратитесь к администратору портала для корректной установки настроек.'
      }
    },
    customRatingValuesEditor: { answer: { placeholder: 'Insert text' } },
    commentList: {
      comment: {
        commentEditButton: {
          buttons: {
            edit: 'Edit',
            answer: 'Answer',
            delete: 'Delete',
            hidden: 'Hidden',
            cancel: 'Cancel',
            save: 'Save'
          },
          confirmDelete: {
            title: 'Delete comment?',
            ok: 'Yes',
            cancel: 'No'
          }
        },
        commentInfo: {
          hiddenCommentTooltip: 'Hidden comment',
          answersCount: 'Answers:'
        },
        createReplyForm: {
          buttons: {
            hidden: 'Hidden',
            cancel: 'Cancel',
            send: 'Send'
          }
        }
      }
    },
    commentsPanel: {
      buttons: {
        addComment: 'Add comment',
        hidden: 'Hidden',
        cancel: 'Cancel',
        send: 'Send'
      },
      commentPlaceholder: 'Comment...'
    },
    commentsMessages: {
      uploadError: 'The image must be less than 3 MB!'
    },
    commentsPreview: {
      leftACommentStr: 'left a comment to:',
      commentPopoverTitle: 'Comments',
      previewIcons: {
        title: 'Comments',
        generalComment: 'General comment'
      }
    },
    timeSpecificSelector: {
      placeholder: 'Choose period',
      otherPeriod: 'Another period',
      selectButton: 'Choose'
    },
    notifications: { logged: 'You logged in!' },
    recordPlayer: {
      loading: 'Loading...',
      addToLibrary: {
        messages: {
          recordStr: 'Recording',
          addedStr: 'successfully added to',
          addRecordToLibraryFailed: 'Failed to add the recording'
        },
        tooltip: 'Add to the library',
        modal: {
          title: 'Add to the library',
          ok: 'Save',
          cancel: 'Cancel',
          enterFolderName: 'Select a folder name:',
          folderNamePlaceholder: 'Folder name:',
          enterRecordName: 'Enter call name:',
          recordNamePlaceholder: 'Call name'
        }
      },
      transcription: {
        tooltip: 'Transcription of the call'
      },
      viewCustomFields: { tooltip: 'Additional information' },
      attachTag: { title: 'Attach tag' },
      download: 'Download',
      copyLink: 'Copy link',
      copyReview: 'Copy the link to the review',
      copyCommunication: 'Copy the communication link',
      copyLinkSuccess: 'Link copied',
      externalLink: 'Go to the source of the communication',
      startLoadingAudio: 'To download the audio, click on the play button',
      recordControls: {
        play: 'Play',
        backward: '-15 sec.',
        forward: '+15 sec.'
      },
      phoneCallInformation: {
        callDate: 'Call date: ',
        callType: 'Type: ',
        callNumber: 'Number: ',
        tooltipButtonInfo:
          'The operator to whom the call and call checks are recorded. If checks have already been made on the call, then when changing the operator they will be rewritten to the new operator.',
        searchPlaceholder: 'Search by operators',
        communicationType: 'Type of communication: ',
        additionally: 'Additionally: ',
        communicationID: 'Communication ID: ',
        attachedFile: 'Attached file: '
      }
    },
    questionModal: {
      title: 'Question settings',
      buttons: {
        addComment: 'Add a comment',
        save: 'Save',
        update: 'Update',
        cancel: 'Cancel'
      },
      form: {
        fields: {
          namePlaceholder: 'Question name',
          nameInfo:
            'The name of the question (evaluation point) will be displayed on the score card.',
          name: 'Name',
          description: 'Description',
          descriptionPlaceholder: 'Question description',
          descriptionInfo:
            'Describe which competencies, knowledge and skills you want to evaluate with this question (evaluation point) on the score card. This description will help you select questions from the Questiosn list to form a complete score card.',
          scaleType: 'Evaluation scale',
          scaleTypeInfo:
            'Choose a ready-made scale to evaluate this question, or specify the points you want to evaluate employees on this question in the "Random scale".',
          valuesDisplayType: 'Display in a score card',
          valuesDisplayTypeInfo:
            'The way the scale will be displayed on the score card during the evaluation. The results will have a numerical value in the analytics.',
          defaultRatingValueEnabled: 'Default score card score',
          defaultRatingValueEnabledInfo:
            'You can specify what point will be automatically scored on this question at the beginning of your communication evaluation. The default setting is "No score".',
          autofailEnabled: 'Score card autofail',
          autofailEnabledInfo:
            'With a certain score on this question, the final score on the score card will be the lowest possible.',
          autofailCriteriesAlert: 'Customize criteries',
          autofailTotalScore: 'Score card score:',
          questionGroupAutofail: 'Question group autofail',
          questionGroupAutofailCriteriesAlert: 'Customize criteries',
          questionGroupAutofailTotalScore: 'Question group score:',
          autofailEnabledAlert: {
            title: 'Autofail',
            description:
              'Specify a score or a few points on the question, which, if appear, will make the final score on the entire score card equal to the minimum possible value.'
          },
          standardCommentsEnabled: 'Standard evaluation comments',
          standardCommentsEnabledInfo:
            'Write down the comments you add most often when assigning a specific score to that question (the score point).'
        },
        messages: {
          enterName: 'Please enter name',
          selectAnyAutoF: 'Please select a rating for the autofail'
        },
        questionAnswers: 'Questions:'
      }
    },
    registerFromIntegration: {},
    textCommunicationContent: {
      conversationInfo: {
        date: 'Date: ',
        communicationId: 'Chat: ',
        title: 'Topic: ',
        phone: 'Phone number: '
      },
      conversationPart: {
        client: 'Client',
        operator: 'Operator',
        buttons: {
          comment: 'Comment',
          viewComments: 'View comments',
          addComment: 'Add comment'
        },
        emptyComments: 'No comments'
      }
    },
    userPreview: {
      userNotFound: 'User not found',
      userNotInstalled: 'User not set'
    },
    addToCalibrationModal: {
      messages: {
        fileSuccessfullyAddedToGroup: 'File successfully added to the group',
        addFileToGroupFailed: 'Failed to add file to the group',
        communicationGroupSuccessfullyCreated: 'Communications group successfully created',
        communicationGroupCreateFailed: 'Failed to create communications group'
      },
      title: 'Save score for calibration',
      ok: 'Continue',
      cancel: 'Cancel',
      form: {
        fileName: 'File name:',
        callName: 'Call name',
        folderName: 'Choose the name of a folder:',
        folderNamePlaceholder: 'Group of communications name',
        buttons: { addGroup: 'Add group' }
      }
    },
    calibrationSessionStatusTag: { unistalledTag: 'Not set' },
    checklistDefinitionStatusTag: { unistalledTag: 'Not set' },
    checklistHistoryModal: {
      loading: 'Recalling the dynamics...🙄',
      empty: 'No data for a selected period 😕'
    },
    checklistsDefinitionsSelect: { allChecklists: 'All score cards' },
    checklistsList: {
      messages: {
        copySuccessfullyCreated: 'Copy successfully created',
        createCopyFailed: 'Failed to create copy',
        sumPercentageLessThanHundren:
          'The sum of weights of questions in score card should be 100%.',
        emptyQuestions: 'To be published a score card needs to contain questions'
      },
      publishChecklistConfirm: {
        title: 'Publish the score card?',
        description:
          'Once the scoe card has been published, you will not be able to edit: the method and evaluation calculation, the evaluation scale, and the question weights. \n Before publishing, make sure everything is set up correctly.',
        ok: 'Continue',
        cancel: 'Cancel'
      },
      confirmChecklistArchive: {
        title: 'Archive the score card?',
        description:
          'Archived score cards are not available for selection as a score cards for evaluation',
        ok: 'Archive',
        cancel: 'Cancel'
      },
      confirmChecklistDelete: {
        title: 'Are you sure you want to delete score card',
        description: 'This action is irreversible',
        ok: 'Delete',
        cancel: 'Cancel'
      },
      checklistQuestionsStr: ' score card questions',
      menu: {
        publish: 'Publish',
        edit: 'Edit',
        view: 'View',
        settings: 'Go to the score card settings',
        copy: 'Create a copy',
        archive: 'Archive',
        delete: 'Delete score card'
      },
      listColumns: {
        name: 'Name',
        description: 'Description',
        createdAt: 'Date of creation',
        author: 'Author',
        status: 'Status',
        actions: 'Actions'
      },
      loading: 'Loading score cards...😤',
      listLoaded: 'Thats all! 😊'
    },
    colorZones: {
      list: {
        name: 'Name',
        color: 'Color',
        description: 'Description',
        range: 'Score range'
      },
      scores: {
        high: 'Excellent',
        medium: 'Normal',
        low: 'Critical'
      }
    },
    colorZonesBeforePublishModal: {
      title: 'Setting up evaluation zones',
      ok: 'Publish',
      cancel: 'Cancel'
    },
    commentsModal: {
      title: 'Comments',
      empty: 'No comments'
    },
    durationPicker: {
      from: 'From',
      to: 'To'
    },
    questionDetailsModal: {
      ok: 'Continue',
      cancel: 'Cancel',
      loading: 'Recalling the dynamics...🙄 ',
      empty: 'No data for a selected period 😕'
    },
    questionsList: {
      messages: {
        blockedDeleteQuestion:
          'You cannot delete a question added to a published or archived score card.'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete the question?',
        description: 'This action is irreversible.',
        ok: 'Delete',
        cancel: 'Cancel'
      },
      menu: {
        edit: 'Edit question',
        copy: 'Make a copy',
        delete: 'Delete question'
      },
      isNotAddedToChecklist: 'Not added to score cards',
      and: 'besides',
      listColumns: {
        name: 'Name',
        author: 'Author',
        createdAt: 'Date of creation',
        description: 'Description',
        isAddedToChecklist: 'Add to score cards',
        actions: 'Actions'
      },
      loading: 'Loading questions...😤',
      listLoaded: 'Thats all! 😊'
    },
    rangeInputs: {
      from: 'From',
      to: 'To'
    },
    communicationInfoModal: {
      title: 'Additional information',
      chain: "Call's chain",
      ok: 'Continue',
      emptyFieldDescription:
        'We could not find the field description. To avoid errors, please describe the settings of all custom fields.'
    },
    statusSelector: {
      selectStatus: 'Contact status',
      newStatusNamePlaceholder: 'New status type',
      resetStatus: 'Reset contact status',
      addStatus: 'Add',
      blankNameError: `Status name can't be blank`
    },
    timestamp: 'Time:',
    appealHead: {
      sendAppeal: 'Send appeal',
      cancelAppeal: 'Cancel appeal',
      closeAppeal: 'Finish processing',
      editAppeal: 'Edit processing',
      createAppeal: 'Submit an appeal'
    },
    createTask: 'Create task',
    addUsersModal: 'Employees',
    selectUsers: {
      admin: 'Admin',
      selectUsersTooltip1: 'User with this email',
      selectUsersTooltip2:
        'already registered in the system. You can change his email and make it active in the "Team" settings'
    },
    syncBackgroundJob: {
      waitText: 'It may take a few minutes.',
      waitTextDescription: 'You can wait or start using the application right away'
    },
    syncData: { selectSyncPeriod: 'Choose synchronization period' },
    datasetsModal: {
      title: 'Dataset',
      createTitle: 'New Dataset',
      editTitle: 'Edit',
      buttons: {
        save: 'Save',
        update: 'Update',
        cancel: 'Cancel'
      },
      messages: {
        fileText: 'The file',
        successLoad: 'has been uploaded',
        removedLoad: 'deleted',
        successCreate: 'is being created and will soon appear in the table',
        successUpdate: 'updated',
        errorLoad: 'failed to upload',
        uploading: 'Please wait for the file download to finish'
      },
      form: {
        fields: {
          name: 'Title',
          isActive: 'Turn on / Switch off',
          upload: {
            text: 'Click or drag the file to this area to download',
            hint: 'Only the file format is supported: .txt and .pdf'
          }
        },
        placeholder: {
          name: 'Enter a name'
        },
        messages: {
          enterName: 'Please enter the name'
        }
      }
    }
  }
};
