import { get, put, post, destroy } from 'redux-bees';

const unitsEndpoints = apiPath => ({
  getUnits: { method: get, path: `${apiPath}/organization/units` },
  createUnit: { method: post, path: `${apiPath}/organization/units` },
  updateUnit: { method: put, path: `${apiPath}/organization/units/:id` },
  deleteUnit: { method: destroy, path: `${apiPath}/organization/units/:id` }
});

export default unitsEndpoints;
