export default {
  libraryPage: {
    buttons: { createFolder: 'Create folder' },
    libraryItem: {
      emptyName: 'No name given',
      added: 'Folder added:',
      dropdown: {
        edit: 'Edit',
        delete: 'Delete'
      },
      deleteFolderModal: {
        title: 'Are you sure you want to delete folder?',
        description: 'This action is irreversible',
        buttons: {
          cancel: 'Cancel',
          delete: 'Delete'
        }
      },
      deleteItemModal: {
        title: 'The call will be deleted from the library. Shall we continue?',
        buttons: {
          cancel: 'Cancel',
          delete: 'Delete'
        }
      },
      empty: {
        folders: 'No folders added yet',
        calls: 'No calls in this folder'
      }
    },
    modal: {
      title: 'Enter name for a new folder',
      form: { name: 'Name' },
      buttons: {
        cancel: 'Cancel',
        create: 'Сreate'
      }
    },
    messages: {
      folderSuccessfullyCreated: 'Folder successfully created',
      folderCreateFailed: 'Failed to create folder'
    }
  }
};
