import { Col, Input, InputNumber, List, Row, Typography } from 'antd';
import {
  RATING_CALCULATION,
  RATING_MODE,
  SCALE_TYPES,
  ZONE_TO_COLOR_RELATIONS
} from 'core/utils/constants';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import RangeInputs from 'components/Inputs/RangeInputs';
import SRow from 'components/Standard/SRow';

const { Text } = Typography;
const { TextArea } = Input;

export const ColorBox = styled.div`
  width: 64px;
  height: 32px;
  border-radius: 4px;
  background: ${({ color }) => color};
`;

const ColorZones = ({
  col,
  colorZones,
  scaleType = SCALE_TYPES.max_5.type,
  ratingMode = RATING_MODE.NUMBERS,
  ratingCalculation = RATING_CALCULATION.SUM,
  binary = false,
  onChange,
  max = 1,
  min,
  disabledText,
  disabled,
  hideValues = false,
  forceWithoutMedium
}) => {
  // TODO: improve color zones with two
  const zones = isEmpty(colorZones)
    ? get(SCALE_TYPES, [scaleType, 'colorZones'], SCALE_TYPES.max_5.colorZones)
    : colorZones;

  const { t } = useTranslation();

  const withoutMedium =
    binary || ratingMode === RATING_MODE.NUMBERS
      ? !zones.medium ||
        forceWithoutMedium ||
        (ratingCalculation !== RATING_CALCULATION.SUM && max <= 2)
      : false;

  const medium = get(zones, 'medium', zones.low);

  const mediumLow = parseInt(zones.low.stop);
  const highLow = parseInt(medium.stop) || 1;
  const mediumTop = parseInt(highLow - 0.1);
  const lowTop = parseInt(mediumLow - 0.1);

  const onLowChange = ({ to }) => {
    onChange({
      ...zones,
      low: { ...zones.low, stop: to + 1 }
    });
  };

  const onMediumChange = ({ from, to }) => {
    withoutMedium
      ? onLowChange({ to })
      : onChange({
          ...zones,
          medium: { ...medium, stop: to + 1 },
          low: { ...zones.low, stop: from }
        });
  };

  const onHighChange = ({ from }) => {
    onChange({
      ...zones,
      medium: { ...medium, stop: from }
    });
  };

  return (
    <Row className="ant-form-item">
      <Col {...col}>
        <List
          header={
            <Row>
              <Col span={4}>
                <Text strong>{t('components.colorZones.list.name')}</Text>
              </Col>
              <Col span={2}>
                <Text strong>{t('components.colorZones.list.color')}</Text>
              </Col>
              <Col span={12}>
                <Text strong>{t('components.colorZones.list.description')}</Text>
              </Col>
              <Col span={6}>
                <Text strong>{t('components.colorZones.list.range')}</Text>
              </Col>
            </Row>
          }
          dataSource={[
            <SRow type="flex" align="top" width="100%" gutter={[8, 0]}>
              <Col span={4}>{t('components.colorZones.scores.high')}</Col>
              <Col span={2}>
                <ColorBox color={ZONE_TO_COLOR_RELATIONS.high} />
              </Col>
              <Col span={12}>
                <TextArea
                  disabled={disabledText}
                  autoSize={{ minRows: 3 }}
                  value={t(zones.high.text)}
                  onChange={e =>
                    onChange({
                      ...zones,
                      high: { ...zones.high, text: e.target.value }
                    })
                  }
                />
              </Col>
              <Col span={6}>
                {!binary ? (
                  <RangeInputs
                    disabled={disabled}
                    min={min}
                    max={hideValues || disabled ? undefined : max}
                    precision={0}
                    value={
                      hideValues
                        ? {}
                        : {
                            from: highLow,
                            to: max
                          }
                    }
                    onChange={onHighChange}
                  />
                ) : (
                  <InputNumber value={max} disabled />
                )}
              </Col>
            </SRow>,
            <SRow type="flex" align="top" width="100%" gutter={[8, 0]}>
              {!withoutMedium && !binary && (
                <>
                  <Col span={4}>{t('components.colorZones.scores.medium')}</Col>
                  <Col span={2}>
                    <ColorBox color={ZONE_TO_COLOR_RELATIONS.medium} />
                  </Col>
                  <Col span={12}>
                    <TextArea
                      disabled={disabledText}
                      autoSize={{ minRows: 3 }}
                      value={t(medium.text)}
                      onChange={e =>
                        onChange({
                          ...zones,
                          medium: { ...medium, text: e.target.value }
                        })
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <RangeInputs
                      disabled={disabled}
                      min={min}
                      max={hideValues || disabled ? undefined : max}
                      precision={0}
                      value={
                        hideValues
                          ? {}
                          : {
                              from: mediumLow,
                              to: mediumTop
                            }
                      }
                      onChange={onMediumChange}
                    />
                  </Col>
                </>
              )}
            </SRow>,
            <SRow type="flex" align="top" width="100%" gutter={[8, 0]}>
              <Col span={4}>{t('components.colorZones.scores.low')}</Col>
              <Col span={2}>
                <ColorBox color={ZONE_TO_COLOR_RELATIONS.low} />
              </Col>
              <Col span={12}>
                <TextArea
                  disabled={disabledText}
                  autoSize={{ minRows: 3 }}
                  value={t(zones.low.text)}
                  onChange={e =>
                    onChange({
                      ...zones,
                      low: { ...zones.low, text: e.target.value }
                    })
                  }
                />
              </Col>
              <Col span={6}>
                {!binary ? (
                  <RangeInputs
                    disabled={disabled}
                    min={min}
                    max={hideValues || disabled ? undefined : max}
                    precision={0}
                    value={
                      hideValues
                        ? {}
                        : {
                            from: min,
                            to: lowTop
                          }
                    }
                    onChange={onLowChange}
                  />
                ) : (
                  <InputNumber value={min} disabled />
                )}
              </Col>
            </SRow>
          ]}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </Col>
    </Row>
  );
};

export default ColorZones;
