import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  foldersByIds: {},
  itemsByIds: {},
  loading: false,
  errors: []
};

export const loadingFolders = createAction('library/loadingFolders');
export const foldersLoaded = createAction('library/foldersLoaded');

export const creatingFolder = createAction('library/creatingFolder');
export const folderCreated = createAction('library/folderCreated');

export const updatingFolder = createAction('library/updatingFolder');
export const folderUpdated = createAction('library/folderUpdated');

export const libraryItemsLoaded = createAction('library/libraryItemsLoaded');

export const creatingLibraryItem = createAction('library/creatingLibraryItem');
export const libraryItemCreated = createAction('library/libraryItemCreated');

export const deletingLibraryItem = createAction('library/deletingLibraryItem');
export const libraryItemDeleted = createAction('library/libraryItemDeleted');

export const updatingLibraryItem = createAction('library/updatingLibraryItem');
export const libraryItemUpdated = createAction('library/libraryItemUpdated');

export const deletingFolder = createAction('library/deletingFolder');
export const folderDeleted = createAction('library/folderDeleted');

export const catchError = createAction('library/catchError');

export const libraryReducer = createReducer(defaultState, {
  [loadingFolders]: state => {
    return { ...state, loading: true };
  },
  [creatingLibraryItem]: state => ({ ...state, loading: true }),
  [foldersLoaded]: (state, { payload }) => ({
    ...state,
    foldersByIds: payload,
    loading: false
  }),
  [folderCreated]: (state, { payload }) => ({
    ...state,
    foldersByIds: {
      ...state.foldersByIds,
      [payload.id]: payload
    },
    loading: false
  }),
  [folderUpdated]: (state, { payload }) => ({
    ...state,
    foldersByIds: {
      ...state.foldersByIds,
      [payload.id]: payload
    },
    loading: false
  }),
  [libraryItemCreated]: (state, { payload }) => ({
    ...state,
    itemsByIds: { ...state.itemsByIds, [payload.id]: payload },
    loading: false
  }),
  [libraryItemUpdated]: (state, { payload }) => ({
    ...state,
    itemsByIds: {
      ...state.itemsByIds,
      [payload.id]: payload
    }
  }),
  [libraryItemsLoaded]: (state, { payload }) => ({
    ...state,
    itemsByIds: payload,
    loading: false
  }),
  [folderDeleted]: (state, { payload }) => {
    const newFolders = { ...state.foldersByIds };
    delete newFolders[payload];
    return { ...state, foldersByIds: newFolders };
  },
  [libraryItemDeleted]: (state, { payload }) => {
    const newItems = { ...state.itemsByIds };
    delete newItems[payload];
    return { ...state, itemsByIds: newItems };
  },
  [catchError]: (state, { payload }) => ({ ...state, errors: payload, loading: false })
});

export default libraryReducer;
