export const basePosition = 65536;

export const getNewPosition = ({ top, bottom, listBottom, destinationIndex }) => {
  const addingItem = top === listBottom;

  if (destinationIndex === 0) {
    // adding on top strategy
    return bottom / 2;
  }

  if (addingItem) {
    // adding new item / to bottom strategy
    return listBottom + basePosition;
  }

  const newPosition = (top + bottom) / 2;
  return newPosition;
};

export const moveItemInArray = (array, fromIndex, toIndex) => {
  const input = [...array];
  let numberOfDeletedElm = 1;

  const elm = input.splice(fromIndex, numberOfDeletedElm)[0];

  numberOfDeletedElm = 0;

  input.splice(toIndex, numberOfDeletedElm, elm);
  return input;
};
