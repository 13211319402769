import React from 'react';
import { Input, message } from 'antd';
import { connect } from 'react-redux';
import {
  setNewFolderName,
  closeCreateNewFolderModal,
  openCreateNewFolderModal
} from 'redux/ui/libraryPage/reducer';
import { reviewLibrariesResource } from 'redux/resources/reviewLibraries';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import SButton from 'components/Standard/SButton';
import SModal from 'components/Standard/SModal';
import { WidgetTitle } from 'components/WidgetTitle';

const CreateFolder = ({
  name,
  changeName,
  openModal,
  onCancel,
  visible,
  loading,
  createFolder
}) => {
  const { t } = useTranslation();

  const onSave = async () => {
    const newFolder = await createFolder({ name });
    if (newFolder) {
      message.success(t('libraryPage.messages.folderSuccessfullyCreated'));
      onCancel();
      return;
    }
    message.error(t('libraryPage.messages.folderCreateFailed'));
  };

  const ConditionalCreateFolderButton = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MANAGE_LIBRARY] },
    SButton
  );

  return (
    <>
      <ConditionalCreateFolderButton type="primary" onClick={openModal}>
        {t('libraryPage.buttons.createFolder')}
      </ConditionalCreateFolderButton>
      <SModal
        title={<WidgetTitle>{t('libraryPage.modal.title')}</WidgetTitle>}
        size="big"
        visible={visible}
        bodyStyle={{ padding: '24px' }}
        okText={t('libraryPage.modal.buttons.create')}
        cancelText={t('libraryPage.modal.buttons.cancel')}
        onOk={onSave}
        okButtonProps={{ disabled: loading || !name || !name.trim() }}
        cancelButtonProps={{ type: 'danger' }}
        onCancel={onCancel}
      >
        <Input
          placeholder={t('libraryPage.modal.form.name')}
          value={name}
          onChange={e => changeName(e.target.value)}
        />
      </SModal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    name: state.uiLibraryPage.newFolderName,
    visible: state.uiLibraryPage.showCreateNewFolderModal,
    loading: state.library.loading
  };
};

const mapDispatchToProps = {
  changeName: setNewFolderName,
  onCancel: closeCreateNewFolderModal,
  openModal: openCreateNewFolderModal,
  createFolder: reviewLibrariesResource.operations.create
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFolder);
