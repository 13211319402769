export default {
  backgroundJob: {
    title: "L'applicazione viene installata e il database sincronizzato",
    description: 'Potrebbero essere necessari alcuni minuti',
    messages: { error: 'Errore. Riprova' },
    status: {
      process: 'Nel corso di'
    }
  }
};
