import React, { useState } from 'react';
import { Button, Col, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CUSTOM_FIELDS_TYPES, DATE_PICKER_MODES } from 'core/utils/constants';
import { find, isEmpty, isNil, some, sortBy, uniqBy } from 'lodash';
import { selectSearch } from 'core/utils/selectSearch';
import { getCustomFieldsByIds } from 'redux/selectors/customFields';
import { actions } from 'redux/lists/clientInteractionsList';
import { connect } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import SRow from '../../../components/Standard/SRow';
import { FilterContainer, StyledSelect } from '../../ClientInteractionsPage/components/styled';
import SCol from '../../../components/Standard/SCol';
import FilterName from '../../ClientInteractionsPage/components/FilterName';
import DurationPicker from '../../../components/Inputs/DurationPicker';
import RangeInputs from '../../../components/Inputs/RangeInputs';
import CustomDatePicker from '../../../components/DatePicker/DatePicker';

const { Option } = Select;

const CustomFields = ({
  customFieldFilters,
  updateCustomFieldFilters,
  customFieldsByIds,
  customFields,
  isEditingData,
  deleteCustomFieldFilters,
  disableEditBtn
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(!isEditingData);
  const [searchCustomFields, setSearchCustomFields] = useState('');
  const handleSearchChangeCustomFields = e => {
    setSearchCustomFields(e.target.value.toLowerCase());
  };

  console.log('customFieldFilters', customFieldFilters)

  const renderCustomFieldFilter = ({
    id,
    fieldType,
    key,
    name,
    options,
    dependsOnId,
    settings
  }) => {
    const getComponent = () => {
      if (fieldType === CUSTOM_FIELDS_TYPES.DURATION) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <DurationPicker
              from={customFieldFilters[key]?.from}
              to={customFieldFilters[key]?.to}
              disabled={false}
              size="medium"
              style={{ width: '100%' }}
              allowClear
              onChange={({ from, to }) => {
                updateCustomFieldFilters({
                  [key]: {
                    from: isNil(from) ? undefined : `${from}`,
                    to: isNil(to) ? undefined : `${to}`
                  }
                });
              }}
            />
          </>
        );
      }
      if (fieldType === CUSTOM_FIELDS_TYPES.BOOLEAN) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <StyledSelect
              disabled={false}
              showSearch
              allowClear
              optionLabelProp="label"
              placeholder={name}
              value={customFieldFilters[key]?.eq || undefined}
              onChange={value => updateCustomFieldFilters({ [key]: { eq: value } })}
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {[
                { label: t('general.yes'), value: 'true' },
                { label: t('general.no'), value: 'false' }
              ].map(({ label, value }) => (
                <Option key={label} value={value} label={label}>
                  {label}
                </Option>
              ))}
            </StyledSelect>
          </>
        );
      }

      if (
        fieldType === CUSTOM_FIELDS_TYPES.STRING ||
        (fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY && isEmpty(options))
      ) {
        return (
          <div>
            <Input
              disabled={false}
              value={customFieldFilters[key]?.contains || undefined}
              onChange={e => updateCustomFieldFilters({ [key]: { contains: e.target.value } })}
              placeholder={name}
            />
          </div>
        );
      }

      if (
        fieldType === CUSTOM_FIELDS_TYPES.NUMBER ||
        fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY
      ) {
        return (
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Col style={{ width: '90%' }}>
              <FilterName>{name}</FilterName>
              <RangeInputs
                disabled={false}
                value={customFieldFilters[key]}
                precision={0}
                onChange={({ from, to }) => updateCustomFieldFilters({ [key]: { from, to } })}
              />
            </Col>
            <Col>
              <DeleteOutlined
                style={{
                  color: 'red',
                  marginLeft: '8px',
                  fontSize: '20px',
                  cursor: isEditingData && disableEditBtn ? 'not-allowed' : 'pointer'
                }}
                onClick={() =>
                  isEditingData && disableEditBtn ? console.log('') : deleteCustomFieldFilters(key)
                }
              />
            </Col>
          </Row>
        );
      }
      if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <CustomDatePicker
              onChange={value => updateCustomFieldFilters({ [key]: value })}
              value={customFieldFilters[key] || {}}
              mode={DATE_PICKER_MODES.custom.customFields}
              isClientInteractionPage
              allowClear
              // loading={disabled}
              disabled={false}
            />
          </>
        );
      }

      if (!isEmpty(settings)) {
        // id, fieldType, key, name, options, dependsOnId, settings
        const parentKey = customFieldsByIds[dependsOnId]?.key;
        const parentSelectedValue = customFieldFilters[parentKey]?.eq;

        // * allow options on parent key or allow all
        const options = parentSelectedValue
          ? settings.filter(({ parentKey }) => parentKey === parentSelectedValue)
          : uniqBy(settings, 'key');

        const setControlledValueToUndefined = () => {
          if (customFieldFilters[key]?.eq) {
            updateCustomFieldFilters({ [key]: { eq: undefined } });
          }

          const childKey = find(customFieldsByIds, field => field.dependsOnId === id)?.key;
          if (childKey && customFieldFilters[childKey]?.eq) {
            updateCustomFieldFilters({ [childKey]: { eq: undefined } });
          }

          return undefined;
        };

        // * if value should be controlled - check if that option exists. if not - set to undefined
        const controlledValue =
          some(options, ({ key: allowedKey }) => allowedKey === customFieldFilters[key]?.eq) ||
          !parentKey
            ? customFieldFilters[key]?.eq
            : undefined;

        if (controlledValue !== customFieldFilters[key]?.eq) {
          setControlledValueToUndefined();
        }

        return (
          <StyledSelect
            disabled={false}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={parentSelectedValue ? controlledValue : customFieldFilters[key]?.eq || undefined}
            onChange={value => updateCustomFieldFilters({ [key]: { eq: value } })}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {sortBy(options, ['text']).map(({ key, text }) => (
              <Option key={key} value={key} label={text}>
                {text}
              </Option>
            ))}
          </StyledSelect>
        );
      }

      if (!isEmpty(options)) {
        const compareType = fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY ? 'contains' : 'eq';
        return (
          <StyledSelect
            disabled={false}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={customFieldFilters[key]?.[compareType] || undefined}
            onChange={value => updateCustomFieldFilters({ [key]: { [compareType]: value } })}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {options.map(option => (
              <Option key={option} value={option} label={option}>
                {option}
              </Option>
            ))}
          </StyledSelect>
        );
      }
    };

    return <FilterContainer key={id}>{getComponent()}</FilterContainer>;
  };

  const { otherCustomFields, amoCustomFields } = customFields.reduce(
    (acc, field) => {
      if (field?.entityType) {
        return { ...acc, amoCustomFields: [...acc.amoCustomFields, field] };
      }

      return { ...acc, otherCustomFields: [...acc.otherCustomFields, field] };
    },
    { otherCustomFields: [], amoCustomFields: [] }
  );

  const combinedFields = [...otherCustomFields, ...amoCustomFields];

  // Собираем кастомные поля которые выбраны в фильтрах задачи
  const selectedCustomFields = combinedFields.reduce((acc, currentField) => {
    if (currentField.key in customFieldFilters) {
      acc.push(currentField);
    }
    return acc;
  }, []);

  console.log('selectedCustomFields', selectedCustomFields)

  // Если режим редактирования берём кастомные поля из фильтра задачи, в противном случае все кастомные поля
  const filteredCustomFields = isEditingData ? selectedCustomFields : combinedFields;

  console.log('filteredCustomFields', filteredCustomFields)
  console.log('combinedFields', combinedFields)

  const filteredCustomFieldsKeys = filteredCustomFields.map(field => field.key);
  // Кастомные поля без полей которые есть в фильтре задачи
  const filteredCustomFieldsViewAll = combinedFields.filter(
    customField => !filteredCustomFieldsKeys.includes(customField.key)
  );

  console.log('filteredCustomFieldsViewAll', filteredCustomFieldsViewAll)

  const sortOrderCustomFields = {
    [CUSTOM_FIELDS_TYPES.NUMBER]: 1,
    [CUSTOM_FIELDS_TYPES.NUMBER_ARRAY]: 2,
    [CUSTOM_FIELDS_TYPES.BOOLEAN]: 3,
    [CUSTOM_FIELDS_TYPES.DATETIME]: 4,
    [CUSTOM_FIELDS_TYPES.DURATION]: 5,
    [CUSTOM_FIELDS_TYPES.STRING_URL]: 6,
    [CUSTOM_FIELDS_TYPES.STRING]: 7,
    [CUSTOM_FIELDS_TYPES.STRING_ARRAY]: 8
  };
  const getSortWeightCustomFields = type => sortOrderCustomFields[type] || 10;

  const sortCustomFields = (a, b) => {
    const weightA = getSortWeightCustomFields(a.fieldType);
    const weightB = getSortWeightCustomFields(b.fieldType);

    return weightA - weightB;
  };

  const renderCustomFields = () => {
    if (isEditingData && !visible) {
      return filteredCustomFields
        .filter(field => field.name.toLowerCase().includes(searchCustomFields))
        .sort(sortCustomFields)
        .map(renderCustomFieldFilter);
    }
    if (isEditingData && visible) {
      return (
        <>
          {/* Отображаем кастомные поля которые выбраны в фильтрах задачи */}
          {filteredCustomFields
            .filter(field => field.name.toLowerCase().includes(searchCustomFields))
            .sort(sortCustomFields)
            .map(renderCustomFieldFilter)}
          {/* Отображаем все остальные кастмые поля */}
          {filteredCustomFieldsViewAll
            .filter(field => field.name.toLowerCase().includes(searchCustomFields))
            .sort(sortCustomFields)
            .map(renderCustomFieldFilter)}
        </>
      );
    }
    if (!isEditingData && visible) {
      return filteredCustomFields
        .filter(field => field.name.toLowerCase().includes(searchCustomFields))
        .sort(sortCustomFields)
        .map(renderCustomFieldFilter);
    }
    if (!isEditingData && !visible && !isEmpty(selectedCustomFields)) {
      return selectedCustomFields
        .filter(field => field.name.toLowerCase().includes(searchCustomFields))
        .sort(sortCustomFields)
        .map(renderCustomFieldFilter);
    }
  };

  return (
    <SCol>
      <SRow>
        <Col span={24}>
          <Input
            placeholder={t('clientInteractionsPage.tableFilters.search.search')}
            value={searchCustomFields}
            onChange={handleSearchChangeCustomFields}
          />
        </Col>
        <Col span={24}>
          <Button style={{ marginTop: '5px' }} type="default" onClick={() => setVisible(!visible)}>
            {visible ? 'Скрыть поля' : 'Показать все поля'}
          </Button>
          {renderCustomFields()}
        </Col>
      </SRow>
    </SCol>
  );
};

const mapStateToProps = state => {
  const { customFieldFilters } = state.clientInteractionsList;
  const customFieldsByIds = getCustomFieldsByIds(state, true);

  return {
    customFieldsByIds,
    customFieldFilters,
    customFields: Object.values(customFieldsByIds)
      .filter(customField => customField?.usedForFilters)
      .sort((a, b) => {
        if (a.id === b?.dependsOnId) {
          return -1;
        }

        if (b.id === a?.dependsOnId) {
          return 1;
        }

        return 0;
      })
  };
};

const mapDispatchToProps = {
  updateCustomFieldFilters: actions.updateCustomFieldFilters,
  deleteCustomFieldFilters: actions.deleteCustomFieldFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
