import { TimePicker } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledTimePicker = styled(TimePicker)`
  &.ant-time-picker .ant-time-picker-input[disabled] {
    color: gray;
  }
`;

const SecondsSpecificSelector = ({ changeOnSelect, ...props }) => {
  const onSelect = changeOnSelect ? props.onChange : props.onSelect;
  return <StyledTimePicker {...{ ...props, onSelect }} />;
};

export default SecondsSpecificSelector;
