import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { useParams } from 'react-router-dom';
import SText from 'components/Standard/SText';
import moment from 'moment';
import { get, isEqual } from 'lodash';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import { getUserName } from 'components/UserPreview/getUserName';

const TaskItemsInfo = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const { totalCount } = useSelector(state => state.clientInteractionsList);

  const {
    id: currentConfigurationId,
    task_id: currentTaskId,
    assignment_id: currentAssignmentId
  } = useParams();

  const tasksByIds = useSelector(state => state.workPlanTasksResource.byIds, isEqual);

  const currentTask = tasksByIds[currentTaskId] || {};

  const workPlanTaskConfigurationsByIds = useSelector(
    state => state.workPlanTaskConfigurationsResource.byIds,
    isEqual
  );

  const currentConfiguration = get(workPlanTaskConfigurationsByIds, currentConfigurationId, {});

  const usersByIds = useSelector(state => state.usersResource.byIds);

  const assignmetnsByIds = useSelector(
    state => state.workPlanTaskAssignmentsResource.byIds,
    isEqual
  );

  const currentAssignment = assignmetnsByIds[currentAssignmentId];

  return (
    <SCard rounded={false}>
      <SRow type="flex" align="middle" gutter={[16, 0]}>
        <SCol>
          <Title level={5}>{currentConfiguration?.name}</Title>
        </SCol>
        <SCol>
          <SText type="secondary">{`${t('general.reviewer')}: `}</SText>
          <SText>{getUserName({ user: usersByIds[currentAssignment?.reviewerId] })}</SText>
        </SCol>
        <SCol>
          <SText type="secondary">{`${t('workPlanTasks.assignments.taskStartedAt')}: `}</SText>
          <SText>{moment(currentTask?.timeFrom).format('DD/MM/YYYY')}</SText>
        </SCol>
        <SCol>
          <SText type="secondary">
            {`${t('workPlanTasks.assignments.completedTaskItemsCount')}: `}
          </SText>
          <SText>{totalCount}</SText>
        </SCol>
        <SCol>
          <SText type="secondary">{`${t('general.status')}: `}</SText>
          <SText color={WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[currentTask?.status]?.color}>
            {t(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[currentTask?.status]?.title)}
          </SText>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default TaskItemsInfo;
