import createListReducer from 'core/redux/lists/listReducer';
import { datasetsBindingResource } from 'redux/resources/datasetBinding';

export const listParams = {
  name: 'datasetsBinding',
  loader: datasetsBindingResource.operations.load,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const datasetsBindingReducer = reducer;

export { actions, datasetsBindingReducer, operations };
