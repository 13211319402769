import camelcase from 'camelcase';
import { assignChildrenToParents } from 'core/utils';
import {
  difference,
  find,
  get,
  isEmpty,
  isEqual,
  isNil,
  keyBy,
  mapValues,
  omit,
  some
} from 'lodash';
import { phoneCallsResource } from 'redux/resources/calls';
import { checklistsResource } from 'redux/resources/checklist';
import { commentsResource } from 'redux/resources/comments';
import { reviewsResource } from 'redux/resources/reviews';
import { tasksResource } from 'redux/resources/tasks';
import { getReviewTaskDefinitionsIds } from 'redux/selectors/taskDefinitions';
import * as checklistManagerActions from 'redux/ui/checklistManager/reducer';
import { addTasks } from 'redux/ui/clientInteractionPage/reducer';
import {
  clearAddingCommentsMassAction,
  setAddingCommentsToQuestionId
} from 'redux/ui/checklistManager/reducer';
import { updateTableRow } from '../clientInteractions/operations';
import * as actions from './reducer';
import { appellationsResource } from '../../resources/appellations';
import createReviewWithNestedAttributes from './createReviewWithNestedAttributes';
import { clearComments, clearCommentsToSave, startClear } from '../checklistEditor/reducer';

export const mapReviewToUiReducer = ({ state, review }) => {
  const getChecklistWithAnswers = ({ state, checklistId }) => {
    const checklist = state.checklistsResource.byIds[checklistId];

    if (!isEmpty(checklist)) {
      const questionIdToAnswerValue = mapValues(
        keyBy(
          checklist.answersIds.map(id => state.checklistAnswersResource.byIds[id]),
          'questionId'
        ),
        'value'
      );

      const questionIdToStandardComment = mapValues(
        keyBy(
          checklist.answersIds.map(id => state.checklistAnswersResource.byIds[id]),
          'questionId'
        ),
        'standardComments'
      );

      return { checklist, questionIdToAnswerValue, questionIdToStandardComment };
    }

    return { checklist: {}, questionIdToAnswerValue: {}, questionIdToStandardComment: {} };
  };

  const {
    checklist,
    questionIdToAnswerValue,
    questionIdToStandardComment
  } = getChecklistWithAnswers({
    state,
    checklistId: review.checklistId
  });
  return { checklist, questionIdToAnswerValue, questionIdToStandardComment };
};

const createCommentFromUi = commentFromUi => async dispatch => {
  const comment = await dispatch(commentsResource.operations.create(commentFromUi));
  await dispatch(actions.deleteComment(commentFromUi));
  await dispatch(actions.addComment(comment));
};

const isCommentsEqual = (realComment, uiComment) => {
  if (realComment.commentType === 'review_comment')
    return isEqual(
      omit(realComment, ['createdAt', 'updatedAt']),
      omit(uiComment, ['createdAt', 'updatedAt'])
    );

  // * exclude position comparison for other comments
  return isEqual(
    omit(realComment, ['position', 'createdAt', 'updatedAt']),
    omit(uiComment, ['position', 'createdAt', 'updatedAt'])
  );
};

export const updateComments = ({
  currentChecklist,
  reviewId,
  commentsByIds,
  commentsToSave = null
}) => async (dispatch, getState) => {
  const state = getState();
  const review = state.reviewsResource.byIds[reviewId];

  const oldComments = review.commentsIds.reduce(
    (acc, id) =>
      state.commentsResource.byIds[id] ? [...acc, state.commentsResource.byIds[id]] : acc,
    []
  );

  const newCommentsByIds = { ...commentsByIds, ...commentsToSave };

  // * running all requests in parallel
  await Promise.all([
    ...oldComments.map(comment => {
      // * delete old comments that deleted on ui
      if (isEmpty(newCommentsByIds[comment.id])) {
        delete newCommentsByIds[comment.id];
        return dispatch(commentsResource.operations.deleteById({ id: comment.id }));
      }

      // * update old comments that updated on ui
      const newComment = { ...comment, ...newCommentsByIds[comment.id] };
      delete newCommentsByIds[comment.id];
      // * check if comment needs update
      if (!isCommentsEqual(comment, newComment)) {
        return dispatch(commentsResource.operations.updateById(omit(newComment, 'ratingFlag')));
      }
    }),
    // * create new comments (should wokr only for Review Comments, replies are sent to BE instantly)
    ...Object.values(newCommentsByIds)
      .filter(comment => comment?.commentType === 'review_comment')
      .map(comment =>
        dispatch(
          createCommentFromUi({
            ...comment,
            reviewId,
            // установка связи комментария с чеклистом где он был создан
            checklistDefinitionId: currentChecklist.checklistDefinitionId ?? null
          })
        )
      )
  ]);
  await dispatch(clearCommentsToSave());
  await dispatch(clearComments());
  await dispatch(setAddingCommentsToQuestionId(null));
  await dispatch(clearAddingCommentsMassAction());
  await dispatch(startClear(false));
};

export const createTasks = ({ reviewId }) => async (dispatch, getState) => {
  const state = getState();
  const tasksByIds = state.tasksResource.byIds;
  const currentReviewTasks = state.reviewsResource.byIds[reviewId]?.tasksIds;
  const currentReviewTaskDefifnitions = currentReviewTasks.map(
    task => tasksByIds[task].taskDefinitionId
  );
  const uiTaskDefinitionsIds = get(state.uiClientInteractionPage, 'tasks.taskDefinitionsIds', []);
  const taskDefinitionsToDelete = difference(currentReviewTaskDefifnitions, uiTaskDefinitionsIds);

  const tasksToDelete = taskDefinitionsToDelete.map(
    taskDefinition => find(tasksByIds, { reviewId, taskDefinitionId: taskDefinition }).id
  );
  const tasksDefinitionsToCreate = uiTaskDefinitionsIds.filter(
    taskDefinitionId => !currentReviewTaskDefifnitions.includes(taskDefinitionId)
  );

  if (!isEmpty(tasksToDelete)) {
    await Promise.all([
      tasksToDelete.map(taskId => dispatch(tasksResource.operations.deleteById({ id: taskId })))
    ]);
  }

  if (!isEmpty(tasksDefinitionsToCreate)) {
    await Promise.all([
      tasksDefinitionsToCreate.map(taskDefinitionId =>
        dispatch(tasksResource.operations.create({ reviewId, taskDefinitionId }))
      )
    ]);
  }
};

export const loadReviewById = ({ id, shouldLoad = true, ...params }) => {
  return async (dispatch, getState) => {
    await dispatch(actions.setLoading(true));
    const review = await dispatch(reviewsResource.operations.loadById({ id, ...params }));

    if (!review) {
      return;
    }

    const state = getState();
    const clientInteraction = state.clientInteractionsResource.byIds[review.clientInteractionId];
    dispatch(actions.setContentType(camelcase(clientInteraction.clientInteractionType)));
    dispatch(checklistManagerActions.setReviewState(mapReviewToUiReducer({ state, review })));
    const reviewComments = keyBy(
      review.commentsIds.map(id => state.commentsResource.byIds[id]),
      'id'
    );

    dispatch(actions.setOperatorId(review?.operatorId || clientInteraction?.operatorId));
    dispatch(actions.setComments(assignChildrenToParents({ nodesByIds: reviewComments })));
    dispatch(actions.setLoading(false));
    return review;
  };
};

export const loadAppealReviewById = ({ id, shouldLoad = true, ...params }) => {
  return async (dispatch, getState) => {
    await dispatch(actions.setLoading(true));
    const appellation = await dispatch(appellationsResource.operations.loadById({ id, ...params }));

    if (!appellation) {
      return;
    }

    const state = getState();

    const review = state.reviewsResource.byIds[appellation.reviewId];
    const clientInteraction = state.clientInteractionsResource.byIds[review.clientInteractionId];
    dispatch(actions.setContentType(camelcase(clientInteraction.clientInteractionType)));
    dispatch(checklistManagerActions.setReviewState(mapReviewToUiReducer({ state, review })));
    const reviewComments = keyBy(
      [...review.commentsIds, ...appellation.commentsIds].map(
        id => state.commentsResource.byIds[id]
      ),
      'id'
    );

    dispatch(actions.setOperatorId(appellation?.operatorId || clientInteraction?.operatorId));
    dispatch(actions.setComments(assignChildrenToParents({ nodesByIds: reviewComments })));
    dispatch(actions.setLoading(false));
    return review;
  };
};

export const submitReview = ({
  id,
  review,
  fromDrawer = false,
  customCommunicationAttributes,
  questionsWithValuesAndBindings,
  paramsId = null
}) => {
  // * createReview
  if (isEmpty(review)) {
    return async dispatch => {
      const createdReview = await dispatch(
        createReviewWithNestedAttributes({
          clientInteractionId: id,
          customCommunicationAttributes,
          questionsWithValuesAndBindings,
          currentCalibrationSessionId: paramsId
        })
      );

      if (get(createdReview, 'body.data.type') === 'reviews') {
        const createdReviewId = get(createdReview, 'body.data.id');
        return createdReviewId;
      }
    };
  }

  // * updateReview
  if (!isEmpty(review)) {
    return async (dispatch, getState) => {
      const reviewId = id;
      let state = getState();
      const {
        currentChecklist,
        questionIdToAnswerValue,
        questionIdToStandardComment
      } = state.uiChecklistManager;
      const { commentsByIds } = state.uiClientInteractionPage;
      const { commentsToSave } = state.uiChecklistEditor;
      dispatch(startClear(true));

      const answers = Object.keys(questionIdToAnswerValue).reduce((result, questionId) => {
        const value = questionIdToAnswerValue[questionId];
        const standardComments = questionIdToStandardComment[questionId];

        if (isNil(value)) {
          return result;
        }

        return [...result, { questionId, value, standardComments }];
      }, []);

      // Добавляем критерии без балла в массив answers
      questionsWithValuesAndBindings.forEach(question => {
        if (answers.find(item => item.questionId === question.id)) {
          return;
        }
        answers.push({
          question_id: question.id,
          standard_comments:
            Object.values(question.standardComments).length === 0
              ? undefined
              : question.standardComments,
          value: null
        });
      });

      const checklist = currentChecklist
        ? {
            id: currentChecklist.id,
            checklistDefinitionId: currentChecklist.checklistDefinitionId,
            answers,
            comment: currentChecklist.comment,
            metadata: currentChecklist.metadata || {}
          }
        : {};

      await dispatch(updateComments({ currentChecklist, commentsByIds, reviewId, commentsToSave }));

      await dispatch(createTasks({ reviewId }));

      const shouldCreateChecklist =
        checklist.checklistDefinitionId &&
        !isEmpty(questionIdToAnswerValue) &&
        some(questionIdToAnswerValue, value => !isNil(value));

      let newChecklist = {};

      if (shouldCreateChecklist) {
        state = getState();

        newChecklist = await dispatch(
          checklistsResource.operations.updateById({
            ...checklist,
            id: checklist.id || state.reviewsResource.byIds[reviewId].checklistId
          })
        );

        state = getState();
        const reviewTaskDefinitionsIds = getReviewTaskDefinitionsIds(state, reviewId);
        await dispatch(addTasks({ reviewId, taskDefinitionsIds: reviewTaskDefinitionsIds }));
      }

      await dispatch(checklistManagerActions.setReviewState({ checklist: newChecklist }));

      fromDrawer &&
        (await dispatch(
          updateTableRow({
            clientInteractionId: review?.clientInteractionId,
            review
          })
        ));

      return reviewId;
    };
  }
};

export const loadCallById = ({ id, fromDrawer, ...params }) => async dispatch => {
  dispatch(actions.setLoading(true));
  dispatch(checklistManagerActions.setLoading(true));

  const call = await dispatch(phoneCallsResource.operations.loadById({ id, ...params }));

  dispatch(actions.setOperatorId(call?.operatorId));

  dispatch(actions.setLoading(false));
  dispatch(checklistManagerActions.setLoading(false));

  return call;
};

export const updateReplyComment = ({ id, ...params }) => async dispatch => {
  const comment = await dispatch(commentsResource.operations.updateById({ id, ...params }));
  await dispatch(actions.updateComment(comment));
  return comment;
};

export const deleteReplyComment = ({ id }) => async (dispatch, getState) => {
  await dispatch(commentsResource.operations.deleteById({ id }));
  const state = getState();
  const comment = get(state.uiClientInteractionPage.commentsByIds, id, {});
  const parentComment = state.uiClientInteractionPage.commentsByIds[comment.parentId];
  if (parentComment)
    await dispatch(
      actions.updateComment({
        ...parentComment,
        childrenIds: [...parentComment.childrenIds.filter(relationId => relationId !== id)]
      })
    );
  await dispatch(actions.deleteComment({ id }));
};

export const createReplyComment = params => async (dispatch, getState) => {
  const state = getState();
  const parentComment = state.uiClientInteractionPage.commentsByIds[params.id];
  const questionId = get(parentComment, ['metadata', 'questionId']);
  const comment = await dispatch(
    commentsResource.operations.createCommentReply({
      ...params,
      ...(questionId && { metadata: { questionId } }),
      parentComment
    })
  );

  if (comment) {
    dispatch(actions.addComment(comment));
  }

  if (parentComment && comment)
    await dispatch(
      actions.updateComment({
        ...parentComment,
        childrenIds: [...parentComment.childrenIds, comment.id]
      })
    );
  return comment;
};
