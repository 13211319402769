export default {
  autoFailScoresPage: {
    buttonAddReport: {
      dynamicCriticalErrors: {
        header: 'Динамика критических ошибок',
        description:
          'Показывает кол-во коммуникаций, кол-во и процент проверок, кол-во и процент критичных ошибок в динамике. Есть два варианта отображения: таблица и график.'
      },
      criticalErrorsCriteria: {
        header: 'Критерии с критичными ошибками',
        description:
          'Показывает процент критичных ошибок по критериям. Есть два варианта отображения: сотрудники и отделы. В отчете отображаются только те критерии, по которым в форме оценок настроены критические ошибки.'
      }
    }
  }
};
