import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import React from 'react';
import Icon from './Icon';

export const Info = ({
  tooltip = {},
  popover = null,
  iconProps,
  disabled = false,
  icon = InfoCircleOutlined
}) => {
  if (disabled) {
    return <Icon icon={icon} {...iconProps} opacity=".5" />;
  }

  if (popover) {
    return (
      <Popover {...popover}>
        <Icon icon={icon} {...iconProps} />
      </Popover>
    );
  }

  return (
    <Tooltip {...tooltip}>
      <span>
        <Icon icon={icon} {...iconProps} />
      </span>
    </Tooltip>
  );
};
