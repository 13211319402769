import { ChevronDown, ChevronRight, Edit, MoreVertical, Trash2 } from 'react-feather';
import { Col, Dropdown, Menu, Modal, Row } from 'antd';
import { CHECKLIST_DEFINITION_STATUS, RATING_METHOD } from 'core/utils/constants';
import { basePosition } from 'core/utils/dnd';
import { usePrevious } from 'core/utils/hooks';
import { calculateQuestions } from 'core/utils/ratingsCalculations';
import { find, get, isEmpty, isEqual, max, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { questionGroupsResource } from 'redux/resources/questionGroups';
import {
  getChecklistDefinitionBindings,
  getQuestionGroupBindings,
  getQuestionGroupBindingsIds,
  getQuestionGroupQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { setEditableChecklistDefinition } from 'redux/ui/checklistEditor/operations';
import {
  setAddingQuestionToGroup,
  setEditableGroup,
  updateSortedQuestionGroupBindings
} from 'redux/ui/checklistEditor/reducer';
import uniqid from 'uniqid';
import Icon from 'components/Icon';
import LabelWithInfo from 'components/LabelWithInfo';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import Question from './Question';
import {
  MarginLeftButton,
  QestionGroupCard,
  StyledButton,
  StyledInput,
  ValueCol,
  ValuesContainer
} from './styled';

const { confirm } = Modal;

const QuestionGroup = ({
  sumRates,
  sumPercentage,
  questionGroup,
  currentChecklist,
  provided,
  questionGroupBindings = [],
  questionGroupBindingsIds = [],
  setEditableChecklistDefinition,
  setEditableGroup,
  deleteQuestionGroup,
  setAddingQuestionToGroup,
  listBottom,
  sortedBindings = [],
  updateSortedQuestionGroupBindings,
  loading,
  isDragging,
  isDraggingGlobal,
  isDraggingOver,
  totalPercentage,
  updateCurrentChecklist
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const prevQuestionGroupBindingsIds = usePrevious(questionGroupBindingsIds);

  useEffect(() => {
    if (!loading && !isEqual(prevQuestionGroupBindingsIds, questionGroupBindingsIds)) {
      updateSortedQuestionGroupBindings({
        questionGroupId: questionGroup.id,
        bindings: questionGroupBindings
      });
    }
  }, [questionGroupBindingsIds]);

  const id = get(questionGroup, 'id', '');
  const name = get(questionGroup, 'name', '');
  const ratingMethod = get(
    currentChecklist,
    'ratingMethod',
    t('checklistsPage.questionGroup.ratingMethod')
  );
  const status = get(currentChecklist, 'status', 'archived');

  const onAddQuestion = () => {
    setAddingQuestionToGroup({ questionGroup });
  };

  const onAddQuestionGroup = () => {
    setEditableGroup({ id: uniqid() });
  };

  const deleteGroupOrHideRootGroup = async ({ id, currentChecklist }) => {
    if (currentChecklist.isGroupable && currentChecklist.questionGroupsIds.length === 1) {
      return updateCurrentChecklist({ id: currentChecklist.id, isGroupable: false }).then(r => {
        setCollapsed(false);
        return r;
      });
    }
    return deleteQuestionGroup({ id });
  };

  const handlMenuClick = e => {
    switch (e.key) {
      case 'edit':
        setEditableGroup(questionGroup);
        return;

      case 'delete':
        return confirm({
          okText: t('checklistsPage.questionGroup.confirmDelete.ok'),
          cancelText: t('checklistsPage.questionGroup.confirmDelete.cancel'),
          title: t('checklistsPage.questionGroup.confirmDelete.title'),
          content:
            currentChecklist.questionGroupsIds.length !== 1
              ? t('checklistsPage.questionGroup.confirmDelete.description')
              : null,
          okType: 'danger',
          onOk: async () => {
            await deleteGroupOrHideRootGroup({ id, currentChecklist });
            setEditableChecklistDefinition({ id: get(currentChecklist, 'id', '') });
          }
        });

      default:
        console.log();
    }
  };

  const menu = (
    <Menu onClick={e => handlMenuClick(e)}>
      <Menu.Item key="edit">
        <Icon icon={Edit} />
        <span>{t('checklistsPage.questionGroup.menu.edit')}</span>
      </Menu.Item>
      {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('checklistsPage.questionGroup.menu.delete')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div {...provided.droppableProps} ref={provided.innerRef}>
      <Col>
        {currentChecklist.isGroupable && (
          <QestionGroupCard
            onClick={() => setCollapsed(!collapsed)}
            isDraggingOver={isDraggingOver}
          >
            <Row type="flex" align="middle">
              <SCol flex="auto" wrap={false}>
                <Icon icon={collapsed ? ChevronRight : ChevronDown} style={{ marginRight: 8 }} />
                <SText ellipsis strong title={name}>
                  {name}
                </SText>
              </SCol>
              <ValuesContainer flex="none">
                <ValueCol wrap={false}>
                  <LabelWithInfo infoTitle={t('checklistsPage.questionGroup.sumRatesInfo')}>
                    {t('checklistsPage.questionGroup.sumRates')}
                  </LabelWithInfo>
                  <StyledInput
                    disabled={status !== CHECKLIST_DEFINITION_STATUS.DRAFT.value}
                    value={sumRates}
                    onClick={e => e.stopPropagation()}
                  />
                </ValueCol>

                {ratingMethod === RATING_METHOD.WEIGHTED && (
                  <ValueCol>
                    <LabelWithInfo infoTitle={t('checklistsPage.questionGroup.sumPercentageInfo')}>
                      {t('checklistsPage.questionGroup.sumPercentage')}
                    </LabelWithInfo>
                    <StyledInput value={`${sumPercentage}%`} onClick={e => e.stopPropagation()} />
                  </ValueCol>
                )}

                <div onClick={e => e.stopPropagation()}>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
                  </Dropdown>
                </div>
              </ValuesContainer>
            </Row>
          </QestionGroupCard>
        )}
      </Col>
      {!collapsed &&
        sortedBindings.map(({ id, questionId }, i) => (
          <Draggable key={id} draggableId={id} index={i}>
            {(provided, snapshot) => {
              return (
                <Question
                  sumPercentage={totalPercentage}
                  questionId={questionId}
                  bindingId={id}
                  questionGroupId={questionGroup.id}
                  ratingMethod={ratingMethod}
                  currentChecklist={currentChecklist}
                  provided={provided}
                  isDragging={snapshot.isDragging}
                />
              );
            }}
          </Draggable>
        ))}
      {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value &&
        !isDragging &&
        !isDraggingOver &&
        !isDraggingGlobal && (
          <Row type="flex" justify="end" style={{ margin: '4px 0px' }}>
            {listBottom === get(questionGroup, 'position', basePosition) && (
              <StyledButton type="primary" ghost onClick={onAddQuestionGroup}>
                {t('checklistsPage.questionGroup.buttons.addQuestionGroup')}
              </StyledButton>
            )}

            <StyledButton type="primary" ghost onClick={onAddQuestion}>
              {t('checklistsPage.questionGroup.buttons.addQuestion')}
            </StyledButton>
          </Row>
        )}
      {provided.placeholder}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    currentChecklist,
    editableGroup,
    sortedQuestionGroupBindings,
    addingQuestionGroup
  } = state.uiChecklistEditor;
  const { ratingCalculation, ratingMethod } = currentChecklist;
  const { loading } = state.questionsResource;
  const {
    updateByIdStarted,
    createStarted,
    byIds: bindingsByIds
  } = state.questionToGroupBindingsResource;

  const questionGroup = get(ownProps, 'questionGroup', {});

  const sortedBindings = get(sortedQuestionGroupBindings, questionGroup.id, []).filter(Boolean);
  const questionGroupBindings = getQuestionGroupBindings(state, ownProps.questionGroup);
  const questionGroupBindingsIds = getQuestionGroupBindingsIds(state, ownProps.questionGroup);
  const questionGroupsQuestions = getQuestionGroupQuestions(state, questionGroup);

  const allRatingValues = questionGroupsQuestions.map(question => ({
    ...question,
    value: max(question.ratingValues),
    percentage: find(questionGroupBindings, { questionId: question.id }).percentage
  }));
  const currentChecklistBindings = getChecklistDefinitionBindings(state, currentChecklist);

  const sumPercentage = sumBy(questionGroupBindings, 'percentage');
  const totalPercentage = sumBy(currentChecklistBindings, 'percentage');

  const sumRates = calculateQuestions({
    questions: allRatingValues,
    ratingCalculation,
    ratingMethod
  });

  return {
    sumRates,
    sumPercentage,
    totalPercentage,
    questionGroupBindings,
    questionGroupBindingsIds,
    sortedBindings,
    bindingsByIds,
    loading:
      loading ||
      !isEmpty(editableGroup) ||
      updateByIdStarted ||
      createStarted ||
      !isEmpty(addingQuestionGroup)
  };
};

const mapDispatchToProps = {
  deleteQuestionGroup: questionGroupsResource.operations.deleteById,
  setEditableChecklistDefinition,
  setEditableGroup,
  setAddingQuestionToGroup,
  updateSortedQuestionGroupBindings,
  updateCurrentChecklist: checklistDefinitionsResource.operations.updateById
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionGroup);
