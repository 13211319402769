import camelize from 'camelcase';
import { processJsonApiCollection } from 'core/jsonapi';
import { isArray, isEmpty, groupBy } from 'lodash';

const loadIncludedObjectsToRedux = ({ objects, dispatch }) => {
  if (isEmpty(objects)) return;
  if (isArray(objects)) {
    const groupByObj = groupBy(objects, 'type');
    Object.keys(groupByObj).forEach(type =>
      dispatch({
        type: `${camelize(type)}/loadSucceed`,
        payload: processJsonApiCollection(groupByObj[type])
      })
    );
  }
};

export default loadIncludedObjectsToRedux;
