import createListReducer from 'core/redux/lists/listReducer';
import { promptsCustomFieldsAllResource } from 'redux/resources/promptsCustomFieldsAll';

export const listParams = {
  name: 'promptsCustomFieldsAllList',
  loader: promptsCustomFieldsAllResource.operations.load,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const promptsCustomFieldsAllListReducer = reducer;

export { actions, promptsCustomFieldsAllListReducer, operations };
