import React from 'react';
import { Trash2 } from 'react-feather';
import Icon from 'components/Icon';
import { InputNumber, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SButton from 'components/Standard/SButton';

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
`;

const ConditionSetting = ({ condition, onUpdateCondition, onRemoveCondition, index }) => {
  const { min, max, kpi } = condition;
  const updateCondition = (updatedCondition = {}) => {
    const newCondition = { ...condition, ...updatedCondition };
    if (onUpdateCondition) onUpdateCondition(newCondition);
  };

  const { t } = useTranslation();

  const handleChangeCondition = key => value => {
    updateCondition({ [key]: value });
  };

  return (
    <Wrapper>
      {t('kpi.condition.if')}
      <InputNumber
        style={{ width: 60 }}
        min={0}
        max={100}
        value={min || 0}
        onChange={handleChangeCondition('min')}
      />
      {t('kpi.condition.to')}
      <InputNumber
        style={{ width: 60 }}
        min={0}
        max={100}
        value={max || 0}
        onChange={handleChangeCondition('max')}
      />
      {t('kpi.condition.then')}
      <InputNumber
        style={{ width: 60 }}
        min={0}
        max={100}
        value={kpi || 0}
        onChange={handleChangeCondition('kpi')}
      />
      <Tooltip title={t('kpi.condition.remove')}>
        <StyledButton
          size="icon"
          icon={<Icon icon={Trash2} size="16px" />}
          color="var(--black_6)"
          backgroundColor="transparent"
          onClick={() => onRemoveCondition(index)}
        />
      </Tooltip>
    </Wrapper>
  );
};

export default ConditionSetting;
