import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';

export const checklistsResource = createReduxResource({
  name: 'checklists',
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const review = state.reviewsResource.byIds[resource.reviewId];
      if (review)
        updateResource({
          dispatch,
          type: 'reviews',
          resource: { ...review, checklistId: resource.id }
        });
    },
    onUpdateByIdSucceed: ({ resource, included, dispatch, getState }) => {
      const state = getState();
      const review = state.reviewsResource.byIds[resource.reviewId];
      if (review) {
        const includedTasksIds = included
          .filter(object => object.type === 'tasks')
          .map(object => object.id);

        updateResource({
          dispatch,
          type: 'reviews',
          resource: { ...review, tasksIds: [...includedTasksIds] }
        });
      }
    }
  }
});
