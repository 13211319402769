export default {
  libraryPage: {
    buttons: { createFolder: 'Crear una capreta' },
    libraryItem: {
      emptyName: 'No ha establecido el nombre',
      added: 'Agregado:',
      dropdown: {
        edit: 'Redactar',
        delete: 'Eliminar'
      },
      deleteFolderModal: {
        title: '¿Está seguro que quiere eliminar la carpeta?',
        description: 'Esta acción es irreversible',
        buttons: {
          cancel: 'Cancelar',
          delete: 'Eliminar'
        }
      },
      deleteItemModal: {
        title: 'La llamada será eliminada de la biblioteca. ¿Continuar?',
        buttons: {
          cancel: 'Cancelar',
          delete: 'Eliminar'
        }
      },
      empty: {
        folders: 'De momento no se ha añadido ningana carpeta',
        calls: 'En esta carpeta no hay llamadas'
      }
    },
    modal: {
      title: 'Introduzca el nombre de la nueva carpeta',
      form: { name: 'Nombre' },
      buttons: {
        cancel: 'Cancelar',
        create: 'Crear'
      }
    },
    messages: {
      folderSuccessfullyCreated: 'La carpera se ha creado correctamente',
      folderCreateFailed: 'No se pudo crear la carpeta'
    }
  }
};
