import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { loadTaskDefinitionById } from './reducer';

export const setTaskDefinitionToEdit = ({ id }) => {
  return async dispatch => {
    const taskDefinition = await dispatch(taskDefinitionsResource.operations.loadById({ id }));

    await dispatch(loadTaskDefinitionById(taskDefinition));
    return taskDefinition;
  };
};
