import React from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Tooltip } from 'antd';
import {
  widgetInfoTextSetter,
  widgetNameSetter,
  widgetPeriodSetter
} from 'pages/DashboardPage/utils';
import SettingsModal from 'pages/DashboardPage/Components/SettingsModal';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import WidgetViewLastUpdate from '../../../components/Dashboard/WidgetViewLastUpdate';

const SMLWidgetHeader = ({ name, type, widgetData, filters, dateLastUpdate }) => (
  <>
    <SRow type="flex" wrap={false} alignItems="center">
      <SCol flex="auto" display="flex" alignItems="center">
        <Tooltip title={widgetNameSetter({ name, type })}>
          <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
        </Tooltip>
        {widgetInfoTextSetter({ type })}
      </SCol>
      <SCol
        display="flex"
        justifyContent="flex-end"
        flex="none"
        alignItems="center"
        height="inherit"
      >
        <SettingsModal widgetData={widgetData} />
        <WidgetViewLastUpdate widgetData={widgetData} dateLastUpdate={dateLastUpdate} />
      </SCol>
    </SRow>
    <SRow>
      <SCol>
        <TimePeriod type="secondary">
          {widgetPeriodSetter({ widgetsFilters: filters, widgetType: type })}
        </TimePeriod>
      </SCol>
    </SRow>
  </>
);

export default SMLWidgetHeader;
