import React from 'react';
import {
  LineChartOutlined,
  TableOutlined,
  PercentageOutlined,
  FieldBinaryOutlined
} from '@ant-design/icons';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { IconButton } from 'components/Buttons/IconButton';
import { useTranslation } from 'react-i18next';

const WidgetViewChanger = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();
  const views = [WIDGET_VIEW_MODES.TABLE.value, WIDGET_VIEW_MODES.CHART.value];
  const scores = [WIDGET_VIEW_MODES.SCORE.value, WIDGET_VIEW_MODES.PERCENTAGE.value];

  const getIcon = value => {
    if (views.includes(value))
      return WIDGET_VIEW_MODES.TABLE.value === value ? <LineChartOutlined /> : <TableOutlined />;

    if (scores.includes(value))
      return WIDGET_VIEW_MODES.PERCENTAGE.value === value ? (
        <PercentageOutlined />
      ) : (
        <FieldBinaryOutlined />
      );
  };

  const onClick = () => {
    if (views.includes(value)) {
      onChange(
        value === WIDGET_VIEW_MODES.TABLE.value
          ? WIDGET_VIEW_MODES.CHART.value
          : WIDGET_VIEW_MODES.TABLE.value
      );
    }

    if (scores.includes(value)) {
      onChange(
        value === WIDGET_VIEW_MODES.PERCENTAGE.value
          ? WIDGET_VIEW_MODES.SCORE.value
          : WIDGET_VIEW_MODES.PERCENTAGE.value
      );
    }
  };

  return (
    <IconButton
      tooltip={{
        title: t('dashboardPage.tableReviewsCountByReviewers.changeViewType'),
        placement: 'topRight'
      }}
      button={{
        icon: getIcon(value),
        marginTop: '2px',
        onClick,
        size: 'icon',
        disabled
      }}
    />
  );
};

export default WidgetViewChanger;
