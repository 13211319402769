import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CrmEntity from 'components/CrmEntityContent';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer';

const CrmEntityPage = ({ crmEntityId }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.crmEntityPage.title')}</title>
      </Helmet>

      <CrmEntity crmEntityId={crmEntityId} />
      <ClientInteractionDrawer shouldLoad padding={0} />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const crmEntityId = ownProps?.id || ownProps?.match?.params?.id;
  return {
    crmEntityId
  };
};

export default withRouter(connect(mapStateToProps, null)(CrmEntityPage));
