import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Typography
} from 'antd';
import ColorZones from 'components/ColorZones/ColorZones';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import {
  AUTOFAIL_ENABLED,
  CHECKLIST_DEFINITION_STATUS,
  CLIENT_INTERACTIONS_TYPES,
  CLIENT_INTERACTIONS_TYPES_LITERALS,
  ONLY_POSITIVE_SCORES,
  PERCENTAGE_COLOR_ZONE,
  RATING_CALCULATION,
  RATING_CALCULATIONS,
  RATING_METHOD,
  RATING_METHODS,
  RATING_MODE,
  RATING_MODES,
  SCALE_TYPES
} from 'core/utils/constants';
import { basePosition } from 'core/utils/dnd';
import { calculateQuestions } from 'core/utils/ratingsCalculations';
import { isScaleTypeCustom } from 'core/utils/scaleTypes';
import { selectSearch } from 'core/utils/selectSearch';
import { debounce, find, get, isEmpty, isNil, max, min as getMin, min, minBy, some } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { questionGroupsResource } from 'redux/resources/questionGroups';
import { unitsResource } from 'redux/resources/units';
import {
  getChecklistDefinitionBindings,
  getChecklistDefinitionQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { setEditingChecklist, updateEditingChecklist } from 'redux/ui/checklistsPage/reducer';
import LabelWithInfo from 'components/LabelWithInfo';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const labelCol = {
  md: { offset: 0, span: 8 },
  lg: { offset: 0, span: 10 },
  xl: { offset: 4, span: 8 },
  xxl: { offset: 4, span: 7 }
};
const inputCol = {
  md: { span: 18 },
  lg: { span: 18 },
  xl: { span: 9 },
  xxl: { span: 9 }
};
const col = {
  md: { offset: 0, span: 24 },
  xl: { offset: 4, span: 16 }
};

const ChecklistSettingsPage = ({
  sumRates,
  minimalRate,
  checklistDefinition = {},
  unitsByIds = {},
  loadUnits,
  loadingUnits,
  updateEditingChecklist,
  setEditingChecklist,
  loadChecklistDefinitionById,
  id,
  loading,
  history,
  isNew,
  createChecklistDefinition,
  updateChecklistDefinition,
  currentChecklistQuestions,
  createQuestionGroup
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const getChecklistDefinition = async () => {
    const checklistDefinition = await loadChecklistDefinitionById({
      id,
      include: 'question_groups.question_to_group_bindings.question'
    });

    if (checklistDefinition) {
      setEditingChecklist(checklistDefinition);
      form.setFieldsValue(checklistDefinition);
    }
  };

  const loadInitialState = async () => {
    const response = await loadUnits();

    if (id) {
      await getChecklistDefinition();
    } else {
      const newChecklist = {
        name: '',
        description: '',
        unitsIds: Object.keys(response?.resources || {}),
        communicationTypes: Object.values(CLIENT_INTERACTIONS_TYPES),
        ratingMethod: RATING_METHOD.SIMPLE,
        ratingCalculation: RATING_CALCULATION.SUM,
        ratingMode: RATING_MODE.PERCENTAGE,
        scaleType: SCALE_TYPES.max_5.type,
        colorZones: PERCENTAGE_COLOR_ZONE,
        valuesDisplayType: 'numbers',
        noAnswerAsMax: false,
        onlyPositiveScores: false
      };
      setEditingChecklist(newChecklist);
      form.setFieldsValue(newChecklist);
    }
  };

  useEffect(() => {
    loadInitialState().then(r => r);
  }, []);

  const units = Object.values(unitsByIds);

  const {
    name = '',
    description = '',
    status = '',
    unitsIds = Object.keys(unitsByIds),
    communicationTypes = Object.values(CLIENT_INTERACTIONS_TYPES),
    ratingMethod = RATING_METHOD.SIMPLE,
    ratingCalculation = RATING_CALCULATION.SUM,
    ratingMode = RATING_MODE.PERCENTAGE,
    scaleType = SCALE_TYPES.max_5.type,
    colorZones = PERCENTAGE_COLOR_ZONE,
    valuesDisplayType = 'numbers',
    noAnswerAsMax = false
  } = checklistDefinition;

  const initialValues = {
    name,
    description,
    unitsIds,
    communicationTypes,
    ratingMode,
    ratingMethod,
    ratingCalculation,
    scaleType,
    valuesDisplayType,
    noAnswerAsMax
  };

  // useEffect(() => {
  //   form.setFieldsValue(initialValues);
  // }, [id]);

  const numbersRatingMode = ratingMode === RATING_MODE.NUMBERS;
  const disabledByStatus = CHECKLIST_DEFINITION_STATUS.DRAFT.value !== status && id;
  const disabledByQuestions = !isEmpty(currentChecklistQuestions);
  const disabledColorZones =
    numbersRatingMode &&
    (isScaleTypeCustom(scaleType) || ratingCalculation === RATING_CALCULATION.SUM);
  const hideColorZoneValues =
    isScaleTypeCustom(scaleType) &&
    CHECKLIST_DEFINITION_STATUS.PUBLISHED.value !== status &&
    numbersRatingMode;

  const maxRatingValue = numbersRatingMode ? SCALE_TYPES[scaleType].max || sumRates : 100;

  const minRatingValue = isNil(min(SCALE_TYPES[scaleType].numbers))
    ? minimalRate
    : min(SCALE_TYPES[scaleType].numbers);

  const createChecklist = async () => {
    try {
      const createdChecklist = await createChecklistDefinition({
        ...checklistDefinition,
        status: 'draft',
        isGroupable: false
      });
      if (!isEmpty(createdChecklist)) {
        await createQuestionGroup({
          name: t('checklistSettingsPage.questionsWithoutGroup'),
          position: basePosition,
          checklistDefinitionId: createdChecklist?.id
        });
        return createdChecklist;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateChecklistByStatus = ({ checklistDefinition }) => {
    const { id, name, description, unitsIds, communicationTypes } = checklistDefinition;
    return disabledByStatus
      ? updateChecklistDefinition({
          id,
          name,
          description,
          unitsIds,
          communicationTypes
        })
      : updateChecklistDefinition(checklistDefinition);
  };

  const onSubmit = async () => {
    const result = isNew
      ? await createChecklist()
      : await updateChecklistByStatus({ checklistDefinition });

    if (result) {
      message.success(
        isNew
          ? t('checklistSettingsPage.checklistSuccessfullyCreated')
          : t('checklistSettingsPage.checklistSuccessfullyUpdated')
      );
      history.push(`/checklist-editor/${result.id}`);
    } else {
      message.error(
        isNew
          ? t('checklistSettingsPage.createChecklistFailed')
          : t('checklistSettingsPage.updateChecklistFailed')
      );
    }
  };

  const onRatingMethodChange = ({ target }) => {
    if (target.value === RATING_METHOD.WEIGHTED) {
      form.setFieldsValue({ ratingCalculation: RATING_CALCULATION.AVERAGE });
      updateEditingChecklist({ ratingCalculation: RATING_CALCULATION.AVERAGE });
    }
  };

  const onRatingModeChange = ({ target }) => {
    const newRatingMode = target.value;
    const newColorZones =
      RATING_MODE.NUMBERS === newRatingMode
        ? {
            ...colorZones,
            medium: {
              ...colorZones.medium,
              stop: get(SCALE_TYPES, [scaleType, 'colorZones', 'medium', 'stop'])
            },
            low: {
              ...colorZones.low,
              stop: SCALE_TYPES[scaleType].colorZones.low.stop
            }
          }
        : {
            ...colorZones,
            medium: {
              ...colorZones.medium,
              stop: SCALE_TYPES.max_10.colorZones.medium.stop * 10
            },
            low: {
              ...colorZones.low,
              stop: SCALE_TYPES.max_10.colorZones.low.stop * 10
            }
          };

    return updateEditingChecklist({ colorZones: newColorZones });
  };

  const onScaleTypeChange = newScaleType => {
    if (isScaleTypeCustom(newScaleType)) {
      const newColorZones = {
        ...colorZones,
        medium: {
          ...colorZones.medium,
          stop: SCALE_TYPES.max_10.colorZones.medium.stop * 10
        },
        low: {
          ...colorZones.low,
          stop: SCALE_TYPES.max_10.colorZones.low.stop * 10
        }
      };

      if (newScaleType === SCALE_TYPES.custom_binary.type) {
        form.setFieldsValue({
          ratingCalculation: RATING_CALCULATION.SUM,
          ratingMethod: RATING_METHOD.SIMPLE
        });
      }
      return updateEditingChecklist({ colorZones: newColorZones });
    }

    const newColorZones =
      ratingMode === RATING_MODE.NUMBERS
        ? {
            ...colorZones,
            medium: {
              ...colorZones.medium,
              stop: get(SCALE_TYPES, [newScaleType, 'colorZones', 'medium', 'stop'])
            },
            low: { ...colorZones.low, stop: SCALE_TYPES[newScaleType].colorZones.low.stop }
          }
        : {
            ...colorZones,
            medium: {
              ...colorZones.medium,
              stop: SCALE_TYPES.max_10.colorZones.medium.stop * 10
            },
            low: {
              ...colorZones.low,
              stop: SCALE_TYPES.max_10.colorZones.low.stop * 10
            }
          };

    return updateEditingChecklist({
      colorZones: newColorZones
    });
  };

  const onValuesChange = debounce(changedValues => {
    updateEditingChecklist(changedValues);
  }, 250);

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.checklistEditorPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SCard bordered shadowed rounded={false}>
          <SRow display="flex">
            <SCol flex="auto">
              <Breadcrumb style={{ margin: '6px 0px 5px 0px' }}>
                <Breadcrumb.Item>
                  <Link to="/checklists">{t('checklistSettingsPage.breadcrumbs.checklists')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={id && `/checklist-settings/${id}`}>
                    {t('checklistSettingsPage.breadcrumbs.checklistSettings')}
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </SCol>
          </SRow>
          <Row>
            <Col>
              <Title level={4} style={{ margin: '0 0 8px 0' }}>
                {t('checklistSettingsPage.title')}
              </Title>
            </Col>
          </Row>
          <Row style={{ marginBottom: 8 }}>
            <Col>
              <Text>
                {t('checklistSettingsPage.info')}
                <a
                  href="https://wiki.qolio.ru/qolio-or-baza-znanii/vozmozhnosti-platformy/formy-ocenki/nastroika-form-ocenki"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('checklistSettingsPage.infoButton')}
                </a>
              </Text>
            </Col>
          </Row>
        </SCard>
      </SCol>
      <SCol span={24} padding="16px">
        <SCard bordered shadowed>
          <Form
            form={form}
            initialValues={initialValues}
            onValuesChange={onValuesChange}
            labelCol={labelCol}
            labelAlign="left"
            colon={false}
            wrapperCol={inputCol}
            onFinish={onSubmit}
            scrollToFirstError
          >
            <Row gutter={[16, 16]}>
              <Col {...col}>
                <Title level={4}>{t('checklistSettingsPage.form.title')}</Title>
              </Col>
            </Row>
            <Form.Item
              label={
                <LabelWithInfo infoTitle={t('checklistSettingsPage.form.fields.nameInfo')}>
                  {t('checklistSettingsPage.form.fields.name')}
                </LabelWithInfo>
              }
              name="name"
              rules={[
                {
                  required: true,
                  message: t('checklistSettingsPage.form.messages.enterName'),
                  whitespace: true
                }
              ]}
            >
              <Input placeholder={t('checklistSettingsPage.form.fields.namePlaceholder')} />
            </Form.Item>
            <Form.Item
              label={
                <LabelWithInfo infoTitle={t('checklistSettingsPage.form.fields.descriptionInfo')}>
                  {t('checklistSettingsPage.form.fields.description')}
                </LabelWithInfo>
              }
              name="description"
            >
              <TextArea
                placeholder={t('checklistSettingsPage.form.fields.descriptionPlaceholder')}
              />
            </Form.Item>
            <Form.Item
              label={
                <LabelWithInfo infoTitle={t('checklistSettingsPage.form.fields.unitsInfo')}>
                  {t('checklistSettingsPage.form.fields.units')}
                </LabelWithInfo>
              }
              name="unitsIds"
            >
              <Select
                mode="multiple"
                optionLabelProp="label"
                allowClear
                placeholder={t('checklistSettingsPage.form.fields.unitsPlaceholder')}
                maxTagCount={0}
                loading={loadingUnits}
                maxTagPlaceholder={selectedKeys =>
                  `${t('checklistSettingsPage.form.fields.unitsPlaceholderMax')} ${
                    selectedKeys.length
                  }`
                }
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
              >
                {units.map(({ id, name }) => (
                  <Option key={id} value={id} label={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <LabelWithInfo
                  infoTitle={t('checklistSettingsPage.form.fields.communicationTypesInfo')}
                >
                  {t('checklistSettingsPage.form.fields.communicationTypes')}
                </LabelWithInfo>
              }
              name="communicationTypes"
            >
              <Select
                mode="multiple"
                allowClear
                optionLabelProp="label"
                placeholder={t('checklistSettingsPage.form.fields.communicationTypesPlaceholder')}
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${t('checklistSettingsPage.form.fields.communicationTypesPlaceholderMax')} ${
                    selectedKeys.length
                  }`
                }
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
              >
                {Object.values(CLIENT_INTERACTIONS_TYPES).map(type => (
                  <Option
                    key={type}
                    value={type}
                    label={`${t('checklistSettingsPage.form.fields.communicationType')} ${t(
                      CLIENT_INTERACTIONS_TYPES_LITERALS[type]
                    )}`}
                  >
                    {t(CLIENT_INTERACTIONS_TYPES_LITERALS[type])}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <LabelWithInfo infoTitle={t('checklistSettingsPage.form.fields.ratingModeInfo')}>
                  {t('checklistSettingsPage.form.fields.ratingMode')}
                </LabelWithInfo>
              }
              name="ratingMode"
            >
              <Radio.Group
                disabled={disabledByStatus || disabledByQuestions}
                style={{ width: '100%' }}
                onChange={onRatingModeChange}
              >
                <Row align="middle">
                  {Object.values(RATING_MODES).map(item => (
                    <Col span={8} key={item.value}>
                      <Radio value={item.value}>{t(item.title)}</Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={
                <LabelWithInfo infoTitle={t('checklistSettingsPage.form.fields.ratingMethodInfo')}>
                  {t('checklistSettingsPage.form.fields.ratingMethod')}
                </LabelWithInfo>
              }
              name="ratingMethod"
            >
              <Radio.Group
                style={{ width: '100%' }}
                disabled={disabledByStatus || scaleType === SCALE_TYPES.custom_binary.type}
                onChange={onRatingMethodChange}
              >
                <Row align="middle">
                  {Object.values(RATING_METHODS).map(item => (
                    <Col span={8} key={item.value}>
                      <Radio value={item.value}>{t(item.title)}</Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={
                <LabelWithInfo
                  infoTitle={t('checklistSettingsPage.form.fields.ratingCalculationInfo')}
                >
                  {t('checklistSettingsPage.form.fields.ratingCalculation')}
                </LabelWithInfo>
              }
              name="ratingCalculation"
            >
              <Radio.Group
                style={{ width: '100%' }}
                disabled={
                  disabledByStatus ||
                  ratingMethod === RATING_METHOD.WEIGHTED ||
                  scaleType === SCALE_TYPES.custom_binary.type
                }
              >
                <Row align="middle">
                  {Object.values(RATING_CALCULATIONS).map(item => (
                    <Col span={8} key={item.value}>
                      <Radio value={item.value}>{t(item.title)}</Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col {...col}>
                <Title level={4}>{t('checklistSettingsPage.form.fields.additionalSettings')}</Title>
              </Col>
            </Row>
            <Form.Item
              label={
                <LabelWithInfo infoTitle={t('checklistSettingsPage.form.fields.scaleTypeInfo')}>
                  {t('checklistSettingsPage.form.fields.scaleType')}
                </LabelWithInfo>
              }
              name="scaleType"
            >
              <Select
                disabled={disabledByStatus || disabledByQuestions}
                onChange={onScaleTypeChange}
              >
                {Object.values(SCALE_TYPES).map(({ type, name }) => (
                  <Option key={type} value={type}>
                    {t(name)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={[16, 16]}>
              <Col {...col}>
                {disabledByQuestions && (
                  <Alert
                    message={t('checklistSettingsPage.form.alerts.disabledByQuestions.title')}
                    type="info"
                    showIcon={false}
                  />
                )}
              </Col>
            </Row>

            {scaleType !== SCALE_TYPES.custom.type &&
              scaleType !== SCALE_TYPES.custom_with_views.type && (
                <Form.Item
                  label={
                    <LabelWithInfo
                      infoTitle={t('checklistSettingsPage.form.fields.valuesDisplayTypeInfo')}
                    >
                      {t('checklistSettingsPage.form.fields.valuesDisplayType')}
                    </LabelWithInfo>
                  }
                  name="valuesDisplayType"
                  // dependencies={['scaleType']}
                >
                  <Select disabled={scaleType === SCALE_TYPES.custom.type || disabledByStatus}>
                    {Object.values(SCALE_TYPES[scaleType].valuesOptions).map(({ type, text }) => (
                      <Option key={type} value={type}>
                        {t(text)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

            <Form.Item
              label={
                <LabelWithInfo infoTitle={t('checklistSettingsPage.form.fields.noAnswerAsMaxInfo')}>
                  {t('checklistSettingsPage.form.fields.noAnswerAsMax')}
                </LabelWithInfo>
              }
              name="noAnswerAsMax"
            >
              <Radio.Group style={{ width: '100%' }} disabled={disabledByStatus}>
                <Row align="middle">
                  {Object.values(AUTOFAIL_ENABLED).map(item => (
                    <Col span={8} key={item.value}>
                      <Radio value={item.value}>{t(item.title)}</Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={
                <LabelWithInfo
                  infoTitle={t('checklistSettingsPage.form.fields.onlyPositiveScoresInfo')}
                >
                  {t('checklistSettingsPage.form.fields.onlyPositiveScores')}
                </LabelWithInfo>
              }
              name="onlyPositiveScores"
            >
              <Radio.Group style={{ width: '100%' }} disabled={disabledByStatus}>
                <Row align="middle">
                  {Object.values(ONLY_POSITIVE_SCORES).map(item => (
                    <Col span={8} key={item.value}>
                      <Radio value={item.value}>{t(item.title)}</Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Row className="ant-form-item">
              <SCol span={16} xs={22} sm={16} offset={4} margin="0 auto">
                <Alert
                  showIcon={false}
                  type="info"
                  message={t('checklistSettingsPage.form.alerts.colorZones.title')}
                  description={
                    <>
                      {disabledColorZones && (
                        <>
                          <Text style={{ display: 'block' }}>
                            {t('checklistSettingsPage.form.alerts.colorZones.descriptionPT1')}
                          </Text>
                          <br />
                        </>
                      )}
                      <Text>
                        {t('checklistSettingsPage.form.alerts.colorZones.descriptionPT2')}
                      </Text>
                      <br />
                      <a
                        href="https://wiki.qolio.ru/qolio-or-baza-znanii/vozmozhnosti-platformy/formy-ocenki/zony-ocenki"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('checklistSettingsPage.form.alerts.button')}
                      </a>
                    </>
                  }
                />
              </SCol>
            </Row>
            <ColorZones
              col={col}
              max={maxRatingValue}
              min={numbersRatingMode ? minRatingValue : 0}
              onChange={colorZones => updateEditingChecklist({ colorZones })}
              scaleType={scaleType}
              ratingMode={ratingMode}
              ratingCalculation={ratingCalculation}
              colorZones={colorZones || SCALE_TYPES[scaleType].colorZones}
              disabled={disabledByStatus || disabledColorZones}
              hideValues={hideColorZoneValues}
            />
            <Row>
              <Col {...col}>
                <Row type="flex" justify="end" gutter={[16]}>
                  <Col>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button onClick={() => history.goBack()}>
                        {t('checklistSettingsPage.buttons.cancel')}
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button type="primary" htmlType="submit" loading={loading}>
                        {t('checklistSettingsPage.buttons.save')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </SCard>
      </SCol>
    </SRow>
  );
};

const mapStateToProps = (state, props) => {
  const {
    loadByIdStarted,
    updateByIdStarted,
    byIds,
    createStarted
  } = state.checklistDefinitionsResource;
  const { id } = props.match.params;
  const checklistDefinition = state.uiChecklistsPage.editingChecklist;
  const currentChecklistQuestions = getChecklistDefinitionQuestions(state, checklistDefinition);
  const currentChecklistBindings = getChecklistDefinitionBindings(state, checklistDefinition);

  const allRatingValues = [...currentChecklistQuestions].map(question => ({
    ...question,
    value: max(get(question, 'ratingValues', [])),
    percentage: get(find(currentChecklistBindings, { questionId: question.id }), 'percentage', 0)
  }));

  const sumRates = calculateQuestions({
    ratingCalculation: checklistDefinition.ratingCalculation,
    ratingMethod: checklistDefinition.ratingMethod,
    questions: allRatingValues
  });

  const minimalRate = get(minBy(allRatingValues, 'min'), 'min', 1);

  // * calc min
  const minFromAllRatingValues = some(
    allRatingValues,
    ({ binding }) => binding?.questionGroup?.percentageCalculationStartingWithZero
  )
    ? 0
    : calculateQuestions({
        trueResult: true,
        max,
        ratingMethod: checklistDefinition.ratingMethod,
        ratingCalculation: checklistDefinition.ratingCalculation,
        questions: allRatingValues.map(({ ratingValues, ...question }) => ({
          ...question,
          value: getMin(ratingValues)
        }))
      });

  const onlyPositiveScores = get(checklistDefinition, 'onlyPositiveScores', false);
  const minimalRateValue = onlyPositiveScores ? 0 : getMin([minimalRate, minFromAllRatingValues]);

  return {
    allRatingValues,
    sumRates,
    minimalRate: minimalRateValue,
    unitsByIds: state.unitsResource.byIds,
    loadingUnits: state.unitsResource.loading,
    checklistDefinition,
    currentChecklistQuestions,
    id,
    loading: loadByIdStarted || updateByIdStarted || createStarted,
    isNew: !byIds[id]
  };
};

const mapDispatchToProps = {
  loadUnits: unitsResource.operations.load,
  loadChecklistDefinitionById: checklistDefinitionsResource.operations.loadById,
  createChecklistDefinition: checklistDefinitionsResource.operations.create,
  createQuestionGroup: questionGroupsResource.operations.create,
  updateChecklistDefinition: checklistDefinitionsResource.operations.updateById,
  setEditingChecklist,
  updateEditingChecklist
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistSettingsPage));
