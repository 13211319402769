export default {
  calibrationsPage: {
    messages: {
      sessionSuccessfullyCreated: 'La sesión se ha formado',
      sessionSuccessfullyUpdated: 'La sesión se ha reiniciado',
      createSessionFailed: 'No se logró abrir la sesión',
      updateSessionFailed: 'No se logró reiniciar la sesión'
    },
    buttons: {
      viewFolderItems: 'Comunicación a revisar',
      createSession: 'Planificar una sesión'
    },
    sessionInfo: {
      breadcrumbs: {
        allSessions: 'Todas las sesiones',
        currentSession: 'Sesión en curso'
      },
      buttons: { viewSessionResult: 'Pasar a los resultados' },
      infoItems: {
        organizer: 'Organizador: ',
        scheduledTime: 'Fecha: ',
        checklistDefinition: 'Lista a chequear: ',
        members: 'Participantes: ',
        description: 'Descripción de la sesión: ',
        place: 'Lugar de la sesión: ',
        totalReviewedItems: 'Termino general: '
      },
      tooltip: 'Refleja la cantidad total de comunicaciones evaluadas en la sesión en curso'
    },
    userReviewStatusTable: {
      tooltip:
        'Refleja la cantidad de comunicaciones evaluadas de un usuario concreto en la sesión en curso',
      reviewedItems: 'Terminación: ',
      table: {
        columns: {
          operator: 'Nombres y Apellidos',
          name: 'Nombre',
          startedAt: 'Fecha y hora',
          clientPhoneNumber: 'Número de teléfono',
          isReviewed: 'Estatus'
        }
      }
    },
    calibrationsList: {
      item: {
        confirmDelete: {
          title: 'Está seguro que quiere elimar la sesión de calibración?',
          ok: 'Eliminar',
          cancel: 'Cancelación'
        },
        menu: {
          view: 'Ver el contenido de la sesión',
          setting: 'Pasar al ajuste de la sesión',
          result: 'Pasar a los resultados',
          delete: 'Borrar la sesión'
        },
        name: 'Nombre: ',
        organizer: 'Organizador: ',
        reviewedItems: 'Terminación: ',
        scheduledTime: 'Fecha: ',
        loading: 'Descargamos la sesión 😤',
        empty: 'Aqui de momento no hay sesión 😟',
        all: 'Es todo 😊'
      }
    },
    folders: {
      messages: {
        fileSuccessfullyDeleted: 'El documento se ha eliminado',
        groupSuccessfullyDeleted: 'El grupo se ha eliminado'
      },
      buttons: {
        deleteFile: 'Eliminar el documento',
        deleteFolder: 'Eliminar la carpeta'
      },
      table: {
        columns: {
          clientInteraction: 'Tipo',
          operator: 'Nombre y apellido',
          name: 'Nombre',
          startedAt: 'Fecha y hora',
          phoneNumber: 'Número de teléfono'
        }
      },
      confirmDeleteFolder: {
        title: '¿Eliminar la carpeta?',
        description:
          'Todos los comentarios añadidos a esta carpeta serán borrados de todas las sesiones de calibración.',
        ok: 'Borrar',
        cancel: 'Cancelación'
      },
      confirmDeleteFolderItem: {
        title: '¿Borrar la grabación?',
        description: 'La grabación será borrada de todas las sesiones de calibración.',
        ok: 'Borrar',
        cancel: 'Cancelar'
      },
      loading: 'Descargamos los grupos...🧐',
      empty: 'No se encontraron grupos con esos filtros 😟'
    },
    modal: {
      title: 'Ajustes de la sesión de calibración',
      buttons: {
        save: 'Guardar',
        cancel: 'Cancelar'
      },
      form: {
        communicationsTitle: 'Comunicación de evaluación:',
        fields: {
          organizer: 'Organizador',
          name: 'Nombre de la sesión',
          namePlaceholder: 'Introduzca el nombre de la sesión',
          scheduledTime: 'Fecha y hora de la sesión',
          place: 'Lugar de ejecución de la sesión',
          placePlaceholder: 'Introduzca el lugar de ejecución de la sesión',
          description: 'Descripción de la sesión',
          checklistDefinition: 'Lista a chequear',
          members: 'Participantes de la sesión',
          membersPlaceholder: 'Elija los participantes de la sesión',
          folder: 'Grupo de comunicación',
          folderPlaceholder: 'Elija el grupo de comunicación',
          folderItems: 'Comunicación',
          folderItemsPlaceholder: 'Elija la comunicación',
          folderItemsMax: 'Elegida la comunicación: '
        },
        messages: {
          requiredName: 'Por favor introduzca el nombre de la sesión',
          requiredScheduledTime: 'Por favor introduzca la fecha de la sesión',
          requiredСhecklistDefinition: 'Por favor elija la lista a chequear',
          requiredMembers: 'Por favor elija los participantes',
          requiredFolder: 'Por favor elija el grupo de comunicación',
          requiredFolderItems: 'Por favor elija la comunicación'
        }
      }
    }
  }
};
