import { post } from 'redux-bees';

const workPlanTaskConfigurationsV3 = apiPath => ({
  copyWorkPlanTaskConfiguration: {
    method: post,
    path: `${apiPath}/task/copy`
  }
});

export default workPlanTaskConfigurationsV3;
