export default {
  calibrationsPage: {
    messages: {
      sessionSuccessfullyCreated: 'Сессия создана',
      sessionSuccessfullyUpdated: 'Сессия обновлена',
      createSessionFailed: 'Не удалось создать сессию',
      updateSessionFailed: 'Не удалось обновить сессию'
    },
    buttons: {
      viewFolderItems: 'Коммуникации на проверку',
      createSession: 'Запланировать сессию'
    },
    sessionInfo: {
      breadcrumbs: {
        allSessions: 'Все сессии',
        currentSession: 'Текущая сессия'
      },
      buttons: {
        viewSessionResult: 'Перейти к результатам'
      },
      infoItems: {
        organizer: 'Организатор: ',
        scheduledTime: 'Дата: ',
        checklistDefinition: 'Формы оценки: ',
        members: 'Участники: ',
        description: 'Описание сессии: ',
        place: 'Место проведения сессии: ',
        totalReviewedItems: 'Общая готовность: '
      },
      tooltip: 'Отображает общее количество оцененных коммуникаций в текущей сессии'
    },
    userReviewStatusTable: {
      tooltip:
        'Отображает количество оцененных коммуникаций конкретного пользователя в текущей сессии',
      reviewedItems: 'Готовность: ',
      table: {
        columns: {
          operator: 'ФИО',
          name: 'Название',
          startedAt: 'Дата и время',
          clientPhoneNumber: 'Номер телефона',
          isReviewed: 'Статус'
        }
      }
    },
    calibrationsList: {
      item: {
        confirmDelete: {
          title: 'Вы уверены что хотите удалить калибровочную сессию?',
          ok: 'Удалить',
          cancel: 'Отмена'
        },
        menu: {
          view: 'Просмотреть содержание сессии',
          setting: 'Перейти к настройкам сессии',
          result: 'Перейти к результатам',
          delete: 'Удалить сессию'
        },
        name: 'Имя: ',
        organizer: 'Организатор: ',
        reviewedItems: 'Готовность: ',
        scheduledTime: 'Дата: ',
        loading: 'Загружаем сессии...😤',
        empty: 'Здесь пока нет сессий 😟',
        all: 'Это всё! 😊'
      }
    },
    folders: {
      messages: {
        fileSuccessfullyDeleted: 'Файл удалён',
        groupSuccessfullyDeleted: 'Группа удалена'
      },
      buttons: {
        deleteFile: 'Удалить файл',
        deleteFolder: 'Удалить папку'
      },
      table: {
        columns: {
          clientInteraction: 'Тип',
          operator: 'ФИО',
          name: 'Название',
          startedAt: 'Дата и время',
          phoneNumber: 'Номер телефона'
        }
      },
      confirmDeleteFolder: {
        title: 'Удалить папку?',
        description:
          'Все прикрепленные к папке записи также будут удалены из всех калибровочных сессий.',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      confirmDeleteFolderItem: {
        title: 'Удалить запись?',
        description: 'Запись будет удалена из всех калибровочных сессий.',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      loading: 'Загружаем группы...🧐',
      empty: 'Не нашлось групп по текущим фильтрам 😟'
    },
    modal: {
      title: 'Настройка калибровочной сессии',
      buttons: {
        save: 'Сохранить',
        cancel: 'Отмена'
      },
      form: {
        communicationsTitle: 'Коммуникации на оценку:',
        fields: {
          organizer: 'Организатор',
          name: 'Название сессии',
          namePlaceholder: 'Введите название сессии',
          scheduledTime: 'Дата и время сессии',
          place: 'Место проведения сессии',
          placePlaceholder: 'Введите место проведения сессии',
          description: 'Описание сессии',
          checklistDefinition: 'Формы оценки',
          members: 'Участники сессии',
          membersPlaceholder: 'Выберите участников сессии',
          folder: 'Группа коммуникаций',
          folderPlaceholder: 'Выберите группу коммуникаций',
          folderItems: 'Коммуникации',
          folderItemsPlaceholder: 'Выберите коммуникации',
          folderItemsMax: 'Выбранно коммуникаций: '
        },
        messages: {
          requiredName: 'Пожалуйста введите название сессии',
          requiredScheduledTime: 'Пожалуйста введите дату сессии',
          requiredСhecklistDefinition: 'Пожалуйста выберите форму оценки',
          requiredMembers: 'Пожалуйста выберите участников',
          requiredFolder: 'Пожалуйста выберите группу коммуникаций',
          requiredFolderItems: 'Пожалуйста выберите коммуникации'
        }
      }
    }
  }
};
