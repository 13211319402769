export default {
  calibrationResultPage: {
    messages: {
      loadSessionsFailed: 'Не удалось загрузить сессию',
      sessionSuccessfullyCompleted: 'Сессия успешно завершена',
      emptyReviews: 'Нет оценок'
    },
    commentPlaceholder: 'Место для ваших заметок по сессии ✍️',
    buttons: {
      completeSession: 'Завершить сессию'
    },
    table: {
      organizer: 'Организатор:',
      columns: {
        gradedPoints: 'Оцениваемые пункты',
        coincidence: 'Совпадение',
        result: 'Результат оценки'
      }
    }
  }
};
