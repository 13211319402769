import { get, post } from 'redux-bees';

const naumenEndpoints = apiPath => ({
  getEntityCustomFieldsNaumen: {
    method: get,
    path: `${apiPath}/naumen_custom_fields`
  },
  refreshEntityCustomFieldsNaumen: {
    method: post,
    path: `${apiPath}/naumen_refresh_custom_fields`
  },
  connectEntityCustomFields: {
    method: post,
    path: `${apiPath}/naumen_connect_custom_field`
  },
  disconnectEntityCustomFields: {
    method: post,
    path: `${apiPath}/naumen_unconnect_custom_field`
  },
  naumenSyncDataByPeriod: { method: post, path: `${apiPath}/naumen_synchronization` },
  getEntityProject: {
    method: get,
    path: `${apiPath}/naumen_projects`
  },
  connectEntityProject: {
    method: post,
    path: `${apiPath}/naumen_connect_project`
  },
  disconnectEntityProject: {
    method: post,
    path: `${apiPath}/naumen_unconnect_project`
  },
  createNaumenIntegration: {
    method: post,
    path: `${apiPath}/naumen_install`
  },
  connectAllEntityCustomFields: {
    method: post,
    path: `${apiPath}/naumen_bulk_action_fields`
  },
  bindEmployeesNaumen: {
    method: post,
    path: `${apiPath}/naumen_bind_employees`
  },
  getNaumenCredentials: {
    method: get,
    path: `${apiPath}/naumen_get_credentials`
  },
  getNaumenDb: {
    method: get,
    path: `${apiPath}/naumen_get_db`
  },
  setNaumenDb: {
    method: post,
    path: `${apiPath}/naumen_set_db`
  },
  getStatusConnectNaumen: {
    method: get,
    path: `${apiPath}/naumen_status`
  }
});

export default naumenEndpoints;
