import React from 'react';
import styled from 'styled-components';
import { Tooltip, Button } from 'antd';

const IconButton = ({ icon, tooltipProps, buttonProps }) => {
  const StyledButton = styled(Button)`
    &.ant-btn {
      color: #82858c;
      width: 24px;
      height: 24px;
      padding: 2px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.ant-btn svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #839df2;
    }
  `;

  return (
    <Tooltip {...tooltipProps}>
      <StyledButton type="link" icon={icon} {...buttonProps} />
    </Tooltip>
  );
};

export default IconButton;
