import { Col, Select } from 'antd';
import styled from 'styled-components';
import SRow from 'components/Standard/SRow';
import { ReactComponent as Divider } from './divider.svg';

export const PlayerRow = styled(SRow)`
  height: 80px;
  background-color: 'var(--gray_4)';
  cursor: pointer;
  border-radius: 4px;
`;

export const PlayerTrack = styled(Col)`
  background-color: ${props => (props.withMessageBoxes ? 'var(--gray_4)' : 'var(--blue_4)')};
  height: ${props => (props.withMessageBoxes ? '100%' : '40px')};
  border-radius: 4px;
  flex: auto;
`;

export const CommentLabelIcon = iconComponent => styled(iconComponent)`
  position: absolute;
  left: -5px;
  bottom: -20px;
  cursor: pointer;
  color: ${props => props.color} !important;
`;

export const BufferedStatus = styled(Col)`
  position: absolute !important;
  left: 0px;
  top: 0px;
  background-color: var(--blue_6);
  height: 40px;
  border-radius: 4px;
  width: ${props => `${props.bufferedPercentage}%`} !important;
`;

export const CommentLabelDivider = styled(Col)`
  &.ant-col {
    left: ${props => props.left} !important;
    background-color: ${props => props.backgroundColor} !important;
    width: 2px;
    height: 80px;
    position: absolute;
    top: ${props => (props.withMessageBoxes ? '0' : '-20px')};
  }
`;

export const PlayerDivider = styled(Divider)`
  left: ${props => props.left} !important;
  position: relative;
  top: ${props => (props.withMessageBoxes ? '0' : '-20px')};
  z-index: 1;
`;

export const PlaybackRateSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    > .ant-select-selector {
    border: none;
    box-shadow: none;
  }
`;

export const Message = styled.div`
  left: ${props => `${props.offset}%`};
  width: ${props => `${props.width}%`};
  height: 80%;
  position: absolute;
  border-radius: 4px;
  transition: opacity 0.6s;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const OperatorMessage = styled(Message)`
  background: var(--blue_4);
  bottom: -1px;
`;

export const ClientMessage = styled(Message)`
  background: var(--purple_4);
`;
