import { orderBy } from 'lodash';

const colorZonesFromRatingValues = (ratingValues = [], colorZones) => {
  const orderedValues = orderBy(ratingValues);
  return {
    ...colorZones,
    medium: { ...colorZones.medium, stop: orderedValues[2] },
    low: { ...colorZones.low, stop: orderedValues[1] }
  };
};

export default colorZonesFromRatingValues;
