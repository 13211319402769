export default {
  crmEntity: {
    responsible: {
      title: 'Responsabile'
    },
    communications: {
      title: 'Comunicazioni'
    },
    dateOfApplication: 'Data di applicazione'
  }
};
