import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { CONVERT_PERIOD_STRING } from 'core/utils/constants';

// TODO: move to dashboards

const PeriodInfo = ({ startDate, groupingPeriod, isVisibleYear }) => {
  const start = moment(startDate);
  const end = moment(startDate)
    .add(1, CONVERT_PERIOD_STRING[groupingPeriod])
    .subtract(1, 'day');

  const tooltipString =
    groupingPeriod === 'day'
      ? start.format('DD/MM/YYYY')
      : `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`;

  return (
    <Tooltip title={tooltipString}>{start.format(isVisibleYear ? 'DD/MM/YYYY' : 'DD/MM')}</Tooltip>
  );
};
export default PeriodInfo;
