import { get, post } from 'redux-bees';

const promptsEndpoints = apiPath => ({
  getPrompts: { method: get, path: `${apiPath}/prompts/list` },
  getListPromptsQuestionsAll: { method: get, path: `${apiPath}/prompts/listbindable` },
  getListPromptsCustomFieldsAll: { method: get, path: `${apiPath}/prompts/listbindable` },
  getPrompt: { method: get, path: `${apiPath}/prompts` },
  getCategoryOrganizationsPrompts: { method: get, path: `${apiPath}/prompts/categories` },
  deletePrompt: { method: post, path: `${apiPath}/prompts/delete` },
  createPrompt: { method: post, path: `${apiPath}/prompts/add` },
  updatePrompt: { method: post, path: `${apiPath}/prompts/update` }
});

export default promptsEndpoints;
