import { get, post, put, destroy } from 'redux-bees';

const rolesEndpoints = apiPath => ({
  getRoles: { method: get, path: `${apiPath}/organization/roles` },
  updateRole: { method: put, path: `${apiPath}/organization/roles/:id` },
  createRole: { method: post, path: `${apiPath}/organization/roles` },
  deleteRole: { method: destroy, path: `${apiPath}/organization/roles/:id` }
});

export default rolesEndpoints;
