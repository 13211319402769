import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  updatedToken: '',
  loading: false
};

export const updateTokenStarted = createAction('customHttpTelephonyIntegration/updateTokenStarted');
export const tokenUpdatedSucceed = createAction(
  'customHttpTelephonyIntegration/tokenUpdatedSucceed'
);

export const customHttpTelephonyReducer = createReducer(defaultState, {
  [updateTokenStarted]: () => ({
    loading: true
  }),
  [tokenUpdatedSucceed]: (state, { payload }) => ({
    ...state,
    updatedToken: payload,
    loading: false
  })
});

export default customHttpTelephonyReducer;
