import { get, post } from 'redux-bees';

const ednaEndpoints = apiPath => ({
  createEdnaIntegration: {
    method: post,
    path: `${apiPath}/edna_install`
  },
  getStatusConnectEdna: {
    method: get,
    path: `${apiPath}/edna_status`
  },
  getEdnaDb: {
    method: get,
    path: `${apiPath}/edna_get_credentials`
  },
  setEdnaDb: {
    method: post,
    path: `${apiPath}/edna_set_credentials`
  },
  connectAllEntityCustomFieldsEdna: {
    method: post,
    path: `${apiPath}/edna_bulk_action_fields`
  },
  connectEntityCustomFieldsEdna: {
    method: post,
    path: `${apiPath}/edna_connect_custom_field`
  },
  disconnectEntityCustomFieldsEdna: {
    method: post,
    path: `${apiPath}/edna_unconnect_custom_field`
  },
  getEntityCustomFieldsEdna: {
    method: get,
    path: `${apiPath}/edna_custom_fields`
  }
});

export default ednaEndpoints;
