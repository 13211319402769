export default {
  triggersSettingsPage: {
    loading: 'Caricamento in corso',
    card: {
      titles: {
        create: 'Crea etichetta',
        edit: 'Modifica tag'
      },
      buttons: {
        enable: 'Attivare',
        disable: 'Disattivare',
        settings: 'Vai alle impostazioni dei tag',
        delete: 'Elimina tag',
        createTaskDefinitionCard: 'Crea tag'
      },
      confirmDelete: {
        title: 'Sei sicuro di voler eliminare il tag?',
        ok: 'Elimina',
        cancel: 'Annulla'
      },
      info: {
        type: 'Tipo: ',
        author: 'Autore',
        status: {
          title: 'Stato',
          enable: 'Attivo',
          disable: 'Non attivo'
        },
        checklistDefinition: 'Modulo di valutazione',
        createdAt: 'Creato'
      },
      loading: 'Caricamento tag...😤',
      listLoaded: "E' tutto! 😊"
    },
    editTaskDefinition: {
      messages: {
        tagSuccessfullyUpdated: 'Tag aggiornato',
        tagSuccessfullySaved: 'Tag salvato',
        tagSuccessfullyDeleted: 'Tag eliminato',
        addSettingsToAutomaticTag: 'Aggiungi impostazioni per il tuo tag automatico:'
      },
      buttons: {
        cancel: 'Annulla',
        save: 'Salvare'
      },
      text: {
        form: 'da:',
        to: 'a:'
      },
      empty: {
        checklistDefinition:
          "Non e' stato selezionato alcun modulo di valutazione o non ci sono criteri nel modulo di valutazione",
        checklistDefinitionItemName: 'Nome non impostato'
      },
      form: {
        name: 'Nome',
        namePlaceholder: 'Inserisci il nome',
        checklistDefinition: 'Modulo di valutazione',
        type: {
          title: 'Tipo',
          automatic: {
            name: 'Automatico ',
            description:
              '(viene aggiunto alla chiamata dal risultato della compilazione del modulo di valutazione)'
          },
          manual: {
            name: 'Manuale ',
            description: '(puoi aggiungere tu stesso alla chiamata)'
          }
        }
      },
      changeTagTypeConfirm: {
        title: 'Cambiare il tipo del tag?',
        description: 'Tutti i dati non salvati verranno eliminati',
        ok: 'Si',
        cancel: 'No'
      },
      changeChecklistDefinitionConfirm: {
        title: 'Cambiare la lista di controllo?',
        description: 'Tutti i dati non salvati verranno eliminati',
        ok: 'Si',
        cancel: 'No'
      },
      resetChecklistDefinitionConditionsConfirm: {
        title: "Una condizione non puo' includere criteri o gruppi di criteri",
        description: "Reimpostare le condizioni per l'intero modulo di valutazione?",
        ok: 'Si',
        cancel: 'No'
      },
      resetChecklistDefinitionQuestionsConditionsConfirm: {
        title: "Una condizione non puo' includere criteri",
        description: 'Reimpostare le condizioni per il gruppo di criteri?',
        ok: 'Si',
        cancel: 'No'
      }
    }
  }
};
