import { destroy, get, put, post } from 'redux-bees';

// * folders
const reviewLibrariesEndpoints = apiPath => ({
  // getReviewLibraries: {
  //   method: get,
  //   path: `/api/v3/libraries/get`
  // },
  getReviewLibraries: {
    method: get,
    path: `${apiPath}/libraries`
  },
  getReviewLibrary: {
    method: get,
    path: `${apiPath}/libraries/:id`
  },
  createReviewLibrary: {
    method: post,
    path: `${apiPath}/libraries`
  },
  updateReviewLibrary: {
    method: put,
    path: `${apiPath}/libraries/:id`
  },
  deleteReviewLibrary: {
    method: destroy,
    path: `${apiPath}/libraries/:id`
  }
});

export default reviewLibrariesEndpoints;
