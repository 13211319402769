import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  widgets: {},
  loadingWidgets: false,
  updatingWidgets: false
};

export const loadingWidgets = createAction('analytics/loadingWidgets');

export const loadedWidgets = createAction('analytics/loadedWidgets');

export const loadingWidgetsFailed = createAction('analytics/loadingWidgetsFailed');

export const updatingWidgets = createAction('analytics/updatingWidgets');

export const updatedWidgets = createAction('analytics/updatedWidgets');

export const updatingWidgetsFailed = createAction('analytics/updatingWidgetsFailed');

export const analyticsWidgetsReducer = createReducer(defaultState, {
  [loadingWidgets]: state => ({ ...state, loadingWidgets: true }),
  [loadedWidgets]: (state, { payload }) => ({
    ...state,
    loadingWidgets: false,
    widgets: payload
  }),
  [loadingWidgetsFailed]: state => ({ ...state, widgets: {}, loadingWidgets: false }),
  [updatingWidgets]: state => ({ ...state, updatingWidgets: true }),
  [updatedWidgets]: (state, { payload }) => ({
    ...state,
    updatingWidgets: false,
    widgets: { ...state.widgets, ...payload }
  }),
  [updatingWidgetsFailed]: state => ({ ...state, widgets: {}, updatingWidgets: false })
});

export default analyticsWidgetsReducer;
