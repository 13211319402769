import { Button, Col, message, Row, Typography } from 'antd';
import SpecificUsersSelect from 'components/Inputs/SpecificUsersSelect';
import { PERMISSIONS } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { communicationTablesResource } from 'redux/resources/communicationTables';
import { getUsersWithPermissions } from 'redux/selectors/users';
import { toogleVisibleShareSettings, updateActiveTable } from 'redux/ui/clientInteractions/reducer';

const ShareSettings = ({
  toogleVisibleShareSettings,
  usersByPermission,
  updateActiveTable,
  tableId,
  tableSharedUsers,
  shareCommunicationTable
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const setUsersToShare = value => {
    updateActiveTable({ tableSharedUsers: value });
  };

  const shareTable = async () => {
    try {
      await shareCommunicationTable({
        id: tableId,
        usersIds: tableSharedUsers
      });
      await toogleVisibleShareSettings();
      message.success(
        t('clientInteractionsPage.drawer.tables.shareTable.messages.tableSuccessfullyShared')
      );
    } catch (error) {
      console.log(error);
      message.error(
        t('clientInteractionsPage.drawer.tables.shareTable.messages.tableSharedFailed')
      );
    }
  };

  return (
    <>
      <Row type="flex" align="middle" justify="space-between" style={{ height: '30px' }}>
        <Text strong>{t('clientInteractionsPage.drawer.tables.shareTable.title')}</Text>
      </Row>
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        gutter={[8, 8]}
        style={{ margin: '-4px' }}
      >
        <Col span={24}>
          <SpecificUsersSelect
            placeholder={t(
              'clientInteractionsPage.drawer.tables.shareTable.sharedUserSelectPlaceholder'
            )}
            style={{ width: '100%' }}
            mode="multiple"
            onChange={setUsersToShare}
            updateFilters={updateActiveTable}
            items={usersByPermission}
            keyName="tableSharedUsers"
            value={tableSharedUsers}
            filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            usersToSelect={usersByPermission}
          />
        </Col>

        <Col span={24}>
          <Button block onClick={shareTable}>
            {t('clientInteractionsPage.drawer.tables.shareTable.buttons.apply')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  const { isVisibleAddColumnBlock, tableId, tableSharedUsers } = state.uiClientInteractions;
  const currentUserId = get(state, 'reduxTokenAuth.currentUser.attributes.id', '');
  const usersByPermission = Object.values(
    getUsersWithPermissions(state, {
      permissions: [PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS]
    })
  ).filter(user => user.active && user.id !== currentUserId);
  return {
    usersByPermission,
    tableId,
    tableSharedUsers,
    isVisibleAddColumnBlock
  };
};

const mapDispatchToProps = {
  shareCommunicationTable: communicationTablesResource.operations.shareTableById,
  toogleVisibleShareSettings,
  updateActiveTable
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareSettings));
