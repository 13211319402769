export default {
  recoverPasswordPage: {
    title: 'Recupero della password',
    description: "Inserisci l'indirizzo e-mail che utilizzi per il tuo accaunt.",
    buttons: { login: 'Login' },
    form: {
      fields: { emailPlaceholder: 'Inserisci la tua e-mail' },
      messages: {
        enterEmail: 'Inserisci e-mail',
        wrongEmail: "Inserisci l'email corretta"
      },
      buttons: { continue: 'Continuare' }
    },
    success: {
      title: 'Grazie!',
      description:
        "Abbiamo inviato le istruzioni su come recuperare la tua password all'indirizzo email che hai fornito. Se l'email con le istruzioni non e' arrivata entro 10 minuti, controlla la correttezza dell'indirizzo email che hai inserito.",
      buttons: {
        login: 'Entrare',
        register: 'Registrazione'
      }
    },
    setNewPassword: {
      title: {
        newUser: '👋 Benvenuto!',
        setPass: '🔐 Scegli una password sicura'
      },
      form: {
        newPassMessage: 'Scegli una password sicura per',
        fields: { passwordPlaceholder: 'Inserisci la password' },
        messages: {
          enterPass: 'Inserisci la password',
          minLengthPassword: 'Minimo 6 caratteri'
        },
        buttons: { continue: 'Continuare' }
      }
    }
  }
};
