import { exportWidgetData } from 'pages/DashboardPage/utils';
import { updateDashboardPageWidget } from 'redux/entities/dashboardPages/operations';
import { beatifyFloat } from 'core/utils/convertFloat';
import React from 'react';
import {
  isArray,
  isObject,
  keyBy,
  orderBy,
  compact,
  valuesIn,
  isNil,
  uniq,
  keys,
  reduce,
  get
} from 'lodash';
import { Tooltip } from 'antd';
import camelcaseKeys from 'camelcase-keys';
import UserPreview from 'components/UserPreview';
import { getUserName } from 'components/UserPreview/getUserName';

import i18next from 'i18next';
import {
  StyledRenderCell,
  TruncatedSText
} from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/AutoFailCountsBy/styled';
import { calcPercent, formatTableCellValue, prepareFooterData } from '../utils';
import { prepareTableData } from '../AutoFailHistoryReport/prepareTableData';

export const VISIBLE_WIDGET_DATE_FORMAT = 'DD.MM.YYYY';
export const BACKEND_WIDGET_DATE_FORMAT = 'YYYY-MM-DD';

export const getColorizedColumn = ({ color, component }) => ({
  props: {
    style: {
      background: color,
      border: '2px solid white',
      borderRadius: '4px',
      padding: '5px'
    }
  },
  children: component
});

const renderCell = (text, record) =>
  getColorizedColumn({
    color: text?.color || 'var(--gray_4)',
    component: (
      <StyledRenderCell>
        <TruncatedSText ellipsis strong={record.key === 'footer'}>
          {text?.value || text}
        </TruncatedSText>
      </StyledRenderCell>
    )
  });

export const AUTO_FAIL_SCORES_COLUMNS = [
  {
    dataIndex: 'date',
    key: 'date',
    fixed: 'left',
    align: 'left',
    render: renderCell
  },
  {
    dataIndex: 'checklistDefinitionAverageScoreHistory',
    key: 'checklistDefinitionAverageScoreHistory',
    align: 'center',
    render: (text, record) =>
      getColorizedColumn({
        color: text?.color || 'var(--gray_4)',
        component: (
          <TruncatedSText strong={record.key === 'footer'}>
            {formatTableCellValue(text?.value, 'checklistDefinitionAverageScoreHistory')}
          </TruncatedSText>
        )
      })
  },
  {
    dataIndex: 'reviewsCountHistory',
    key: 'reviewsCountHistory',
    align: 'center',
    render: renderCell
  },
  {
    dataIndex: 'clientInteractionsCountHistory',
    key: 'clientInteractionsCountHistory',
    align: 'center',
    render: renderCell
  },
  {
    dataIndex: 'percentOfClientInteractions',
    key: 'percentOfClientInteractions',
    align: 'center',
    render: (text, record) =>
      getColorizedColumn({
        color: text?.color || 'var(--gray_4)',
        component: (
          <TruncatedSText strong={record.key === 'footer'}>
            {formatTableCellValue(text?.value, 'percentOfClientInteractions')}
          </TruncatedSText>
        )
      })
  },
  {
    dataIndex: 'anyTypeAutofailsCountHistory',
    key: 'anyTypeAutofailsCountHistory',
    align: 'center',
    render: renderCell
  },
  {
    dataIndex: 'reviewsWithAnyTypeAutofailCount',
    key: 'reviewsWithAnyTypeAutofailCount',
    align: 'center',
    render: renderCell
  },
  {
    dataIndex: 'percentOfReviewsWithAnyTypeAutofailCount',
    key: 'percentOfReviewsWithAnyTypeAutofailCount',
    align: 'center',
    render: (text, record) =>
      getColorizedColumn({
        color: text?.color || 'var(--gray_4)',
        component: (
          <TruncatedSText strong={record.key === 'footer'}>
            {formatTableCellValue(text?.value, 'percentOfReviewsWithAnyTypeAutofailCount')}
          </TruncatedSText>
        )
      })
  }
];

export const onTableChange = async ({
  pagination,
  sorter,
  widgetId,
  tableColumns,
  filters,
  dispatch,
  setInitialState
}) => {
  setInitialState({
    number: pagination?.current,
    size: pagination?.pageSize - 1
  });

  const columnsKeys = tableColumns.map(item => item.key);
  const { columnKey } = sorter;

  const isNewSort = columnsKeys.includes(columnKey);

  const getSorterString = () =>
    sorter?.order === 'ascend' ? sorter?.columnKey : `-${sorter?.columnKey}`;

  await dispatch(
    updateDashboardPageWidget({
      id: widgetId,
      filters,
      page: {
        number: pagination.current,
        size: pagination.pageSize - 1
      },
      sort: isNewSort ? getSorterString() : ''
    })
  );
};

export const onAdd = ({
  id,
  updatedFilter,
  filters,
  setVisibleFilterModal,
  dispatch,
  widgetPageSize,
  name
}) => {
  dispatch(
    updateDashboardPageWidget({
      id,
      filters: {
        ...filters,
        ...updatedFilter
      },
      page: {
        number: 1,
        size: widgetPageSize
      },
      name
    })
  );
  setVisibleFilterModal(false);
};

// ?we can used it with antd since 4.16.0 to separate summary and tooter data for table
// export const prepareSummaryData = ({ data }) => {
//   const totalReviewsCountHistory = sumBy(data, 'reviewsCountHistory');
//   const totalClientInteractionsCountHistory = sumBy(data, 'clientInteractionsCountHistory');
//   const totalAnyTypeAutofailsCountHistory = sumBy(data, 'anyTypeAutofailsCountHistory');
//   const totalReviewsWithAnyTypeAutofailCount = sumBy(data, 'reviewsWithAnyTypeAutofailCount');
//
//   const percentOfClientInteractions = formatTableCellValue(
//     calcPercent(totalReviewsCountHistory, totalClientInteractionsCountHistory),
//     'percentOfClientInteractions'
//   );
//
//   const percentOfReviewsWithAnyTypeAutofailCount = formatTableCellValue(
//     calcPercent(totalAnyTypeAutofailsCountHistory, totalReviewsWithAnyTypeAutofailCount),
//     'percentOfReviewsWithAnyTypeAutofailCount'
//   );
//
//   return {
//     id: 'footer',
//     date: i18next.t('dashboardPages.autoFailScores.footer.total'),
//     reviewsCountHistory: totalReviewsCountHistory,
//     clientInteractionsCountHistory: totalClientInteractionsCountHistory,
//     anyTypeAutofailsCountHistory: totalAnyTypeAutofailsCountHistory,
//     reviewsWithAnyTypeAutofailCount: totalReviewsWithAnyTypeAutofailCount,
//     percentOfClientInteractions,
//     percentOfReviewsWithAnyTypeAutofailCount
//   };
// };

export const getValues = (obj, field) => {
  const result = [];

  if (isArray(obj?.[field])) result.push(...obj?.[field]);
  else if (isObject(obj?.[field])) result.push(...valuesIn(obj[field]).map(item => item * 1));
  else if (obj?.[field]) result.push(...[obj[field] * 1]);

  return compact(result);
};

export const prepareCriticalErrorsTableData = ({ widgetAnalytics, questions, isUnitsMode }) => {
  return orderBy(
    widgetAnalytics?.data?.reduce((acc, item) => {
      const units = keyBy(widgetAnalytics.units, 'id');
      const questionsData = questions.reduce((acc, question) => {
        const replacedData = keyBy(item?.data, 'question_id');
        const value = replacedData[question?.id]?.percentage;

        return {
          ...acc,
          [question?.id]: {
            value: parseFloat(beatifyFloat(value)),
            color: 'var(--gray_4)'
          }
        };
      }, {});

      const data = {
        dataIndex: isUnitsMode ? item.unit_id : item.operator_id,
        key: isUnitsMode ? item.unit_id : item.operator_id,
        id: isUnitsMode ? item.unit_id : item.operator_id,
        name: isUnitsMode ? units[item?.unit_id]?.attributes?.name : '',
        align: 'center',
        ...questionsData
      };
      return [...acc, data];
    }, []),
    ''
  );
};

export const prepareCriticalErrorsTableColumns = ({ questions, isUnitsMode }) => {
  const criteriaColumns = questions.reduce((acc, question) => {
    if (question.type === 'checklist-question-groups') return [...acc];
    const data = {
      title: question.name,
      dataIndex: question.id,
      key: question.id,
      id: question.id,
      align: 'center',
      width: questions.length < 10 ? null : 100,
      render: (text, record) => {
        const value =
          beatifyFloat(record[question.id]?.value) === 'N/A'
            ? 'N/A'
            : `${beatifyFloat(record[question.id]?.value)}%`;
        return getColorizedColumn({
          color: 'var(--gray_4)',
          component: <TruncatedSText strong={record.key === 'footer'}>{value}</TruncatedSText>
        });
      }
    };
    return [...acc, data];
  }, []);
  return [
    {
      title: isUnitsMode ? i18next.t('general.unit') : i18next.t('general.employees'),
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      ellipsis: true,
      width: 350,
      render: (text, record) => {
        const getComponent = () => {
          const targetComponent =
            record?.key === 'footer' ? (
              <TruncatedSText strong>{`${i18next.t('general.total')}:`}</TruncatedSText>
            ) : (
              <>
                {isUnitsMode ? (
                  <Tooltip title={record.key === 'footer' ? '' : record.name}>
                    <TruncatedSText ellipsis strong={record.key === 'footer'}>
                      {record.name}
                    </TruncatedSText>
                  </Tooltip>
                ) : (
                  <UserPreview userId={text} showAvatar showEmail disabled />
                )}
              </>
            );
          return (
            <div style={{ height: '42px', display: 'flex', alignItems: 'center' }}>
              {targetComponent}
            </div>
          );
        };
        return getColorizedColumn({
          color: 'var(--gray_4)',
          component: getComponent()
        });
      }
    },
    ...criteriaColumns
  ];
};

export const prepareCriticalErrorsFooterRow = ({ widgetAnalytics, questions }) => {
  if (widgetAnalytics?.data?.length === 0) return null;

  const totalScoresByDates = keyBy(widgetAnalytics?.total_autofails_count, 'question_id');
  return {
    key: 'footer',
    title: i18next.t('general.total'),
    id: i18next.t('general.total'),
    ...questions.reduce((acc, question) => {
      const value = totalScoresByDates[question.id]?.percentage;
      return {
        ...acc,
        [question.id]: { value: parseFloat(beatifyFloat(value)) }
      };
    }, {})
  };
};

export const startExport = async ({
  id,
  meta,
  filters,
  dispatch,
  initialState,
  setLoadingExport,
  datesArray,
  columns,
  checklistDefinition
}) => {
  const res = await dispatch(
    updateDashboardPageWidget({
      id,
      filters,
      page: {
        number: 1,
        size: meta.total_count
      }
    })
  );

  const { metadata, meta: _, total, ...data } = res[id];

  let dataTmp = camelcaseKeys(data);
  datesArray.forEach(item => {
    const dateKey = item.format(BACKEND_WIDGET_DATE_FORMAT);
    const percentOfClientInteractions = calcPercent(
      dataTmp?.reviewsCountHistory?.[dateKey],
      dataTmp?.clientInteractionsCountHistory?.[dateKey]
    );
    const percentOfReviewsWithAnyTypeAutofailCount = calcPercent(
      dataTmp?.reviewsWithAnyTypeAutofailCount?.[dateKey],
      dataTmp?.anyTypeAutofailsCountHistory?.[dateKey]
    );

    dataTmp = {
      ...dataTmp,
      percentOfClientInteractions: {
        ...dataTmp.percentOfClientInteractions,
        ...{ [dateKey]: percentOfClientInteractions }
      },
      percentOfReviewsWithAnyTypeAutofailCount: {
        ...dataTmp.percentOfReviewsWithAnyTypeAutofailCount,
        ...{ [dateKey]: percentOfReviewsWithAnyTypeAutofailCount }
      }
    };
  });

  const tableData = prepareTableData({
    data: dataTmp,
    datesArray,
    columns: AUTO_FAIL_SCORES_COLUMNS,
    checklistDefinition
  });

  const rows = [
    ...tableData,
    ...[
      {
        ...prepareFooterData({
          data: camelcaseKeys(total || {}),
          checklistDefinition
        })
      }
    ]
  ];

  const columnsForExport = columns.reduce((prev, { title, dataIndex, key }) => {
    prev.push({ title, dataIndex, key });
    return prev;
  }, []);

  const body = {
    widgetData: {
      columns: columnsForExport,
      rows
    }
  };

  await exportWidgetData({ body, setLoadingExport });

  await dispatch(
    updateDashboardPageWidget({
      id,
      filters,
      page: {
        ...initialState
      }
    })
  );
};

export const startCriticalErrorExport = async ({
  id,
  meta,
  users,
  filters,
  dispatch,
  questions,
  initialState,
  isUnitsMode,
  setLoadingExport
}) => {
  const res = await dispatch(
    updateDashboardPageWidget({
      id,
      filters,
      page: {
        number: 1,
        size: meta.total_count
      }
    })
  );

  const columns = prepareCriticalErrorsTableColumns({
    questions,
    isUnitsMode
  });
  const prepareRows = () => {
    return res[id]?.data.reduce((acc, item) => {
      const units = keyBy(res[id]?.units, 'id');
      const questionsData = questions.reduce((acc, question) => {
        const replacedData = keyBy(item?.data, 'question_id');
        const value = replacedData[question?.id]?.percentage;

        return {
          ...acc,
          [question?.id]: { value: parseFloat(beatifyFloat(value)) }
        };
      }, {});

      const data = {
        id: isUnitsMode
          ? units[item?.unit_id]?.attributes?.name
          : getUserName({ user: users[item.operator_id] }),
        dataIndex: item.operator_id,
        key: item.operator_id,
        align: 'center',
        ...questionsData
      };
      return [...acc, data];
    }, []);
  };

  const rows = [
    ...prepareRows(),
    prepareCriticalErrorsFooterRow({
      widgetAnalytics: res[id],
      questions
    })
  ];

  const body = {
    widgetData: {
      columns,
      rows
    }
  };

  await exportWidgetData({ body, setLoadingExport });

  await dispatch(
    updateDashboardPageWidget({
      id,
      filters,
      page: {
        ...initialState
      }
    })
  );
};

export const prepareChartData = (dataSource, datesArray) => {
  let chartData = {};

  Object.keys(dataSource)
    .filter(propName =>
      [
        'percentOfReviewsWithAnyTypeAutofailCount',
        'checklistDefinitionAverageScoreHistory',
        'percentOfClientInteractions'
      ].includes(propName)
    )
    .forEach(propName => {
      datesArray.forEach(dateMoment => {
        const dateKey = dateMoment.format(BACKEND_WIDGET_DATE_FORMAT);
        const value = dataSource?.[propName]?.[dateKey];
        if (!isNil(value)) {
          chartData = {
            ...chartData,
            [propName]: {
              ...chartData[propName],
              [dateKey]: Math.ceil(parseFloat(value) * 10) / 10
            }
          };
        }
      });
    });

  const categories = uniq(reduce(chartData, (result, value) => [...result, ...keys(value)], []));

  return { chartData, categories };
};

export const getSeriesData = ({ dataSource }) => {
  return [
    {
      name: i18next.t(
        'dashboardPages.autoFailScores.columns.checklistDefinitionAverageScoreHistory'
      ),
      type: 'column',
      data: Object.values(get(dataSource, 'checklistDefinitionAverageScoreHistory', {}))
    },
    {
      name: i18next.t('dashboardPages.autoFailScores.columns.percentOfClientInteractions'),
      type: 'line',
      data: Object.values(get(dataSource, 'percentOfClientInteractions', {}))
    },
    {
      name: i18next.t(
        'dashboardPages.autoFailScores.columns.percentOfReviewsWithAnyTypeAutofailCount'
      ),
      type: 'line',
      data: Object.values(get(dataSource, 'percentOfReviewsWithAnyTypeAutofailCount', {}))
    }
  ];
};
