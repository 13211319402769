import { flatMap, isEmpty, range, uniq } from 'lodash';

export const isKpiSettingsHasIntersection = ({ conditions = [] }) => {
  if (isEmpty(conditions)) return false;
  const arrayOfRanges = flatMap(
    conditions.map(condition => {
      return range(condition.min, condition.max + 1);
    })
  );
  const uniqValues = uniq(arrayOfRanges);

  return arrayOfRanges.length !== uniqValues.length;
};
