import { Card, Col, Divider, InputNumber, Radio, Row } from 'antd';
import styled from 'styled-components';

export const StyledRadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledRow = styled(Row)`
  &.ant-row {
    margin-bottom: 24px !important;
  }
`;

export const StyledDivider = styled(Divider)`
  &.ant-divider {
    margin: 12px 0 8px 0;
  }
`;

export const SelectChecklistCol = styled(Col)`
  &.ant-col {
    margin-bottom: 6px;
  }
`;

export const StyledCard = styled(Card)`
  &.ant-card {
    margin: 4px 0;
    border-radius: 4px;
    background-color: ${props => props.color};
  }
  &.ant-card > .ant-card-body {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const RateInput = styled(InputNumber)`
  width: 63px !important;
  margin: 0 5px !important;

  .ant-input-number-handler-wrap {
    opacity: 1 !important;
  }
`;

export const RateCol = styled(Col)`
  &.ant-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
