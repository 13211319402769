import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { beatifyFloat } from 'core/utils/convertFloat';
import React from 'react';
import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

const DifferenceArrow = ({ previousPeriod, currentPeriod, displayTypeString }) => {
  const { t } = useTranslation();
  if (previousPeriod === 'N/A' || currentPeriod === 'N/A') return 'N/A';

  if (previousPeriod > currentPeriod) {
    const prevDiff = previousPeriod - currentPeriod;
    if (prevDiff < 0.1) return 'N/A';

    return (
      <Tooltip
        title={`${t('teamPage.unitTable.infoIcons.previousResultInfo')}: -${beatifyFloat(
          prevDiff,
          1
        )} ${displayTypeString}`}
      >
        <Text>{`-${beatifyFloat(prevDiff, 1)}${displayTypeString}`}</Text>
        <ArrowDown />
      </Tooltip>
    );
  }

  if (previousPeriod < currentPeriod) {
    const currDiff = currentPeriod - previousPeriod;
    if (currDiff < 0.1) return 'N/A';

    return (
      <Tooltip
        title={`${t('teamPage.unitTable.infoIcons.previousResultInfo')}: +${beatifyFloat(
          currDiff,
          1
        )} ${displayTypeString}`}
      >
        <Text>{`+${beatifyFloat(currDiff, 1)}${displayTypeString}`}</Text>
        <ArrowUp />
      </Tooltip>
    );
  }

  return 'N/A';
};

const ArrowUp = styled(ArrowUpOutlined)`
  &.anticon {
    color: var(--green_6);
  }
`;

const ArrowDown = styled(ArrowDownOutlined)`
  &.anticon {
    color: var(--red_6);
  }
`;

export default DifferenceArrow;
