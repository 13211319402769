export default {
  calibrationsPage: {
    messages: {
      sessionSuccessfullyCreated: 'Sessione creata',
      sessionSuccessfullyUpdated: 'Sessione aggiornata',
      createSessionFailed: 'Impossibile creare la sessione',
      updateSessionFailed: 'Impossibile aggiornare la sessione'
    },
    buttons: {
      viewFolderItems: 'Comunicazioni per la verifica',
      createSession: 'Pianifica una sessione'
    },
    sessionInfo: {
      breadcrumbs: {
        allSessions: 'Tutte le sessioni',
        currentSession: 'Sessione corrente'
      },
      buttons: { viewSessionResult: 'Passare ai risultati' },
      infoItems: {
        organizer: 'Organizzatore: ',
        scheduledTime: 'Data: ',
        checklistDefinition: 'Moduli di valutazione: ',
        members: 'Partecipanti: ',
        description: 'Descrizione della sessione: ',
        place: 'Luogo della sessione: ',
        totalReviewedItems: 'Prontezza generale: '
      },
      tooltip: 'Visualizza il numero totale di comunicazioni valutate nella sessione corrente'
    },
    userReviewStatusTable: {
      tooltip:
        'Visualizza il numero di comunicazioni valutate di un particolare utente nella sessione corrente',
      reviewedItems: 'Prontezza: ',
      table: {
        columns: {
          operator: 'Nome e cognome',
          name: 'Nome',
          startedAt: 'Data e ora',
          clientPhoneNumber: 'Numero di telefono',
          isReviewed: 'Stato'
        }
      }
    },
    calibrationsList: {
      item: {
        confirmDelete: {
          title: 'Sei sicuro di voler eliminare la sessione di calibrazione?',
          ok: 'Elimina',
          cancel: 'Annulla'
        },
        menu: {
          view: 'Visualizza il contenuto della sessione',
          setting: 'Vai alle impostazioni della sessione',
          result: 'Vai ai risultati',
          delete: 'Elimina sessione'
        },
        name: 'Nome: ',
        organizer: 'Organizzatore: ',
        reviewedItems: 'Prontezza: ',
        scheduledTime: 'Data: ',
        loading: 'Caricamento sessioni...😤',
        empty: 'Non ci sono ancora sessioni qui...😟',
        all: "E' tutto! 😊"
      }
    },
    folders: {
      messages: {
        fileSuccessfullyDeleted: 'File eliminato',
        groupSuccessfullyDeleted: 'Gruppo eliminato'
      },
      buttons: {
        deleteFile: 'Elimina un file',
        deleteFolder: 'Elimina cartella'
      },
      table: {
        columns: {
          clientInteraction: 'Tipo',
          operator: 'Nome e cognome',
          name: 'Nome',
          startedAt: 'Data e ora',
          phoneNumber: 'Numero di telefono'
        }
      },
      confirmDeleteFolder: {
        title: 'Eliminare la cartella?',
        description:
          'Tutti i registrazioni allegati alla cartella veranno rimossi anche da tutte le sessioni di calibrazione.',
        ok: 'Elimina',
        cancel: 'Annulla'
      },
      confirmDeleteFolderItem: {
        title: 'Eliminare la registrazione?',
        description: "La registrazione verra' rimossa da tutte le sessioni di calibrazione.",
        ok: 'Elimina',
        cancel: 'Annulla'
      },
      loading: 'Caricamento gruppi...🧐',
      empty: "Non c'erano gruppi in base ai filtri correnti 😟"
    },
    modal: {
      title: 'Impostazione della sessione di calibrazione',
      buttons: {
        save: 'Salvare',
        cancel: 'Annulla'
      },
      form: {
        communicationsTitle: 'Comunicazioni per la valutazione:',
        fields: {
          organizer: 'Organizzatore',
          name: 'Nome della sessione',
          namePlaceholder: 'Immetti il nome della sessione',
          scheduledTime: 'Data e ora della sessione',
          place: 'Luogo della sessione',
          placePlaceholder: 'Immetti il luogo della sessione',
          description: 'Descrizione della sessione',
          checklistDefinition: 'Moduli di valutazione',
          members: 'Partecipanti della sessione',
          membersPlaceholder: 'Seleziona i partecipanti della sessione',
          folder: 'Gruppo di comunicazione',
          folderPlaceholder: 'Seleziona un gruppo di comunicazione',
          folderItems: 'Comunicazioni',
          folderItemsPlaceholder: 'Seleziona comunicazioni',
          folderItemsMax: 'Comunicazioni selezionate: '
        },
        messages: {
          requiredName: 'Immetti per favore un nome di sessione',
          requiredScheduledTime: 'Inserisci per favore la data della sessione',
          requiredСhecklistDefinition: 'Per favore seleziona un modulo di valutazione',
          requiredMembers: 'Per favore seleziona i partecipanti',
          requiredFolder: 'Per favore seleziona un gruppo di comunicazione',
          requiredFolderItems: 'Per favore seleziona comunicazioni'
        }
      }
    }
  }
};
