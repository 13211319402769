import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const ClientInteractionsMeta = ({ loading, loadingTables, tableLoading, meta, hasConflicts }) => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Row type="flex" gutter={[8, 8]} style={{ margin: '-12px -4px -4px -4px' }} align="middle">
        <Col style={{ paddingBottom: '12px' }}>
          <Typography.Text strong>
            {t('clientInteractionsPage.clientInteractionMeta.searchResult')}
          </Typography.Text>
          {loading || loadingTables || tableLoading ? (
            <LoadingOutlined style={{ color: '#0061D0' }} />
          ) : (
            <Typography.Text>{hasConflicts ? '' : meta?.totalCount}</Typography.Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  loading: state.uiClientInteractions.loadingMeta,
  loadingTables: state.communicationTablesResource.loading,
  tableLoading: state.uiClientInteractions.tableLoading,
  meta: state.uiClientInteractions.meta
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInteractionsMeta);
