import React, { useCallback } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { Typography, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';

const { Title } = Typography;

const UnitSettings = ({ setAddingUserToUnit }) => {
  const { t } = useTranslation();
  const selectedUnit = useSelector(state => state.uiOrganizationPage.selectedUnit, isEqual);
  const { name, id } = selectedUnit;
  const onAddEmployee = useCallback(() => {
    setAddingUserToUnit(id);
  }, [id]);

  if (isEmpty(selectedUnit)) return null;

  return (
    <SRow
      type="flex"
      justify="space-between"
      align="middle"
      gutter={[0, 16]}
      style={{ marginBottom: '-8px' }}
      wrap={false}
    >
      <SCol flex="auto">
        <Title ellipsis className="no-margin" level={5}>{`${name}`}</Title>
      </SCol>
      <SCol flex="none">
        <Button type="primary" size="small" onClick={onAddEmployee}>
          {t('organizationStructure.addEmployee')}
        </Button>
      </SCol>
    </SRow>
  );
};

export default UnitSettings;
