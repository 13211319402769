import { Button } from 'antd';
import styled from 'styled-components';
import {
  flexbox,
  background,
  space,
  layout,
  color,
  variant,
  typography,
  border,
  shadow,
  position
} from 'styled-system';

export default styled(Button)`
&.ant-btn {
    ${variant({
      prop: 'size',
      variants: {
        large: {
          width: '172px',
          height: '42px',
          fontSize: '16px'
        }
      }
    })}
    ${typography}
    ${flexbox}
    ${space}
    ${layout}
    ${color}
    ${border}
    ${background}
    ${shadow}
    ${position}
}
`;
