import { Typography } from 'antd';
import { beatifyFloat } from 'core/utils/convertFloat';
import { valueFormatter } from 'core/utils/valueFormatter';
import { Trans } from 'react-i18next';
import { get, isEmpty, keyBy, mean, orderBy, sum } from 'lodash';
import moment from 'moment';
import React from 'react';
import PeriodInfo from 'components/PeriodInfo';
import UserPreview from 'components/UserPreview';
import { stringify } from 'core/utils/queryString';
import { getDatesArray, getFilters, valueToColorZoneColor } from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({ checklistDefinition, filters, timeFrom, timeTo }) => {
  const { isWeekendView, historyGroupingPeriod } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });

  const datesColumns = datesArray.map(day => ({
    title: (
      <PeriodInfo startDate={day} groupingPeriod={historyGroupingPeriod} isVisibleYear={false} />
    ),
    dataIndex: moment(day).format('YYYY/MM/DD'),
    key: moment(day).format('YYYY/MM/DD'),
    align: 'center',
    width: datesArray.length < 15 ? null : 90,
    render: (text, record) => {
      return {
        props: {
          style: {
            background: text?.color
          }
        },
        children:
          record.key === 'header'
            ? text?.value
            : (text?.value || parseFloat(text?.value) === 0) && (
                <Text strong={record.key === 'footer'}>
                  {valueFormatter({
                    value: text?.value,
                    ratingMode: checklistDefinition.ratingMode
                  })}
                </Text>
              )
      };
    }
  }));

  return [
    {
      title: <Trans i18nKey="dashboardPage.tableReviewsCountByOperators.table.columns.dates" />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 270,
      render: (text, record) => {
        return {
          children:
            record.key === 'header' || record.key === 'footer' ? (
              <Text strong={record.key === 'footer'}>
                <Trans i18nKey={record.name} />
              </Text>
            ) : (
              <UserPreview
                userId={record.key}
                margin="0px"
                showAvatar
                showUnit
                link={`/user/${record.key}/charts${stringify({
                  filters: { ...getFilters(filters) }
                })}`}
              />
            )
        };
      }
    },

    {
      title: <Trans i18nKey="dashboardPage.widget.unitName" />,
      dataIndex: 'unitName',
      key: 'unitName',
      fixed: 'left',
      width: 80
    },

    {
      title: <Trans i18nKey="dashboardPage.dashboard.reviewsCountShort" />,
      dataIndex: 'reviewsCount',
      key: 'reviewsCount',
      fixed: 'left',
      width: 80,
      align: 'center',
      render: text => text
    },

    ...datesColumns,

    {
      title: (
        <Trans i18nKey="dashboardPage.tableReviewsCountByOperators.table.columns.totalScore" />
      ),
      dataIndex: 'totalScore',
      key: 'totalScore',
      align: 'center',
      fixed: 'right',
      width: 140,
      render: (text, record) => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: (
            <Text strong={record.key === 'footer'}>
              {record.key === 'header'
                ? text?.value
                : valueFormatter({
                    value: text?.value,
                    ratingMode: checklistDefinition.ratingMode
                  })}
            </Text>
          )
        };
      }
    }
  ];
};

export const prepareTableData = ({
  filters,
  timeFrom,
  timeTo,
  usersByIds,
  unitsByIds,
  checklistDefinition,
  checklistDefinitionAverageScore,
  checklistDefinitionAverageScoreHistory,
  checklistDefinitionAverageScoreByOperatorsHistory,
  checklistDefinitionAverageScoreByOperators,
  reviewsCountByOperatorsHistory,
  reviewsCountByOperators
}) => {
  const { isWeekendView, historyGroupingPeriod } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });

  const getReviewCountByDate = ({ date }) => {
    const sumByDate = sum(
      reviewsCountByOperatorsHistory.map(item => item.history[moment(date).format('YYYY-MM-DD')])
    );
    return sumByDate || '';
  };

  const totalReviewsCount = sum(Object.values(reviewsCountByOperators).map(item => item.value));
  const averageReviewsCount = beatifyFloat(
    mean(Object.values(reviewsCountByOperators).map(item => item.value))
  );

  const mappedUsersScore = () => {
    const checklistDefinitionAverageScoreByOperatorsIds = keyBy(
      checklistDefinitionAverageScoreByOperators,
      'id'
    );
    const reviewCountByOperatorsByIds = keyBy(reviewsCountByOperators, 'id');

    return orderBy(
      checklistDefinitionAverageScoreByOperatorsHistory.reduce((acc, item) => {
        if (isEmpty(item.history)) return acc;

        const history = datesArray.reduce((acc, day) => {
          const value = parseFloat(item.history[moment(day).format('YYYY-MM-DD')]);
          return {
            ...acc,
            [moment(day).format('YYYY/MM/DD')]: {
              value,
              color: valueToColorZoneColor({ value, colorZones: checklistDefinition.colorZones })
            }
          };
        }, {});

        const checklistDefinitionAverageScoreByOperator = get(
          checklistDefinitionAverageScoreByOperatorsIds,
          `${item.id}.value`,
          0
        );

        const data = {
          key: item.id,
          name: item?.user_name?.trim() === '' ? usersByIds[item?.id]?.email : item?.user_name,
          unitName: unitsByIds[usersByIds[item.id]?.unitId]?.name,
          lastName: usersByIds[item.id]?.lastName,
          ...history,
          totalScore: {
            value: checklistDefinitionAverageScoreByOperator,
            color: valueToColorZoneColor({
              value: checklistDefinitionAverageScoreByOperator,
              colorZones: checklistDefinition.colorZones
            })
          },
          reviewsCount: get(reviewCountByOperatorsByIds, `${item.id}.value`, 0),
          totalReviewsScore: beatifyFloat(
            checklistDefinitionAverageScoreByOperatorsIds[item.id]?.value
          )
        };
        return [...acc, data];
      }, []),
      'lastName'
    );
  };

  return [
    {
      key: 'header',
      name: 'dashboardPage.tableReviewsCountByOperators.table.columns.reviewsCount',
      ...datesArray.reduce((acc, day) => {
        const formattedDayString = moment(day).format('YYYY/MM/DD');
        const value = getReviewCountByDate({
          date: formattedDayString
        });
        return {
          ...acc,
          [formattedDayString]: {
            value,
            color: undefined
          }
        };
      }, {}),
      reviewsCount: totalReviewsCount,
      totalScore: {
        value: totalReviewsCount,
        color: undefined
      }
    },

    ...mappedUsersScore(),

    {
      key: 'footer',
      name: 'dashboardPage.tableReviewsCountByOperators.table.columns.averageScore',
      ...datesArray.reduce((acc, day) => {
        const value = parseFloat(
          get(checklistDefinitionAverageScoreHistory, `${moment(day).format('YYYY-MM-DD')}`)
        );
        return {
          ...acc,
          [moment(day).format('YYYY/MM/DD')]: {
            value,
            color: valueToColorZoneColor({
              value,
              colorZones: checklistDefinition.colorZones
            })
          }
        };
      }, {}),
      totalScore: {
        value: parseFloat(checklistDefinitionAverageScore),
        color: valueToColorZoneColor({
          value: checklistDefinitionAverageScore,
          colorZones: checklistDefinition.colorZones
        })
      },
      reviewsCount: parseFloat(averageReviewsCount),
      totalReviewsScore: beatifyFloat(checklistDefinitionAverageScore)
    }
  ];
};
