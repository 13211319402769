import React from 'react';
import { Row } from 'antd';
import { ResponsiveBar } from '@nivo/bar';
import { WIDGET_FLAGS_COLORS_RELATIONS, FLAGS_COLORS_LITERALS_RUS } from 'core/utils/constants';
import { tooltipSetter } from 'pages/DashboardPage/utils';
import styled from 'styled-components';
import { sum, get } from 'lodash';
import { beatifyFloat } from 'core/utils/convertFloat';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';

const CommentsBar = ({ comments, textColor, onClickHandler }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const withoutFlag = get(comments, 'without_flag', 0);
  const red = get(comments, 'red', 0);
  const yellow = get(comments, 'yellow', 0);
  const green = get(comments, 'green', 0);

  const preparedData = {
    id: uniqid(),
    without_flag: withoutFlag,
    red,
    yellow,
    green
  };

  const commentsCount = sum([withoutFlag, red, yellow, green]);

  const percentageValues = {
    without_flag: (100 / commentsCount) * withoutFlag,
    red: (100 / commentsCount) * red,
    yellow: (100 / commentsCount) * yellow,
    green: (100 / commentsCount) * green
  };

  return (
    <StyledRow>
      <ResponsiveBar
        data={[preparedData]}
        keys={['without_flag', 'red', 'yellow', 'green']}
        indexBy="id"
        margin={{ top: -4, right: 0, bottom: -4, left: 0 }}
        layout="horizontal"
        colors={bar => WIDGET_FLAGS_COLORS_RELATIONS[bar.id]}
        tooltip={tooltip =>
          tooltipSetter({
            name: t(FLAGS_COLORS_LITERALS_RUS[tooltip.id]),
            value: `${beatifyFloat(percentageValues[tooltip.id])}%`
          })
        }
        labelTextColor={textColor || '#ffffff'}
        labelSkipWidth={12}
        labelSkipHeight={12}
        animate={false}
        motionStiffness={0}
        motionDamping={0}
        onClick={
          onClickHandler ? (node, e) => onClickHandler({ e, history, nodeEvent: node }) : undefined
        }
      />
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  &.ant-row {
    height: 36px !important;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
  }
`;

export default CommentsBar;
