import React from 'react';
import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { EmptyDashboardCard } from '../styled';

const LoadingDashboard = () => {
  const { t } = useTranslation();

  return (
    <EmptyDashboardCard>
      <Empty
        image={<Spin />}
        imageStyle={{
          height: 20
        }}
        description={t('dashboardPage.dashboard.loading')}
      />
    </EmptyDashboardCard>
  );
};

export default LoadingDashboard;
