import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  id: '',
  type: '',
  name: '',
  filters: {},
  reviewsCount: {
    loading: false,
    axiosToken: null,
    count: 0
  }
};

export const updateUiWidgetSettings = createAction(
  'uiWidgetSettingsModalReducer/updateUiWidgetSettings'
);

export const updateUiWidgetFilters = createAction(
  'uiWidgetSettingsModalReducer/updateUiWidgetFilters'
);

export const resetUiWidgetModal = createAction('uiWidgetSettingsModalReducer/resetUiWidgetModal');

export const resetUiWidgetFilters = createAction(
  'uiWidgetSettingsModalReducer/resetUiWidgetFilters'
);

export const setReviewsCount = createAction('uiWidgetSettingsModalReducer/setReviewsCount');

export const setReviewsCountLoading = createAction(
  'uiWidgetSettingsModalReducer/setReviewsCountLoading'
);

export const updateAxiosToken = createAction('uiWidgetSettingsModalReducer/updateAxiosToken');

export const uiWidgetSettingsModalReducer = createReducer(defaultState, {
  [updateUiWidgetSettings]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [updateUiWidgetFilters]: (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload }
  }),
  [resetUiWidgetModal]: () => ({ ...defaultState }),
  [resetUiWidgetFilters]: state => ({ ...state, filters: {} }),
  [setReviewsCount]: (state, { payload }) => ({
    ...state,
    reviewsCount: { ...state.reviewsCount, count: payload, loading: false }
  }),
  [setReviewsCountLoading]: (state, { payload }) => ({
    ...state,
    reviewsCount: { ...state.reviewsCount, loading: payload }
  }),
  [updateAxiosToken]: (state, { payload }) => ({
    ...state,
    reviewsCount: {
      ...state.reviewsCount,
      axiosToken: payload
    }
  })
});
