import { createAction, createReducer } from '@reduxjs/toolkit';
import { omit } from 'lodash';

const defaultState = {
  currentUser: undefined,
  currentChecklistDefinitionId: 'all',
  checklistDefinitionsIds: [],
  filters: {},
  currentLevel: undefined,
  reviewsIds: [],
  allSkills: {
    questionGroups: {
      analytics: {},
      history: {}
    },
    questions: {
      analytics: {},
      history: {}
    }
  },
  answersHistoryQuestionId: undefined,
  scoresHistoryChecklistDefinitionId: undefined
};

export const setCurrentChecklistDefinitionId = createAction(
  'uiUserAnalyticsPage/setCurrentChecklistDefinitionId'
);
export const setChecklistDefinitionsIds = createAction(
  'uiUserAnalyticsPage/setChecklistDefinitionsIds'
);
export const setCurrentUser = createAction('uiUserAnalyticsPage/setCurrentUser');
export const setCurrentLevel = createAction('uiUserAnalyticsPage/setCurrentLevel');
export const setFiltersByUserId = createAction('uiUserAnalyticsPage/setFiltersByUserId');
export const resetFiltersByUserId = createAction('uiUserAnalyticsPage/resetFiltersByUserId');

export const setReviewsIds = createAction('uiUserAnalyticsPage/setReviewsIds');

export const skillsLoaded = createAction('uiUserAnalyticsPage/skillsLoaded');

export const setAnswersHistoryQuestionId = createAction(
  'uiUserAnalytics/setAnswersHistoryQuestionId'
);

export const setScoresHistoryChecklistDefinitionId = createAction(
  'uiUserAnalytics/scoresHistoryChecklistDefinitionId'
);

export const userAnalyticsPageReducer = createReducer(defaultState, {
  [setCurrentChecklistDefinitionId]: (state, { payload }) => {
    return { ...state, currentChecklistDefinitionId: payload, analytics: {} };
  },
  [setCurrentLevel]: (state, { payload }) => ({ ...state, setCurrentLevel: payload }),
  [setChecklistDefinitionsIds]: (state, { payload }) => {
    return { ...state, checklistDefinitionsIds: payload };
  },
  [setFiltersByUserId]: (state, { payload }) => {
    const item = state.filters[payload.userId] || {};
    return {
      ...state,
      filters: {
        ...state.filters,
        [payload.userId]: { ...item, ...omit(payload, 'userId') }
      }
    };
  },
  [resetFiltersByUserId]: (state, { payload }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [payload.userId]: {}
      }
    };
  },
  [setCurrentUser]: (state, { payload }) => {
    return { ...state, currentUser: payload, analytics: {} };
  },
  [setReviewsIds]: (state, { payload }) => {
    return { ...state, reviewsIds: payload };
  },
  [skillsLoaded]: (state, { payload }) => {
    return { ...state, allSkills: payload };
  },
  [setAnswersHistoryQuestionId]: (state, { payload }) => ({
    ...state,
    answersHistoryQuestionId: payload
  }),
  [setScoresHistoryChecklistDefinitionId]: (state, { payload }) => ({
    ...state,
    scoresHistoryChecklistDefinitionId: payload
  })
});

export default userAnalyticsPageReducer;
