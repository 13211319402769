export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'Loaded',
      loadFailed: 'File loading failed',
      maxFileText: 'File Size',
      maxFileSize: 'exceeds 30 MB.',
      fileFormatText: 'Incorrect file extension',
      fileFormatMessage: 'only mp3, wav or csv files are allowed.',
      fileFormat: 'You can upload files in mp3, wav or csv format, up to 30 megabytes/file in size',
      deleteSuccess: 'Successfully deleted',
      editSuccess: 'The changes are saved',
      saveEditingTooltip: 'To save it, you must fill in the required fields'
    },
    buttons: {
      loadCalls: 'Upload calls',
      downloadExample: 'CSV Example',
      delete: 'Remove',
      save: 'Save',
      cancelEditing: 'Undo changes'
    },
    table: {
      columns: {
        operator: 'Operator',
        direction: 'Call direction',
        startedAt: 'Call date',
        startedAtPlaceholder: 'Choose call date',
        clientPhoneNumber: 'Client phone number',
        duration: 'Call duration',
        actions: 'Actions',
        communicationType: 'Call',
        fileSize: 'Size',
        directionTitle: 'Direction',
        selectOperator: 'Select an operator'
      },
      uinstalledCallDirection: 'Unknown',
      confirmChange: {
        title: 'Changes will not be saved',
        ok: 'Continue',
        cancel: 'Cancel'
      },
      buttons: {
        cancel: 'Cancel',
        edit: 'Edit',
        delete: 'Delete'
      },
      confirmDelete: {
        title: 'Are you sure?',
        content: 'It will be impossible to cancel this action',
        ok: 'Remove',
        cancel: 'Cancel'
      },
      cancelEditing: {
        title: 'Editing',
        content: 'Are you sure you want to undo the changes?',
        ok: 'OK',
        cancel: 'Cancel'
      }
    }
  }
};
