import { destroy, get, post, put } from 'redux-bees';

const checklistDefinitionsEndpoints = apiPath => ({
  getChecklistDefinitions: { method: get, path: `${apiPath}/checklist/definitions` },
  getChecklistDefinition: { method: get, path: `${apiPath}/checklist/definitions/:id` },
  deleteChecklistDefinition: { method: destroy, path: `${apiPath}/checklist/definitions/:id` },
  createChecklistDefinition: { method: post, path: `${apiPath}/checklist/definitions` },
  updateChecklistDefinition: { method: put, path: `${apiPath}/checklist/definitions/:id` },
  copyChecklistDefinition: { method: post, path: `${apiPath}/checklist/definitions/:id/duplicate` },
  getChecklistDefinitionsWithInclude: { method: get, path: '/api/v2/checklist/definitions' }
});

export default checklistDefinitionsEndpoints;
