import { Modal } from 'antd';
import api from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import BitrixService from 'core/services/bitrix';
import { INTEGRATIONS, PERMISSIONS } from 'core/utils/constants';
import decamelize from 'decamelize-keys';
import { isEmpty } from 'lodash';
import { signInUser } from 'redux/reducers/reduxTokenAuthReducer';
import { integrationsResource } from 'redux/resources/integrations';
import { getCurrentUser } from 'redux/selectors/users';
import { saveBackgroundJob } from '../backgroundJob/operations';
import { setCurrentBackgroundJob } from '../backgroundJob/reducer';

export const registerWithBitrix = ({
  password,
  email,
  organizationName,
  id,
  firstName,
  lastName,
  phoneNumber
}) => async dispatch => {
  try {
    const auth = BitrixService.getAuth();

    if (isEmpty(auth)) return;

    const response = await api.bitrixRegisterOrganization(
      decamelize({
        adminPassword: password,
        organizationName,
        adminIntegrationUid: id,
        adminPhoneNumber: phoneNumber,
        adminEmail: email,
        adminFirstName: firstName,
        adminLastName: lastName,
        ...auth,
        endpoint: auth.domain
      })
    );

    await dispatch(signInUser({ email, password }));

    const integration = processJsonApiObject(response.body.data);

    dispatch(saveBackgroundJob(integration.currentBackgroundJob));
    return integration;
  } catch (error) {
    console.log(error);
  }
};

export const syncDataWithBitrix = ({ id, fetchFrom }) => async dispatch => {
  const response = await api.bitrixSyncDataByPeriod(
    { id },
    decamelize({
      fetchFrom
    })
  );

  const backgroundJob = processJsonApiObject(response.body.data);

  return dispatch(saveBackgroundJob(backgroundJob));
};

export const redirectToBitrix = bitrixDomain => {
  const domain = bitrixDomain.match(/^(?:\/\/|[^/]+)*/)[0] || '';
  const subdomain = domain.match(/([^.]*)$/)[0];

  const subdomainToBitrixLink = {
    ru: 'https://www.bitrix24.ru/apps/?app=dealappio.dealapp_ru',
    by: 'https://www.bitrix24.by/apps/?app=dealappio.dealapp'
  };

  window.location.href = subdomainToBitrixLink[subdomain] || subdomainToBitrixLink.ru;
};

export const showRedirectModal = bitrixDomain => {
  Modal.info({
    onOk: () => redirectToBitrix(bitrixDomain),
    okText: 'Перейти',
    title: 'Интеграция с Bitrix24',
    width: '560px',
    content:
      'Для интеграции с Битрикс24 вы будете перенаправлены на наше приложение из маркетплейса. Установите приложение, авторизируйтесь внутри Битрикс24 и возвращайтесь сюда для создания интеграции!  😉'
  });
};

export const connectToBitrix = () => async (dispatch, getState) => {
  const user = getCurrentUser(getState());
  const auth = BitrixService.getAuth();
  const response = await api.bitrixConnectToOrganization(
    decamelize({
      ...auth,
      endpoint: auth.domain
    })
  );
  const integration = processJsonApiObject(response.body.data);
  return { integration, user };
};

export const createBitrixIntegration = ({ name, bitrixDomain }) => async dispatch => {
  if (!BitrixService.isIntegrated) {
    //  TODO: create integration
    try {
      await dispatch(
        integrationsResource.operations.create({
          name,
          endpoint: bitrixDomain,
          integrationType: INTEGRATIONS.bitrix_crm.type
        })
      );
      return showRedirectModal(bitrixDomain);
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  const auth = BitrixService.getAuth();

  if (isEmpty(auth)) return;

  try {
    await dispatch(
      integrationsResource.operations.create({
        name,
        endpoint: bitrixDomain,
        integrationType: INTEGRATIONS.bitrix_crm.type
      })
    );

    if (auth.domain !== bitrixDomain) {
      return showRedirectModal(bitrixDomain);
    }

    const { integration: connectedIntegration } = await dispatch(connectToBitrix());

    dispatch(saveBackgroundJob(connectedIntegration?.currentBackgroundJob));

    return connectedIntegration?.id;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const checkUncompleteBitrixInstallation = ({ history }) => async dispatch => {
  const { INSTALLED } = await BitrixService.getAppInfo();

  if (!INSTALLED) {
    const { integration, user } = await dispatch(connectToBitrix());

    if (!integration.initialized) {
      if (!isEmpty(integration?.currentBackgroundJob)) {
        dispatch(setCurrentBackgroundJob(integration?.currentBackgroundJob));
      }

      if (user?.role?.permissions.includes(PERMISSIONS.CAN_MANAGE_INTEGRATIONS)) {
        history.push(
          `/user/${user.id}/integrations-settings/new/${INTEGRATIONS.bitrix_crm.type}/${integration.id}`
        );
        return false;
      }
    } else {
      BitrixService.finishInstall();
    }
  }
  return true;
};
