import { Col, Empty, Row, Spin, Table } from 'antd';
import SCard from 'components/Standard/SCard';
import { isEmpty, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { libraryCountEndpoint } from 'core/api';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reviewLibrariesResource } from 'redux/resources/reviewLibraries';
import { usersResource } from 'redux/resources/users';
import SRow from 'components/Standard/SRow';
import { setCurrentInteractionId } from 'redux/ui/libraryPage/reducer';
import { setEditingItem } from 'redux/ui/libraryPage/operations';
import { reviewLibraryItemsResource } from 'redux/resources/reviewLibraryItems';
import ClientInteraction from './components/ClientInteraction';
import ClientInteractionPreview from './components/ClientInteractionPreview';
import CreateFolder from './components/CreateFolder';
import Folder from './components/Folder';

const LibraryPage = ({
  loading,
  foldersByIds = {},
  currentFolder,
  currentClientInteractionId,
  itemsByIds,
  users,
  loadLibraries,
  loadUsers,
  loadLibrary,
  currentOrganizationId
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState();
  const [total, setTotal] = useState();

  useEffect(async () => {
    if (isEmpty(users)) {
      loadUsers({
        pagination: 'false',
        sort: 'last_name',
        'filters[with_inactive]': 'true'
      });
    }
    loadLibraries();
  }, []);

  // Получаем дату соответствующую часовому поясу юзера
  const getDateTimezone = id => {
    if (itemsByIds?.[id]) {
      const createdAt = itemsByIds[id]?.createdAt;
      const createdAtStr = new Date(createdAt);
      const timezoneOffset = createdAtStr.getTimezoneOffset();
      const adjustedDate = new Date(createdAtStr.getTime() - timezoneOffset * 60000);

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      };

      return adjustedDate.toLocaleString(options).replace(',', '');
    }
    return null;
  };

  const elems = [];
  if (!isEmpty(currentFolder)) {
    const itemIds = currentFolder.itemsIds;
    if (!isEmpty(itemIds)) {
      itemIds.forEach((id, index) => {
        const row = {
          key: index + 1,
          name: itemsByIds?.[id] ? <ClientInteractionPreview key={id} id={id} /> : null,
          added_at: getDateTimezone(id)
        };

        if (itemsByIds?.[id]) {
          elems.push(row);
        }
      });
      // for (const id of itemIds) {
      //   const row = {
      //     key,
      //     name: itemsByIds?.[id] ? <ClientInteractionPreview key={id} id={id} /> : null,
      //     added_at: itemsByIds?.[id]
      //       ? itemsByIds[id].createdAt.replace('T', ' ').split('.')[0]
      //       : null
      //   };
      //
      //   key++;
      //
      //   if (itemsByIds?.[id]) {
      //     elems.push(row);
      //   }
      // }
    }
  }

  elems.sort((a, b) => new Date(b.added_at) - new Date(a.added_at));

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      width: '300px',
      key: 'name'
    },
    {
      title: 'Добавлено',
      dataIndex: 'added_at',
      width: '200px',
      key: 'added_at'
    }
  ];

  return (
    <SRow gutter={[16, 16]} padding="16px">
      <Helmet>
        <title>{t('pagesMeta.libraryPage.title')}</title>
      </Helmet>

      <Col span={24}>
        <CreateFolder />
      </Col>
      {isEmpty(foldersByIds) && (
        <Col span={24}>
          <SCard>
            <Spin spinning={loading}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('libraryPage.libraryItem.empty.folders')}
              />
            </Spin>
          </SCard>
        </Col>
      )}
      {!isEmpty(foldersByIds) && (
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Row gutter={[16, 16]}>
                {orderBy(foldersByIds, ['createdAt'], ['desc']).map(({ id, name }) => (
                  <Col flex="auto" style={{ minWidth: '220px' }} key={id}>
                    <Folder
                      id={id}
                      page={1}
                      setTotal={setTotal}
                      setPage={setPage}
                      name={name}
                      loadLibrary={loadLibrary}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={12}>
              {currentFolder && isEmpty(currentFolder.itemsIds) && (
                <SCard style={{ height: 208 }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('libraryPage.libraryItem.empty.calls')}
                    style={{ margin: '52px 0' }}
                  />
                </SCard>
              )}
              {currentFolder && !isEmpty(currentFolder.itemsIds) && (
                <Table
                  size="small"
                  columns={columns}
                  dataSource={elems}
                  headerBorderRadius={40}
                  style={{ width: '900px' }}
                  pagination={{
                    total,
                    onChange: async page => {
                      await loadLibraries({
                        type: 'library',
                        folder_id: currentFolder?.id,
                        organization_id: currentOrganizationId,
                        page
                      });
                      const url = new URL(libraryCountEndpoint);
                      url.search = new URLSearchParams({
                        folder_id: currentFolder?.id,
                        organization_id: currentOrganizationId,
                        page
                      });
                      const response = await fetch(url);
                      const records = await response.json();
                      setTotal(records.count);
                      setPage(page);
                    },
                    showSizeChanger: false,
                    pageSizeOptions: [],
                    current: page,
                    pageSize: 50
                  }}
                />
              )}
            </Col>
            <Col span={8}>
              {currentClientInteractionId && <ClientInteraction id={currentClientInteractionId} fromLibrary={true} />}
            </Col>
          </Row>
        </Col>
      )}
    </SRow>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { byIds: foldersByIds, loading } = state.reviewLibrariesResource;
  const { byIds: itemsByIds } = state.reviewLibraryItemsResource;

  if (loading) return { loading };

  const { currentFolderId, currentClientInteractionId, editingFolder } = state.uiLibraryPage;
  const currentFolder = currentFolderId ? foldersByIds[currentFolderId] : undefined;
  const currentOrganizationId = state.organizationInfo.organization.id;
  const users = state.usersResource.byIds;

  return {
    loading,
    foldersByIds: editingFolder
      ? { ...foldersByIds, [editingFolder.id]: editingFolder }
      : foldersByIds,
    currentFolderId,
    currentFolder,
    currentOrganizationId,
    selectInteraction: setCurrentInteractionId,
    itemsByIds,
    users,
    clientInteractions: state.reviewsResource.byIds,
    onDelete: reviewLibraryItemsResource.operations.deleteById,
    isSelected: ownProps.id === state.uiLibraryPage.currentClientInteractionId,
    currentClientInteractionId
  };
};

const mapDispatchToProps = {
  loadLibraries: reviewLibrariesResource.operations.load,
  loadLibrary: reviewLibrariesResource.operations.loadById,
  loadUsers: usersResource.operations.load,
  onEdit: setEditingItem
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryPage);
