const es = {
  name: 'es',
  options: {
    months: [
      'Enero',
      'Febrero',
      'Mar',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ],
    shortMonths: [
      'Enero',
      'Feb',
      'Marzo',
      'Abr',
      'Mayo',
      'Jun',
      'Jul',
      'Ago',
      'Sept',
      'Oct',
      'Nov',
      'Dic'
    ],
    days: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
    shortDays: ['Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vie', 'Sáb'],
    toolbar: {
      download: 'Cargar SVG',
      selection: 'Elección',
      selectionZoom: 'Acercar el período',
      zoomIn: 'Acercar',
      zoomOut: 'Alejar',
      pan: 'Movimiento',
      reset: 'Restablecer la aproximación'
    }
  }
};
export default es;
