import { Col, List, Row, Spin } from 'antd';
import ClientInteractionLink from 'components/ClientInteractionLink';
import Truncated from 'components/Truncated';
import UserPreview from 'components/UserPreview';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import { orderBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shallowEqual from 'react-redux/lib/utils/shallowEqual';
import { phoneCallChainsResource } from 'redux/resources/phoneCallChains';
import { getPhoneCallsByChainId } from 'redux/selectors/phoneCallChains';
import styled from 'styled-components';
import SCol from 'components/Standard/SCol';

const StyledCol = Truncated(SCol);
const StyledItem = styled(List.Item)`
  font-weight: ${props => (props.currentCall ? 500 : 'inherit')};
`;

const PhoneCallChain = ({ callId, phoneCallChainId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (phoneCallChainId) {
      dispatch(
        phoneCallChainsResource.operations.loadById({
          id: phoneCallChainId,
          include: 'phone_calls.operator'
        })
      );
    }
  }, []);

  const calls = useSelector(
    state => orderBy(getPhoneCallsByChainId(state, phoneCallChainId), 'startedAt'),
    shallowEqual
  );

  const loading = useSelector(state => state.phoneCallChainsResource.loadByIdStarted);

  if (loading)
    return (
      <Row justify="center" align="middle" style={{ minHeight: 130 }}>
        <Col>
          <Spin tip={t('general.loading')} spinning />
        </Col>
      </Row>
    );

  return (
    <List
      loading={loading}
      dataSource={calls}
      rowKey="id"
      renderItem={item => (
        <StyledItem currentCall={item.id === callId}>
          <Row
            justify="space-between"
            align="middle"
            gutter={[8, 8]}
            style={{ margin: '-4px', width: '100%' }}
          >
            <StyledCol span={2}>
              <ClientInteractionLink id={item.id} communication={item} useDrawer={false} />
            </StyledCol>
            <StyledCol span={6}>
              <UserPreview userId={item?.operatorId} disabled showAvatar />
            </StyledCol>
            <StyledCol span={4}>{t(CALL_DIRECTION_LITERALS[item.direction])}</StyledCol>
            <StyledCol span={4}>
              {moment()
                .startOf('day')
                .seconds(item.duration)
                .format('HH:mm:ss')}
            </StyledCol>
            <StyledCol span={4}>{item.clientPhoneNumber}</StyledCol>
            <StyledCol span={4}>{moment(item.startedAt).format('DD/MM/YYYY HH:mm')}</StyledCol>
          </Row>
        </StyledItem>
      )}
    />
  );
};

export default PhoneCallChain;
