import { workTimeUserTracking, workTimeUserTrackingInit } from 'core/api';
import axios from 'axios';

export const initWorkTimeTracking = async (type, entityId) => {
  try {
    const data = {
      uid: localStorage.getItem('uid'),
    };

    if (type) {
      data.type = type;
    }
    if (entityId) {
      data.entity_id = entityId;
    }
    await axios.post(`${workTimeUserTrackingInit}`, data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const workTimeTracking = async (type, entityId) => {
  try {
    const data = {
      uid: localStorage.getItem('uid'),
    };

    if (type) {
      data.type = type;
    }
    if (entityId) {
      data.entity_id = entityId;
    }

    await axios.post(`${workTimeUserTracking}`, data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
