import { Button, Popover } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import CustomTag from './CustomTag';

const TagsContainer = styled.div`
  display: inline;
  overflow: hidden;
  width: ${props => props.width || 'auto'};
`;

export const TagsDisplay = ({ tasks, width, truncate }) => {
  if (isEmpty(tasks)) {
    return null;
  }

  const buttonText = ` +${tasks.length - 1}`;

  const renderedTask = tasks[0];

  if (isEmpty(renderedTask) || !renderedTask.taskDefinition) {
    return null;
  }

  if (tasks.length > 1) {
    return (
      <TagsContainer width={width}>
        <Popover
          content={
            <>
              {tasks.slice(1).map(({ taskDefinition }) => {
                if (!taskDefinition) {
                  return null;
                }
                return (
                  <CustomTag
                    color={taskDefinition.labelColor}
                    text={taskDefinition.labelName}
                    key={taskDefinition.labelName}
                  />
                );
              })}
            </>
          }
          placement="leftTop"
        >
          <Button style={{ padding: '0px 4px 0px 0px', maxHeight: 22 }} type="link">
            {buttonText}
          </Button>
        </Popover>
        <CustomTag
          color={renderedTask.taskDefinition.labelColor}
          text={renderedTask.taskDefinition.labelName}
          truncate={truncate}
        />
      </TagsContainer>
    );
  }

  return (
    <TagsContainer width={width}>
      <CustomTag
        color={renderedTask.taskDefinition.labelColor}
        text={renderedTask.taskDefinition.labelName}
        truncate={truncate}
      />
    </TagsContainer>
  );
};
