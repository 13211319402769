import { TASKS_STATUS } from 'core/utils/constants';
import { reduce } from 'lodash';
import { tasksResource } from 'redux/resources/tasks';
import createListReducer from 'core/redux/lists/listReducer';
import camelcase from 'camelcase';

export const mapTaskStatusToListReducer = status => `${camelcase(status)}TasksList`;

const tasksListReducers = reduce(
  TASKS_STATUS,
  (acc, status) => {
    const config = {
      name: `${status}Tasks`,
      defaultState: {
        filters: { taskStatuses: status },
        page: {
          number: '1',
          size: '10'
        }
      },
      additionalReducer: {
        removeTask: (state, { payload }) => {
          const newIds = [...state.ids].filter(id => id !== payload.id);
          return { ...state, ids: newIds };
        }
      },
      rebuildQuery: true,
      loader: tasksResource.operations.load,
      keepResults: true
    };

    return { ...acc, [mapTaskStatusToListReducer(status)]: createListReducer(config) };
  },
  {}
);

export const getTasksListReducers = () =>
  reduce(tasksListReducers, (acc, slice) => ({ ...acc, [slice.name]: slice.reducer }), {});

export default tasksListReducers;
