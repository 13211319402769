import { FileDoneOutlined, FlagFilled, MessageFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import { FLAGS_TO_COLORS_RELATIONS, THEME_COLORS_LITERALS } from 'core/utils/constants';
import { isEqual, isNil, slice, sortBy, uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCommentsFilteredByPermission } from 'redux/selectors/comments';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import uniqid from 'uniqid';
import CommentContent from './CommentContent';
import PreviewIcons from './PreviewIcons';
import { FlagRow, SingleIcon } from './styled';

const types = [
  {
    id: uniqid(),
    icon: FlagFilled,
    colorName: THEME_COLORS_LITERALS.RED,
    themeColor: FLAGS_TO_COLORS_RELATIONS.red
  },
  {
    id: uniqid(),
    icon: FlagFilled,
    colorName: THEME_COLORS_LITERALS.GREEN,
    themeColor: FLAGS_TO_COLORS_RELATIONS.green
  },
  {
    id: uniqid(),
    icon: FlagFilled,
    colorName: THEME_COLORS_LITERALS.YELLOW,
    themeColor: FLAGS_TO_COLORS_RELATIONS.yellow
  },
  {
    id: uniqid(),
    icon: MessageFilled,
    colorName: null,
    themeColor: FLAGS_TO_COLORS_RELATIONS.without_flag
  },
  {
    id: uniqid(),
    icon: FileDoneOutlined,
    colorName: null,
    themeColor: FLAGS_TO_COLORS_RELATIONS.without_flag
  }
];

const filterCommentsByColor = (comments, color) => {
  return comments
    .map(comment => ({ ...comment, ratingFlag: comment.ratingFlag || null }))
    .filter(comment => {
      return comment.ratingFlag === color;
    });
};

const sortByTimestamp = comments =>
  slice(
    sortBy(comments, [
      comment => {
        return isNil(comment.metadata) ? null : comment.metadata.timestamp;
      }
    ]),
    0,
    5
  );

const textByColor = (comments, color, disabledUserLink) => {
  return sortByTimestamp(filterCommentsByColor(comments, color)).map(comment => (
    <CommentContent {...comment} key={comment.id} disabledUserLink={disabledUserLink} />
  ));
};

const commentsWithColors = (comments, color, disabledUserLink = false) => {
  return sortByTimestamp(filterCommentsByColor(comments, color)).map(comment => (
    <CommentContent
      {...comment}
      showColor
      colorName={color}
      key={comment.id}
      disabledUserLink={disabledUserLink}
    />
  ));
};

const CommentsPreview = ({
  comments,
  checklistData,
  singleIcon = false,
  style,
  disabledUserLink = false
}) => {
  const getUserPermissions = useMemo(() => getCurrentUserPermissions, []);
  const currentUserPermissions = useSelector(state => getUserPermissions(state));

  const filteredCommentsByPermission = useMemo(
    () =>
      getCommentsFilteredByPermission({
        currentUserPermissions,
        comments
      }),
    [currentUserPermissions, comments]
  );

  const { t } = useTranslation();

  if (singleIcon) {
    return (
      <Popover
        overlayStyle={{
          width: '600px'
        }}
        placement="left"
        content={
          <>
            {uniqBy(types, 'colorName').map(({ colorName }) =>
              commentsWithColors(filteredCommentsByPermission, colorName, disabledUserLink)
            )}
          </>
        }
        title={t('components.commentsPreview.commentPopoverTitle')}
      >
        <SingleIcon style={{ color: 'var(--blue_6)' }} />
      </Popover>
    );
  }

  return (
    <FlagRow style={style}>
      {types.map(({ icon, colorName, themeColor, id }) => (
        <PreviewIcons
          key={id}
          commentsLength={filterCommentsByColor(filteredCommentsByPermission, colorName).length}
          color={themeColor}
          text={textByColor(filteredCommentsByPermission, colorName, disabledUserLink)}
          icon={icon}
          checklistData={checklistData}
        />
      ))}
    </FlagRow>
  );
};

export default React.memo(CommentsPreview, isEqual);
