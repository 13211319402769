import { Card, Row } from 'antd';
import styled from 'styled-components';

export const PreviewRow = styled(Row)`
  &.ant-row {
    background-color: var(--gray);
    height: 4em;
    padding: 0 4px;
    margin: 4px 0 !important;
    cursor: pointer;
  }

  &.ant-row:hover {
    background-color: var(--gray_6);
  }
`;

export const WidgetPreview = styled(Card)`
  &.ant-card {
    text-align: center;
  }
  &.ant-card > .ant-card-body {
    padding: 8px;
  }
`;
