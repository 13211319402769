import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  settings: {},
  errors: []
};

export const startloading = createAction('appellationsSettings/startloading');

export const settingsLoaded = createAction('appellationsSettings/settingsLoaded');

export const settingsUpdated = createAction('appellationsSettings/settingsUpdated');

export const catchError = createAction('appellationsSettings/catchError');

export const appellationsSettingsReducer = createReducer(defaultState, {
  [startloading]: state => {
    return { ...state, loading: true };
  },

  [settingsLoaded]: (state, { payload }) => ({
    ...state,
    settings: payload,
    loading: false
  }),

  [settingsUpdated]: (state, { payload }) => ({
    ...state,
    settings: payload,
    loading: false
  }),

  [catchError]: (state, { payload }) => ({ ...state, errors: payload, loading: false })
});

export default appellationsSettingsReducer;
