import { post } from 'redux-bees';

const helpDeskEddyEndpointsV3 = apiPath => ({
  helpDeskEddySyncDataByPeriodV3: {
    method: post,
    path: `${apiPath}/handler.php`
  }
});

export default helpDeskEddyEndpointsV3;
