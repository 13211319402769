import { Col, Row, Typography } from 'antd';
import CommentsList from 'components/Comments/CommentsList';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { get, isEmpty, isEqual, reduce, throttle, uniq } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { loadAnalyticsWidgets } from 'redux/entities/analytics/widgets/operations';
import { actions, operations } from 'redux/lists/userReviewsWithComments';
import { commentsResource } from 'redux/resources/comments';
import { getCommentsFilteredByPermission } from 'redux/selectors/comments';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import { setCommunication } from 'redux/ui/clientInteractionDrawer/reducer';
import { omitAllQuestionsFilterAndZonesFilter } from 'redux/ui/userAnalyticsPage/operations';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { setFiltersByUserId } from 'redux/ui/userAnalyticsPage/reducer';
import { stringify } from 'core/utils/queryString';
import MainControlsPanel from '../MainControlsPanel';
import ReviewPreview from './ReviewPreview';
import FlagsFilter from './FlagsFilter';
import EmptyForUserPage from '../EmptyForUserPage';

const { Text } = Typography;

const ReviewsWithComments = () => {
  const flagSelectRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { page, ids, totalPages } = useSelector(
    state => state.userReviewsWithCommentsList,
    isEqual
  );

  const userId = useSelector(state => get(state.uiUserAnalyticsPage, 'currentUser.id', undefined));

  const filters = useSelector(state => get(state.uiUserAnalyticsPage.filters, userId, {}));

  const currentUserPermissions = useSelector(state => getCurrentUserPermissions(state), isEqual);
  const { widgets } = useSelector(state => state.analyticsWidgets, isEqual);

  const totalCount = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.COMMENTS_COUNT_BY_OPERATORS,
    userId,
    'value'
  ]);

  const loading = useSelector(
    state =>
      state.userReviewsWithCommentsList.loading ||
      state.commentsResource.loading ||
      state.reviewsResource.loading,
    isEqual
  );

  const hasNextPage = totalPages > page.number;

  const reviews = useSelector(
    state =>
      reduce(
        uniq(ids),
        (result, reviewId) => {
          const review = state.reviewsResource.byIds[reviewId];
          const comments = reduce(
            review.commentsIds,
            (result, commentId) => {
              const comment = state.commentsResource.byIds[commentId];
              return comment ? [...result, comment] : result;
            },
            []
          );
          if (isEmpty(comments)) return result;

          const direction = get(state.phoneCallsResource.byIds, `${review.phoneCallId}.direction`);
          const score = get(state.checklistsResource.byIds, `${review.checklistId}.score`);

          return [...result, { ...review, comments, direction, score }];
        },
        []
      ),
    isEqual
  );

  const loadReviewsWithComments = async ({ filters }) => {
    const reviews = await dispatch(
      operations.load({
        filters: {
          ...filters,
          hasComments: true
        },
        pagination: false,
        include:
          'custom_communication,text_communication,client_interaction,checklist,comments.uploaded_files'
      })
    );

    if (!isEmpty(reviews))
      await dispatch(
        commentsResource.operations.load({
          filters: {
            reviewsIds: Object.keys(reviews),
            commentsRatingFlags: filters.commentsRatingFlags
          },
          include: 'author.unit,author.level'
        })
      );
  };

  useEffect(() => {
    if (
      filters.checklistDefinitionsIds &&
      filters.operatorsIds &&
      userId &&
      filters.operatorsIds === userId
    ) {
      dispatch(actions.clearList());
      loadReviewsWithComments({ filters: omitAllQuestionsFilterAndZonesFilter(filters) });
      dispatch(
        loadAnalyticsWidgets({
          widgetNames: [ANALYTICS_WIDGETS_NAMES.COMMENTS_COUNT_BY_OPERATORS],
          filters: omitAllQuestionsFilterAndZonesFilter(filters)
        })
      );
    }
  }, [page.number, filters]);

  const { t } = useTranslation();

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 40 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      dispatch(
        actions.updatePage({
          number: newPage
        })
      );
    }
  }, 250);

  const getReviewComments = review => {
    return (
      getCommentsFilteredByPermission({
        currentUserPermissions,
        comments: review.comments
      }) || []
    ).filter(({ ratingFlag, commentType }) => {
      const allowedFlags = get(filters, 'commentsRatingFlags', []);

      if (isEmpty(allowedFlags) || commentType !== 'review_comment') {
        return true;
      }

      if (!ratingFlag && allowedFlags.includes('empty')) {
        return true;
      }

      if (allowedFlags.includes(ratingFlag)) {
        return true;
      }

      return false;
    });
  };

  const onLinkClick = id => e => {
    if (!e.ctrlKey) {
      e.preventDefault();
      dispatch(setCommunication({ type: 'review', id }));
    }
  };

  const setFiltersWithQuery = ({ userId, filters }) => {
    dispatch(
      setFiltersByUserId({
        userId,
        operatorsIds: userId,
        ...filters
      })
    );
    history.replace({
      pathname: location.pathname,
      search: stringify({
        filters
      })
    });
  };

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
      </Helmet>
      <Col span={24}>
        <MainControlsPanel
          additionalFilterComponent={
            <FlagsFilter
              loading={loading}
              filters={filters}
              onChangeFilters={setFiltersWithQuery}
              flagSelectRef={flagSelectRef}
              userId={userId}
            />
          }
          additionalInfoComponent={
            <Text type="secondary">{`${t('general.found')}: ${totalCount || '0'}`}</Text>
          }
        />
      </Col>

      <SCol span={24}>
        <PerfectScrollbar
          onScrollY={onScrollY}
          options={{ suppressScrollX: true }}
          className="reviews-with-comments-container"
        >
          {reviews.map(review => (
            <SRow
              display="flex"
              flexDirection="column"
              key={review.id}
              className="review"
              gutter={[16, 16]}
            >
              <Col span={24}>
                <ReviewPreview review={review} onClick={onLinkClick(review.id)} />
                <CommentsList comments={getReviewComments(review)} allowActions={false} />
              </Col>
            </SRow>
          ))}
          {loading && (
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">
                  {t('userAnalyticsPage.reviewsWithComments.loading.load')}
                </Text>
              </Col>
            </Row>
          )}
          {!loading && !hasNextPage && isEmpty(reviews) && <EmptyForUserPage />}

          {!loading && !hasNextPage && !isEmpty(reviews) && (
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">
                  {t('userAnalyticsPage.reviewsWithComments.loading.all')}
                </Text>
              </Col>
            </Row>
          )}
        </PerfectScrollbar>
      </SCol>
    </SRow>
  );
};

export default withRouter(ReviewsWithComments);
