import React from 'react';
import { Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DeleteButton, StyledCheckedInfo } from './styled';

const CheckedInfo = ({ handleDelete }) => {
  const { t } = useTranslation();

  return (
    <StyledCheckedInfo onClick={handleDelete}>
      <DeleteButton type="primary" icon={<DeleteOutlined style={{ color: 'red' }} />}>
        <Typography.Text style={{ fontSize: '0.8em', marginLeft: '4px' }}>
          {t('datasetAiPage.buttons.delete')}
        </Typography.Text>
      </DeleteButton>
    </StyledCheckedInfo>
  );
};

export default CheckedInfo;
