import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';

export const tasksResource = createReduxResource({
  name: 'tasks',
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const review = state.reviewsResource.byIds[resource.reviewId];
      if (review) {
        updateResource({
          dispatch,
          type: 'reviews',
          resource: { ...review, tasksIds: [...review.tasksIds, resource.id] }
        });
      }
    },
    onDeleteByIdSucceed: ({ id, dispatch, getState }) => {
      const state = getState();
      const tasks = state.tasksResource.byIds[id];
      const review = state.reviewsResource.byIds[tasks.reviewId];
      if (review) {
        updateResource({
          dispatch,
          type: 'reviews',
          resource: {
            ...review,
            tasksIds: [...review.tasksIds.filter(relationId => relationId !== id)]
          }
        });
      }
    }
  }
});
