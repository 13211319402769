import createListReducer from 'core/redux/lists/listReducer';
import { promptsResource } from 'redux/resources/prompts';
import decamilize from 'decamelize';

const parseSorter = sorter => {
  if (!sorter.order) return '';
  return `${sorter.order === 'descend' ? '-' : ''}${decamilize(sorter.columnKey, '_')}`;
};

const additionalReducer = {
  onPromptCreate: (state, { payload }) => {
    const { id } = payload;
    return state.ids.includes(id) ? state : { ...state, ids: [id, ...state.ids] };
  },
  updateSort: (state, { payload }) => {
    return { ...state, sort: parseSorter(payload) };
  }
};

export const listParams = {
  name: 'promptsList',
  loader: promptsResource.operations.load,
  additionalReducer,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const promptsListReducer = reducer;

export { actions, promptsListReducer, operations };
