import React, { useCallback, useEffect } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SText from 'components/Standard/SText';
import SButton from 'components/Standard/SButton';
import UserPreview from 'components/UserPreview';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compact, isEqual } from 'lodash';
import { actions, operations } from 'redux/lists/workPlanTaskAssignmentsListReducer';
import { Tooltip } from 'antd';
import { HelpCircle } from 'react-feather';
import Icon from 'components/Icon';
import ProgressBar from '../Components/ProgressBar';
import AssignmentsInfo from './AssignmentsInfo';

const WorkPlanTaskAssignments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: currentConfigurationId, task_id: currentTaskId } = useParams();

  const usersByIds = useSelector(state => state.usersResource.byIds);
  const rolesByIds = useSelector(state => state.rolesResource.byIds);

  const taskAssignmentsByIds = useSelector(
    state => state.workPlanTaskAssignmentsResource.byIds,
    isEqual
  );

  const { ids, totalCount, page, sort, filters, loading } = useSelector(
    state => state.workPlanTaskAssignmentsList
  );
  const taskAssignments = ids.map(id => taskAssignmentsByIds[id]);

  const { updatePage, setSorting, setFilters } = actions;

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      setFilters({
        taskId: [currentTaskId]
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      operations.load({
        page,
        filters: {
          taskId: [currentTaskId]
        },
        sort,
        include: 'reviewer.unit,reviewer.role,task.task_configuration.filters.fields'
      })
    );
  }, [page, sort, filters]);

  const tableColumns = [
    {
      title: t('general.employee'),
      dataIndex: 'reviewerId',
      key: 'reviewerId',
      ellipsis: true,
      width: 350,
      render: text => <UserPreview userId={text} disabled showAvatar />
    },

    {
      title: t('general.role'),
      dataIndex: 'role',
      key: 'role',
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        const userRoleId = usersByIds[record?.reviewerId]?.roleId;
        const roleName = rolesByIds[userRoleId]?.name;
        return <SText>{roleName}</SText>;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.reviewsCount'),
      dataIndex: 'totalTaskItemsCount',
      key: 'totalTaskItemsCount',
      align: 'center',
      sorter: true,
      width: 150,
      render: text => <SText color>{text}</SText>
    },

    {
      title: t('workPlanTasks.tableColumns.completed'),
      dataIndex: 'completedTaskItemsCount',
      key: 'completedTaskItemsCount',
      align: 'center',
      sorter: true,
      width: 150,
      render: text => <SText>{text}</SText>
    },

    {
      title: t('workPlanTasks.tableColumns.incompleted'),
      dataIndex: 'incompletedTaskItemsCount',
      key: 'incompletedTaskItemsCount',
      align: 'center',
      width: 150,
      render: (text, record) => {
        const diff = record?.totalTaskItemsCount - record?.completedTaskItemsCount;
        return <SText>{diff}</SText>;
      }
    },

    {
      title: (
        <SRow gutter={[8, 0]}>
          <SCol>
            <SText>{t('workPlanTasks.tableColumns.progress')}</SText>
          </SCol>
          <SCol display="flex" alignItems="center">
            <Tooltip title={t('workPlanTasks.tooltips.progress')} trigger="click">
              <Icon icon={HelpCircle} color="var(--black_6)" />
            </Tooltip>
          </SCol>
        </SRow>
      ),
      dataIndex: 'countData',
      key: 'countData',
      width: 300,
      render: (text, record) => {
        return (
          <ProgressBar
            totalCount={record?.totalTaskItemsCount}
            doneCount={record?.completedTaskItemsCount}
          />
        );
      }
    },

    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: text => (
        <SButton
          type="primary"
          onClick={() =>
            history.push(
              `/work-plan/task-configurations/${currentConfigurationId}/tasks/${currentTaskId}/task-assignment/${text}`
            )
          }
        >
          {t('general.view')}
        </SButton>
      )
    }
  ];

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <AssignmentsInfo />
          </SCol>
          <SCol span={24}>
            <SRow type="flex" margin="16px">
              <SCol span={24}>
                <SCard bordered shadowed>
                  <STable
                    rowKey="id"
                    dataSource={compact(taskAssignments)}
                    columns={tableColumns}
                    loading={loading}
                    border
                    pagination={{
                      pageSize: parseInt(page.size),
                      current: parseInt(page.number),
                      total: totalCount,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '25', '50'],
                      size: 'small'
                    }}
                    onChange={handleTableChange}
                    fixCenterSorterColumns
                    scroll={{ y: 'calc(100vh - 245px)', x: 'max-content' }}
                    size="small"
                  />
                </SCard>
              </SCol>
            </SRow>
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTaskAssignments;
