import { Row } from 'antd';
import backgroundCloud from 'assets/images/bg-cloud.png';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled(Row)`
  && {
    flex: auto;
    background-image: url(${backgroundCloud});
    background-size: auto 160vh;
    background-position: 50vw;
    background-repeat: no-repeat;
  }
`;

export default ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const languages = {
      'en-US': 'en',
      'ru-RU': 'ru'
    };

    if (navigator.language) {
      i18n.changeLanguage(languages[navigator.language]);
    }
  }, []);

  return <Container type="flex">{children}</Container>;
};
