import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  currentBackgroundJob: null,
  errors: []
};

export const setCurrentBackgroundJob = createAction('backgroundJobs/setCurrent');
export const clearCurrentBackgroundJob = createAction('backgroundJobs/clearCurrent');

export const backgroundJobsReducer = createReducer(defaultState, {
  [setCurrentBackgroundJob]: (state, { payload }) => ({ ...state, currentBackgroundJob: payload }),
  [clearCurrentBackgroundJob]: state => ({ ...state, currentBackgroundJob: null })
});
