import { createAction, createReducer } from '@reduxjs/toolkit';
import moment from 'moment';

const defaultState = {
  datePicker: {
    mode: 'clientInteraction',
    timeFrom: moment().startOf('month'),
    timeTo: moment()
  }
};

export const setMode = createAction('components/setMode');
export const setTime = createAction('components/setTime');

export const componentsReducer = createReducer(defaultState, {
  [setMode]: (state, { payload }) => {
    return { ...state, datePicker: { ...state.datePicker, ...payload } };
  },
  [setTime]: (state, { payload }) => {
    return {
      ...state,
      datePicker: {
        ...state.datePicker,
        timeFrom: { ...payload.timeFrom },
        timeTo: { ...payload.timeTo }
      }
    };
  }
});

export default componentsReducer;
