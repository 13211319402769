import { isEmpty } from 'lodash';
import { INTEGRATIONS, LINK_MANUAL_ALL_INTEGRATIONS } from 'core/utils/constants';
import i18next from 'i18next';

export const getManualLinkInfo = integrationType => {
  const integrationName = INTEGRATIONS?.[integrationType]?.name;
  const integrationLink = INTEGRATIONS?.[integrationType]?.linkToManual;
  const exceptions = ['Infinity X', 'Bright Pattern'];
  return {
    manualTextLink: exceptions.includes(integrationName)
      ? ''
      : isEmpty(integrationLink)
      ? i18next.t('integrationsSettingsPage.integrationPage.manualArticles')
      : `${i18next.t('integrationsSettingsPage.integrationPage.manualLinkText')}: ${i18next.t(
          integrationName
        )}`,
    manualLink: exceptions.includes(integrationName)
      ? ''
      : integrationLink || LINK_MANUAL_ALL_INTEGRATIONS
  };
};

export const getPlaceholder = fieldName =>
  `${i18next.t('integrationsSettingsPage.nameAndType.enterPlaceholderTemplate')} ${fieldName}`;

export const getIntegrationRequiredFields = integrationType =>
  INTEGRATIONS?.[integrationType]?.requiredFields || []
