import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  editingChecklist: {}
};

export const setEditingChecklist = createAction('uiChecklistsPage/setEditingChecklist');
export const updateEditingChecklist = createAction('uiChecklistsPage/updateEditingChecklist');

export const checklistSettingsPageReducer = createReducer(defaultState, {
  [setEditingChecklist]: (state, { payload }) => ({ ...state, editingChecklist: payload }),
  [updateEditingChecklist]: (state, { payload }) => ({
    ...state,
    editingChecklist: { ...(state.editingChecklist || {}), ...payload }
  })
});
