export default {
  workPlanTasks: {
    taskPlaceholder: 'Задача',
    colorizedNumbers: {
      completed: 'Количество выполненых проверок',
      incompleted: 'Количество просроченых проверок',
      total: 'Количество оставшихся проверок'
    },
    tableColumns: {
      reviewsCount: 'Проверить',
      reviewsCount2: 'Проверки',
      completed: 'Проверено',
      incompleted: 'Осталось',
      closeTask: 'Завершить задачу',
      stopRepeating: 'Остановить повторения',
      name: 'Название задачи',
      lastTaskTimeFrom: 'Дата начала',
      lastTaskTimeTo: 'Дата завершения',
      creator: 'Создано',
      schedule: 'Повтор',
      repeatedCount: 'Запуски',
      reviewCreatedAt: 'Дата и время оценки',
      progress: 'Прогресс',
      repeat: 'Повторение выборки'
    },
    assignments: {
      closeTask: 'Остановить выполнение',
      close: 'Остановить',
      taskStartedAt: 'Дата запуска',
      assignmentsCount: 'Кол-во проверяющих',
      completedTaskItemsCount: 'Назначено проверок'
    },
    filters: {
      createTask: 'Создать задачу'
    },
    confirmStopRepeating: 'Остановить повторения?',
    confirmClosing: 'Завершить задачу?',
    confirmDelete: 'Удалить конфигурацию?',
    tasksStartedAt: 'Дата первого запуска',
    reviewersCount: 'Кол-во проверяющих',
    operatorsCount: 'Кол-во операторов',
    reviewedOperator: 'Проверяемый сотрудник',
    communicationsNotFound: 'Коммуникации не найдены',
    nextCommunicationDate: 'Коммуникации будут добавлены в следующем запуске, который состоится ',
    checkCommunication: 'Проверьте настройки задачи',
    howToBlock: {
      howToButton: 'Как использовать',
      watchVideo: 'Смотреть видео',
      readManual: 'Прочитать полную инструкцию',
      startTour: 'Интерактивный тур'
    },
    menu: {
      inProgress: 'В процессе',
      pending: 'В ожидании',
      total: 'Общее выполнение'
    },
    tooltips: {
      configurationProgress:
        'Отношение количества проверенных коммуникаций к общему количеству в выборке. Информация по прогрессу указана по последней задаче.',
      progress: 'Отношение количества проверенных коммуникаций к общему количеству в выборке.',
      schedule:
        'Период повторения выборки коммуникаций. Выборка может автоматически обновляться и запускаться ежедневно, еженедельно или ежемесячно.',
      repeatedCount: 'Количество запусков задач по проверке выборки.'
    },
    repeat: 'повторение',
    confirmModal: {
      title: 'Вы собираетесь завершить задачу. Продолжить?',
      content: 'Все ее повторения в будущем тоже будут завершены.'
    }
  }
};
