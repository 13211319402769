import React, { useState } from 'react';
import SRow from 'components/Standard/SRow';
import { Col, Typography } from 'antd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SearchAiSettings from './SearchAiSettings';
import AiSettingsTable from './AiSettingsTable';
import ApplicationModal from './ApplicationModal';

const AiSettingsPage = () => {
  const { t } = useTranslation();
  const [isModalOpenAddorEditTask, setIsModalOpenAddorEditTask] = useState(false);
  const [isEditingData, setIsEditingData] = useState(false);

  return (
    <SRow gutter={[0, 16, 16]} padding="16px">
      <ApplicationModal />
      <Helmet>
        <title>{t('pagesMeta.aiSettingsPage.title')}</title>
      </Helmet>
      <Col span={24}>
        <Typography.Title level={4}>{t('aiSettingsPage.title')}</Typography.Title>
      </Col>
      <SearchAiSettings
        isModalOpenAddorEditTask={isModalOpenAddorEditTask}
        setIsModalOpenAddorEditTask={setIsModalOpenAddorEditTask}
        isEditingData={isEditingData}
        setIsEditingData={setIsEditingData}
      />
      <AiSettingsTable
        isModalOpenAddorEditTask={isModalOpenAddorEditTask}
        setIsModalOpenAddorEditTask={setIsModalOpenAddorEditTask}
        isEditingData={isEditingData}
        setIsEditingData={setIsEditingData}
      />
    </SRow>
  );
};

export default AiSettingsPage;
