import { Button, Col, Dropdown, Input, Menu, Modal, Tooltip, Typography } from 'antd';
import { isEmpty, uniq } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/promptsList';
import { operations as promptsQuestionAllListOperations } from 'redux/lists/promptsQuestionsAllList';
import { operations as promptsCustomFieldAllListOperations } from 'redux/lists/promptsCustomFieldsAllList';
import UserPreview from 'components/UserPreview';
import Icon from 'components/Icon';
import STable from 'components/Standard/STable';
import SText from 'components/Standard/SText';
import { IconButton } from 'components/Buttons';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  SearchOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { setEditingPrompt } from 'redux/ui/promptModal/reducer';
import { promptsResource } from 'redux/resources/prompts';

const { Text } = Typography;
const sortableColumns = ['createdAt'];

const PromptsList = ({
  setEditingPrompt,
  updatePage,
  updateSort,
  promptsByIds,
  prompts,
  loadPrompts,
  loadAllQuestions,
  loadAllCustomFields,
  clearList,
  deletePrompt,
  listLoading,
  page,
  totalCount
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchFlag, setSearchFlag] = useState(false);
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const sort = useSelector(state => state.promptsList.sort);
  const promptsLoading = useSelector(state => state.promptsResource.loading);
  const promptsQuestionAllLoading = useSelector(state => state.promptsQuestionAllList.loading);
  const promptsCustomFieldsAllLoading = useSelector(
    state => state.promptsCustomFieldsAllList.loading
  );
  const loading =
    listLoading || promptsLoading || promptsQuestionAllLoading || promptsCustomFieldsAllLoading;
  useEffect(() => {
    clearList();
    return clearList;
  }, []);
  useEffect(() => {
    // prompts list
    loadPrompts({
      organization_id: organizationId,
      page_number: page.number,
      page_size: page.size,
      sort,
      search_input: searchText
    });
    // all questions
    loadAllQuestions({
      organization_id: organizationId,
      type: 'questions',
      availability: 'all_availability'
    });
    // all custom fields
    loadAllCustomFields({
      organization_id: organizationId,
      type: 'custom_fields',
      availability: 'all_availability'
    });
  }, [page.number, page.size, sort, searchFlag]);

  const loadUpdatedData = () => {
    // prompts list
    loadPrompts({
      organization_id: organizationId,
      page_number: page.number,
      page_size: page.size,
      sort,
      search_input: searchText
    });
    // all questions
    loadAllQuestions({
      organization_id: organizationId,
      type: 'questions',
      availability: 'all_availability'
    });
    // all custom fields
    loadAllCustomFields({
      organization_id: organizationId,
      type: 'custom_fields',
      availability: 'all_availability'
    });
  };

  const handleMenuClick = ({ key, id }) => {
    const actions = {
      edit: () => {
        setEditingPrompt(promptsByIds[id]);
      },
      delete: () =>
        Modal.confirm({
          maskClosable: true,
          title: t('components.promptsList.promptModalDelete.title'),
          content: t('components.promptsList.promptModalDelete.content'),
          okText: t('components.promptsList.promptModalDelete.ok'),
          cancelText: t('components.promptsList.promptModalDelete.cancel'),
          okType: 'danger',
          onOk: async () => {
            await deletePrompt({ id });
            loadUpdatedData();
          }
        })
    };

    actions[key]();
  };

  const getMenu = ({ id }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id })}>
      <Menu.Item key="edit">
        <EditOutlined />
        {t('components.promptsList.menu.edit')}
      </Menu.Item>

      <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
        <DeleteOutlined />
        {t('components.promptsList.menu.delete')}
      </Menu.Item>
    </Menu>
  );

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    const { pageSize, current } = pagination;
    updatePage({ size: pageSize.toString(), number: current.toString() });
    dispatch(updateSort(sorter));
  }, []);

  const handleSearch = () => {
    setSearchFlag(!searchFlag);
    // Выставляем 1 страницу при поиске
    updatePage({ size: page?.size, number: '1' });
  };

  const handleInputChange = e => {
    setSearchText(e.target.value.toLowerCase());
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const columns = [
    {
      title: t('components.promptsList.listColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ellipsis: {
        showTitle: false
      },
      render: (name, { description }) => (
        <>
          <Tooltip title={name}>
            <SText>{name}</SText>
          </Tooltip>
          {!isEmpty(description) && (
            <IconButton
              tooltip={{ title: description }}
              button={{
                icon: <InfoCircleOutlined />,
                size: 'icon'
              }}
            />
          )}
        </>
      )
    },
    {
      title: t('components.promptsList.listColumns.author'),
      dataIndex: 'operatorId',
      key: 'operatorId',
      width: 150,
      ellipsis: true,
      render: operatorId => (
        <UserPreview disabled userId={operatorId} showAvatar truncateSize={25} />
      )
    },
    {
      title: t('components.promptsList.listColumns.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 60,
      render: createdAt => <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>,
      sorter: sortableColumns.includes('createdAt')
    },
    {
      title: t('components.promptsList.listColumns.value'),
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: value => value
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: 25,
      fixed: 'right',
      render: id => {
        return (
          <Dropdown overlay={getMenu({ id })} trigger={['click']}>
            <Button icon={<Icon icon={MoreOutlined} />} />
          </Dropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col style={{ paddingBottom: '14px' }}>
        <Input
          value={searchText}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          style={{ width: 200 }}
        />
        <Button type="primary" onClick={handleSearch}>
          {loading ? <LoadingOutlined /> : <SearchOutlined />}
        </Button>
      </Col>
      <STable
        dataSource={prompts}
        rowKey="id"
        size="small"
        loading={loading}
        columns={columns}
        border
        pagination={{
          current: parseInt(page?.number),
          pageSize: parseInt(page?.size),
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50']
        }}
        onChange={handleTableChange}
        scroll={{ y: window.innerWidth > 1320 ? 'calc(100vh - 286px)' : 'calc(100vh - 356px)' }}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { ids, loading: listLoading, page, totalCount } = state.promptsList;
  const { byIds } = state.promptsResource;

  const prompts = uniq(ids)
    .map(id => byIds[id])
    .filter(Boolean);

  return {
    listLoading,
    page,
    totalCount: parseInt(totalCount) || 0,
    promptsByIds: byIds,
    prompts
  };
};

const mapDispatchToProps = {
  setEditingPrompt,
  updatePage: actions.updatePage,
  clearList: actions.clearList,
  loadPrompts: operations.load,
  loadAllQuestions: promptsQuestionAllListOperations.load,
  loadAllCustomFields: promptsCustomFieldAllListOperations.load,
  updateSort: actions.updateSort,
  deletePrompt: promptsResource.operations.deleteById
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromptsList));
