import { destroy, get, post, put } from 'redux-bees';

const appellationsEndpoints = apiPath => ({
  getAppellations: { method: get, path: `${apiPath}/appellations` },

  getAppellation: { method: get, path: `${apiPath}/appellations/:id` },

  createAppellation: {
    method: post,
    path: `${apiPath}/appellations`
  },

  updateAppellation: {
    method: put,
    path: `${apiPath}/appellations/:id`
  },

  deleteAppellation: {
    method: destroy,
    path: `${apiPath}/appellations/:id`
  },

  processObjections: {
    method: post,
    path: `${apiPath}/appellations/:id/process_objections`
  },

  updateWatchers: {
    method: put,
    path: `${apiPath}/appellations/:id/watchers`
  },

  changeAppellationStatus: {
    method: post,
    path: `${apiPath}/appellations/:id/change_status`
  }
});

export default appellationsEndpoints;
