import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { get, isEmpty, isEqual } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { prepareData, prepareKeys, searchMaxValue } from './prepareFunctions';
import { WidgetCard } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const ClientInteractionsCountByUnitsHistory = ({
  widgetData,
  loadingWidgetId,
  colors,
  isWarningWidget
}) => {
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);

  let widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);
  const lastItem = Array.isArray(widgetValue) ? widgetValue[widgetValue.length - 1] : null;
  const dateLastUpdate = lastItem ? lastItem.dateLastUpdate : null;

  if (Array.isArray(widgetValue) && lastItem) {
    widgetValue = widgetValue.filter((item, idx) => idx !== widgetValue.length - 1);
  }
  const { id, type, filters, name } = widgetData;

  const widgetHistory = widgetValue.filter(item => !isEmpty(item.history));

  return (
    <>
      {isEmpty(widgetValue) ||
      isEmpty(widgetHistory) ||
      loadingWidgetId === id ||
      isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={12} key={id}>
          <WidgetCard>
            <SMLWidgetHeader
              name={name}
              type={type}
              widgetData={widgetData}
              filters={filters}
              dateLastUpdate={dateLastUpdate}
            />

            <ResponsiveBar
              data={prepareData({ widgetValue })}
              keys={prepareKeys({ widgetValue })}
              indexBy="period"
              margin={{ top: 30, right: 20, bottom: 110, left: 45 }}
              padding={0.3}
              maxValue={searchMaxValue({ widgetValue })}
              groupMode="grouped"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              tooltip={tooltip => (
                <>
                  <span>{`${unitsByIds[tooltip.id]?.name}  - ${tooltip.data.period}: `}</span>
                  <strong>{tooltip.data[tooltip.id]}</strong>
                </>
              )}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                format: d => moment(d).format('DD.MM.YY')
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default ClientInteractionsCountByUnitsHistory;
