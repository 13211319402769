import React, { useCallback, useMemo } from 'react';
import { Trash2 } from 'react-feather';
import { Typography, Spin, Input, Empty } from 'antd';
import SCard from 'components/Standard/SCard';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { sortBy, isEqual, isEmpty } from 'lodash';
import uniqid from 'uniqid';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import { saveStatuses } from 'redux/ui/statusesPage/operations';
import { useSelector, useDispatch } from 'react-redux';
import { addStatus, deleteStatus, updateStatus } from 'redux/ui/statusesPage/reducer';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';

const Statuses = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const dispatch = useDispatch();

  const { loading, byIds: oldStatusesByIds } = useSelector(
    state => state.statusesResource,
    isEqual
  );

  const statusesByIds = useSelector(state => state.uiStatusesPage.statusesByIds, isEqual);

  const savingStatuses = useSelector(
    state =>
      state.statusesResource.updateByIdStarted ||
      state.statusesResource.createByIdStarted ||
      state.statusesResource.deleteByIdStarted
  );

  const statuses = Object.values(statusesByIds);

  const canAddStatus = statuses.length < 20;

  const canSave = useMemo(() => !isEqual(oldStatusesByIds, statusesByIds), [
    oldStatusesByIds,
    statusesByIds
  ]);

  const onAddStatus = useCallback(() => {
    dispatch(addStatus({ id: uniqid(), name: '' }));
  }, [dispatch]);

  const onSave = useCallback(() => {
    dispatch(saveStatuses({ statusesByIds }));
  }, [dispatch, statusesByIds]);

  const renderStatus = status => {
    const onUpdateStatus = e => {
      dispatch(updateStatus({ ...status, name: e.target.value }));
    };

    const onDeleteStatus = () => {
      dispatch(deleteStatus(status));
    };

    return (
      <Spin spinning={loading} key={status.id}>
        <SRow type="flex" gutter={[8, 8]} align="middle">
          <SCol flex="auto">
            <Input
              onChange={onUpdateStatus}
              placeholder={t('organizationStructure.tabs.statuses.form.namePlaceholder')}
              value={status?.name}
            />
          </SCol>
          <SCol display="flex" justifyContent="flex-end">
            <SButton
              icon={<Icon icon={Trash2} />}
              type="link"
              disabled={loading || savingStatuses}
              onClick={onDeleteStatus}
              color="var(--red_6)"
            />
          </SCol>
        </SRow>
      </Spin>
    );
  };

  return (
    <SCol sm={24} lg={24} xl={12}>
      <SCard bordered loading={loading}>
        <SRow gutter={[0, 16]}>
          <SCol span={24}>
            <Title level={5}>{t('organizationStructure.tabs.statuses.title')}</Title>
          </SCol>
          <SCol span={24}>
            <PerfectScrollbar
              style={{ maxHeight: 'calc(100vh - 56px)' }}
              options={{ suppressScrollX: true }}
            >
              <SRow gutter={[8, 8]}>
                {!isEmpty(statuses) ? (
                  <SCol span={24}>{sortBy(statuses, 'createdAt').map(renderStatus)}</SCol>
                ) : (
                  <SCol span={24}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </SCol>
                )}
                {canAddStatus && (
                  <SCol span={24}>
                    <SButton type="dashed" block onClick={onAddStatus}>
                      {t('organizationStructure.tabs.statuses.form.addStatus')}
                    </SButton>
                  </SCol>
                )}
              </SRow>
            </PerfectScrollbar>
          </SCol>
          <SCol span={24} textAlign="end">
            <SButton
              type="primary"
              disabled={!canSave || loading || savingStatuses}
              loading={loading || savingStatuses}
              onClick={onSave}
            >
              {t('organizationStructure.tabs.statuses.form.save')}
            </SButton>
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default Statuses;
