import React from 'react';
import { Select, message, Button, Row, Col, Typography } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import {
  updateUiWidgetSettings,
  updateUiWidgetFilters,
  resetUiWidgetFilters,
  resetUiWidgetModal
} from 'redux/ui/widgetSettingsModal/reducer';
import {
  PERMISSIONS,
  CHECKLIST_DEFINITION_STATUS,
  WIDGET_DATE_MODE_TO_STRING,
  MAX_COUNT_WIDGET_CLIENT_INTERACTIONS,
  SYMBOLIC_TIME_RANGE
} from 'core/utils/constants';
import { IconButton } from 'components/Buttons/IconButton';
import {
  getUsersByUnitIdsWithPermissions,
  getUnitsWithActiveUsers,
  getCurrentUser,
  getUsersWithPermissions
} from 'redux/selectors/users';
import { connect, useSelector } from 'react-redux';
import { get, isNil, isEmpty, omit } from 'lodash';
import { getChecklistsDefinitionsByParams } from 'redux/selectors/checklists';
import { Trans, useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { isKpiSettingsHasIntersection } from 'core/utils/isKpiSettingsHasIntersection';
import { SButton } from 'components/Standard';
import styled from 'styled-components';
import { SettingOutlined } from '@ant-design/icons';
import SModal from 'components/Standard/SModal';
import FilterForm from './FilterForm';
import { StyledSelect, StyledInput } from '../styled';
import {
  requiredChecklistDefinitionsWidgetTypes,
  requiredOperatorIdsWidgetTypes,
  requiredKpiWidgetTypes,
  requiredSingleDateWidgetTypes
} from '../utils';
import smallWidgets from '../widgetsDefinitions/smallWidgets';
import mediumWidgets from '../widgetsDefinitions/mediumWidgets';
import largeWidgets from '../widgetsDefinitions/largeWidgets';
import extraLargeWidgets from '../widgetsDefinitions/extraLargeWidgets';
import WidgetMeta from './WidgetMeta';
import widgetsDefinitions from '../widgetsDefinitions/widgetsDefinitions';

const { Option } = Select;

const mapStateToProps = state => {
  const unitsWithActiveUsers = getUnitsWithActiveUsers(state);
  const { filters } = state.uiWidgetSettingsModal;
  const { meta, loadingMeta } = state.uiClientInteractions;

  const operators = getUsersByUnitIdsWithPermissions(state, {
    permissions: [PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION],
    unitIds: isEmpty(filters.unitsIds) ? undefined : filters.unitsIds
  });

  const reviewers = Object.values(
    getUsersWithPermissions(state, {
      permissions: [PERMISSIONS.CAN_MAKE_REVIEW]
    })
  ).filter(operator => operator.active);

  const checklistDefinitions = getChecklistsDefinitionsByParams(state, {
    params: {
      unitsIds: filters.unitsIds,
      statuses: [
        CHECKLIST_DEFINITION_STATUS.ARCHIVED.value,
        CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
      ]
    }
  });

  return {
    widgetModalData: state.uiWidgetSettingsModal,
    activeDashboardId: state.uiDashboard.activeDashboardId,
    checklistDefinitions,
    taskDefinitionsByIds: state.taskDefinitionsResource.byIds,
    unitsByIds: unitsWithActiveUsers,
    reviewers,
    operators,
    loadingMeta,
    meta,
    currentUser: getCurrentUser(state)
  };
};

const SettingsModal = ({
  widgetData,
  updateDashboardWidget,
  updateUiWidgetSettings,
  updateUiWidgetFilters,
  resetUiWidgetFilters,
  resetUiWidgetModal,
  isDisabledTypeSelectAndNameInput
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const { size } = widgetData;

  const {
    widgetModalData,
    checklistDefinitions,
    taskDefinitionsByIds,
    unitsByIds,
    reviewers,
    operators,
    loadingMeta,
    meta,
    currentUser
  } = useSelector(state => mapStateToProps(state));
  const { type } = widgetModalData;

  const widgetsSizesRelations = {
    small: smallWidgets,
    medium: mediumWidgets,
    large: largeWidgets,
    extra_large: extraLargeWidgets
  };

  const isVisibleKpiAnalytics = get(
    currentUser,
    'organization.settings.analytics.scores-by-units-by-communication-type-enabled',
    false
  );

  const availableWidgetsDefinitions = widgetsSizesRelations[size];

  const selectedWidgetDefinition = availableWidgetsDefinitions[type];

  const selectedWidgetAvailableFilters = get(selectedWidgetDefinition, 'availableFilters', []);

  const setWidgetSettings = () => {
    const defaultWidgetSettings = get(selectedWidgetDefinition, 'defaultWidgetSettings', {});

    const { filters, ...otherWidgetModalData } = widgetModalData;

    const newFilters = {
      ...omit(filters, 'operatorsIds'),
      ...(type !== 'table_reviews_count_by_reviewers' &&
        filters?.operatorsIds && { operatorsIds: filters?.operatorsIds })
    };

    updateDashboardWidget(
      isEmpty(defaultWidgetSettings)
        ? { ...otherWidgetModalData, ...{ filters: newFilters }, sort: '' }
        : {
            ...otherWidgetModalData,
            ...{ filters: newFilters },
            sort: '',
            ...defaultWidgetSettings
          }
    )
      .then(() => {
        message.success(t('dashboardPage.widget.modal.messages.widgetSuccessfullyUpdated'));
      })
      .catch(error => {
        error.status !== 422 &&
          message.error(t('dashboardPage.widget.modal.messages.updateWidgetFailed'));
      });
  };

  const deleteWidgetSettings = () => {
    updateDashboardWidget({
      id: widgetModalData.id,
      name: '',
      type: '',
      filters: {}
    })
      .then(() => {
        message.success(t('dashboardPage.widget.modal.messages.widgetSuccessfullyUpdated'));
      })
      .catch(() => {
        message.error(t('dashboardPage.widget.modal.messages.updateWidgetFailed'));
      });
  };

  const setWidgetFilters = ({ widgetType }) => {
    const dateMode = get(widgetsDefinitions, `${widgetType}.dateMode`);
    const additionalDateMode = get(widgetsDefinitions, `${widgetType}.additionalDateMode`);
    const selectorType = WIDGET_DATE_MODE_TO_STRING[dateMode]?.symbolicTimeRange;
    const additionalSelectorType =
      WIDGET_DATE_MODE_TO_STRING[additionalDateMode]?.symbolicTimeRange;

    const conditionalFilter = { [additionalSelectorType]: SYMBOLIC_TIME_RANGE.THIS_MONTH };

    if (Object.keys(extraLargeWidgets).includes(widgetType)) {
      return {
        isWeekendView: true,
        historyGroupingPeriod: 'day',
        viewMode: extraLargeWidgets[widgetType]?.defaultViewMode,
        viewType: extraLargeWidgets[widgetType]?.defaultViewType,
        viewResult: extraLargeWidgets[widgetType]?.defaultViewResult,
        [selectorType]: SYMBOLIC_TIME_RANGE.THIS_MONTH,
        ...(additionalSelectorType && conditionalFilter)
      };
    }

    return {
      [selectorType]: SYMBOLIC_TIME_RANGE.THIS_MONTH
    };
  };

  const setDefaultWidgetNames = ({ widgetType }) => {
    if (!Object.keys(extraLargeWidgets).includes(widgetType)) return [];

    const selectedWidget = extraLargeWidgets[widgetType];
    const { defaultViewMode, defaultViewType, viewResult } = selectedWidget;
    return get(selectedWidget, ['widget_names', defaultViewType, defaultViewMode, viewResult], []);
  };

  const changeWidgetDataType = value => {
    resetUiWidgetFilters();
    updateUiWidgetSettings({
      type: value,
      filters: {
        ...setWidgetFilters({ widgetType: value })
      },
      widgetNames: setDefaultWidgetNames({ widgetType: value })
    });
  };

  const isDisallowToSave = ({
    widgetType,
    checklistDefinitionsIds,
    loadingMeta,
    clientInteractionsCount,
    singleStartDate,
    settings
  }) => {
    if (isEmpty(widgetType)) return true;
    if (
      loadingMeta ||
      (clientInteractionsCount >
        MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.MAX_CLIENT_INTERACTIONS_COUNT &&
        widgetType === 'table_reviews_with_scores')
    )
      return true;
    return (
      (requiredSingleDateWidgetTypes.includes(widgetType) && isEmpty(singleStartDate)) ||
      (requiredKpiWidgetTypes.includes(widgetType) && isEmpty(settings)) ||
      (requiredKpiWidgetTypes.includes(widgetType) &&
        isKpiSettingsHasIntersection({ conditions: settings })) ||
      (requiredChecklistDefinitionsWidgetTypes.includes(widgetType) &&
        isEmpty(checklistDefinitionsIds)) ||
      (requiredOperatorIdsWidgetTypes.includes(widgetType) &&
        isEmpty(widgetModalData.filters.operatorsIds)) ||
      isNil(widgetType)
    );
  };

  const StyledResetButton = styled(SButton)`
    &.ant-btn {
      color: #e23c39;
      border-color: #e23c39;
      font-weight: 700;
    }
    &.ant-btn:hover {
      color: #e23c39;
      border-color: #e23c39;
    }
  `;

  return (
    <>
      <IconButton
        tooltip={{ title: t('dashboardPage.widget.modal.tooltip') }}
        button={{
          onClick: () => updateUiWidgetSettings(widgetData),
          size: 'icon',
          icon: <Icon icon={SettingOutlined} />
        }}
      />

      <SModal
        key={`${widgetModalData.id}_${widgetData.id}`}
        title={t('dashboardPage.widget.modal.title')}
        visible={widgetModalData.id === widgetData.id}
        onCancel={() => resetUiWidgetModal()}
        destroyOnClose
        footer={
          <Row type="flex" justify="space-between" style={{ padding: '14px 8px' }}>
            <Col>
              <StyledResetButton key="clean" onClick={() => deleteWidgetSettings()}>
                {t('dashboardPage.widget.modal.buttons.reset')}
              </StyledResetButton>
            </Col>
            <Col>
              <Button
                key="save"
                disabled={isDisallowToSave({
                  widgetType: widgetModalData.type,
                  checklistDefinitionsIds: widgetModalData.filters.checklistDefinitionsIds,
                  loadingMeta,
                  clientInteractionsCount: meta?.totalCount,
                  singleStartDate: widgetModalData.filters.singleStartDate,
                  settings: widgetModalData.filters.settings
                })}
                type="primary"
                onClick={setWidgetSettings}
              >
                {t('dashboardPage.widget.modal.buttons.save')}
              </Button>
            </Col>
          </Row>
        }
      >
        <>
          <Text strong>{t('dashboardPage.widget.modal.form.type')}</Text>
          <StyledSelect
            disabled={isDisabledTypeSelectAndNameInput}
            placeholder={<Trans i18nKey="dashboardPage.widget.modal.form.typePlaceholder" />}
            value={widgetModalData?.type === '' ? undefined : widgetModalData?.type}
            onChange={changeWidgetDataType}
          >
            {Object.values(availableWidgetsDefinitions).reduce((acc, widgetDefinition) => {
              if (widgetDefinition.outdated) return acc;
              if (
                !isVisibleKpiAnalytics &&
                widgetDefinition.type === 'table_reviews_count_by_operators_with_kpi'
              )
                return acc;
              return [
                ...acc,
                <Option key={widgetDefinition.type} value={widgetDefinition.type}>
                  {widgetDefinition.name}
                </Option>
              ];
            }, [])}
          </StyledSelect>
          <Text strong>{t('dashboardPage.widget.modal.form.name')}</Text>
          <StyledInput
            disabled={isDisabledTypeSelectAndNameInput}
            value={widgetModalData.name}
            placeholder={t('dashboardPage.widget.modal.form.namePlaceholder')}
            onChange={e => updateUiWidgetSettings({ name: e.target.value })}
          />
          <Row>
            {selectedWidgetAvailableFilters.map(availableWidgetFiltersData => (
              <FilterForm
                key={availableWidgetFiltersData.type}
                filterData={{
                  filtersDefinition: availableWidgetFiltersData,
                  filtersData: get(widgetData, 'filters', {}),
                  widgetId: widgetData.id,
                  unitsByIds,
                  reviewers,
                  operators,
                  checklistDefinitions,
                  updateUiWidgetFilters,
                  widgetModalData,
                  taskDefinitionsByIds
                }}
              />
            ))}
            {Object.keys(extraLargeWidgets).includes(type) && <WidgetMeta />}
          </Row>
        </>
      </SModal>
    </>
  );
};

const mapDispatchToProps = {
  updateDashboardWidget,
  updateUiWidgetSettings,
  resetUiWidgetModal,
  updateUiWidgetFilters,
  resetUiWidgetFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
