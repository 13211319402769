import { get, put, post, destroy } from 'redux-bees';

const unitsByTypeDashboardPageEndpoints = apiPath => ({
  getUnitsByTypeDashboard: {
    method: get,
    path: `${apiPath}/dashboards/pages/scores_by_units_by_communication_type`
  },
  getUnitsByTypeDashboardPart: {
    method: get,
    path: `${apiPath}/dashboards/pages/scores_by_units_by_communication_type/widgets`
  },
  getUnitsByTypeDashboardAnalytics: {
    method: get,
    path: `${apiPath}/analytics/dashboards/pages/scores_by_units_by_communication_type/widgets`
  },
  updateUnitsByTypeDashboard: {
    method: put,
    path: `${apiPath}/dashboards/pages/scores_by_units_by_communication_type`
  },
  createUnitsByTypeDashboardPageWidget: {
    method: post,
    path: `${apiPath}/dashboards/pages/scores_by_units_by_communication_type/widgets`
  },
  deleteUnitsByTypeDashboardPageWidgetById: {
    method: destroy,
    path: `${apiPath}/dashboards/pages/scores_by_units_by_communication_type/widgets/:id`
  }
});

export default unitsByTypeDashboardPageEndpoints;
