import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  editableGroup: {},
  currentChecklist: {},
  currentChecklistQuestionGroups: [],
  currentChecklistQuestions: [],
  addingQuestionToGroup: {},
  comments: {},
  addingQuestionGroup: false,
  selectingQuestionsFromSaved: false,
  selectedQuestionsFromSaved: [],
  sortedQuestionGroups: [],
  sortedQuestionGroupBindings: {},
  editingColorZonesBeforePublish: {},
  employees: {},
  commentsToSave: {},
  clearFlag: false
};

export const setCurrentChecklist = createAction('uiChecklistEditor/setCurrentChecklist');
export const setEditableGroup = createAction('uiChecklistEditor/setEditableGroup');
export const setAddingQuestionToGroup = createAction('uiChecklistEditor/setAddingQuestionToGroup');
export const setComments = createAction('uiChecklistEditor/setComments');
export const setSelectingQuestionsFromSaved = createAction(
  'uiChecklistEditor/setSelectingQuestionsFromSaved'
);
export const setSelectedQuestionsFromSaved = createAction(
  'uiChecklistEditor/setSelectedQuestionsFromSaved'
);
export const setAddingQuestionGroup = createAction('uiChecklistEditor/setAddingQuestionGroup');
export const setSortedQuestionGroups = createAction('uiChecklistEditor/setSortedQuestionGroups');
export const updateSortedQuestionGroupBindings = createAction(
  'uiChecklistEditor/updateSortedQuestionGroupBindings'
);
export const updateMultipleSortedQuestionGroupBindings = createAction(
  'uiChecklistEditor/updateMultipleSortedQuestionGroupBindings'
);
export const setEditingColorZonesBeforePublish = createAction(
  'uiChecklistEditor/setEditingColorZonesBeforePublish'
);
export const setEmployees = createAction('uiChecklistEditor/setEmployees');
export const setCommentsToSave = createAction('uiChecklistEditor/setCommentsToSave');
export const clearCommentsToSave = createAction('uiChecklistEditor/clearCommentsToSave');
export const removeCommentsToSave = createAction('uiChecklistEditor/removeCommentsToSave');
export const clearComments = createAction('uiChecklistEditor/clearComments');
export const startClear = createAction('uiChecklistEditor/startClear');

export const uiChecklistEditorReducer = createReducer(defaultState, {
  [setCurrentChecklist]: (state, { payload }) => {
    return {
      ...state,
      ...payload
    };
  },
  [setEditableGroup]: (state, { payload }) => {
    return {
      ...state,
      editableGroup: payload
    };
  },
  [setAddingQuestionToGroup]: (state, { payload }) => ({
    ...state,
    addingQuestionToGroup: payload
  }),
  [setComments]: (state, { payload }) => {
    return {
      ...state,
      comments: { ...state.comments, ...payload }
    };
  },
  [setSelectingQuestionsFromSaved]: (state, { payload }) => ({
    ...state,
    selectingQuestionsFromSaved: payload,
    selectedQuestionsFromSaved: payload ? state.selectedQuestionsFromSaved : []
  }),
  [setSelectedQuestionsFromSaved]: (state, { payload }) => ({
    ...state,
    selectedQuestionsFromSaved: payload
  }),
  [setAddingQuestionGroup]: (state, { payload }) => ({ ...state, addingQuestionGroup: payload }),
  [setSortedQuestionGroups]: (state, { payload }) => ({ ...state, sortedQuestionGroups: payload }),
  [updateSortedQuestionGroupBindings]: (state, { payload }) => ({
    ...state,
    sortedQuestionGroupBindings: {
      ...state.sortedQuestionGroupBindings,
      [payload.questionGroupId]: payload.bindings
    }
  }),
  [updateMultipleSortedQuestionGroupBindings]: (state, { payload }) => ({
    ...state,
    sortedQuestionGroupBindings: { ...state.sortedQuestionGroupBindings, ...payload }
  }),
  [setEditingColorZonesBeforePublish]: (state, { payload }) => ({
    ...state,
    editingColorZonesBeforePublish: payload
  }),
  [setEmployees]: (state, { payload }) => {
    return {
      ...state,
      employees: { ...state.employees, ...payload }
    };
  },
  [setCommentsToSave]: (state, { payload }) => {
    if (!payload.id) {
      return state;
    }
    const existingComment = state.commentsToSave[payload.id];
    // Обновление уже существующего объекта
    if (existingComment) {
      // Удаление объекта из стейта если у него нет комментария и загруженных изображений
      if (!existingComment.text && existingComment?.uploadedFilesIds?.length === 0) {
        const updatedObject = { ...state.commentsToSave };
        delete updatedObject[existingComment?.id];
        return { ...state, commentsToSave: updatedObject };
      }
      // Обновление уже существующего объекта
      return {
        ...state,
        commentsToSave: {
          ...state.commentsToSave,
          [payload.id]: {
            ...existingComment,
            ...payload
          }
        }
      };
    }
    // Проверка перед добавлением в стейт на наличие комментария и загруженных изображений
    if (!payload.text && payload?.uploadedFilesIds?.length === 0) {
      return state;
    }
    return {
      ...state,
      commentsToSave: {
        ...state.commentsToSave,
        [payload.id]: payload
      }
    };
  },
  // При нажатии Отправить/Отменить удаляем из стейта
  [removeCommentsToSave]: (state, { payload }) => {
    const updatedObject = { ...state.commentsToSave };
    delete updatedObject[payload];
    return { ...state, commentsToSave: updatedObject };
  },
  // После сохранения ревью очищаем стейт
  [clearCommentsToSave]: state => {
    return {
      ...state,
      commentsToSave: {}
    };
  },
  [clearComments]: state => {
    return {
      ...state,
      comments: {}
    };
  },
  [startClear]: (state, { payload }) => {
    return {
      ...state,
      clearFlag: payload
    };
  }
});
