import AddUsersModal from 'components/AddUsersModal';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import UserPreview from 'components/UserPreview';
import { PERMISSIONS } from 'core/utils/constants';
import { isEqual } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Plus, Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { appellationsResource } from 'redux/resources/appellations';
import { getUsersByIds, getCurrentUser } from 'redux/selectors/users';
import { useSelections } from 'ahooks';

const ParticipantsCard = ({ appeal, loading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const assignedUser = useSelector(state => getUsersByIds(state)[appeal?.assignedToId], isEqual);
  const appellantUser = useSelector(state => getUsersByIds(state)[appeal?.appellantId], isEqual);
  const [showAddModal, setShowAddModal] = useState(false);
  const [watchersIds, setWatchersIds] = useState(appeal?.watchersIds || []);
  const review = useSelector(state => state.reviewsResource.byIds[appeal?.reviewId], isEqual);
  const currentUser = useSelector(state => getCurrentUser(state));
  const { ids } = useSelector(state => state.usersToUnitList, isEqual);
  const { allSelected, selected, isSelected, toggle, setSelected } = useSelections(ids, []);

  const deleteWatcherHandler = userId => async () => {
    try {
      const newIds = watchersIds.filter(id => id !== userId);
      await dispatch(
        appellationsResource.operations.updateWatchers({
          id: appeal.id,
          watchersIds: newIds
        })
      );
      setWatchersIds(newIds);
    } catch (error) {
      console.log(error);
    }
  };

  const addUsersHandler = async ids => {
    try {
      await dispatch(
        appellationsResource.operations.updateWatchers({
          id: appeal.id,
          watchersIds: ids
        })
      );
      setWatchersIds(ids);
      setShowAddModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (appeal?.watchersIds && !isEqual(appeal?.watchersIds, watchersIds)) {
      setWatchersIds(appeal?.watchersIds);
    }
  }, [appeal?.watchersIds]);

  const isHiddenReviewerName =
    assignedUser?.id === review?.reviewerId && currentUser?.id !== review?.reviewerId;

  return (
    <>
      <SCard shadowed bordered loading={!appeal || loading}>
        <SRow gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
          <SCol span={24}>
            <SRow gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
              <SCol span={24} display="flex" justifyContent="center">
                <UserPreview
                  size="large"
                  userId={assignedUser?.id}
                  disabled
                  disabledInitials
                  showAvatar
                  hidden={isHiddenReviewerName}
                />
              </SCol>

              <SCol span={24} display="flex" justifyContent="center" textAlign="center">
                <UserPreview
                  userId={assignedUser?.id}
                  disabled
                  showAvatar={false}
                  hidden={isHiddenReviewerName}
                />
              </SCol>

              <SCol span={24} display="flex" justifyContent="center">
                <SText fontWeight="600" fontSize="14px" type="secondary">
                  {t('general.executor')}
                </SText>
              </SCol>
            </SRow>
          </SCol>

          <SCol span={24}>
            <SRow gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
              <SCol span={24}>
                <SText fontSize="16px" fontWeight="500">
                  {t('appealsPage.participantsCard.appealed')}
                </SText>
              </SCol>
              <SCol span={24}>
                <UserPreview userId={appellantUser?.id} disabled showEmail showAvatar />
              </SCol>
            </SRow>
          </SCol>

          <SCol span={24}>
            <SRow gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
              <SCol span={24}>
                <SText fontSize="16px" fontWeight="500">
                  {t('appealsPage.participantsCard.watchers')}
                </SText>
              </SCol>
              <SCol span={24}>
                <SRow gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
                  {watchersIds.map(id => (
                    <SCol span={24} data-testid="appeal-watcher" key={id}>
                      <SRow align="middle" justify="space-between">
                        <SCol maxWidth="calc(100% - 24px)">
                          <UserPreview
                            userId={id}
                            showAvatar
                            disabled
                            showEmail
                            hidden={
                              id === review?.reviewerId && review?.reviewerId !== currentUser?.id
                            }
                          />
                        </SCol>
                        <SCol>
                          <SButton
                            onClick={deleteWatcherHandler(id)}
                            size="icon"
                            border="none"
                            icon={<Icon color="var(--black_2)" icon={Trash2} />}
                          />
                        </SCol>
                      </SRow>
                    </SCol>
                  ))}
                  <SCol span={24}>
                    <SButton
                      icon={<Icon icon={Plus} />}
                      type="link"
                      padding="0"
                      onClick={() => setShowAddModal(true)}
                    >
                      {t('appealsPage.participantsCard.addWatcher')}
                    </SButton>
                  </SCol>
                </SRow>
              </SCol>
            </SRow>
          </SCol>
        </SRow>
      </SCard>
      <AddUsersModal
        title={t('appealsPage.participantsCard.addingWatcher')}
        selectedUsersIds={watchersIds}
        onCancel={() => setShowAddModal(false)}
        visible={showAddModal}
        onAdd={addUsersHandler}
        addedFilters={{ withRolePermissions: [PERMISSIONS.CAN_WATCH_APPELLATIONS] }}
        allSelected={allSelected}
        selected={selected}
        isSelected={isSelected}
        toggle={toggle}
        setSelected={setSelected}
      />
    </>
  );
};

export default ParticipantsCard;
