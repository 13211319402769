import { Col, Modal, Row, Typography } from 'antd';
import { get, isEmpty, isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundJob from 'pages/BackgroundJob';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  useRouteMatch,
  Route,
  useHistory,
  Redirect,
  useParams,
  useLocation
} from 'react-router-dom';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import { syncDataWithAmo, syncDataWithMango } from 'redux/entities/amoIntegration/operations';
import moment from 'core/moment';
import { INTEGRATIONS, SYNC_DATA_PERIODS } from 'core/utils/constants';
import { syncDataWithBinotel } from 'redux/entities/binotelIntegration/operations';
import { syncDataWithBitrix } from 'redux/entities/bitrixIntegration/operations';
import { syncDataWithUsedesk } from 'redux/entities/usedeskIntegration/operations';
import { integrationsResource } from 'redux/resources/integrations';
import { syncDataWithZendesk } from 'redux/entities/zendeskIntegration/operations';
import { syncDataWithInfinity } from 'redux/entities/infinityIntegration/operations';
import { syncDataWithBrightPattern } from 'redux/entities/brightPatternIntegration/operations';
import { syncDataWithHelpDeskEddy } from 'redux/entities/helpDeskEddyIntegration/operations';
import { getCurrentUser } from 'redux/selectors/users';
import { syncDataWithNaumen } from 'redux/entities/naumenIntegration/operations';
import { syncDataWithS2 } from 'redux/entities/s2Integration/operations';
import { syncDataWithEdna } from 'redux/entities/ednaIntegration/operations';
import AmoFields from '../IntegrationSettingsModules/AmoFields';
import ManageUsers from '../IntegrationSettingsModules/ManageUserAccounts';
import SyncPeriod from '../IntegrationSettingsModules/SyncPeriod';
import { syncDataWithUis } from '../../../../redux/entities/uisIntegration/operations';
import ProjectManger from '../IntegrationSettingsModules/ProjectManger';
import { syncDataWithPyrus } from '../../../../redux/entities/pyrus/operations';
import ProjectMangerCheckbox from '../IntegrationSettingsModules/ProjectMangerCheckbox';
import StatusManager from '../IntegrationSettingsModules/StatusManager';

const { Title } = Typography;

export const integrationTypeToSyncDataHandler = {
  [INTEGRATIONS.amocrm.type]: syncDataWithAmo,
  [INTEGRATIONS.mango.type]: syncDataWithMango,
  [INTEGRATIONS.bitrix_crm.type]: syncDataWithBitrix,
  [INTEGRATIONS.usedesk.type]: syncDataWithUsedesk,
  [INTEGRATIONS.binotel.type]: syncDataWithBinotel,
  [INTEGRATIONS.zendesk.type]: syncDataWithZendesk,
  [INTEGRATIONS.help_desk_eddy.type]: syncDataWithHelpDeskEddy,
  [INTEGRATIONS.infinity.type]: syncDataWithInfinity,
  [INTEGRATIONS.bright_pattern.type]: syncDataWithBrightPattern,
  [INTEGRATIONS.uis.type]: syncDataWithUis,
  [INTEGRATIONS.naumen.type]: syncDataWithNaumen,
  [INTEGRATIONS.edna.type]: syncDataWithEdna,
  [INTEGRATIONS.s2.type]: syncDataWithS2,
  [INTEGRATIONS.pyrus.type]: syncDataWithPyrus
};

const CreateSaasIntegrationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { integrationId, id: userId } = useParams();
  const manageUsersRef = useRef();

  const integration = useSelector(
    state => state.integrationsResource.byIds[integrationId],
    isEqual
  );
  useEffect(() => {
    dispatch(
      integrationsResource.operations.loadByIdIntegration({
        id: integrationId,
        type: integration?.integrationType
      })
    );
  }, [integrationId]);

  const integrationPageFlows = {
    [INTEGRATIONS.amocrm.type]: ['users', 'fields', 'period'],
    [INTEGRATIONS.naumen.type]: ['project', 'users', 'fields', 'period'],
    [INTEGRATIONS.edna.type]: ['users', 'fields', 'period'],
    [INTEGRATIONS.s2.type]: ['users', 'period'],
    [INTEGRATIONS.pyrus.type]: ['projectCheckbox', 'status', 'users', 'fields', 'period'],
    default: ['users', 'period']
  };
  const getPageFlowForIntegration = integrationType => {
    return integrationPageFlows[integrationType] || integrationPageFlows.default;
  };
  const initialPage = getPageFlowForIntegration(integration?.integrationType)[0];
  const navigateToNextPage = currentPage => {
    const pageFlow = getPageFlowForIntegration(integration?.integrationType);
    const currentIndex = pageFlow.indexOf(currentPage);
    const nextPage = pageFlow[currentIndex + 1];

    if (nextPage) {
      history.push(`${url}/${nextPage}`);
    } else {
      history.push(
        `/user/${userId}/integrations-settings/${integration.integrationType}/${integrationId}`
      );
    }
  };
  const bitrixIntegrationEveningSynchronizedEnabled = useSelector(
    state =>
      get(
        getCurrentUser(state),
        'organization.settings.bitrixIntegrationEveningSynchronized.enabled',
        false
      ),
    isEqual
  );

  const bitrixIntegrationNightlySynchronizedEnabled = useSelector(
    state =>
      get(
        getCurrentUser(state),
        'organization.settings.bitrixIntegrationNightlySynchronized.enabled',
        false
      ),
    isEqual
  );

  const syncAtTime =
    (integration?.bitrixIntegrationNightlySynchronizedEnabled ||
    bitrixIntegrationNightlySynchronizedEnabled
      ? '04:00'
      : null) ||
    (integration?.bitrixIntegrationEveningSynchronizedEnabled ||
    bitrixIntegrationEveningSynchronizedEnabled
      ? '23:00'
      : null) ||
    '';

  const isDisabledSyncPeriod =
    integration?.bitrixIntegrationNightlySynchronizedEnabled ||
    bitrixIntegrationNightlySynchronizedEnabled ||
    integration?.bitrixIntegrationEveningSynchronizedEnabled ||
    bitrixIntegrationEveningSynchronizedEnabled ||
    false;

  const currentBackgroundJob = useSelector(state => state.backgroundJob.currentBackgroundJob);

  const selectIntegrationPeriod = async fetchFrom => {
    if (
      integration?.integrationType === INTEGRATIONS.amocrm.type ||
      integration?.integrationType === INTEGRATIONS.naumen.type ||
      integration?.integrationType === INTEGRATIONS.edna.type
    ) {
      await dispatch(
        integrationTypeToSyncDataHandler[integration.integrationType]({
          id: integration.id,
          syncFrom: fetchFrom
        })
      );
    } else {
      const fetchFromToISO = moment()
        .subtract(SYNC_DATA_PERIODS[fetchFrom].number, SYNC_DATA_PERIODS[fetchFrom].momentUnit)
        .toISOString();
      await dispatch(
        integrationTypeToSyncDataHandler[integration.integrationType]({
          id: integration.id,
          fetchFrom: fetchFromToISO
        })
      );
    }
    return history.push(
      `/user/${userId}/integrations-settings/${integration.integrationType}/${integrationId}`
    );
  };

  const handleSelectUsers = () => navigateToNextPage('users');
  const handleSelectFields = () => navigateToNextPage('fields');
  const handleSelectProject = () => navigateToNextPage('project');
  const handleSelectCheckboxProject = () => navigateToNextPage('projectCheckbox');
  const handleSelectStatus = () => navigateToNextPage('status');

  const onBackgroundJobDone = () => {
    if (window.location.pathname.split('/').includes('users')) {
      return manageUsersRef?.current?.load();
    }

    if (window.location.pathname.split('/').includes('period'))
      return history.push(
        `/user/${userId}/integrations-settings/${integration.integrationType}/${integrationId}`
      );
  };

  const onBackgroundJobFailed = () => {
    Modal.error({
      title: `${t('integrationsSettingsPage.createSaasIntegration.errorMessage')} ${t(
        INTEGRATIONS[integration.integrationType].name
      )}`,
      content: t('integrationsSettingsPage.createSaasIntegration.errorMessageContent')
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Switch>
          <Route path={`${path}/period`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('integrationsSettingsPage.createSaasIntegration.sync')}</Title>
              </Col>
              <Col span={24}>
                <SyncPeriod
                  disabled={isDisabledSyncPeriod}
                  okText={t('general.continue')}
                  onOk={selectIntegrationPeriod}
                  syncAtTime={syncAtTime}
                  integrationId={integrationId}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/project`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>Проект</Title>
              </Col>
              <Col span={24}>
                <ProjectManger
                  integrationId={integrationId}
                  okText={t('general.continue')}
                  onOk={handleSelectProject}
                  integrationType={integration?.integrationType}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/status`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>Статус</Title>
              </Col>
              <Col span={24}>
                <StatusManager
                  integrationId={integrationId}
                  okText={t('general.continue')}
                  onOk={handleSelectStatus}
                  integrationType={integration?.integrationType}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/projectCheckbox`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>Проект</Title>
              </Col>
              <Col span={24}>
                <ProjectMangerCheckbox
                  integrationId={integrationId}
                  okText={t('general.continue')}
                  onOk={handleSelectCheckboxProject}
                  integrationType={integration?.integrationType}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/users`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('general.users')}</Title>
              </Col>
              <Col span={24}>
                <ManageUsers
                  ref={manageUsersRef}
                  okText={t('general.continue')}
                  integrationId={integrationId}
                  integrationInProcess
                  onOk={handleSelectUsers}
                  integrationType={integration?.integrationType}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/fields`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>Поля</Title>
              </Col>
              <Col span={24}>
                <AmoFields
                  integrationId={integrationId}
                  okText={t('general.continue')}
                  onOk={handleSelectFields}
                  integration={integration}
                />
              </Col>
            </Row>
          </Route>

          <Redirect exact from={`${path}/`} to={`${path}/${initialPage}`} />
        </Switch>
      </Col>
      <Modal
        visible={!isEmpty(currentBackgroundJob)}
        footer={null}
        closable={false}
        maskClosable
        // maskClosable={false}
      >
        {currentBackgroundJob && (
          <BackgroundJob
            text={
              <video autoPlay loop muted playsinline>
                <source src={catWithSpinner} type="video/mp4" />
              </video>
            }
            description={
              <>
                <div>{t('backgroundJob.description')}</div>
              </>
            }
            spinning={false}
            onDone={onBackgroundJobDone}
            onFailed={onBackgroundJobFailed}
          />
        )}
      </Modal>
    </Row>
  );
};

export default CreateSaasIntegrationPage;
