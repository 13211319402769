import React from 'react';
import { Empty, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EmptyDashboardCard } from '../styled';

const EmptyDashboardRows = ({ toggleVisibleDrawer, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <EmptyDashboardCard>
      <Spin spinning={loading}>
        <Empty
          imageStyle={{
            height: 60
          }}
          description={t('dashboardPage.dashboard.emptyDashboardRows')}
        >
          <Button type="primary" onClick={() => dispatch(toggleVisibleDrawer())}>
            {t('dashboardPage.dashboard.buttons.create')}
          </Button>
        </Empty>
      </Spin>
    </EmptyDashboardCard>
  );
};

export default EmptyDashboardRows;
