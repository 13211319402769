import { Tooltip } from 'antd';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { isEmpty, isEqual } from 'lodash';
import React, { useState } from 'react';
import { Plus, HelpCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PERMISSIONS } from 'core/utils/constants';
import AddUsersModal from '../../../components/AddUsersModal';
import ReviewsWithDistribution from './ReviewsWithDistribution';

const Reviewers = ({
  onChange,
  reviewersIds = [],
  distribution,
  isFiltersValid,
  removedReviewersIds,
  setRemovedReviewersIds = [],
  clientInteractionsTypesConfigs = {},
  reviewersCommunicationsDistributionMethod,
  setReviewersCommunicationsDistributionMethod,
  allSelected,
  selected,
  isSelected,
  toggle,
  setSelected
}) => {
  const { t } = useTranslation();
  const [usersIds, setUsersIds] = useState(reviewersIds);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addReviewer, setAddReviewer] = useState(false);
  const commonUnitsIds = useSelector(
    state => state.organizationInfo.commonUnitsIdsforUsers,
    isEqual
  );

  const onAdd = ({ reviewersIdsFromModal }) => {
    const removedReviewersIds = usersIds.filter(
      reviewerId => !reviewersIdsFromModal.includes(reviewerId)
    );

    setRemovedReviewersIds(removedReviewersIds);
    setUsersIds(reviewersIdsFromModal);
    setShowAddModal(false);
    setAddReviewer(false);
  };
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  const disabledReviewersIds = Object.values(usersByIds).reduce((acc, user) => {
    if (
      commonUnitsIds.includes(user?.unitId) ||
      user?.managedUnitsIds.some(id => commonUnitsIds.includes(id))
    )
      return acc;
    return [...acc, user?.id];
  }, []);

  return (
    <SRow gutter={[0, 12]} style={{ marginBottom: '-6px' }}>
      <SCol span={24}>
        <SCard bordered shadowed width="100%">
          {!isEmpty(usersIds) && (
            <SCol span={24} style={{ paddingBottom: '24px' }}>
              <ReviewsWithDistribution
                reviewersIds={usersIds}
                removedReviewersIds={removedReviewersIds}
                clientInteractionsTypesConfigs={clientInteractionsTypesConfigs}
                onChange={onChange}
                distribution={distribution}
                reviewersCommunicationsDistributionMethod={
                  reviewersCommunicationsDistributionMethod
                }
                setReviewersCommunicationsDistributionMethod={
                  setReviewersCommunicationsDistributionMethod
                }
              />
            </SCol>
          )}

          <SRow type="flex" align="middle">
            <SCol>
              <SButton
                icon={<Icon icon={Plus} />}
                type="link"
                padding="0"
                disabled={!isFiltersValid}
                onClick={() => {
                  setShowAddModal(true);
                  setAddReviewer(true);
                }}
              >
                {t('workPlanTaskConfigurationEditor.distribution.addReviewer')}
              </SButton>
            </SCol>
            {isEmpty(usersIds) && (
              <SCol display="inline-flex" marginLeft="6px" alignItems="center" width="20px">
                <Tooltip
                  title={
                    !isFiltersValid
                      ? t('workPlanTaskConfigurationEditor.filters.invalid')
                      : t('workPlanTaskConfigurationEditor.filters.valid')
                  }
                >
                  <Icon icon={HelpCircle} color="var(--red_primary)" />
                </Tooltip>
              </SCol>
            )}
          </SRow>
        </SCard>
      </SCol>
      <AddUsersModal
        selectedUsersIds={usersIds}
        onCancel={() => {
          setShowAddModal(false);
          setAddReviewer(false);
        }}
        visible={showAddModal}
        onAdd={ids => onAdd({ reviewersIdsFromModal: ids })}
        title={t('general.reviewers')}
        disabledUsersIds={disabledReviewersIds}
        addedFilters={{ withRolePermissions: [PERMISSIONS.CAN_MAKE_REVIEW] }}
        showDisableReason
        disableReasonComponent={
          <Tooltip title={t('workPlanTaskConfigurationEditor.reviewersWithoutPermissions')}>
            <Icon icon={InfoCircleOutlined} color="var(--red_primary)" />
          </Tooltip>
        }
        rolesDisplayPermissions={[PERMISSIONS.CAN_MAKE_REVIEW]}
        allSelected={allSelected}
        selected={selected}
        isSelected={isSelected}
        toggle={toggle}
        setSelected={setSelected}
        addReviewer={addReviewer}
      />
    </SRow>
  );
};

export default Reviewers;
