import { Button, Result } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledCard } from './styled';

const NotFound = ({ history }) => {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <Helmet>
        <title>{t('pagesMeta.notFoundPage.title')}</title>
      </Helmet>
      <Result
        status="404"
        title="404"
        subTitle={t('errorPages.notFound.title')}
        extra={
          <Button type="primary" onClick={() => history.push('/')}>
            {t('errorPages.buttons.goToHomePage')}
          </Button>
        }
      />
    </StyledCard>
  );
};

export default withRouter(NotFound);
