import React from 'react';
import { Typography } from 'antd';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { PERMISSIONS, PERMISSIONS_DESCRIPTIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';

export const AdministrationPermissions = ({ getCheckboxWithTooltip, getCheckbox }) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <SRow gutter={[8, 8]} type="flex">
      <SCol span={24}>
        <Text strong>{t('organizationStructure.tabs.roles.modal.form.administrateCategory')}</Text>
      </SCol>

      <SCol span={12}>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_ORGANIZATION_SETTINGS.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE.title),
            [PERMISSIONS.CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE]
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_MANAGE_INTEGRATIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_INTEGRATIONS.title)
          )}
        </SRow>
      </SCol>

      <SCol span={12}>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_TASK_DEFINITIONS.title)
          )}
        </SRow>
        <SRow>
          {getCheckboxWithTooltip(
            PERMISSIONS.CAN_MANAGE_LIBRARY,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_LIBRARY.title),
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_LIBRARY.description),
            [PERMISSIONS.CAN_ACCESS_LIBRARY, PERMISSIONS.CAN_ADD_LIBRARY_ITEMS]
          )}
        </SRow>
      </SCol>
    </SRow>
  );
};
