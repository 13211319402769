import React, { useCallback, useEffect } from 'react';
import { Menu, Modal, Dropdown, Button, message } from 'antd';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import STable from 'components/Standard/STable';
import SText from 'components/Standard/SText';
import { Shuffle, StopCircle, PlayCircle, Trash2, Edit, Copy } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { PERIOD_REPEAT, WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import moment from 'core/moment';
import UserPreview from 'components/UserPreview';
import { actions, operations } from 'redux/lists/workPlanTaskConfigurationsListReducer';
import { Helmet } from 'react-helmet';
import { QuestionCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons';
import Filters from './Filters';

const WorkPlanTaskConfigurationsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { updatePage, setSorting, onConfigurationDelete } = actions;

  const { ids, totalCount, page, sort, filters, loading } = useSelector(
    state => state.workPlanTaskConfigurationsList,
    isEqual
  );

  const runConfiguration =
    workPlanTaskConfigurationsResource.operations.runWorkPlanTaskConfiguration;

  const loadConfigurations = () =>
    dispatch(
      operations.load({
        page,
        filters,
        sort,
        include: 'created_by,filters.fields,reviewer_bindings.reviewer,last_task,schedule'
      })
    );

  useEffect(() => {
    loadConfigurations();
  }, [page, sort, filters]);

  const workPlanTaskConfigurationsByIds = useSelector(
    state => state.workPlanTaskConfigurationsResource.byIds,
    isEqual
  );

  const workPlanTaskConfigurations = ids.map(id => workPlanTaskConfigurationsByIds[id]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  const workPlanTasksByIds = useSelector(state => state.workPlanTasksResource.byIds, isEqual);

  const workPlanTaskSchedulesByIds = useSelector(
    state => state.workPlanTaskSchedulesResource.byIds,
    isEqual
  );

  const deleteWorkPlanConfiguration = async ({ id }) => {
    try {
      const res = await dispatch(workPlanTaskConfigurationsResource.operations.deleteById({ id }));
      if (res) {
        dispatch(onConfigurationDelete({ id }));
        message.success(t('messages.success.configurationDeleted'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('messages.error.failedDeleteConfiguration'));
    }
  };

  const cancelWorkPlanTaskConfiguration = async ({ id }) => {
    try {
      const res = await dispatch(
        workPlanTaskConfigurationsResource.operations.cancelWorkPlanTaskConfiguration({ id })
      );
      if (res) message.success(t('messages.success.taskCompleted'));
    } catch (error) {
      console.log(error);
      message.error(t('messages.error.failedCloseTask'));
    }
  };

  const runTasks = async ({ id }) => {
    try {
      const res = await dispatch(runConfiguration({ id }));

      if (res) {
        message.success(t('workPlanTaskConfigurationEditor.taskStarted'));
        await loadConfigurations();
      }
    } catch (error) {
      console.log(error);
      message.warning('Не удалось запустить задачу');
    }
  };
  const copyWorkPlanConfiguration = async id => {
    try {
      const res = await dispatch(workPlanTaskConfigurationsResource.operations.copyById(id));

      if (res.status === 'success') {
        message.success(t('workPlanTaskConfigurationEditor.taskSaved'));
        loadConfigurations();
      }
    } catch (error) {
      console.log(error);
      message.error(t('messages.error.failedStopTask'));
    }
  };

  const handleMenuClick = ({ key, id }) => {
    const configuration = workPlanTaskConfigurationsByIds[id];

    const repeatType =
      PERIOD_REPEAT[workPlanTaskSchedulesByIds[configuration?.scheduleId]?.repeatEvery]?.value;

    switch (key) {
      case 'edit':
        return history.push(`/work-plan/task-configurations/editor/${id}`);

      case 'copy':
        return copyWorkPlanConfiguration({ id });

      case 'cancel':
        return Modal.confirm({
          destroyOnClose: true,
          closable: true,
          title: t('workPlanTasks.confirmModal.title'),
          content:
            repeatType !== PERIOD_REPEAT.never.value ? t('workPlanTasks.confirmModal.content') : '',
          cancelText: t('organizationStructure.tabs.units.confirmDelete.cancel'),
          okText: t('general.continue'),
          onOk: () => cancelWorkPlanTaskConfiguration({ id })
        });

      case 'run':
        return runTasks({ id });

      case 'delete':
        return Modal.confirm({
          destroyOnClose: true,
          closable: true,
          title: t('workPlanTasks.confirmDelete'),
          cancelText: t('organizationStructure.tabs.units.confirmDelete.cancel'),
          okText: t('organizationStructure.tabs.units.confirmDelete.ok'),
          onOk: () => deleteWorkPlanConfiguration({ id })
        });

      default:
        console.log('Wrong case');
    }
  };

  const getMenu = ({ id }) => {
    const isVisibleStopMenuItem =
      workPlanTaskConfigurationsByIds[id]?.status !==
        WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value &&
      workPlanTaskConfigurationsByIds[id]?.status !==
        WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.completed.value &&
      workPlanTaskConfigurationsByIds[id]?.status !==
        WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.stopped.value;

    const isVisibleEditMenuItem =
      workPlanTaskConfigurationsByIds[id]?.status !==
        WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.completed.value &&
      workPlanTaskConfigurationsByIds[id]?.status !==
        WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.stopped.value;

    const isVisibleRunMenuItem =
      workPlanTaskConfigurationsByIds[id]?.status ===
        WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value &&
      workPlanTaskConfigurationsByIds[id]?.status ===
        WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.stopped.value;

    return (
      <Menu onClick={({ key }) => handleMenuClick({ key, id })}>
        {isVisibleEditMenuItem && (
          <Menu.Item key="edit">
            <Icon icon={Edit} />
            <span>{t('general.edit')}</span>
          </Menu.Item>
        )}

        <Menu.Item key="copy">
          <Icon icon={Copy} />
          <span>{t('workPlanTaskConfigurationEditor.copyTask')}</span>
        </Menu.Item>

        {isVisibleRunMenuItem && (
          <Menu.Item key="run">
            <Icon icon={PlayCircle} />
            <span>{t('workPlanTaskConfigurationEditor.startTask')}</span>
          </Menu.Item>
        )}

        {isVisibleStopMenuItem && (
          <Menu.Item key="cancel" style={{ color: 'var(--red_primary)' }}>
            <Icon icon={StopCircle} />
            <span>{t('workPlanTasks.tableColumns.closeTask')}</span>
          </Menu.Item>
        )}

        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('general.delete')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const tableColumns = [
    {
      title: t('general.title'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        const link =
          record?.repeatedCount > 1
            ? `/work-plan/task-configurations/${record?.id}/tasks`
            : `/work-plan/task-configurations/${record?.id}/tasks/${record?.lastTaskId}`;

        return record?.status === WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value ? (
          <SRow gutter={[8, 0]}>
            <SCol display="flex" alignItems="center">
              <Icon icon={Shuffle} />
            </SCol>
            <SCol className="truncated" width="calc(100% - 24px)">
              {text}
            </SCol>
          </SRow>
        ) : (
          <Link to={link} type="link">
            <SRow gutter={[8, 0]}>
              <SCol display="flex" alignItems="center">
                <Icon icon={Shuffle} />
              </SCol>
              <SCol className="truncated" width="calc(100% - 24px)">
                {text}
              </SCol>
            </SRow>
          </Link>
        );
      }
    },

    {
      title: (
        <SRow gutter={[8, 0]}>
          <SCol>
            <SText>{t('workPlanTasks.tableColumns.progress')}</SText>
          </SCol>
          <SCol display="flex" alignItems="center">
            <IconButton
              tooltip={{
                title: t('workPlanTasks.tooltips.configurationProgress'),
                trigger: 'click'
              }}
              button={{
                icon: <QuestionCircleOutlined />,
                size: 'icon'
              }}
            />
          </SCol>
        </SRow>
      ),
      dataIndex: 'reviewsCount',
      key: 'reviewsCount',
      ellipsis: true,
      width: 170,
      render: (text, record) => {
        const lastTask = workPlanTasksByIds[record?.lastTaskId];
        if (
          (!lastTask?.completedTaskItemsCount && lastTask?.completedTaskItemsCount !== 0) ||
          (!lastTask?.totalTaskItemsCount && lastTask?.totalTaskItemsCount !== 0) ||
          record.status === WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.pending.value ||
          record.status === WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value
        )
          return '-';

        return (
          <SText>{`${lastTask?.completedTaskItemsCount}/${lastTask?.totalTaskItemsCount}`}</SText>
        );
      }
    },

    {
      title: t('general.start'),
      dataIndex: 'lastTaskTimeFrom',
      key: 'lastTaskTimeFrom',
      ellipsis: true,
      sorter: true,
      width: 200,
      render: (text, record) => {
        const lastTask = workPlanTasksByIds[record?.lastTaskId];
        if (!lastTask?.timeFrom) return '-';
        return <SText>{moment(lastTask?.timeFrom).format('DD/MM/YYYY, HH:mm:ss')}</SText>;
      }
    },

    {
      title: t('general.finish'),
      dataIndex: 'lastTaskTimeTo',
      key: 'lastTaskTimeTo',
      ellipsis: true,
      sorter: true,
      width: 200,
      render: (text, record) => {
        const lastTask = workPlanTasksByIds[record?.lastTaskId];
        if (!lastTask?.timeTo) return '-';
        return <SText>{moment(lastTask?.timeTo).format('DD/MM/YYYY, HH:mm:ss')}</SText>;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.creator'),
      dataIndex: 'createdById',
      key: 'createdById',
      ellipsis: true,
      render: text => {
        return <UserPreview userId={text} showAvatar disabled />;
      }
    },

    {
      title: (
        <SRow gutter={[8, 0]}>
          <SCol>
            <SText>{t('workPlanTasks.tableColumns.schedule')}</SText>
          </SCol>
          <SCol display="flex" alignItems="center">
            <IconButton
              tooltip={{ title: t('workPlanTasks.tooltips.schedule'), trigger: 'click' }}
              button={{
                icon: <QuestionCircleOutlined />,
                size: 'icon'
              }}
            />
          </SCol>
        </SRow>
      ),
      dataIndex: 'schedule',
      key: 'schedule',
      ellipsis: true,
      width: 150,
      render: (text, record) => {
        return (
          <SText>
            {t(PERIOD_REPEAT[workPlanTaskSchedulesByIds[record?.scheduleId]?.repeatEvery]?.title)}
          </SText>
        );
      }
    },

    {
      title: (
        <SRow gutter={[8, 0]} justify="center">
          <SCol>
            <SText>{t('workPlanTasks.tableColumns.repeatedCount')}</SText>
          </SCol>
          <SCol display="flex" alignItems="center">
            <IconButton
              tooltip={{ title: t('workPlanTasks.tooltips.repeatedCount'), trigger: 'click' }}
              button={{
                icon: <QuestionCircleOutlined />,
                size: 'icon'
              }}
            />
          </SCol>
        </SRow>
      ),
      dataIndex: 'repeatedCount',
      key: 'repeatedCount',
      width: 115,
      align: 'center',
      render: text => <SText>{text}</SText>
    },

    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      sorter: true,
      width: 150,
      render: text => (
        <SText color={WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[text]?.color}>
          {t(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[text]?.title)}
        </SText>
      )
    },

    {
      title: '',
      dataIndex: 'id',
      key: 'menu',
      width: 50,
      ellipsis: true,
      fixed: 'right',
      render: text => {
        return (
          <Dropdown overlay={getMenu({ id: text })} trigger={['click']}>
            <Button icon={<Icon icon={MoreOutlined} />} />
          </Dropdown>
        );
      }
    }
  ];

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <Filters />
          </SCol>
          <SCol span={24}>
            <SRow type="flex" margin="16px">
              <SCol span={24}>
                <SCard bordered shadowed>
                  <STable
                    rowKey="id"
                    dataSource={workPlanTaskConfigurations}
                    columns={tableColumns}
                    loading={loading}
                    border
                    pagination={{
                      pageSize: parseInt(page.size),
                      current: parseInt(page.number),
                      total: totalCount,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '25', '50'],
                      size: 'small'
                    }}
                    onChange={handleTableChange}
                    fixSorterColumns
                    scroll={{ y: 'calc(100vh - 254px)', x: 'max-content' }}
                    size="small"
                  />
                </SCard>
              </SCol>
            </SRow>
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTaskConfigurationsTable;
