import { Button, Col, Form, Input, Row, Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import AmoService from 'core/services/amocrm';
import { handleError } from 'core/services/errors';
import { AMO_COMMANDS } from 'core/utils/constants';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { passwordSetRequest } from 'redux/entities/authPassword/operations';
import { signInUser } from 'redux/reducers/reduxTokenAuthReducer';
import styled from 'styled-components';
import { AuthLogo, LinkToLandingPage } from '../styled';

const { Title, Text } = Typography;

const FormContainer = styled(Col)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-card {
      width: 80%;
      max-width: 560px;
    }
  }
`;

const SetNewPasswordPage = ({ history, passwordSetRequest, signInUser }) => {
  const [loading, setLoading] = useState(false);

  const { search } = history.location;
  const { email = '', reset_password_token: resetPasswordToken = '', newUser = false } = qs.parse(
    search || '',
    {
      ignoreQueryPrefix: true
    }
  );
  const { t } = useTranslation();

  const setNewPassword = async values => {
    const { password } = values;
    setLoading(true);
    try {
      const result = await passwordSetRequest({
        resetPasswordToken,
        email,
        password,
        passwordConfirmation: password
      });

      if (result) {
        await signInUser({ email, password });
        setLoading(false);
        history.push('/');
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      handleError(err?.response?.status, err?.response?.data);
    }
  };

  useEffect(() => {
    return () => {
      AmoService.sendMessage({
        command: AMO_COMMANDS.SEND_USER_DATA,
        params: {
          dealappUser: {
            'access-token': localStorage.getItem('access-token'),
            client: localStorage.getItem('client'),
            uid: localStorage.getItem('uid'),
            expiry: localStorage.getItem('expiry')
          }
        }
      });
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.setNewPasswordPage.title')}</title>
      </Helmet>
      <AuthLogo />
      <Row type="flex" align="middle" justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <FormContainer span={24}>
            <SCard shadowed>
              <Row type="flex" align="middle">
                <Col>
                  <Title level={3}>
                    {newUser
                      ? t('recoverPasswordPage.setNewPassword.title.newUser')
                      : t('recoverPasswordPage.setNewPassword.title.setPass')}
                  </Title>
                </Col>
              </Row>

              <Form hideRequiredMark name="password" onFinish={setNewPassword}>
                <Row className="ant-form-item">
                  <Col>
                    <Text strong>
                      {newUser
                        ? `${t('recoverPasswordPage.setNewPassword.form.newPassMessage')} ${email}`
                        : email}
                    </Text>
                  </Col>
                </Row>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('recoverPasswordPage.setNewPassword.form.messages.enterPass')
                    },
                    {
                      min: 6,
                      message: t(
                        'recoverPasswordPage.setNewPassword.form.messages.minLengthPassword'
                      )
                    }
                  ]}
                >
                  <Input.Password
                    placeholder={t(
                      'recoverPasswordPage.setNewPassword.form.fields.passwordPlaceholder'
                    )}
                  />
                </Form.Item>

                <Button type="primary" htmlType="submit" block loading={loading}>
                  {t('recoverPasswordPage.setNewPassword.form.buttons.continue')}
                </Button>
              </Form>
            </SCard>
          </FormContainer>
        </Col>
      </Row>
      <LinkToLandingPage />
    </>
  );
};

const mapDispatchToProps = {
  passwordSetRequest,
  signInUser
};

export default withRouter(connect(null, mapDispatchToProps)(SetNewPasswordPage));
