import { Button, Col, Dropdown, Menu, Row, Typography } from 'antd';
import Icon from 'components/Icon';
import Search from 'components/Inputs/Search';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import UserPreview from 'components/UserPreview';
import { find, isEmpty, orderBy, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trash } from 'react-feather';
import { MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { operations, actions } from 'redux/lists/usersListReducer';
import { integrationsResource } from 'redux/resources/integrations';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';
import SelectUserAccountList from './SelectUserAccountList';
import decamelizeKeys from 'decamelize-keys';

const { Text } = Typography;

const Connections = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const integrations = useSelector(state =>
    orderBy(Object.values(state.integrationsResource.byIds), 'createdAt')
  );
  const [addingUserAccountToUser, setAddingUserAccountToUser] = useState(null);
  const userAccountsByIds = useSelector(state => state.userAccountBindingsResource.byIds, isEqual);
  const { ids, search, page, filters, totalCount } = useSelector(state => state.usersList);
  const users = useSelector(state =>
    ids.map(id => state.usersResource.byIds[id]).filter(user => !!user)
  );
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const loadUsersWithAccounts = async () => {
    setLoading(true);
    const usersByIds = await dispatch(
      operations.load({
        search,
        page,
        filters,
        sort: 'last_name',
        include: 'unit,role.levels'
      })
    );
    await dispatch(
      userAccountBindingsResource.operations.load({
        pagination: false,
        filters: {
          usersIds: Object.values(usersByIds).map(({ id }) => id)
        }
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    loadUsersWithAccounts();
  }, [search, page.number, page.size]);

  useEffect(() => {
    dispatch(integrationsResource.operations.load(decamelizeKeys({ id: organizationId})));
    return () => dispatch(actions.clearList());
  }, []);

  const onAddUserAccount = ({ integrationId, userId }) => {
    setAddingUserAccountToUser({ integrationId, userId });
  };

  const getColumnFromIntegration = integration => {
    return {
      title: integration.name,
      dataIndex: integration.id,
      key: integration.id,
      width: 200,
      ellipsis: true,
      render: (_, user) => {
        const userAccount = find(
          userAccountsByIds,
          account => account.userId === user.id && account.integrationId === integration.id
        );

        if (userAccount) {
          return <UserPreview user={userAccount} showAvatar disabled />;
        }

        return (
          <SButton
            size="small"
            onClick={() => onAddUserAccount({ integrationId: integration.id, userId: user.id })}
          >
            {t('organizationStructure.tabs.units.modal.ok')}
          </SButton>
        );
      }
    };
  };

  const handleMenuClick = userAccountId => {
    dispatch(userAccountBindingsResource.operations.unbind({ id: userAccountId }));
  };

  const menu = ({ userId }) => {
    // const editableUserStatus = get(usersByIds, [userId, 'active']);
    const integrationsUserAddedTo = integrations.reduce((acc, integration) => {
      const userConnectionToIntegration = find(
        userAccountsByIds,
        account => account.userId === userId && integration.id === account.integrationId
      );

      if (!userConnectionToIntegration) return acc;

      return [...acc, { ...integration, userConnectionToIntegration }];
    }, []);

    if (isEmpty(integrationsUserAddedTo))
      return (
        <Menu>
          <Row gutter={[16, 16]} style={{ margin: 0 }}>
            <Col>{t('organizationStructure.tabs.connections.table.userHasNoIntegrations')}</Col>
          </Row>
        </Menu>
      );

    return (
      <Menu onClick={e => handleMenuClick(e.key)}>
        {integrationsUserAddedTo.map(integration => (
          <Menu.Item
            key={integration.userConnectionToIntegration.id}
            style={{ color: 'var(--red_primary)' }}
          >
            <Icon icon={Trash} />
            <span>
              {`${t('organizationStructure.tabs.connections.table.deleteFrom')} ${
                integration.name
              }`}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const tableConfig = {
    columns: [
      {
        title: t('organizationStructure.tabs.employees.table.columns.name'),
        dataIndex: 'name',
        key: 'name',
        width: 300,
        render: (text, user) => {
          return (
            <Row>
              <Col span={24}>
                <UserPreview
                  size="mediumLarge"
                  userId={user.id}
                  disabled
                  showEmail
                  showUnit
                  showAvatar
                  margin="0px"
                />
              </Col>
            </Row>
          );
        }
      },
      ...integrations.map(getColumnFromIntegration),
      {
        key: 'isActive',
        dataIndex: 'active',
        fixed: 'right',
        width: 50,
        render: (active, user) => {
          return (
            <Row align="middle" justify="end">
              <Col>
                <Dropdown
                  overlay={menu({ userId: user.id })}
                  trigger={['click']}
                  placement="bottomLeft"
                >
                  <Button icon={<Icon icon={MoreOutlined} />} />
                </Dropdown>
              </Col>
            </Row>
          );
        }
      }
    ]
  };

  const handleTableChange = pagination => {
    const { pageSize, current } = pagination;
    dispatch(actions.updatePage({ size: pageSize.toString(), number: current.toString() }));
  };

  const onSearch = value => dispatch(actions.setSearch(value));

  return (
    <SCard bordered shadowed>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Search
                loading={loading}
                placeholder={t(
                  'organizationStructure.tabs.employees.table.filters.employeesSearch'
                )}
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Text type="secondary">
                {`${t('organizationStructure.tabs.connections.table.users')}: `}
              </Text>
              <Text>{totalCount}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <STable
            dataSource={users}
            rowKey="id"
            size="small"
            loading={loading}
            columns={tableConfig.columns}
            border
            pagination={{
              pageSize: parseInt(page.size),
              current: parseInt(page.number),
              total: totalCount,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50']
            }}
            onChange={handleTableChange}
            // calc(100vh - 286px) for not collapsed
            // calc(100vh - 356px) for collapsed
            // * NOTE: it doesn't work on resize
            scroll={{ y: 'calc(100vh - 380px)' }}
          />
        </Col>
      </Row>

      <SelectUserAccountList
        visible={!isEmpty(addingUserAccountToUser)}
        integrationId={addingUserAccountToUser?.integrationId}
        userId={addingUserAccountToUser?.userId}
        onCancel={() => setAddingUserAccountToUser(null)}
      />
    </SCard>
  );
};

export default Connections;
