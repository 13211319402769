import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons/IconButton';
import WidgetViewChanger from 'components/Dashboard/WidgetViewChanger';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from 'components/Standard/SRow';
import { LargeWidgetCard } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  exportWidgetData,
  widgetInfoTextSetter,
  widgetNameSetter,
  widgetPeriodSetter
} from '../../utils';
import { prepareColumns, prepareTableData } from './prepareTableData';
import { prepareBarData, prepareLineData } from './prepareChartData';
import EmptyWidget from '../../Components/EmptyWidget';
import TableView from './Views/TableView';
import ChartView from './Views/ChartView';
import { ContextExportDashboards } from '../../DashboardPage';
import WidgetViewLastUpdate from '../../../../components/Dashboard/WidgetViewLastUpdate';

const TableReviewsCountByOperators = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);

  const { id, type, filters, name } = widgetData;
  const [loadingExport, setLoadingExport] = useState(false);
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.REVIEWERS.value);

  const widgetValue = get(widgetsAnalyticsByIds, id, {});
  const metadata = widgetValue?.metadata;
  const timeFrom = metadata?.time_from;
  const timeTo = metadata?.time_to;

  const checklistDefinitionAverageScoreByOperatorsHistory = get(
    widgetValue,
    'checklist_definition_average_score_by_operators_history',
    []
  );

  const checklistDefinitionAverageScore = get(widgetValue, 'checklist_definition_average_score');

  const checklistDefinitionAverageScoreHistory = get(
    widgetValue,
    'checklist_definition_average_score_history'
  );

  const reviewsCountByOperatorsHistory = get(widgetValue, 'reviews_count_by_operators_history', []);

  const reviewsCountByOperators = get(widgetValue, 'reviews_count_by_operators', []);

  const checklistDefinitionAverageScoreByOperators = get(
    widgetValue,
    'checklist_definition_average_score_by_operators',
    []
  );

  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});

  const colorZones = get(checklistDefinition, 'colorZones', {});

  const checklistDefinitionMax = () => {
    const currentChecklistRatingMode = get(
      checklistDefinitionsByIds,
      `${filters.checklistDefinitionsIds}.ratingMode`
    );

    return currentChecklistRatingMode === RATING_MODE.PERCENTAGE
      ? 100
      : get(checklistDefinitionsByIds, `${filters.checklistDefinitionsIds}.max`);
  };

  const onChangeViewMode = value => {
    dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: { ...filters, viewMode: value }
      })
    );
  };

  const tableColumns = prepareColumns({
    filters,
    timeFrom,
    timeTo,
    colorZones,
    checklistDefinition
  });

  const tableRows = prepareTableData({
    filters,
    timeFrom,
    timeTo,
    usersByIds,
    unitsByIds,
    checklistDefinition,
    checklistDefinitionAverageScore,
    checklistDefinitionAverageScoreHistory,
    checklistDefinitionAverageScoreByOperatorsHistory,
    checklistDefinitionAverageScoreByOperators,
    reviewsCountByOperatorsHistory,
    reviewsCountByOperators
  });

  const isEmptyWidgetData = tableRows.length === 2;

  const barData = prepareBarData({
    filters,
    timeFrom,
    timeTo,
    usersByIds,
    checklistDefinitionAverageScoreByOperatorsHistory
  });

  const lineData = prepareLineData({
    filters,
    timeFrom,
    timeTo,
    checklistDefinitionAverageScoreHistory
  });

  const body = {
    widgetType: type,
    widgetData: {
      columns: tableColumns.map(item => ({
        ...item,
        title: ['name', 'totalScore', 'reviewsCount', 'unitName'].includes(item.key)
          ? t(item?.title?.props?.i18nKey)
          : moment(item.key).format('DD/MM')
      })),
      rows: tableRows.map(item => ({
        ...item,
        name: item.key === 'header' || item.key === 'footer' ? t(item.name) : item.name
      }))
    }
  };
  const { addElementExportWidget } = useContext(ContextExportDashboards);
  const prevBodyRef = useRef(null);
  useLayoutEffect(() => {
    if (prevBodyRef.current !== body && (!isEmptyWidgetData && !isWarningWidget)) {
      prevBodyRef.current = body;
      addElementExportWidget({ ...prevBodyRef.current, id });
    }
  }, [body]);
  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <SCol span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type,
                    showDateMode: true
                  })}
                </TimePeriod>
              </SCol>
              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                <WidgetViewChanger value={viewMode} onChange={onChangeViewMode} />
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
                <WidgetViewLastUpdate
                  widgetData={widgetData}
                  dateLastUpdate={widgetValue?.dateLastUpdate}
                />
              </SCol>
            </Row>
            {viewMode === WIDGET_VIEW_MODES.TABLE.value ? (
              <TableView
                columns={tableColumns.filter(item => item.key !== 'unitName')}
                dataSource={tableRows}
              />
            ) : (
              <ChartView
                dataSource={{ table: checklistDefinitionAverageScoreByOperators }}
                checklistDefinition={checklistDefinition}
                colorZones={colorZones}
                checklistDefinitionMax={checklistDefinitionMax()}
                barData={barData}
                lineData={lineData}
                filters={filters}
                widgetId={id}
              />
            )}
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableReviewsCountByOperators;
