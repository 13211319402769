import { createAction, createReducer } from '@reduxjs/toolkit';

import { TIME_RANGES } from './constants';

const defaultState = {
  currentChecklistDefinitionId: undefined,
  checklistDefinitionsIds: [],
  currentTimeRangeName: TIME_RANGES[2],
  currentSpecificTimeRange: {},
  currentSkill: undefined,
  loadingSkills: false,
  checklistDefinitionIdToSkills: {}
};

export const setCurrentChecklistDefinitionId = createAction(
  'uiSkillsWidget/setCurrentChecklistDefinitionId'
);
export const setChecklistDefinitionsIds = createAction('uiSkillsWidget/setChecklistDefinitionsIds');
export const setCurrentTimeRangeName = createAction('uiSkillsWidget/setCurrentTimeRangeName');
export const setSpecificTimeRange = createAction('uiSkillsWidget/setSpecificTimeRange');
export const loadingSkills = createAction('uiSkillsWidget/loadingSkills');
export const loadedSkills = createAction('uiSkiilsWidget/loadedSkills');
export const setCurrentSkill = createAction('uiSkillsWidget/setCurrentSkill');

export const skillsWidgetReducer = createReducer(defaultState, {
  [setCurrentChecklistDefinitionId]: (state, { payload }) => ({
    ...state,
    currentChecklistDefinitionId: payload
  }),
  [setChecklistDefinitionsIds]: (state, { payload }) => ({
    ...state,
    checklistDefinitionsIds: payload
  }),
  [setCurrentTimeRangeName]: (state, { payload }) => ({
    ...state,
    currentTimeRangeName: payload,
    currentSpecificTimeRange: {}
  }),
  [setCurrentSkill]: (state, { payload }) => ({ ...state, currentSkill: payload }),
  [loadedSkills]: (state, { payload }) => ({
    ...state,
    checklistDefinitionIdToSkills: {
      ...state.checklistDefinitionIdToSkills,
      [payload.checklistDefinitionId]: payload
    },
    loadingSkills: false
  }),
  [loadingSkills]: state => ({ ...state, loadingSkills: true }),
  [setSpecificTimeRange]: (state, { payload }) => ({ ...state, currentSpecificTimeRange: payload })
});

export default skillsWidgetReducer;
