export default {
  appealsPage: {
    actionsListName: 'Cambiar feed',
    actionsList: {
      changed: 'cambió',
      accept: 'aceptado',
      reject: 'rechazado',
      create: 'archivado',
      add: 'agregado',
      exclude: 'excluido',
      answered: 'respondido',
      createComment: 'dejó un comentario',
      statusBy: 'estado en',
      appealByCritery: 'apelación de criterio',
      appeal: 'apelación',
      wathcer: 'observador',
      viewer: 'observador'
    },
    appealInfo: {
      operator: 'Operador',
      reviewCreate: 'Comprobado'
    },
    appealStatus: {
      process: 'Proceso',
      needsInformation: 'Necesita informacion',
      withoutAnswer: 'Sin respuesta',
      appealInfo: 'Información sobre la apelación',
      notProcessed: 'No procesado'
    },
    participantsCard: {
      appealed: 'Apelado',
      watchers: 'Observadores',
      addWatcher: 'Agregar observadores',
      addingWatcher: 'Agregar observadores'
    },
    appealsList: {
      appealBy: 'Verificador',
      changedBy: 'Cambió'
    },
    filters: { withCurrentUserRoles: 'Apelaciones donde estoy' },
    settings: {
      appealDeadline:
        'El período máximo después de la verificación para presentar una apelación (días)',
      addOperatorAsWatcher: 'Asignar automáticamente al operador como observador para apelar',
      addReviewerAsWatcher: 'Nombrar automáticamente a un revisor como observador en la apelación',
      selectAssignerFromUsersArray: 'Programe la apelación presentada para:',
      selectAssignerFromUsersArrayAdd: 'Seleccione un empleado específico',
      appealed: 'Apelado',
      assignerMethods: {
        reviewer: {
          title: 'Sobre el autor del cheque',
          description:
            'Todas las apelaciones presentadas se asignarán automáticamente al autor de la calificación.'
        },
        usersArray: {
          description:
            'Todas las apelaciones presentadas se asignarán automáticamente a los empleados seleccionados.',
          title: 'Para los empleados seleccionados'
        },
        assignerByApellantRules: {
          description:
            'Puede crear una regla según la cual los recursos presentados por los empleados se distribuirán entre los responsables',
          title: 'Crea tu propia regla'
        }
      },
      addRule: 'Agregar regla',
      assignRule: {
        appellants: 'Si se presenta la apelación',
        assigners: 'Programe una apelación para',
        note: 'Nota'
      }
    },
    confirmDelete: '¿Eliminar la apelación?',
    analytics: {
      noAnswer: 'Sin respuesta',
      pending: 'No procesado',
      tracker: 'Rastreador',
      appellationsByReviewers: {
        title: 'Apelaciones de revisores'
      },
      appellationsByAppellants: {
        title: 'Apelaciones del autor'
      }
    },
    appealAccepted: 'Apelación aceptada',
    appealDenied: 'Apelación denegada'
  }
};
