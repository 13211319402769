import { get, post } from 'redux-bees';

const phoneCallsEndpointsV3 = apiPath => ({
  getPhoneCallsV3: { method: post, path: `${apiPath}/upload/get_calls` },
  updatePhoneCallsV3: { method: post, path: `${apiPath}/upload/set_atributes` },
  deletePhoneCallsV3: { method: post, path: `${apiPath}/upload/delete` },
  getMediaUrlV3: { method: get, path: `${apiPath}/libraries/get_calls_media_url?id=:id` }
});

export default phoneCallsEndpointsV3;
