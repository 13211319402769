import { openDB } from 'idb';

const DB_NAME = 'assessmentApp';
const DB_VERSION = 1;
const ANSWERS_STORE = 'answers';
const COMMENTS_STORE = 'comments';
const FILES_STORE = 'files';
export async function initDB() {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(ANSWERS_STORE)) {
        db.createObjectStore(ANSWERS_STORE, {
          keyPath: ['questionId', 'formId', 'communicationId']
        });
      }
      if (!db.objectStoreNames.contains(COMMENTS_STORE)) {
        db.createObjectStore(COMMENTS_STORE, { keyPath: ['id', 'formId', 'communicationId'] });
      }
      if (!db.objectStoreNames.contains(FILES_STORE)) {
        db.createObjectStore(FILES_STORE, { keyPath: 'id' });
      }
    }
  });
}

export async function saveAnswerIndexDB(communicationId, formId, answer) {
  communicationId = communicationId || 'new';
  const db = await initDB();
  return db.put(ANSWERS_STORE, { ...answer, formId, communicationId });
}

export async function saveCommentIndexDB(communicationId, formId, comment) {
  const db = await initDB();
  return db.put(COMMENTS_STORE, { ...comment, formId, communicationId });
}
export async function saveFileIndexDB(file) {
  const db = await initDB();
  const { id } = file;
  return db.put(FILES_STORE, { ...file, id });
}

export async function getAnswersIndexDB(communicationId, formId) {
  const db = await initDB();
  const tx = db.transaction(ANSWERS_STORE, 'readonly');
  const store = tx.objectStore(ANSWERS_STORE);
  const results = [];
  let cursor = await store.openCursor();
  while (cursor) {
    if (cursor.value.formId === formId && cursor.value.communicationId === communicationId) {
      results.push(cursor.value);
    }
    cursor = await cursor.continue();
  }

  return results;
}

export async function getCommentsIndexDB(communicationId, formId) {
  const db = await initDB();
  const tx = db.transaction(COMMENTS_STORE, 'readonly');
  const store = tx.objectStore(COMMENTS_STORE);
  const results = [];
  let cursor = await store.openCursor();
  while (cursor) {
    if (cursor.value.formId === formId && cursor.value.communicationId === communicationId) {
      results.push(cursor.value);
    }
    cursor = await cursor.continue();
  }

  return results;
}
export async function getFileIndexDB(id) {
  const db = await initDB();
  return db.get(FILES_STORE, id);
}
export async function deleteAnswersByFormIdIndexDB(communicationId, formId) {
  const db = await initDB();
  const tx = db.transaction(ANSWERS_STORE, 'readwrite');
  const store = tx.objectStore(ANSWERS_STORE);
  let cursor = await store.openCursor();
  while (cursor) {
    if (cursor.value.formId === formId && cursor.value.communicationId === communicationId) {
      cursor.delete();
    }
    cursor = await cursor.continue();
  }
  return tx.done;
}

export async function deleteCommentByIdIndexDB(commentId) {
  const db = await initDB();
  const tx = db.transaction(COMMENTS_STORE, 'readwrite');
  const store = tx.objectStore(COMMENTS_STORE);
  let cursor = await store.openCursor();

  while (cursor) {
    const { value } = cursor;
    if (value?.text?.id === commentId || value.id === commentId) {
      await cursor.delete();
      break; // Выход из цикла после удаления нужного комментария
    }
    cursor = await cursor.continue();
  }
  return tx.done;
}

export async function updateCommentTextByIdIndexDB(comment) {
  const db = await initDB();
  const tx = db.transaction(COMMENTS_STORE, 'readwrite');
  const store = tx.objectStore(COMMENTS_STORE);
  let cursor = await store.openCursor();

  while (cursor) {
    const { value } = cursor;
    if (value?.text?.id === comment.id || value.id === comment.id) {
      let updatedComment;

      if (value?.text?.id === comment.id) {
        updatedComment = { ...value, text: { ...value.text, ...comment } };
      } else {
        updatedComment = { ...value, ...comment };
      }

      await cursor.update(updatedComment);
      break;
    }
    cursor = await cursor.continue();
  }
  return tx.done;
}

export async function deleteFileByIdIndexDB(fileId) {
  try {
    const db = await initDB();
    const tx = db.transaction(FILES_STORE, 'readwrite');
    const store = tx.objectStore(FILES_STORE);

    await store.delete(fileId);

    await tx.done;
    console.log(`File with ID ${fileId} deleted successfully.`);
  } catch (error) {
    console.error(`Failed to delete file with ID ${fileId}:`, error);
  }
}
export async function deleteCommentsByFormIdIndexDB(communicationId, formId) {
  const db = await initDB();
  const tx = db.transaction([COMMENTS_STORE, FILES_STORE], 'readwrite');
  const commentsStore = tx.objectStore(COMMENTS_STORE);
  const filesStore = tx.objectStore(FILES_STORE);

  let cursor = await commentsStore.openCursor();
  while (cursor) {
    if (cursor.value.formId === formId && cursor.value.communicationId === communicationId) {
      if (Array.isArray(cursor.value.uploadedFilesIds)) {
        for (const fileId of cursor.value.uploadedFilesIds) {
          await filesStore.delete(fileId);
        }
      } else if (Array.isArray(cursor.value.text.uploadedFilesIds)) {
        for (const fileId of cursor.value.text.uploadedFilesIds) {
          await filesStore.delete(fileId);
        }
      }
      await cursor.delete();
    }

    cursor = await cursor.continue();
  }
  await tx.done;
}
