import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

const EditableLabel = ({ value, onSave, initialState = 'text' }) => {
  const [state, setState] = useState(initialState);
  const save = () => {
    if (!onSave) {
      throw new TypeError('onSave must be passed');
    }

    if (isEmpty(value)) return;
    // passing second value as true for final save
    onSave(value, { final: true });
    setState('text');
  };

  return (
    <>
      {state === 'text' ? (
        <Container>
          <Text style={{ marginBottom: '20px' }}>{value}</Text>
          <CustomButton
            icon={<EditOutlined />}
            style={{ marginBottom: '30px' }}
            onClick={() => setState('edit')}
            type="link"
          />
        </Container>
      ) : (
        <Container>
          <Input
            onChange={e => onSave(e.target.value, { final: false })}
            value={value}
            onPressEnter={save}
          />
          <CustomButton
            icon={<CheckOutlined />}
            onClick={save}
            disabled={!value.trim()}
            type="link"
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  min-height: 42px;
  align-items: center;
`;

export const CustomButton = styled(Button)`
  margin: auto;
`;

const Text = styled.p`
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
`;

export default EditableLabel;
