import decamelize from 'decamelize-keys-deep';
import api from 'core/api';
import { processJsonApiCollection } from 'core/jsonapi';
import * as actions from './reducer';

export const search = params => {
  return async dispatch => {
    dispatch(actions.setLoading(true));
    try {
      const response = await api.search(decamelize(params));
      const results = processJsonApiCollection(response.body.data);
      dispatch(actions.setLoading(false));
      return results;
    } catch (error) {
      console.log(error);

      dispatch(actions.setLoading(false));
      dispatch(actions.catchError(error));
    }
  };
};
