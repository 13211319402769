import { get, isEqual } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Ducalis = () => {
  const isLoadingReduxTokenAuth = useSelector(state => state.reduxTokenAuth.currentUser.isLoading);
  const isSignedIn = useSelector(state => state.reduxTokenAuth.currentUser.isSignedIn);
  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);

  const { user: currentUser } = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes,
    isEqual
  );

  useEffect(() => {
    if (!isLoadingReduxTokenAuth && isSignedIn) {
      window.dclsPxl('init', { appId: 'f42881d61d5b8d6cf6947c074f4688d25636fb7f' });

      window.dclsPxl('addUser', {
        userID: currentUserId,
        name: `${get(currentUser, 'first-name', '')} ${get(currentUser, 'last-name', '')}`,
        email: get(currentUser, 'email'),
        // avatar: get(currentUser, 'avatar-url', ''),
        organization_name: get(currentUser, 'organization.name', '')
      });
    }
  }, [currentUserId, isSignedIn]);

  return null;
};

export default Ducalis;
