import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  byIds: {},
  currentChecklistDefinition: null
};

export const loadChecklistDefinitionsByIds = createAction('uiChecklistDefinition/loadByIds');
export const createChecklistDefinition = createAction('uiChecklistDefinition/create');
export const updateChecklistDefinition = createAction('uiChecklistDefinition/update');
export const deleteChecklistDefinition = createAction('uiChecklistDefinition/delete');
export const setCurrentChecklistDefinition = createAction('uiChecklistDefintion/setCurrent');

export const checklistDefinitionsReducer = createReducer(defaultState, {
  [loadChecklistDefinitionsByIds]: (state, action) => ({
    ...state,
    byIds: { ...state.byIds, ...action.payload }
  }),
  [createChecklistDefinition]: (state, { payload }) => {
    return {
      ...state,
      byIds: { ...state.byIds, [payload.id]: payload },
      currentChecklistDefinition: payload
    };
  },
  [updateChecklistDefinition]: (state, { payload }) => ({
    ...state,
    byIds: { ...state.byIds, [payload.id]: payload },
    currentChecklistDefinition: { ...payload }
  }),
  [deleteChecklistDefinition]: (state, action) => {
    const newByIds = { ...state.byIds };
    delete newByIds[action.id];
    return { ...state, byIds: newByIds };
  },
  [setCurrentChecklistDefinition]: (state, { payload }) => ({
    ...state,
    currentChecklistDefinition: payload
  })
});
