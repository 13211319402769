import { REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS } from 'core/utils/constants';
import { get } from 'lodash';
import { sum } from 'lodash/fp';

export const calcDistributions = ({
  communicationsDistributionMethod,
  clientInteractionTypes,
  reviewersIds,
  setDistribution,
  distribution
}) => {
  const distributeCommunications = function*(clientInteractionsMaxCount, reviewersIds) {
    const divider = reviewersIds.length;

    let rest = clientInteractionsMaxCount % divider;
    const result = clientInteractionsMaxCount / divider;

    for (let i = 0; i < divider; i++) {
      if (rest-- > 0) {
        yield { userId: reviewersIds[i], value: Math.ceil(result) };
      } else {
        yield { userId: reviewersIds[i], value: Math.floor(result) };
      }
    }
  };

  const distributionByReviewersIds = () => {
    const distributionByUsersIds = {};
    for (let result of distributeCommunications(100, reviewersIds)) {
      distributionByUsersIds[result.userId] = result.value;
    }
    return distributionByUsersIds;
  };

  // * автоматический режим
  // ? распределять веса между сотрудниками при создании нового типа коммуникации
  // ? распределять веса между сотрудниками при добавлении нового сотрудника
  // ? пересчитывать веса если удаляется сотрудник
  // ? пересчитывать веса если удаляется тип коммуникации

  if (
    communicationsDistributionMethod === REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS.AUTO.value
  ) {
    // console.log(distribution);
    const distributionMatrix = clientInteractionTypes.reduce((acc, clientInteractionType) => {
      return {
        ...acc,
        [clientInteractionType]: {
          byIds: distributionByReviewersIds(),
          totalDistributed: 100
        }
      };
    }, {});

    setDistribution(distributionMatrix);
  }

  // * ручной режим
  // ? добавлять 0 в новый тип коммуникации
  // ? добавлять 0  к новым сотрудникам
  // ? отнимать вес сотрудника если у сотрудника стоит _destroy: 1
  // ? отнимать вес коммуникации если у коммуникации стоит _destroy: 1

  if (
    communicationsDistributionMethod === REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS.MANUAL.value
  ) {
    const distributionMatrix = clientInteractionTypes.reduce((acc, clientInteractionType) => {
      const distributionByReviewersIds = reviewersIds.reduce((acc, reviewerId) => {
        const reviewerDistribution = get(
          distribution,
          [clientInteractionType, 'byIds', reviewerId],
          0
        );
        return {
          ...acc,
          [reviewerId]: reviewerDistribution
        };
      }, {});

      return {
        ...acc,
        [clientInteractionType]: {
          byIds: distributionByReviewersIds,
          totalDistributed: sum(Object.values(distributionByReviewersIds))
        }
      };
    }, {});

    setDistribution(distributionMatrix);
  }
};
