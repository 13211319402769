export default {
  customCommunicationPage: {
    title: 'New evaluation of communication',
    form: {
      title: 'Author: ',
      fields: {
        operator: 'Employee',
        communicationType: 'Communication type',
        clientPhoneNumber: 'Phone number',
        communicationDate: 'Communication date',
        duration: 'Duration',
        direction: 'Communication direction',
        email: 'E-mail',
        additional: 'Additional',
        communicationId: 'Communication ID',
        mediaUrl: 'Media file link'
      },
      messages: {
        requiredOperator: 'lease choose employee',
        requiredCommunicationType: 'Please choose communication type',
        requiredClientPhoneNumber: 'Please enter phone number',
        requiredCommunicationDate: 'Please choose communication date',
        requiredDirection: 'Please choose direction',
        requiredEmail: 'Please enter email',
        wrongEmail: 'Please enter correct email',
        requiredCommunicationId: 'Please enter communication ID',
        wrongMediaUrl: 'Please enter correct URL',
        communicationIdTooltip:
          'Here you can add any identifier that will help you find the right communication in the future.'
      },
      buttons: {
        openFile: 'Open the attached file',
        addUrl: 'Add a link to a file'
      }
    },
    messages: {
      needToChangeMode: 'To perform the action, go to the evaluation editing mode',
      commentSuccessfullyUpdated: 'Comment successfully updated',
      updateCommentFailed: 'Failed to update the comment',
      communicationSuccessfullyCreated: 'Evaluation successfully created',
      communicationSuccessfullyUpdated: 'Evaluation successfully updated',
      createCommunicationFailed: 'Failed to create communication',
      updateCommunicationFailed: 'Failed to update evaluation',
      reviewSuccessfullyDeleted: 'Evaluation deleted',
      deleteReviewFailed: 'Failed to delete evaluation',
      disabledOperator: 'Uh...this user is not active 😢',
      needToActivateOperator: 'Make the user active to switch to evaluation',
      unsavedReview:
        'Not saved evaluation in the scorecard. If you go to another page, the evaluation will not be saved.',
      isEmptyQuestionsValues:
        'You cannot save an empty evaluation. Rate at least one question in a score card'
    },
    buttons: {
      viewGeneralComment: 'View general comment',
      addGeneralComment: 'Add general comment',
      editGeneralComment: 'Edit general comment',
      viewOperatorSettings: 'Go to settings',
      saveReview: 'Save evaluation',
      editReview: 'Edit evaluation'
    },
    confirmDeleteReview: {
      title: 'Delete review?',
      description: 'Deleted evaluation cannot be restored'
    }
  }
};
