import { Col, Form, Input, Row, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import SButton from 'components/Standard/SButton';
import { CUSTOM_FIELDS_TYPES, USER_CUSTOM_FIELDS_TYPES } from 'core/utils/constants';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AiIcon from '../../../assets/images/ai-icon.svg';

const { Option } = Select;

const UserCustomFieldEditor = ({ customField, editingId, addingId, onDelete, onSubmit }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const active = customField.id === editingId || customField.id === addingId;
  const [isStringUrl, setStringUrl] = useState(false);

  const handleDelete = () => {
    onDelete(customField);
  };

  const handleSubmit = (values = {}) => {
    onSubmit({ ...customField, ...values });
  };

  const customFieldsOptions = Object.values(USER_CUSTOM_FIELDS_TYPES);

  useEffect(() => {
    if (customField?.fieldType === CUSTOM_FIELDS_TYPES.STRING_URL) {
      setStringUrl(true);
      return form.setFieldsValue({ ...customField, usedForFilters: false });
    }
    form.setFieldsValue(customField);
  }, [active]);

  const onValuesChange = (changedProp, allProps) => {
    if (allProps?.fieldType === CUSTOM_FIELDS_TYPES.STRING_URL) {
      setStringUrl(true);
      return form.setFieldsValue({ ...allProps, usedForFilters: false });
    }
    if (allProps?.fieldType !== CUSTOM_FIELDS_TYPES.STRING_URL) {
      setStringUrl(false);
    }
  };

  return (
    <Col span={24}>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={customField}
        onValuesChange={onValuesChange}
      >
        <Row justify="space-between">
          <Col span={18}>
            <Row>
              <Col span={24}>
                <Row gutter={[24, 4]}>
                  <Col>
                    <Form.Item
                      label={t(
                        'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldName'
                      )}
                      rules={[{ required: !isEmpty(customField.name) }]}
                      name="name"
                      style={{ marginBottom: '4px' }}
                    >
                      <Input
                        placeholder={t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldName'
                        )}
                        disabled={!active}
                      />
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      label={t(
                        'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldKey'
                      )}
                      rules={[{ required: true }]}
                      name="key"
                      style={{ marginBottom: '4px' }}
                    >
                      <Input placeholder="sample_key" disabled={!active} />
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      label={t(
                        'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldType'
                      )}
                      rules={[{ required: true }]}
                      name="fieldType"
                      style={{ marginBottom: '4px' }}
                    >
                      <Select
                        style={{ width: '244px' }}
                        placeholder={t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldType'
                        )}
                        disabled={!active}
                      >
                        {customFieldsOptions.map(type => (
                          <Option value={type} key={type}>
                            {type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col>
                    <Form.Item
                      name="usedForAi"
                      valuePropName="checked"
                      style={{ marginBottom: '4px' }}
                    >
                      <Checkbox disabled={!active}>
                        {t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.usedForAI'
                        )}
                        <img
                          src={AiIcon}
                          alt="ai-icon"
                          style={{ marginLeft: '5px', paddingBottom: '2px' }}
                        />
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={6}>
            <Row justify="end" style={{ marginTop: '32px' }}>
              <Col>
                {active || isEmpty(addingId || editingId) ? (
                  <SButton type="link" htmlType="submit" loading={customField?.loading}>
                    {active
                      ? t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.save'
                        )
                      : t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.edit'
                        )}
                  </SButton>
                ) : null}
              </Col>
              <Col>
                <SButton
                  type="link"
                  style={{ color: 'var(--blue_2)' }}
                  onClick={handleDelete}
                  disabled={customField?.loading}
                >
                  {t('integrationsSettingsPage.integrationPage.customFieldsSettings.editor.delete')}
                </SButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

export default UserCustomFieldEditor;
