import { intersection, filter, flatMap, get } from 'lodash';
import { beatifyFloat } from 'core/utils/convertFloat';

const questionsNameToValueMappings = ({ questionsIdsToValue, questionsByIds }) => {
  return Object.keys(questionsIdsToValue).reduce(
    (result, questionId) => ({
      ...result,
      [questionsByIds[questionId]?.name]: beatifyFloat(questionsIdsToValue[questionId])
    }),
    {}
  );
};

const prepareData = ({ widgetValue, questionGroupsByIds, questionsByIds, checklistDefinition }) => {
  return Object.keys(widgetValue).map(questionGroupId => ({
    questionGroupName: get(questionGroupsByIds, `${questionGroupId}.name`) || '',
    questionGroupPosition: get(questionGroupsByIds, `${questionGroupId}.position`),
    ...questionsNameToValueMappings({
      questionsByIds,
      questionsIdsToValue: widgetValue[questionGroupId],
      checklistDefinition
    })
  }));
};

export default ({ widgetValue, questionGroupsByIds, questionsByIds, checklistDefinition }) => {
  const data = prepareData({
    widgetValue,
    questionGroupsByIds,
    questionsByIds,
    checklistDefinition
  });
  const keys = filter(
    intersection(flatMap(data, item => Object.keys(item))),
    key => key !== 'questionGroupName' && key !== 'questionGroupPosition'
  );

  return { data, keys };
};
