export const getAllChildNodesIdsByParentId = (parentId, nodes) => {
  const childIds = nodes.reduce((acc, unit) => {
    if (unit.parentId !== parentId) return acc;
    return [...acc, unit.id];
  }, []);

  const allChildNodesChildrenIds = childIds.reduce((acc, id) => {
    const nodeChildrensIds = getAllChildNodesIdsByParentId(id, nodes);
    return [...acc, ...nodeChildrensIds];
  }, []);
  return [...childIds, ...allChildNodesChildrenIds];
};
