export default {
  integrationsSettingsPage: {
    proceed: 'Continue',
    save: 'Save',
    delete: 'Delete',
    sync: 'Synchronize',
    selectSyncPeriod: 'Select sync period',
    checkboxIsUpdateOldData: 'Update old data',
    syncInternal: 'Also sync internal calls',
    deleteConfirm: {
      title: 'Remove integration?',
      ok: 'Yes',
      cancel: 'No'
    },
    nameAndType: {
      name: 'Name',
      integrationName: 'Enter the name of the integration',
      type: 'Type',
      integrationType: 'Select integration type',
      clientSecretPlaceholder: 'Enter Client Secret',
      clientProductCodePlaceholder: 'Enter Product Code',
      clientSaltPlaceholder: 'Enter Key',
      clientProductCode: 'Product Code',
      clientSalt: 'Key',
      enterPlaceholderTemplate: 'Enter',
      yourB24address: 'Your Bitrix 24 address',
      mangoApiKey: 'Product code',
      mangoSalt: 'Key',
      errors: {
        emptyURL: 'Please, enter the Endpoint',
        invalidURL: 'You entered an invalid Endpoint'
      }
    },
    integrationPage: {
      title: 'Integration',
      sync: 'Synchronization',
      manualSync: 'Manual synchronization',
      fields: 'Fields',
      users: 'Users',
      integrationSettings: 'Integration Settings',
      manualLinkText: 'Instructions for integration',
      manualArticles: 'Instructions for setting up integrations',
      customFieldsSettings: {
        title: 'Custom Fields Settings',
        addField: 'Add field',
        deleteConfirm: {
          title: 'Remove custom field',
          okText: 'Delete'
        },
        editor: {
          fieldName: 'Field name',
          fieldKey: 'Field Key',
          fieldType: 'Field type',
          usedForFilters: 'Used for filtering',
          usedForReviews: 'Used for ratings',
          usedForAI: 'Use it for AI processing',
          save: 'Save',
          edit: 'Edit',
          delete: 'Delete'
        }
      },
      limitSelectedFields: 'Limit on the number of additional fields',
      integrationFieldsList: {
        empty: 'Field name is not set',
        name: 'Name',
        type: 'Type'
      },
      testRequest: {
        title: 'Test Request',
        success: 'Added communication to the system, available at this link',
        failure: 'Failed to add test communication',
        makeRequest: 'Make a request'
      },
      updateConnection: 'Update connection',
      switchOn: 'Turn on synchronization',
      switchOff: 'Turn off synchronization',
      amocrmLink: 'Link to amoCRM account',
      amocrmLastSynced: 'Last synced',
      amocrmIntegrationCreated: 'Created at',
      amocrmConnectionStatus: 'Status',
      naumenConnectionStatus: 'Connecting to an account Naumen',
      ednaConnectionStatus: 'Connecting to an account Edna',
      mangoConnectionStatus: 'Status',
      amocrmActive: 'Active',
      amocrmInactive: 'Inactive'
    },
    messages: {
      integrationSuccessfullyCreate: 'The integration has been successfully created',
      integrationSuccessfullyUpdated: 'Integration successfully updated',
      integrationCreateFailed: 'Failed to create integration'
    },
    addIntegration: {
      title: 'Add Integration',
      yourIntegrations: 'Your integrations'
    },
    manageUserAccounts: {
      totalUsers: 'Users',
      activeUsers: 'Active',
      selectAll: 'Select All',
      columns: {
        user: 'Employee',
        email: 'Email',
        id: 'ID',
        sync: 'Sync',
        status: 'Status in the external system'
      },
      add: 'Add',
      downloadCSV: 'Download CSV example',
      manual: 'Manually',
      fromCSV: 'Via CSV',
      sendingFile: 'Sending a file ...',
      csvModal: {
        title: 'Congratulations',
        content: 'You have uploaded all users from your CSV file!',
        okText: 'Continue'
      },
      usersAlreadyInSystem: 'Qolio already has users with this email',
      accountToUsers: 'These accounts will be added to users',
      brokenEmail: 'The user does not have an email address'
    },
    createCustomIntegration: {
      addUsersTitle: 'Add users'
    },
    createSaasIntegration: {
      errorMessage: 'An error occurred while syncing data with',
      errorMessageContent: 'Please reinstall the app or email us at info@qolio.io',
      sync: 'Synchronization'
    },
    customUserAccountForm: {
      enterEmail: 'Enter email',
      enterCorrectEmail: 'Wrong email format',
      sync: 'Syncing',
      notSync: "Doesn't sync",
      enterId: 'Enter an Id'
    },
    sipuniAccountsImported:
      'User accounts are imported. To configure them, you need to link them to accounts in Qolio. To do this go to ',
    mangoAccountsImported:
      'User accounts are imported. To configure them, you need to link them to accounts in Qolio. To do this go to ',
    urlAndToken: { generate: 'Generate' },
    infoAlert: {
      message: 'Manual sync settings are not available.',
      description: 'For your organization, synchronization occurs daily at {{time}}.',
      syncAlreadyInProgress:
        'Synchronization is currently in progress. Please wait for it to complete or try refreshing the page.',
      setTheRange:
        'Please set a range for data synchronization. Note that the larger the specified range, the longer the synchronization will take. You can always increase the data synchronization range in the integration settings.'
    },
    unsupportedField: 'Field not supported'
  }
};
