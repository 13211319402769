import { uniq, flattenDeep, max, isNil, sortBy } from 'lodash';

export const prepareKeys = ({ widgetValue }) => {
  return widgetValue.map(item => item.id);
};

export const searchMaxValue = ({ widgetValue }) => {
  const values = flattenDeep(widgetValue.map(item => Object.values(item.history))).filter(
    value => !isNil(value)
  );

  return max(values);
};

export const prepareData = ({ widgetValue }) => {
  return sortBy(uniq(flattenDeep(widgetValue.map(item => Object.keys(item.history))))).map(key => ({
    period: key,
    ...widgetValue.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item.history[key] || null
      }),
      {}
    )
  }));
};
