import { post, get } from 'redux-bees';

const helpDeskEddyEndpoints = apiPath => ({
  helpDeskEddySyncDataByPeriod: {
    method: post,
    path: `${apiPath}/integrations/help_desk_eddy/:id/fetch_data`
  },
  helpDeskEddyGetTicketFields: {
    method: get,
    path: `${apiPath}/integrations/help_desk_eddy/:id/ticket_fields`
  },
  helpDeskEddyConnectTicketField: {
    method: post,
    path: `${apiPath}/integrations/help_desk_eddy/:id/connect_ticket_field/:ticketFieldId`
  },
  helpDeskEddyDisconnectTicketField: {
    method: post,
    path: `${apiPath}/integrations/help_desk_eddy/:id/disconnect_ticket_field/:ticketFieldId`
  }
});

export default helpDeskEddyEndpoints;
