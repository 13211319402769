import { destroy, get, put, post } from 'redux-bees';

const levelsEndpoints = apiPath => ({
  getLevels: { method: get, path: `${apiPath}/levels` },
  createLevel: { method: post, path: `${apiPath}/levels` },
  getLevel: { method: get, path: `${apiPath}/levels/:id` },
  updateLevel: { method: put, path: `${apiPath}/levels/:id` },
  deleteLevel: { method: destroy, path: `${apiPath}/levels/:id` }
});

export default levelsEndpoints;
