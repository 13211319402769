import React from 'react';
import Truncated, { TruncatedText } from 'components/Truncated';
import styled from 'styled-components';
import { Tooltip, Button, Collapse, Avatar, Breadcrumb } from 'antd';
import { typography } from 'styled-system';

export const LabelText = styled(TruncatedText)`
  font-weight: 700;
  padding-right: 8px;
  display: block;
  color: var(--black_6);
  font-size: 12px;
`;

export const TextWithTooltip = props => (
  <Tooltip placement="topLeft" title={props?.title || props.children}>
    <TruncatedText block>{props.children}</TruncatedText>
  </Tooltip>
);

export const CollapseButton = styled(Button)`
  &.ant-btn {
    text-transform: capitalize;
    bottom: -20px;
    z-index: 2;
    position: absolute;
    min-width: max-content;
    height: 20px;
    color: #82858c;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    padding: 0 5px 0 4px;
    flex-direction: row-reverse;
    right: 50%;
    transform: translate(50%, -50%);
    & > span {
      margin-top: auto;
      padding: 0px 3px 0;
    }
    &.ant-btn:hover,
    :active {
      color: #839df2 !important;
    }
    &.ant-btn:focus {
      color: rgb(130, 133, 140);
      border-color: rgb(232, 232, 232);
    }

    & > svg {
      margin: auto;
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  && {
    background-color: inherit;
    & > .ant-collapse-item {
      border: 0;
      & > .ant-collapse-header {
        display: none !important;
      }
      &.ant-collapse-item-active {
        padding: 0px;
      }
      & > .ant-collapse-content {
        & > .ant-collapse-content-box {
          padding: 24px;
        }
      }
    }
  }
`;

export const StyledAvatarGroup = styled(Avatar.Group)`
  && {
    display: block;
    ${typography}
  }
`;

export const StyledBreadcrumb = styled(Truncated(Breadcrumb))`
  && {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    & a.ant-breadcrumb-link {
      color: var(--link-color) !important;
    }
    & span.ant-breadcrumb-link {
      color: #82858c;
    }
  }
`;
