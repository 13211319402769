import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadRolesWithLevels } from 'redux/ui/levelsPage/operations';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SRow from 'components/Standard/SRow';
import Levels from './Levels/Levels';
import Permissions from './Permissions/Permissions';

const PermissionsAndLevels = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadRolesWithLevels());
  }, []);

  return (
    <SRow gutter={[16, 16]}>
      <Helmet>
        <title>{t('pagesMeta.organizationStructurePage.tabs.permissionsAndLevels.title')}</title>
      </Helmet>
      <Permissions />
      <Levels />
    </SRow>
  );
};

export default PermissionsAndLevels;
