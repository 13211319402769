import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  selectedComment: null
};

export const selectComment = createAction('uiComments/selectComment');

export const uiCommentsReduсer = createReducer(defaultState, {
  [selectComment]: (state, { payload }) => ({
    ...state,
    selectedComment: payload || defaultState.selectedComment
  })
});
