import { RightOutlined } from '@ant-design/icons';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { Skeleton, Empty } from 'antd';
import { CHECKLIST_DEFINITION_STATUS, PERMISSIONS } from 'core/utils/constants';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { getChecklistsDefinitionsByParams } from 'redux/selectors/checklists';
import styled from 'styled-components';
import { getCurrentUser } from 'redux/selectors/users';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';

const SelectChecklist = ({ onSelect, communication = {} }) => {
  const { t } = useTranslation();

  const { loading: checklistDefinitionsResourceLoading } = useSelector(
    state => state.checklistDefinitionsResource
  );
  const { operatorId, communicationType, type } = communication;
  const loading = checklistDefinitionsResourceLoading || isEmpty(communication);
  // * hacking BE properties

  const operator = useSelector(state => state.usersResource.byIds[operatorId]);

  const allowedType = (communicationType || pluralize.singular(type || '')).replace('-', '_');

  const checklistDefinitions = useSelector(
    state =>
      getChecklistsDefinitionsByParams(state, {
        params: {
          statuses: [CHECKLIST_DEFINITION_STATUS.PUBLISHED.value],
          communicationTypes: [allowedType],
          unitsIds: [operator?.unitId]
        }
      }),
    shallowEqual
  );

  const currentUser = useSelector(state => getCurrentUser(state));

  const getTextForEmpty = () => {
    const isAllowToEditChecklistDefinitions = currentUser?.role?.permissions?.includes(
      PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS
    );

    return isAllowToEditChecklistDefinitions ? (
      <SText type="secondary">
        {t('components.checklistManager.selectChecklist.emptyAdminMessage.pt1')}
        <Link to="/checklists">
          {t('components.checklistManager.selectChecklist.emptyAdminMessage.pt2')}
        </Link>
        {t('components.checklistManager.selectChecklist.emptyAdminMessage.pt3')}
      </SText>
    ) : (
      <SText type="secondary">
        {t('components.checklistManager.selectChecklist.emptyUserMessage')}
      </SText>
    );
  };

  // getTextForEmpty();

  if (isEmpty(checklistDefinitions) && !loading)
    return (
      <SCard bordered shadowed>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={getTextForEmpty()} />
      </SCard>
    );

  return loading ? (
    <SCard bordered shadowed>
      <Skeleton active />
    </SCard>
  ) : (
    <SRow gutter={[4, 4]} style={{ marginTop: 8 }}>
      {checklistDefinitions.map(item => (
        <SCol span={24} key={item.id} marginBottom="8px">
          <StyledCard
            onClick={() => onSelect({ checklistDefinitionId: item.id })}
            bordered
            shadowed={false}
            background="#f5f6f7"
            margin="-2px auto"
          >
            <SRow type="flex" justify="space-between">
              <SCol span={22}>
                <SText width="100%" strong ellipsis fontSize="14px">
                  {item.name}
                </SText>
              </SCol>
              <SCol>
                <RightOutlined />
              </SCol>
            </SRow>
          </StyledCard>
        </SCol>
      ))}
    </SRow>
  );
};

const StyledCard = styled(SCard)`
  &.ant-card {
    cursor: pointer;
  }

  &.ant-card:hover {
    background-color: var(--blue_2);
  }
`;

export default React.memo(SelectChecklist, (prevProps, next) => {
  return shallowEqual(prevProps.communication, next.communication);
});
