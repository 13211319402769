export default {
  crmEntity: {
    responsible: {
      title: 'Responsable'
    },
    communications: {
      title: 'Comunicación'
    },
    dateOfApplication: 'Fecha de aplicacion'
  }
};
