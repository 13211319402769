import decamilize from 'decamelize';
import decamilizeKeys from 'decamelize-keys-deep';
import { isNil, isString } from 'lodash';
import moment from 'moment';

const parseValueForApi = value => {
  if (isNil(value)) return '';

  if (Array.isArray(value)) return value.join(',');

  if (moment(value, moment.ISO_8601, true).isValid()) return value;

  return value.toString ? value.toString() : `${value}`;
};

const parseOptionsForApi = options => {
  let result = {};

  const parserUri = (currentItem, parentKeys = []) => {
    // ignore empty properties
    if (isNil(currentItem) || (isString(currentItem) && currentItem?.trim() === '')) return;
    // ignore aggregated properties
    if (currentItem === 'all') return;

    if (typeof currentItem === 'object' && currentItem !== null && !Array.isArray(currentItem)) {
      // go recursively inside object
      Object.keys(currentItem).forEach(key => parserUri(currentItem[key], [...parentKeys, key]));
    } else {
      // otherwise parse data to variable
      const value = parseValueForApi(currentItem);

      // generating proper key - thanks to react-bees
      const keys = [...parentKeys];
      const firstKey = keys.splice(0, 1);
      const otherKeys = keys.map(i => `[${decamilize(i)}]`).join('');
      const propName = `${firstKey}${otherKeys}`;

      // merging to result object
      result = { ...result, [propName]: value };
    }
  };

  parserUri(options);
  return decamilizeKeys(result);
};

export default parseOptionsForApi;
