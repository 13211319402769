import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  currentFolderId: undefined,
  currentClientInteractionId: undefined,
  newFolderName: null,
  showCreateNewFolderModal: false,
  editingFolderId: null,
  editingFolder: null,
  editingItemId: null,
  editingItem: null
};

export const setCurrentFolderId = createAction('uiLibraryPage/setCurrentFolderId');
export const setNewFolderName = createAction('uiLibraryPage/setNewFolderName');
export const setCurrentInteractionId = createAction('uiLibraryPage/setCurrentInteractionId');

export const openCreateNewFolderModal = createAction('uiLibraryPage/openCreateNewFolderModal');
export const closeCreateNewFolderModal = createAction('uiLibraryPage/closeCreateNewFolderModal');

export const setEditingFolder = createAction('uiLibraryPage/setEditingFolder');
export const updateEditingFolder = createAction('uiLibraryPage/updateEditingFolder');
export const editingFolderSaved = createAction('uiLibraryPage/editingFolderSaved');

export const setEditingItem = createAction('uiLibraryPage/setEditingItem');
export const updateEditingItem = createAction('uiLibraryPage/updateEditingItem');
export const editingItemSaved = createAction('uiLibraryPage/editingItemSaved');

export const libraryPageReducer = createReducer(defaultState, {
  [setCurrentFolderId]: (state, { payload }) => {
    return { ...state, currentFolderId: payload };
  },
  [setCurrentInteractionId]: (state, { payload }) => ({
    ...state,
    currentClientInteractionId: payload
  }),
  [setNewFolderName]: (state, { payload }) => ({ ...state, newFolderName: payload }),
  [openCreateNewFolderModal]: state => ({ ...state, showCreateNewFolderModal: true }),
  [closeCreateNewFolderModal]: state => ({
    ...state,
    showCreateNewFolderModal: false,
    newFolderName: null
  }),
  [setEditingFolder]: (state, { payload }) => ({
    ...state,
    editingFolder: payload,
    editingFolderId: payload.id
  }),
  [updateEditingFolder]: (state, { payload }) => ({
    ...state,
    editingFolder: { ...state.editingFolder, ...payload }
  }),
  [editingFolderSaved]: state => ({ ...state, editingFolder: null, editingFolderId: null }),
  [setEditingItem]: (state, { payload }) => ({
    ...state,
    editingItem: payload,
    editingItemId: payload.id
  }),
  [updateEditingItem]: (state, { payload }) => ({
    ...state,
    editingItem: { ...state.editingItem, ...payload }
  }),
  [editingItemSaved]: state => ({ ...state, editingItem: null, editingItemId: null })
});

export default libraryPageReducer;
