import api from 'core/api';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { reduce, keyBy } from 'lodash';
import {
  loadingWidgets,
  loadedWidgets,
  loadingWidgetsFailed,
  updatedWidgets,
  updatingWidgets,
  updatingWidgetsFailed
} from './reducer';

export const loadAnalyticsWidgets = ({ widgetNames, filters, withDynamics }) => {
  return async dispatch => {
    dispatch(loadingWidgets());
    try {
      if (
        !withDynamics &&
        (filters.checklistDefinitionsIds?.includes('all') ||
          filters.checklistDefinitionsIds?.includes('all-questions'))
      ) {
        filters = { ...filters }; // Создаем новый объект, чтобы избежать ошибок при удалении свойств
        delete filters.checklistDefinitionsIds;
      }
      const response = withDynamics
        ? await api.loadAnalyticsWidgetsWithDynamics(parseOptionsForApi({ widgetNames, filters }))
        : await api.loadAnalyticsWidgets(parseOptionsForApi({ widgetNames, filters }));
      const widgets = reduce(
        response.body,
        (result, widget, widgetName) => ({ ...result, [widgetName]: keyBy(widget, 'id') }),
        {}
      );

      dispatch(loadedWidgets(withDynamics ? response.body : widgets));

      return widgets;
    } catch (error) {
      console.log(error);
      loadingWidgetsFailed();
      return [];
    }
  };
};

export const updateAnalyticsWidgets = ({ widgetNames, filters }) => {
  return async dispatch => {
    dispatch(updatingWidgets());
    try {
      const response = await api.loadAnalyticsWidgets(parseOptionsForApi({ widgetNames, filters }));
      const widgets = reduce(
        response.body,
        (result, widget, widgetName) => ({ ...result, [widgetName]: keyBy(widget, 'id') }),
        {}
      );

      dispatch(updatedWidgets(widgets));

      return widgets;
    } catch (error) {
      console.log(error);
      updatingWidgetsFailed();
    }
  };
};
