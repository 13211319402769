import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { getChecklistsDefinitionsByStatuses } from 'redux/selectors/checklists';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { Select, Tooltip } from 'antd';
import ChecklistDefinitionStatusTag from '../../../components/Checklist/ChecklistDefinitionStatusTag';
import { selectSearch } from '../../../core/utils/selectSearch';

const { Option } = Select;

const ChecklistDefinitionSelector = ({ filters, userId, onChangeFilters }) => {
  const { t } = useTranslation();

  const checklistDefinitions = useSelector(
    state =>
      Object.values(
        getChecklistsDefinitionsByStatuses(state, {
          statuses: [
            CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
            CHECKLIST_DEFINITION_STATUS.ARCHIVED.value
          ]
        })
      ),
    isEqual
  );

  const handleChange = ids => {
    if (ids.length > 1) {
      if (ids[ids.length - 1] === 'all') {
        ids = ['all'];
      } else if (ids[ids.length - 1] === 'all-questions') {
        ids = ['all-questions'];
      } else if (ids.includes('all')) {
        ids = ids.filter(id => id !== 'all');
      } else if (ids.includes('all-questions')) {
        ids = ids.filter(id => id !== 'all-questions');
      }
    }
    if (ids.length === 0) {
      ids = ['all'];
    }
    onChangeFilters({
      userId,
      filters: {
        ...filters,
        checklistDefinitionsIds: ids.join(',')
      }
    });
  };

  return (
    <Select
      mode="multiple"
      defaultValue={['all']}
      value={filters?.checklistDefinitionsIds?.split(',')}
      placeholder={t('userAnalyticsPage.mainControlsPanel.checklistDefinitionsEmpty')}
      onChange={handleChange}
      style={{ width: '100%' }}
      showSearch
      allowClear
      filterOption={(input, option) => selectSearch({ input, option, searchProp: 'searchValue' })}
      maxTagCount={0}
      maxTagPlaceholder={`${t(
        'clientInteractionsPage.tableFilters.tableReviewsFilters.form.checklistsPlaceholder'
      )}: ${filters?.checklistDefinitionsIds?.split(',')?.length}`}
    >
      <Option
        key="all"
        value="all"
        searchValue={t('components.checklistsDefinitionsSelect.allChecklists')}
      >
        {t('components.checklistsDefinitionsSelect.allChecklists')}
      </Option>
      <Option
        key="all-questions"
        value="all-questions"
        searchValue={t('userAnalyticsPage.mainControlsPanel.questionValues')}
      >
        {t('userAnalyticsPage.mainControlsPanel.questionValues')}
      </Option>
      {checklistDefinitions.map(definition => (
        <Option key={definition.id} value={definition.id} searchValue={definition.name}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Tooltip title={definition.name}>{definition.name}</Tooltip>
            </span>
            {definition.status && <ChecklistDefinitionStatusTag status={definition.status} />}
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default ChecklistDefinitionSelector;
