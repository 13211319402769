import React, { useEffect, useCallback } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import SText from 'components/Standard/SText';
import moment from 'moment';
import SButton from 'components/Standard/SButton';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { operations, actions } from 'redux/lists/workPlanTasksListReducer';
import { HelpCircle } from 'react-feather';
import { Tooltip } from 'antd';
import Icon from 'components/Icon';
import TasksInfo from './TasksInfo';
import ProgressBar from '../Components/ProgressBar';

const WorkPlanTasks = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: currentConfigurationId } = useParams();
  const { updatePage, setSorting } = actions;

  const workPlanTasksByIds = useSelector(state => state.workPlanTasksResource.byIds, isEqual);

  const { ids, totalCount, page, sort, filters, loading } = useSelector(
    state => state.workPlanTasksList
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  const workPlanTasks = ids.map(taskId => {
    return {
      ...workPlanTasksByIds[taskId],
      name: `${workPlanTasksByIds[taskId]?.repetitionNumber} ${t('workPlanTasks.repeat')}`
    };
  });

  useEffect(() => {
    dispatch(
      operations.load({
        id: currentConfigurationId,
        page,
        filters,
        sort,
        include:
          'task_configuration.created_by,task_configuration.schedule,task_configuration.filters.fields,assignments.task_items'
      })
    );
  }, [page, sort, filters]);

  const tableColumns = [
    {
      title: t('workPlanTasks.tableColumns.repeat'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 200,
      render: text => {
        return <SText>{text}</SText>;
      }
    },

    {
      title: (
        <SRow gutter={[8, 0]}>
          <SCol>
            <SText>{t('general.progress')}</SText>
          </SCol>
          <SCol display="flex" alignItems="center">
            <Tooltip title={t('workPlanTasks.tooltips.progress')} trigger="click">
              <Icon icon={HelpCircle} color="var(--black_6)" />
            </Tooltip>
          </SCol>
        </SRow>
      ),
      dataIndex: 'reviewsCount',
      key: 'reviewsCount',
      ellipsis: true,
      width: 500,
      render: (text, record) => {
        const completedTaskItemsCount = record?.completedTaskItemsCount || 0;
        const totalTaskItemsCount = record?.totalTaskItemsCount || 0;
        return (
          <SRow type="flex">
            <SCol span={3}>
              <SText>{`${completedTaskItemsCount}/${totalTaskItemsCount}`}</SText>
            </SCol>
            <SCol span={21}>
              <ProgressBar
                totalCount={record?.totalTaskItemsCount}
                doneCount={record?.completedTaskItemsCount}
              />
            </SCol>
          </SRow>
        );
      }
    },

    {
      title: t('general.start'),
      dataIndex: 'timeFrom',
      key: 'timeFrom',
      ellipsis: true,
      sorter: true,
      render: text => <SText>{moment(text).format('DD/MM/YYYY')}</SText>
    },

    {
      title: t('general.finish'),
      dataIndex: 'timeTo',
      key: 'timeTo',
      ellipsis: true,
      sorter: true,
      render: text => <SText>{moment(text).format('DD/MM/YYYY')}</SText>
    },

    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      render: text => (
        <SText color={WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[text]?.color}>
          {t(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[text]?.title)}
        </SText>
      )
    },

    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 140,
      align: 'right',
      fixed: 'right',
      render: text => {
        const disabled =
          workPlanTasksByIds[text]?.status === WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.pending.value;
        return (
          <SButton
            type="primary"
            disabled={disabled}
            onClick={() =>
              history.push(`/work-plan/task-configurations/${currentConfigurationId}/tasks/${text}`)
            }
          >
            {t('general.view')}
          </SButton>
        );
      }
    }
  ];

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <TasksInfo />
          </SCol>
          <SCol span={24}>
            <SRow type="flex" margin="16px">
              <SCol span={24}>
                <SCard bordered shadowed>
                  <STable
                    rowKey="id"
                    dataSource={workPlanTasks}
                    columns={tableColumns}
                    loading={loading}
                    border
                    pagination={{
                      pageSize: parseInt(page.size),
                      current: parseInt(page.number),
                      total: totalCount,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '25', '50'],
                      size: 'small'
                    }}
                    onChange={handleTableChange}
                    fixSorterColumns
                    scroll={{ y: 'calc(100vh - 245px)', x: 'max-content' }}
                    size="small"
                  />
                </SCard>
              </SCol>
            </SRow>
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTasks;
