import React, { useCallback, useState } from 'react';
import { Form, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SInput, SModal } from 'components/Standard';

const chatId = '-1002051925039';
const botToken = '6971124210:AAEbpQku01Ggg726IEMWgVMxK_s-F-ENb9U';
const telegramApiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;
const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

const ApplicationModal = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentUser = useSelector(state => state.reduxTokenAuth.currentUser.attributes);
  const { 'first-name': firstName, 'last-name': LastName, organization, email } = currentUser.user;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const unVisible = localStorage.getItem('AiApplication');
  const closeModal = useCallback(() => {
    history.push(`/user/${currentUser.id}/user-settings`);
  }, []);

  const initialValues = {
    id: currentUser.id || '',
    role: currentUser.user.role.name || '',
    name: `${firstName} ${LastName}` || '',
    organization_name: organization.name || '',
    email: email || '',
    phone_number: ''
  };

  const handleSubmit = async data => {
    try {
      setLoading(true);
      await axios.post(telegramApiUrl, {
        chat_id: chatId,
        text: `Роль пользователя: ${data.role}\nИмя: ${data.name}\nОрганизация: ${data.organization_name}\nПочта: ${data.email}\nТелефон: ${data.phone_number}`
      });
      localStorage.setItem('AiApplication', true);
      Modal.confirm({
        onOk: () => {
          Modal.destroyAll();
        },
        centered: true,
        cancelButtonProps: { style: { display: 'none' } },
        maskClosable: false,
        title: 'Заявка отправлена.',
        content: (
          <>
            <p>Спасибо за интерес к решению Qolio AI. С вами свяжется наш менеджер.</p>
            <p>В демо режиме вам будет доступно 100 проверок Qolio AI на аккаунт.</p>
          </>
        ),
        okText: 'Спасибо!'
      });
      setLoading(false);
      setVisible(false);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <SModal
      title="Оставить заявку"
      confirmLoading={loading}
      centered
      visible={visible && !unVisible}
      onCancel={closeModal}
      size="big"
      onOk={form.submit}
      okText="Отправить"
      cancelText="Отменить"
    >
      <Form
        form={form}
        name="moveUserToUnit"
        initialValues={initialValues}
        scrollToFirstError
        layout="vertical"
        onFinish={handleSubmit}
      >
        {/* <Form.Item label="Описание модального окна" name="description"></Form.Item> */}
        <Form.Item name="id" style={{ display: 'none' }} />
        <Form.Item name="role" style={{ display: 'none' }} />
        <Form.Item
          label="Имя"
          name="name"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите имя'
            }
          ]}
        >
          <SInput placeholder="Введите имя" />
        </Form.Item>
        <Form.Item
          label="Название организации"
          name="organization_name"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите название организации'
            }
          ]}
        >
          <SInput placeholder="Введите название организации" />
        </Form.Item>
        <Form.Item
          label="Почта"
          name="email"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите вашу почту'
            },
            {
              pattern: emailRegex,
              message: 'Пожалуйста, введите корректный адрес почты'
            }
          ]}
        >
          <SInput placeholder="example@gmail.com" />
        </Form.Item>
        <Form.Item
          label="Номер телефона"
          name="phone_number"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите ваш номер телефона'
            }
          ]}
        >
          <SInput placeholder="+7 (123) 456-78-90" />
        </Form.Item>
      </Form>
    </SModal>
  );
};

export default ApplicationModal;
