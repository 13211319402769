import { Col, Row } from 'antd';
import SCard from 'components/Standard/SCard';
import { CHECKLIST_MANAGER_STATES } from 'core/utils/constants';
import { setOperatorId } from 'redux/ui/clientInteractionPage/reducer';
import { get, isEmpty, sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ConversationInfo from './components/ConversationInfo';
import ConversationPart from './components/ConversationPart';
import CustomFields from '../CustomFields';

const TextCommunication = ({
  affixOffsetTop,
  loading,
  clientInteraction,
  parts = [],
  comments,
  allowCommenting,
  onAddCommentButton,
  onCommentSave,
  onCancelComment,
  onDeleteComment,
  onUpdateComment,
  reviewId,
  setOperatorId,
  selectedOperatorId,
  checklistManagerState,
  shouldLoad,
  editingCommentId,
  fromDrawer
}) => {
  const [showSystemEvents, setShowSystemEvents] = React.useState(false);

  return (
    <Row gutter={[16, 4]}>
      <Col
        span={24}
        style={{
          position: 'sticky',
          top: fromDrawer ? affixOffsetTop : 14,
          zIndex: 2
        }}
      >
        <ConversationInfo
          reviewId={reviewId}
          loading={loading}
          textCommunication={clientInteraction}
          setOperatorId={setOperatorId}
          selectedOperatorId={selectedOperatorId}
          canChangeOperator={checklistManagerState !== CHECKLIST_MANAGER_STATES.SAVED}
          shouldLoad={shouldLoad}
          parts={parts}
          setShowSystemEvents={setShowSystemEvents}
          showSystemEvents={showSystemEvents}
        />
      </Col>
      <CustomFields clientInteraction={clientInteraction} />
      {!isEmpty(parts) && (
        <Col span={24}>
          <SCard bodyPadding="16px 6px" loading={loading}>
            <Row gutter={[16, 16]}>
              {sortBy(parts, 'postedAt')
                .filter(
                  ({ partType }) =>
                    showSystemEvents || ['message', 'internal_message', null].includes(partType)
                )
                .map(part => (
                  <ConversationPart
                    {...part}
                    textCommunicationStartedAt={get(clientInteraction, 'startedAt', null)}
                    onAddCommentButton={onAddCommentButton}
                    onCommentSave={comment => {
                      onAddCommentButton();
                      onCommentSave({
                        ...comment,
                        ratingFlag: comment.metadata.ratingFlag,
                        metadata: { ...comment.metadata, textCommunicationPartId: part.id }
                      });
                    }}
                    clientId={clientInteraction?.clientId}
                    onCancelComment={onCancelComment}
                    onDeleteComment={onDeleteComment}
                    onUpdateComment={onUpdateComment}
                    allowCommenting={allowCommenting}
                    comments={Object.values(comments).filter(
                      comment => part.id === get(comment, 'metadata.textCommunicationPartId', null)
                    )}
                    loading={loading}
                    editingCommentId={editingCommentId}
                  />
                ))}
            </Row>
          </SCard>
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = (state, ownProps) => {
  const reviewId = get(ownProps, 'review.id', '');
  const loadingChecklists = state.checklistDefinitionsResource.loading;
  const { editingCommentId } = state.uiClientInteractionPage;

  const parts = ownProps.clientInteraction.textCommunicationPartsIds.map(
    id => state.textCommunicationPartsResource.byIds[id]
  );

  const { operatorId: selectedOperatorId } = state.uiClientInteractionPage;
  const { checklistManagerState } = state.uiChecklistManager;
  return {
    parts,
    reviewId,
    loadingChecklists,
    loading: loadingChecklists,
    editingCommentId,
    selectedOperatorId,
    checklistManagerState
  };
};

const mapDispatchToProps = {
  setOperatorId
};

export default connect(mapStateToProps, mapDispatchToProps)(TextCommunication);
