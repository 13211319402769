export default {
  autoFailScoresPage: {
    buttonAddReport: {
      dynamicCriticalErrors: {
        header: 'La dinámica de los errores críticos',
        description:
          'Muestra el número de comunicaciones, el número y el porcentaje de comprobaciones, el número y el porcentaje de errores críticos a lo largo del tiempo. Hay dos opciones de visualización: una tabla y un gráfico.'
      },
      criticalErrorsCriteria: {
        header: 'Criterios con errores críticos',
        description:
          'Muestra el porcentaje de errores críticos por criterios. Hay dos opciones de visualización: empleados y departamentos. El informe sólo muestra los criterios para los que se establecen errores críticos en el formulario de evaluación.'
      }
    }
  }
};
