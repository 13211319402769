export default {
  loginPage: {
    form: {
      title: 'Login',
      unRegistered: 'Non registrato?',
      fields: {
        emailPlaceholder: 'Email',
        phonePlaceholder: 'Phone',
        passwordPlaceholder: 'Password',
        enterConfimationPasswordPlaceholder: 'Password confirmation',
        firstNamePlaceholder: 'First name',
        lastNamePlaceholder: 'Last name',
        orgNamePlaceholder: 'Organization name',
        WebsitePlaceholder: 'Website address',
        demoPlaceholder: 'Create with demo data'
      },
      messages: {
        agreeWith: 'I agree with ',
        and: ' and ',
        offer: 'public offer agreement',
        privacy: 'privacy policy',
        errorUrl: 'Incorrect url',
        errorPhone: 'Incorrect phone',
        errorReg: 'Registration failed! Please try again',
        successReg: 'You are registered!',
        emailAlreadyRegistered: "L'utente con tale email è già registrato",
        enterEmail: 'Please enter email',
        enterPhone: 'Please enter phone',
        enterFirstName: 'Please enter first name',
        enterLastName: 'Please enter last name',
        shouldAccept: 'Should accept agreement',
        enterPassword: 'Please enter password',
        confirmPassword: 'Please confirm password',
        enterOrgName: 'Please enter organization name',
        enterWebsite: 'Please enter website address',
        wrongEmail: 'Wrong email format',
        minLengthPassword: 'Short password'
      },
      buttons: {
        login: 'Login',
        register: 'Register',
        forgotPassword: 'Hai dimenticato la password?',
        registration: 'Resistrazione',
        goToSite: 'Passare al sito web',
        registrationlink: 'Iscriviti ora'
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Efficienza',
          description:
            "Tutti gli strumenti per controllo della qualita' e la valutazione della comunicazione sono raccolti su un'unica piattaforma"
        },
        motivation: {
          title: 'Motivazione',
          description: 'Coinvolgi, sviluppa e motiva i dipendenti'
        },
        simplicity: {
          title: "Semplicita'",
          description:
            "Elimina fogli di calcolo e strumenti di valutazione e controllo della qualita' obsoleti"
        },
        analytics: {
          title: 'Analitica',
          description: "Analizza gli indicatori di qualita' e trova nuove possibilita' di crescita"
        }
      }
    }
  }
};
