export default {
  appealsPage: {
    actionsListName: 'Modifica feed',
    actionsList: {
      changed: 'cambiato',
      accept: 'accettato',
      reject: 'respinto',
      create: 'archiviato',
      add: 'aggiunto',
      exclude: 'escluso',
      answered: 'ответил',
      createComment: 'ha lasciato un commento',
      statusBy: 'stato attivo',
      appealByCritery: 'criterio di appello',
      appeal: 'appello',
      wathcer: 'osservatore',
      viewer: 'osservatore'
    },
    appealInfo: {
      operator: 'Operatore',
      reviewCreate: 'Controllato'
    },
    appealStatus: {
      process: 'Processi',
      needsInformation: 'Hai bisogno di informazioni',
      withoutAnswer: 'Nessuna risposta',
      appealInfo: 'Informazioni sul ricorso',
      notProcessed: 'Non processato'
    },
    participantsCard: {
      appealed: 'Presenta un ricorso',
      watchers: 'Osservatore',
      addWatcher: 'Aggiungi osservatore',
      addingWatcher: 'Aggiungi osservatore'
    },
    appealsList: {
      appealBy: 'Verificatore',
      changedBy: 'Cambiato'
    },
    filters: { withCurrentUserRoles: 'Appelli dove sono' },
    settings: {
      appealDeadline:
        'Il periodo massimo dopo la verifica per la presentazione di un ricorso (giorni)',
      addOperatorAsWatcher: "Assegna automaticamente l'operatore come osservatore per fare ricorso",
      addReviewerAsWatcher: 'Nomina automaticamente un revisore come osservatore in appello',
      selectAssignerFromUsersArray: 'Pianifica il ricorso presentato per:',
      selectAssignerFromUsersArrayAdd: 'Seleziona un dipendente specifico',
      appealed: 'Presenta un ricorso',
      assignerMethods: {
        reviewer: {
          title: "Sull'autore dell'assegno",
          description:
            "Tutti i ricorsi presentati verranno assegnati automaticamente all'autore del voto"
        },
        usersArray: {
          description:
            'Tutti i ricorsi presentati verranno assegnati automaticamente al dipendente selezionato',
          title: 'Per il dipendente selezionato'
        },
        assignerByApellantRules: {
          description:
            'Puoi creare una regola in base alla quale i ricorsi presentati dai dipendenti saranno distribuiti tra i responsabili',
          title: 'Crea la tua regola'
        }
      },
      addRule: 'Aggiungi regola',
      assignRule: {
        appellants: 'Se il ricorso viene presentato',
        assigners: 'Fissa un appello per',
        note: 'Nota'
      }
    },
    confirmDelete: 'Rimuovere il ricorso?',
    analytics: {
      noAnswer: 'Nessuna risposta',
      pending: 'Non processato',
      tracker: 'Tracker',
      appellationsByReviewers: {
        title: 'Appelli dei revisori'
      },
      appellationsByAppellants: {
        title: 'Appelli per autore'
      }
    },
    appealAccepted: 'Ricorso accolto',
    appealDenied: 'Ricorso respinto'
  }
};
