import { get, post, put } from 'redux-bees';

const s2Endpoints = apiPath => ({
  createS2Integration: {
    method: post,
    path: `${apiPath}/s2`
  },
  updateS2Integration: {
    method: put,
    path: `${apiPath}/:id`
  },
  getS2View: {
    method: get,
    path: `${apiPath}/:id`
  },
  getS2Users: {
    method: get,
    path: `${apiPath}/:id/users`
  }
});

export default s2Endpoints;
