import React from 'react';
import { Col, Row } from 'antd';
import SCard from 'components/Standard/SCard';

const List = ({ dataSource = [], columns = {} }) => {
  const columnsArray = Object.keys(columns);

  const renderRow = (record, i) => {
    const renderColumn = key => {
      const { render = record => `${record[key] || ''}`, col = {} } = columns[key];
      return (
        <Col {...col} key={key}>
          {render(record[key], record)}
        </Col>
      );
    };

    return (
      <Col key={i}>
        <SCard bordered shadowed>
          <Row type="flex" align="middle" justify="start">
            {columnsArray.map(renderColumn)}
          </Row>
        </SCard>
      </Col>
    );
  };

  return <>{dataSource.map(renderRow)}</>;
};

export default List;
