export default {
  aiSettingsPage: {
    title: 'Configuraciones de IA',
    tableColumnsTitle: {
      creationDate: 'Fecha de creación',
      lastLaunchDate: 'Fecha de último lanzamiento',
      status: 'Estado',
      type: 'Tipo',
      checks: 'Chequeos',
      inProgress: 'En progreso',
      successful: 'Exitoso',
      withError: 'Con error',
      duration: 'Duración',
      direction: 'Dirección',
      phoneNumber: 'Número de teléfono',
      dateTime: 'Fecha y hora',
      taskId: 'Id'
    },
    taskStatus: {
      readyToLaunch: 'Listo para lanzar',
      inProcess: 'En proceso',
      validationError: 'Error de validación',
      completed: 'Completado',
      inQueue: 'En cola',
      transcription: 'Transcripción',
      analysis: 'Análisis',
      analysisError: 'Error de análisis',
      transcriptionError: 'Error de transcripción',
      downloadLogs: 'Descargar logs',
      analyzeLimitError: 'Se ha superado el límite de análisis',
      transcriptionLimitError: 'Límite de transcripción excedido'
    },
    taskSettings: {
      selectDataset: 'Seleccionar conjunto de datos',
      selectCustomFields: 'Seleccione campos personalizados',
      customField: 'Campo personalizado',
      rating: 'Calificación',
      selectAiRatingForm: 'Seleccionar formulario de calificación de IA',
      checkStatus: 'Verificar estado',
      addCondition: 'Agregar condición',
      communicationFilter: 'Filtro de comunicación',
      operators: 'Operadores',
      contactStatus: 'Estado de contacto',
      department: 'Departamento',
      levels: 'Niveles',
      period: 'Periodo',
      regular: 'Regular',
      oneTime: 'Una vez',
      immediate: 'Inmediato',
      taskType: 'Tipo de tarea',
      disableEnable: 'Deshabilitar / Habilitar',
      edit: 'Editar',
      newTask: 'Nueva tarea',
      pleaseEnterTaskName: 'Por favor, introduzca el nombre de la tarea',
      enterName: 'Introducir nombre',
      export: 'Exportar',
      communicationList: 'Lista de comunicación',
      incoming: 'Entrante',
      outgoing: 'Saliente',
      byStatus: 'Por estado'
    },
    operations: {
      operationSuccessfully: 'Operación exitosa',
      operationError: 'Error de operación',
      checkAIAssessmentFormOrPromptsValues:
        'Debe especificar al menos un valor en El campo formulario de evaluación AI o Prompt',
      saveAiTask: `Una vez guardada la tarea, se recomienda encarecidamente que no se modifiquen los prompts y el formulario de evaluación para garantizar que QolioAI funcione correctamente. Tenga en cuenta que todos los criterios en el formulario de evaluación y todos los campos deben estar asociados con prompts para que la validación tenga éxito.`
    },
    processStatus: {
      completed: 'Completo',
      process: 'En gestión',
      mistake: 'Error',
      ready: 'Listo para el lanzamiento',
      all: 'Eso es todo'
    },
    tooltips: {
      validationError: `Posibles causas: \nCambio en el formulario de evaluación \nCambiar campos personalizados \nProductos defectuosos`,
      analyzeError: 'Otros errores',
      allStatus: 'Solo se pueden procesar 100 comunicaciones en el modo demo',
      transcriptionError: 'Error de transcripción',
      copyTaskId: 'Copiar id de tarea',
      analyzeLimitError: 'Límite de análisis de comunicación excedido',
      transcriptionLimitError: 'Tiempo límite asignado para transcripción excedido'
    },
    messages: {
      copyTaskIdSuccess: 'Id de tarea copiado'
    }
  }
};
