import { Row, Select } from 'antd';
import STable from 'components/Standard/STable';
import styled from 'styled-components';

export const CategoryName = styled.p`
  font-weight: 600;
  font-size: var(--font-size);
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  color: var(--text);
  margin: 0;
`;

export const CategoryNameBlock = styled(Row)`
  &.ant-row {
    cursor: pointer;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const FilterContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

export const ClientInteractionsStyledTable = styled(STable)`
  &.ant-table-wrapper td {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  margin-top: -16px;
`;
