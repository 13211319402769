import React, { useEffect, useState } from 'react';
import { Divider } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import SText from '../Standard/SText';
import SSelect from '../Standard/SSelect';
import SRow from '../Standard/SRow';

const CustomOption = styled.div`
  && {
    line-height: 22px;
    padding: 5px 12px;
    color: var(--text);

    &:hover {
      cursor: pointer;
    }
  }
`;

const ChoiceSelect = props => {
  const dispatch = useDispatch();
  const { filters, items, updateFilters, keyName, ...otherProps } = props;
  const [checked, setChecked] = useState(!isEmpty(filters));

  useEffect(() => {
    setChecked(!isEmpty(filters));
  }, [filters]);

  const optionsHandler = () => {
    const arrayOfIds = items.map(unit => unit.id);
    setChecked(!checked);
    dispatch(updateFilters({ [keyName]: checked ? [] : arrayOfIds }));
  };
  return (
    <SRow>
      <SSelect
        width="100%"
        {...otherProps}
        dropdownRender={menu => (
          <>
            {menu}
            <Divider type="horizontal" style={{ margin: 0 }} />
            <CustomOption
              className="ant-select-item ant-select-item-option"
              onClick={optionsHandler}
            >
              <SText strong>{checked ? 'Убрать все' : 'Выбрать все'}</SText>
            </CustomOption>
          </>
        )}
      >
        {props.children}
      </SSelect>
    </SRow>
  );
};

export default ChoiceSelect;
