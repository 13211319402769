import { Form, message, Select, Typography } from 'antd';
import LocaleFlag from 'components/LocaleFlag';
import { LOCALES } from 'core/utils/constants';
import { get, isEmpty, omit, isEqual } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { usersResource } from 'redux/resources/users';
import { getUserWithRoleAndUnitById } from 'redux/selectors/users';
import { useParams } from 'react-router-dom';
import SButton from 'components/Standard/SButton';
import SInput, { SInputPassword } from 'components/Standard/SInput';
import SSelect from 'components/Standard/SSelect';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { STitle } from 'components/Standard/SText';
import { SFormItem } from 'components/Standard/SFormItem';
import { SText } from 'components/Standard';
import { useIsQiwiDomain } from 'core/utils/hooks';

const { Text } = Typography;
const { Option } = Select;

const updateUser = usersResource.operations.updateById;

const UserAccountSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id: paramsId } = useParams();
  const isQiwiDomain = useIsQiwiDomain();

  const currentUserId = useSelector(state =>
    get(state, 'reduxTokenAuth.currentUser.attributes.id')
  );

  const userId = paramsId || currentUserId;
  const user = useSelector(state => getUserWithRoleAndUnitById(state, userId), isEqual);

  useEffect(() => {
    form.setFieldsValue(user);
  }, [user?.email]);

  const handleSubmit = useCallback(
    async values => {
      const updatedValues = omit(
        values,
        isEmpty(values?.password) ? ['password', 'passwordConfirmation'] : []
      );
      try {
        const response = await dispatch(
          updateUser({
            id: userId,
            ...updatedValues
          })
        );
        if (response) {
          message.success(t('userSettingsPage.messages.dataSuccessfullyUpdated'));
          if (user?.preferedLocale !== response?.preferedLocale) window.location.reload(false);
        }
      } catch (error) {
        console.log(error);
        message.error(t('userSettingsPage.messages.updateDataFailed'));
      }
    },
    [dispatch, userId, user?.preferedLocale]
  );

  const confirmValidator = ({ getFieldValue }) => ({
    validator: (_, value) => {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        t('userSettingsPage.messages.form.validateMessages.passwordMismathing')
      );
    }
  });

  return (
    <SCol span={24}>
      <SCard shadowed bordered>
        <SRow gutter={[0, 16]}>
          <SCol span={24}>
            <STitle fontSize="18px" lineHeight="26px" strong>
              {t('userSettingsPage.tabs.accountSettings.tabName')}
            </STitle>
          </SCol>
          <SCol span={24}>
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              scrollToFirstError
              form={form}
              initialValues={{
                lastName: user?.lastName,
                firstName: user?.firstName,
                preferedLocale: user?.preferedLocale
              }}
            >
              <SRow gutter={[16, 0]}>
                <SCol lg={12} xl={8} xxl={6}>
                  <SFormItem
                    marginBottom="16px"
                    label={
                      <SText strong>
                        {t('userSettingsPage.tabs.accountSettings.form.lastName')}
                      </SText>
                    }
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: t('userSettingsPage.messages.form.validateMessages.enterLastName'),
                        whitespace: true
                      }
                    ]}
                  >
                    <SInput size="middle" width="100%" />
                  </SFormItem>
                </SCol>
                <SCol lg={12} xl={8} xxl={6}>
                  <SFormItem
                    marginBottom="16px"
                    label={
                      <SText strong>
                        {t('userSettingsPage.tabs.accountSettings.form.firstAndMiddleName')}
                      </SText>
                    }
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'userSettingsPage.messages.form.validateMessages.enterFirstAndMiddleName'
                        ),
                        whitespace: true
                      }
                    ]}
                  >
                    <SInput size="middle" width="100%" />
                  </SFormItem>
                </SCol>

                <SCol lg={12} xl={8} xxl={6}>
                  <SFormItem
                    marginBottom="16px"
                    label={
                      <SText strong>{t('userSettingsPage.tabs.accountSettings.form.locale')}</SText>
                    }
                    name="preferedLocale"
                  >
                    <SSelect size="middle" width="100%">
                      {Object.values(LOCALES).map(item => (
                        <Option key={item.value} value={item.value}>
                          <SRow type="flex" align="middle" gutter={[4, 4]}>
                            <SCol lineHeight="1px">
                              <LocaleFlag locale={item.value} />
                            </SCol>
                            <SCol>
                              <Text>{t(item.title)}</Text>
                            </SCol>
                          </SRow>
                        </Option>
                      ))}
                    </SSelect>
                  </SFormItem>
                </SCol>
              </SRow>

              <SRow gutter={[16, 0]} type="flex" align="middle">
                <SCol lg={12} xl={8} xxl={6}>
                  <SFormItem
                    marginBottom="16px"
                    style={{ margin: '0' }}
                    label={
                      <SText strong>
                        {t('userSettingsPage.tabs.securitySettings.form.newPassword')}
                      </SText>
                    }
                    rules={[
                      form => ({
                        required: !isEmpty(form.getFieldValue('password')),
                        message: t('userSettingsPage.messages.form.validateMessages.enterPassword')
                      }),
                      {
                        min: 6,
                        message: t(
                          'userSettingsPage.messages.form.validateMessages.passwordLenghtMismathing'
                        )
                      }
                    ]}
                    name="password"
                  >
                    <SInputPassword size="middle" width="100%" />
                  </SFormItem>
                </SCol>

                <SCol lg={12} xl={8} xxl={6}>
                  <SFormItem
                    marginBottom="16px"
                    style={{ margin: '0' }}
                    label={
                      <SText strong>
                        {t('userSettingsPage.tabs.securitySettings.form.newPasswordConfirmation')}
                      </SText>
                    }
                    hasFeedback
                    dependencies={['password']}
                    rules={[
                      form => ({
                        required: !isEmpty(form.getFieldValue('password')),
                        message: t('userSettingsPage.messages.form.validateMessages.enterPassword')
                      }),
                      {
                        min: 6,
                        message: ''
                      },
                      confirmValidator
                    ]}
                    name="passwordConfirmation"
                  >
                    <SInputPassword size="middle" width="100%" />
                  </SFormItem>
                </SCol>
                <SCol
                  lg={24}
                  xl={8}
                  xxl={12}
                  display="flex"
                  justifyContent="flex-end"
                  marginTop="22px"
                >
                  <SButton type="primary" htmlType="submit" size="middle" disabled={isQiwiDomain}>
                    {t('userSettingsPage.buttons.update')}
                  </SButton>
                </SCol>
              </SRow>
            </Form>
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default UserAccountSettings;
