import { Row } from 'antd';
import { CLIENT_INTERACTIONS_TYPES_LITERALS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'components/Icon';
import SCard from 'components/Standard/SCard';
import { useSelector } from 'react-redux';
import { PhoneOutlined } from '@ant-design/icons';
import SCol from 'components/Standard/SCol';
import { SText } from 'components/Standard';

const ReviewIcon = styled(PhoneOutlined)`
  && {
    padding: 3px 4px 0 0;
    color: var(--primary);
    &:hover {
      color: var(--blue_4);
    }
  }
`;

const ReviewPreview = ({ review, onClick }) => {
  const { t } = useTranslation();
  const clientInteractionByIds = useSelector(state => state.clientInteractionsResource.byIds);
  const phoneCallStartedAt = useSelector(
    state => state.clientInteractionsResource.byIds[review.clientInteractionId].startedAt
  );
  return (
    <Link to={`/reviews/${review.id}`} onClick={onClick}>
      <SCard bordered shadowed bodyPadding="16px 24px" marginBottom="16px">
        <Row type="flex" justify="space-between" align="middle" style={{ height: '100%' }}>
          <SCol display="flex" alignItems="center" align="middle" flex="auto">
            <Icon icon={ReviewIcon} size="20px" />
            <Data>
              <SText strong>
                {t('general.date')}
                :&nbsp;
              </SText>
              {moment(phoneCallStartedAt).format('DD/MM/YYYY, HH:mm')}
            </Data>
            <Data>
              <SText strong>
                {t('general.clientInteractionType')}
                :&nbsp;
              </SText>
              {t(
                CLIENT_INTERACTIONS_TYPES_LITERALS[
                  clientInteractionByIds[review?.clientInteractionId]?.clientInteractionType
                ]
              )}
            </Data>
          </SCol>
          <SCol display="flex" flex="none">
            <Data>
              <SText strong>
                {t('general.score')}
                :&nbsp;
              </SText>
              {beatifyFloat(review.score)}
            </Data>
          </SCol>
        </Row>
      </SCard>
    </Link>
  );
};

const Data = styled.p`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 4px;
`;

export default ReviewPreview;
