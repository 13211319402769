export default {
  menu: {
    appMenu: {
      analytics: 'Analítica',
      reports: 'Informe',
      dashboards: 'Tablero',
      dynamicsOfEvaluationsByUnits: 'Dinámica de las evaluaciones por unidades',
      communications: 'Lista de comunicaciones',
      tasks: 'Tareas',
      library: 'Biblioteca de llamadas',
      addReview: 'Nuevo cheque',
      team: 'Equipo',
      kpi: 'KPI Analítica',
      settings: 'Herramientas',
      checklistsSettings: 'Formularios de evaluación',
      tagsSettings: 'Etiquetas',
      calibrations: 'Sesiones de calibración',
      workPlanTaskConfigurations: 'Distribución de la carga',
      appeals: 'Apelaciones',
      autoFailScores: 'Dinámica de errores críticos'
    },
    userMenu: {
      profile: 'Área personal',
      profileSettings: 'Configuración de perfil',
      organizationSettings: 'Configuración de la organización',
      organizationStructureSettings: 'Empleados y derechos de acceso',
      integrationsSettings: 'Integración',
      logout: 'Salida',
      organizationId: 'Código de organización',
      copyOrganizationId: 'Copiar el Código de la organización',
      copyOrganizationIdSuccess: 'Código de organización copiado',
      customFields: 'Campos personalizados',
      aiSettings: 'Ajustes'
    },
    helpMenu: {
      helpCenter: 'Centro de ayuda',
      updates: 'Ultimas actualizaciones',
      support: 'Soporte de contacto',
      suggestAnIdea: 'Sugerir una idea',
      hotKeys: 'Teclas de acceso rápido'
    },
    alerts: {
      rolesAlert: {
        title:
          'Papeles del Administrador y el Operador son básicos, sin posibilidad de borrarlos. Detalles de los papeles, derechos y ajustes ',
        button: 'aquí'
      }
    },
    workPlanTasks: 'Mis tareas'
  }
};
