import api from 'core/api';
import { createReduxResource } from 'core/redux/resources';
import { processJsonApiObject } from '../../core/jsonapi';

export const integrationsResource = createReduxResource({
  name: 'integrations',
  customOperations: {
    loadByIdIntegration: {
      callEndpoint: ({ id, ...options }) => {
        if (options?.type === 's2' || options?.type === 'pyrus') {
          return api.getIntegrationV4({ id });
        }
        return api.getIntegration({ id });
      },
      onSuccess: ({ response, dispatch }) => {
        const resource = processJsonApiObject(response.body.data);
        dispatch(integrationsResource.actions.loadByIdSucceed(resource));
        return resource;
      }
    },
    updateCustomHttpTelephonyAuthorizationToken: {
      callEndpoint: ({ id }) => api.updateCustomHttpTelephonyAuthorizationToken({ id }, {}),
      onSuccess: ({ result: integration, dispatch }) => {
        dispatch({ type: 'integrations/updateByIdSucceed', payload: integration });
        return integration;
      }
    },
    updateCustomHttpTextAuthorizationToken: {
      callEndpoint: ({ id }) => api.updateCustomHttpTextAuthorizationToken({ id }, {}),
      onSuccess: ({ result: integration, dispatch }) => {
        dispatch({ type: 'integrations/updateByIdSucceed', payload: integration });
        return integration;
      }
    },
    getAvailableIntegrations: {
      callEndpoint: api.getAvailableIntegrations,
      onSuccess: ({ result }) => result.data
    }
  }
});
