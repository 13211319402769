import SRow from 'components/Standard/SRow';
import { Helmet } from 'react-helmet';
import SCol from 'components/Standard/SCol';
import Filters from 'pages/DashboardPages/AutofailScoresPage/Components/Filters';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingDashboard from 'pages/DashboardPage/Components/LoadingDashboard';
import { StyledEmpty, StyledErrorMessage } from 'pages/DashboardPage/styled';
import { Empty, Pagination } from 'antd';
import SCard from 'components/Standard/SCard';
import SText from 'components/Standard/SText';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAutofailDashboardPageWidget,
  loadAutoFailDashboard
} from 'redux/entities/dashboardPages/autofailsDashboardPage/operations';
import { get, isEqual, orderBy } from 'lodash';
import ReportSettingsModal from 'components/ReportSettingsModal';
import { AUTO_FAIL_TYPES, WIDGET_VIEW_MODES } from 'core/utils/constants';
import AutoFailCountsBy from './Components/Widgets/AutoFailCountsBy/AutoFailCountsBy';
import { onAdd } from './Components/Widgets/AutoFailCountsBy/utils';
import AutoFailHistoryReport from './Components/Widgets/AutoFailHistoryReport/AutoFailHistoryReport';

const AutoFailScoresPage = () => {
  const [viewMode, setViewMode] = useState({
    [AUTO_FAIL_TYPES.HISTORY_REPORT]: WIDGET_VIEW_MODES.TABLE.value
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleWidgetId, setVisibleWidgetId] = useState();
  const dashboardPage = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.autofail_scores', {}),
    isEqual
  );

  const { page } = dashboardPage;

  useEffect(() => {
    dispatch(
      loadAutoFailDashboard({
        page: {
          number: 1,
          size: 5
        }
      })
    );
  }, []);

  const autoFailDashboardWidgetsIds = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.autofail_scores.widgetsIds', []),
    isEqual
  );

  const widgetsByIds = useSelector(state => state.dashboardWidgetsResource.byIds, isEqual);

  const orderedWidgets = orderBy(
    autoFailDashboardWidgetsIds.map(widgetId => widgetsByIds[widgetId]),
    'createdAt',
    'desc'
  );

  const loading = useSelector(state => state.dashboardPages.loading);

  const renderDescription = () => {
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPages.noCreatedReports')}
        </StyledErrorMessage>
        <SText type="secondary">{t('dashboardPages.clickAddReportButton')}</SText>
      </>
    );
  };

  const onPaginationChange = pageNumber => {
    dispatch(
      loadAutoFailDashboard({
        page: {
          number: pageNumber,
          size: 5
        }
      })
    );
  };

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.autoFailAnalyticsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <Filters />
      </SCol>
      <SCol span={24} padding="16px">
        {loading && <LoadingDashboard />}
        {!loading && orderedWidgets.length > 0 && (
          <>
            {orderedWidgets.map(widgetData => {
              return widgetData.type === AUTO_FAIL_TYPES.HISTORY_REPORT ? (
                <AutoFailHistoryReport
                  key={widgetData.id}
                  widgetData={widgetData}
                  setVisibleWidgetId={setVisibleWidgetId}
                  viewMode={viewMode?.[widgetData.type]}
                  changeViewMode={value =>
                    setViewMode(prevState => {
                      return {
                        ...prevState,
                        [widgetData.type]: value
                      };
                    })
                  }
                />
              ) : (
                <AutoFailCountsBy
                  key={widgetData.id}
                  widgetData={widgetData}
                  setVisibleWidgetId={setVisibleWidgetId}
                />
              );
            })}
            <SRow type="flex" justify="end" style={{ marginTop: '20px' }} gutter={[0, 16]}>
              <Pagination
                size="small"
                current={page?.page}
                total={page?.totalCount}
                pageSize={5}
                onChange={onPaginationChange}
              />
            </SRow>
          </>
        )}
        {!loading && orderedWidgets.length === 0 && (
          <SCard bordered shadowed bodyPadding="50px" headPadding="50px">
            <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={renderDescription()} />
          </SCard>
        )}
      </SCol>
      <ReportSettingsModal
        id={visibleWidgetId}
        setVisibleFilterModal={setVisibleWidgetId}
        onCancel={() => setVisibleWidgetId(false)}
        visible={visibleWidgetId}
        onAdd={onAdd}
        deleteDashboardPageWidget={deleteAutofailDashboardPageWidget}
        isAutoFailsAnalytics
      />
    </SRow>
  );
};

export default AutoFailScoresPage;
