import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { downloadCallFromMediaUrl } from '../../../core/api';
import SCol from '../../Standard/SCol';
import SRow from '../../Standard/SRow';

const PartContentVideo = ({ htmlString, pyrusIntegration }) => {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const href = doc.querySelector('a')?.getAttribute('href');

        if (!href) {
          console.error('Ссылка на видео не найдена');
          return;
        }

        const response = await fetch(downloadCallFromMediaUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            media_url: href,
            integration_id: pyrusIntegration?.id
          })
        });

        if (response.ok) {
          const blob = await response.blob();
          const videoBlobUrl = URL.createObjectURL(blob);
          setVideoUrl(videoBlobUrl);
        } else {
          console.error('Ошибка при получении видео:', response.statusText);
        }
      } catch (error) {
        console.error('Ошибка при загрузке видео:', error);
      }
    };

    fetchVideo();
  }, [htmlString, pyrusIntegration]);

  return (
    <SCol
      background="var(--gray_primary)"
      borderRadius="16px"
      margin="2px 2px"
      padding="6px 12px !important"
      border="1px solid #c2c4cc"
      onClick={e => {
        e.stopPropagation();
      }}
      width="fit-content"
    >
      {!htmlString ? (
        <Spin size="large" tip="Загрузка видео..." />
      ) : (
        <SRow display="flex" justify="space-between" align="middle" wrap={false}>
          <SCol flex="auto" width="100%" className="truncated">
            {htmlString && <video controls src={videoUrl} style={{ width: '100%', maxHeight: '400px' }} />}
          </SCol>
        </SRow>
      )}
    </SCol>
  );
};

export default PartContentVideo;
