import CallContent from 'components/CallContent';
import { isEqual, omit } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadCallById } from 'redux/ui/clientInteractionPage/operations';
import ClientInteractionPage from './ClientInteractionPage';

const CallPage = ({ id, offsetTop, affixTarget, shouldLoad = true, fromDrawer = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: paramsId } = useParams();

  const callId = id || paramsId;

  useEffect(() => {
    dispatch(
      loadCallById({
        id: callId,
        include: 'operator.unit,status,phone_call_chain,phone_call_transcription.parts',
        fromDrawer
      })
    );
  }, [callId]);

  const call = useSelector(
    state => state.phoneCallsResource.byIds[callId],
    (next, prev) => isEqual(omit(prev, ['loading']), omit(next, ['loading']))
  );

  const loading = useSelector(state => {
    const call = state.phoneCallsResource.byIds[callId];
    const { loading, loadByIdStarted } = state.phoneCallsResource;
    return loading || loadByIdStarted || !call || call?.loading;
  });

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.callPage.title')}</title>
      </Helmet>
      <ClientInteractionPage
        affixTarget={affixTarget}
        Content={CallContent}
        clientInteraction={call}
        loading={loading}
        offsetTop={offsetTop}
        fromDrawer={fromDrawer}
        shouldLoad={shouldLoad}
        id={callId}
      />
    </>
  );
};

export default React.memo(CallPage, isEqual);
