import { getUserName } from 'components/UserPreview/getUserName';
import { exportWidgetData } from 'pages/DashboardPage/utils';
import { updateDashboardPageWidget } from 'redux/entities/dashboardPages/operations';

export const getOrderString = ({ orderString }) => {
  if (orderString === 'asc') return 'ascend';
  if (orderString === 'desc') return 'descend';
};

export const onTableChange = async ({
  pagination,
  sorter,
  widgetId,
  tableColumns,
  filters,
  dispatch,
  setInitialState
}) => {
  setInitialState({
    number: pagination?.current,
    size: pagination?.pageSize - 1
  });

  const columnsKeys = tableColumns.map(item => item.key);
  const { columnKey } = sorter;

  const isNewSort = columnsKeys.includes(columnKey);

  const getSorterString = () =>
    sorter?.order === 'ascend' ? sorter?.columnKey : `-${sorter?.columnKey}`;

  await dispatch(
    updateDashboardPageWidget({
      id: widgetId,
      filters,
      page: {
        number: pagination.current,
        size: pagination.pageSize - 1
      },
      sort: isNewSort ? getSorterString() : ''
    })
  );
};

export const onAdd = ({ id, updatedFilter, filters, page, setVisibleFilterModal, dispatch }) => {
  dispatch(
    updateDashboardPageWidget({
      id,
      filters: {
        ...filters,
        ...updatedFilter
      },
      page: {
        number: page?.number,
        size: page?.size
      }
    })
  );
  setVisibleFilterModal(false);
};

export const getColorizedColumn = ({ color, component }) => {
  return {
    props: {
      style: {
        background: color,
        border: '1px solid white',
        // borderRadius: '4px',
        padding: '5px'
      }
    },
    children: component
  };
};

export const startExport = async ({
  t,
  id,
  meta,
  type,
  users,
  filters,
  dispatch,
  initialState,
  setLoadingExport
}) => {
  const res = await dispatch(
    updateDashboardPageWidget({
      id,
      filters,
      page: {
        number: 1,
        size: meta.total_count
      }
    })
  );

  const columns = [
    {
      title: t('general.executor'),
      dataIndex: 'id',
      key: 'id'
    },

    {
      title: t('appealsPage.analytics.pending'),
      dataIndex: 'open_appellations_count',
      key: 'open_appellations_count'
    },

    {
      title: `% ${t('appealsPage.analytics.pending')}`,
      dataIndex: 'percentage_open_appellations_count',
      key: 'percentage_open_appellations_count'
    },

    {
      title: t('appealsPage.analytics.noAnswer'),
      dataIndex: 'without_answer_appellations_count',
      key: 'without_answer_appellations_count'
    },

    {
      title: `% ${t('appealsPage.analytics.noAnswer')}`,
      dataIndex: 'percentage_without_answer_appellations_count',
      key: 'percentage_without_answer_appellations_count'
    },

    {
      title: t('general.rejected'),
      dataIndex: 'rejected_appellations_count',
      key: 'rejected_appellations_count'
    },

    {
      title: `% ${t('general.rejected')}`,
      dataIndex: 'percentage_rejected_appellations_count',
      key: 'percentage_rejected_appellations_count'
    },

    {
      title: t('general.accepted'),
      dataIndex: 'accepted_appellations_count',
      key: 'accepted_appellations_count'
    },

    {
      title: `% ${t('general.accepted')}`,
      dataIndex: 'percentage_accepted_appellations_count',
      key: 'percentage_accepted_appellations_count'
    },

    {
      title: t('general.total'),
      dataIndex: 'total_appellations_count',
      key: 'total_appellations_count'
    }
  ];

  const prepareRows = () => {
    const isUnitsMode = type === 'appellation_statuses_count_by_units';
    const isReviewerAnalyticsExports = type === 'appellation_statuses_count_by_reviewers';

    const getPercentageColumns = item => {
      return {
        percentage_open_appellations_count:
          (item?.open_appellations_count / item?.total_appellations_count) * 100,
        percentage_without_answer_appellations_count: {
          value: (item?.without_answer_appellations_count / item?.total_appellations_count) * 100,
          color: 'var(--orange_8)'
        },
        percentage_rejected_appellations_count: {
          value: (item?.rejected_appellations_count / item?.total_appellations_count) * 100,
          color: isReviewerAnalyticsExports ? 'var(--green_4)' : 'var(--red_4)'
        },
        percentage_accepted_appellations_count: {
          value: (item?.accepted_appellations_count / item?.total_appellations_count) * 100,
          color: isReviewerAnalyticsExports ? 'var(--red_4)' : 'var(--green_4)'
        }
      };
    };

    return res[id]?.data.map(item => {
      return {
        id: isUnitsMode ? item?.name : getUserName({ user: users[item.id] }),
        open_appellations_count: item?.open_appellations_count,
        without_answer_appellations_count: {
          value: item?.without_answer_appellations_count,
          color: 'var(--orange_8)'
        },
        rejected_appellations_count: {
          value: item?.rejected_appellations_count,
          color: isReviewerAnalyticsExports ? 'var(--green_4)' : 'var(--red_4)'
        },
        accepted_appellations_count: {
          value: item?.accepted_appellations_count,
          color: isReviewerAnalyticsExports ? 'var(--red_4)' : 'var(--green_4)'
        },
        total_appellations_count: item?.total_appellations_count,
        ...getPercentageColumns(item)
      };
    });
  };

  const body = {
    widgetData: {
      columns,
      rows: [...prepareRows(), { id: t('general.total'), ...res[id]?.total_appellations_count }]
    }
  };

  await exportWidgetData({ body, setLoadingExport });

  await dispatch(
    updateDashboardPageWidget({
      id,
      filters,
      page: {
        ...initialState
      }
    })
  );
};
