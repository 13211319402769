import { integrationsResource } from 'redux/resources/integrations';

export const createCustomIntegration = ({ name, integrationType }) => async dispatch => {
  try {
    const result = await dispatch(
      integrationsResource.operations.create({ name, integrationType })
    );
    return result.id;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
