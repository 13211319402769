import { get, reduce } from 'lodash';
import { getChecklistDefinitionBindings } from 'redux/selectors/checklistItems/checklistItems';
import * as actions from './reducer';

export const createChecklistFromDefinition = ({
  currentChecklist = {},
  checklistDefinitionId
}) => async (dispatch, getState) => {
  const state = getState();
  const checklistDefinition = state.checklistDefinitionsResource.byIds[checklistDefinitionId];
  const bindings = getChecklistDefinitionBindings(state, checklistDefinition);

  const checklist = {
    ...currentChecklist,
    checklistDefinition,
    checklistDefinitionId
  };

  await dispatch(
    actions.setNewChecklistState({
      checklist,
      questionIdToAnswerValue: {
        ...reduce(
          bindings,
          (result, binding) => ({
            ...result,
            [binding.questionId]: binding.defaultRatingValueEnabled
              ? get(binding, 'defaultRatingValueScore', null)
              : null
          }),
          {}
        )
      }
    })
  );
};
