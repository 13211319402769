import { Plus } from 'react-feather';
import { Alert, Button, Col, Divider, Empty, Input, Row, Select } from 'antd';
import styled from 'styled-components';

export const SettingsRow = styled(Row)`
  &.ant-row {
    display: block;
    margin: 8px 0;
  }
`;

export const Column = styled(Col)`
  &.ant-col {
    min-height: 32px;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
    margin-left: 4px;
    max-width: 506px;
    background-color: ${props => (props.isDragging ? 'var(--gray-background)' : 'none')};
  }

  &.ant-col > .ant-typography {
    margin-left: 4px;
  }
`;

export const AddColumnIcon = styled(Plus)`
  & {
    margin-right: 4px;
  }
`;

export const ColumnPreview = styled(Col)`
  &.ant-col {
    min-height: 32px;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
    margin: 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  :hover {
    background-color: var(--gray-background);
  }

  &.ant-col > .ant-typography {
    margin-left: 4px;
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    margin-top: 2px;
  }
`;

export const DrawerButton = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    margin-top: 5px;
  }
`;

export const MoreRowInfoButton = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    margin-top: 1px;
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select {
    width: 100%;
    margin-top: 4px;
  }
`;

export const CreateButton = styled(Button)`
  &.ant-btn {
    border-color: var(--primary);
    margin-top: 8px;
    color: var(--primary);
  }
`;

export const MoveButtonsBlock = styled(Col)`
  &.ant-col {
    width: 32px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    border-left: 1px solid var(--gray-border);
    position: absolute;
    right: 0px;
    top: 0px;
  }
`;

export const OpenDrawerButton = styled(Button)`
  &.ant-btn {
    position: fixed;
    top: 30%;
    right: 0;
    width: 50px !important;
    height: 50px !important;
    font-size: 24px !important;
    z-index: 1000;
  }
`;

export const UrlDisabledInput = styled(Input)`
  &.ant-input-disabled {
    background-color: transparent !important;
    color: var(--text) !important;
  }
`;

export const StyledDivider = styled(Divider)`
  &.ant-divider {
    margin-bottom: 8px;
  }
`;

export const LimitCountTables = styled(Alert)`
  &.ant-alert {
    margin-top: 8px;
  }
`;

export const StyledEmpty = styled(Empty)`
  &.ant-empty-normal {
    margin: 15px 0;
  }
`;
