const { createSelector } = require('@reduxjs/toolkit');
const { reduce } = require('lodash');

const getPhoneCallTranscriptionsByIds = state => state.phoneCallTranscriptionsResource.byIds;
const getPhoneCallTranscriptionPartsByIds = state =>
  state.phoneCallTranscriptionPartsResource.byIds;

export const getTranscriptionWithParts = createSelector(
  getPhoneCallTranscriptionsByIds,
  getPhoneCallTranscriptionPartsByIds,
  (_, id) => id,
  (transcriptionsByIds, partsByIds, transcriptionId) => {
    const transcription = transcriptionsByIds[transcriptionId];
    return transcription
      ? {
          ...transcription,
          parts: reduce(
            transcription?.partsIds || [],
            (acc, id) => (partsByIds[id] ? [...acc, partsByIds[id]] : acc),
            []
          )
        }
      : undefined;
  }
);
