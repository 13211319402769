import camelcase from 'camelcase';
import { singular } from 'pluralize';
import { ACTION_TARGET_TYPES } from 'core/redux/resources/constants';

export const resourceApiRequestsBuilder = ({ api }) => {
  return ({ name, apiMethod, on }) => {
    const resourceName = on === ACTION_TARGET_TYPES.COLLECTION ? name : singular(name);
    return api[camelcase(`${apiMethod}_${resourceName}`)];
  };
};
