export default {
  integrationsSettingsPage: {
    proceed: 'Continuare',
    save: 'Salva',
    delete: 'Cancellare',
    sync: 'Sincronizzare',
    selectSyncPeriod: 'Selezionare il periodo di sincronizzazione',
    checkboxIsUpdateOldData: 'Aggiorna vecchi dati',
    syncInternal: 'Also sync internal calls',
    deleteConfirm: {
      title: "Cancellare l'integrazione?",
      ok: 'Sì',
      cancel: 'No'
    },
    nameAndType: {
      name: 'Nome',
      integrationName: "Immettere il nome dell'integrazione",
      type: 'Tipo',
      integrationType: 'Seleziona il tipo di integrazione',
      clientSecretPlaceholder: 'Inserisci Client Secret',
      clientProductCodePlaceholder: 'Inserisci Codice prodotto',
      clientSaltPlaceholder: 'Inserisci Chiave',
      clientProductCode: 'Codice prodotto',
      clientSalt: 'Chiave',
      enterPlaceholderTemplate: 'Inserisci',
      yourB24address: 'Il tuo indirizzo Bitrix 24',
      mangoApiKey: 'Codice prodotto',
      mangoSalt: 'Chiave',
      errors: {
        emptyURL: "Si prega di inserire l'Endpoint",
        invalidURL: 'Hai inserito un Endpoint non valido'
      }
    },
    integrationPage: {
      title: 'Integrazione',
      sync: 'Sincronizzazione',
      manualSync: 'Manual synchronization',
      fields: 'Campi',
      users: 'Utenti',
      integrationSettings: 'Impostazioni di integrazione',
      manualLinkText: "Istruzioni per l'integrazione",
      manualArticles: 'Istruzioni per la configurazione delle integrazioni',
      customFieldsSettings: {
        title: 'Impostazioni del campo personalizzato',
        addField: 'Aggiungere un campo',
        deleteConfirm: {
          title: 'Cancellare il campo utente',
          okText: 'Cancellare'
        },
        editor: {
          fieldName: 'Nome del campo',
          fieldKey: 'Chiave di campo',
          fieldType: 'Tipo di campo',
          usedForFilters: 'Utilizzato per il filtraggio',
          usedForReviews: 'Usato per le valutazioni',
          usedForAI: "Utilizzare per l'elaborazione ai",
          save: 'Salva',
          edit: 'Modifica',
          delete: 'Cancellare'
        }
      },
      limitSelectedFields: 'Limite al numero di campi aggiuntivi',
      integrationFieldsList: {
        empty: 'Il nome del campo non è impostato',
        name: 'Nome',
        type: 'Tipo'
      },
      testRequest: {
        title: 'Richiesta di test',
        success: 'Aggiunta comunicazione al sistema, disponibile a questo link',
        failure: 'La comunicazione di prova non può essere aggiunta',
        makeRequest: 'Fai una richiesta'
      },
      updateConnection: 'Aggiorna connessione',
      switchOn: 'Turn on synchronization',
      switchOff: 'Turn off synchronization',
      amocrmLink: 'Link to amoCRM account',
      amocrmLastSynced: 'Last synced',
      amocrmIntegrationCreated: 'Created at',
      amocrmConnectionStatus: 'Status',
      naumenConnectionStatus: `Connessione all'account Naumen`,
      ednaConnectionStatus: `Connessione all'account Edna`,
      mangoConnectionStatus: 'Status',
      amocrmActive: 'Active',
      amocrmInactive: 'Inactive'
    },
    messages: {
      integrationSuccessfullyCreate: 'Integrazione creata con successo',
      integrationSuccessfullyUpdated: "L'integrazione è stata aggiornata con successo",
      integrationCreateFailed: "Impossibile creare l'integrazione"
    },
    addIntegration: {
      title: "Aggiungere l'integrazione",
      yourIntegrations: 'Le tue integrazioni'
    },
    manageUserAccounts: {
      totalUsers: 'Utenti',
      activeUsers: 'Attivo',
      selectAll: 'Seleziona tutti',
      columns: {
        user: 'Dipendente',
        email: 'Email',
        id: 'ID',
        sync: 'Sync',
        status: 'Stato nel sistema esterno'
      },
      add: 'Aggiungi',
      downloadCSV: "Scarica l'esempio CSV",
      manual: 'Manualmente',
      fromCSV: 'Via CSV',
      sendingFile: 'Invio di un file...',
      csvModal: {
        title: 'Congratulazioni',
        content: 'Hai caricato tutti gli utenti dal tuo file CSV!',
        okText: 'Continuare'
      },
      usersAlreadyInSystem: 'Qolio ha già utenti con questa email',
      accountToUsers: 'Questi account verranno aggiunti agli utenti',
      brokenEmail: "L'utente non ha un indirizzo email"
    },
    createCustomIntegration: {
      addUsersTitle: 'Aggiungi utente'
    },
    createSaasIntegration: {
      errorMessage: 'Si è verificato un errore durante la sincronizzazione dei dati con',
      errorMessageContent:
        "Si prega di reinstallare l'applicazione o inviare un'e-mail a info@qolio.io",
      sync: 'Sincronizzazione'
    },
    customUserAccountForm: {
      enterEmail: 'Inserisci la tua email',
      enterCorrectEmail: 'Inserisci una email valida',
      sync: 'Синхронизируется',
      notSync: 'Non si sincronizza',
      enterId: 'Inserisci Id'
    },
    sipuniAccountsImported:
      'Gli account utente vengono importati. Per configurarli, devi collegarli agli account in Qolio. Per farlo vai su ',
    mangoAccountsImported:
      'Gli account utente vengono importati. Per configurarli, devi collegarli agli account in Qolio. Per farlo vai su ',
    urlAndToken: { generate: 'Creare' },
    infoAlert: {
      message: 'Le impostazioni di sincronizzazione manuale non sono disponibili.',
      description:
        'Per la tua organizzazione, la sincronizzazione avviene ogni giorno alle {{time}}.',
      syncAlreadyInProgress:
        'La sincronizzazione è attualmente in corso. Si prega di attendere il completamento o di provare a ricaricare la pagina.',
      setTheRange:
        "Si prega di impostare un intervallo per la sincronizzazione dei dati. Si noti che più grande è l'intervallo specificato, più tempo richiederà la sincronizzazione. È sempre possibile aumentare l'intervallo di sincronizzazione dei dati nelle impostazioni di integrazione."
    },
    unsupportedField: 'Campo non supportato'
  }
};
