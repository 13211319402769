import { post } from 'redux-bees';

const infinityEndpoints = apiPath => ({
  infinitySyncDataByPeriod: {
    method: post,
    path: `${apiPath}/integrations/infinity/:id/fetch_data`
  }
});

export default infinityEndpoints;
