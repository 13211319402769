import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Col, Radio, Row, Tooltip } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEqual, keyBy } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons/IconButton';
import { getChecklistDefinitionQuestionGroupsBeforeQuestions } from 'redux/selectors/checklistItems/checklistItems';
import WidgetTable from 'components/Dashboard/WidgetTable';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from 'components/Standard/SRow';
import { LargeWidgetCard } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  exportWidgetData,
  widgetInfoTextSetter,
  widgetNameSetter,
  widgetPeriodSetter
} from '../../utils';
import { prepareColumns, prepareTableData } from './prepareTableData';
import EmptyWidget from '../../Components/EmptyWidget';
import { getChecklistsDefinitionsByIds } from '../../../../redux/selectors/checklists';
import { ContextExportDashboards } from '../../DashboardPage';
import WidgetViewLastUpdate from '../../../../components/Dashboard/WidgetViewLastUpdate';

const TableChecklistItemsByOperators = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, type, filters, name } = widgetData;
  const [loadingExport, setLoadingExport] = useState(false);

  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);
  const checklistDefinitionId = get(widgetData, 'filters?.checklistDefinitionsIds');
  const currentChecklistForMode = useSelector(state => {
    return getChecklistsDefinitionsByIds(state)[filters?.checklistDefinitionsIds] || [];
  });

  useEffect(async () => {
    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: {
          ...filters,
          viewType: currentChecklistForMode?.ratingMode === 'numbers' ? 'score' : 'percentage'
        }
      })
    );
  }, [filters?.checklistDefinitionsIds]);

  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const checklistDefinitionsForFilters = [];
  if (filters?.checklistDefinitionsIdsForFilter) {
    checklistDefinitionsForFilters.push(filters?.checklistDefinitionsIds);
    checklistDefinitionsForFilters.push(...filters?.checklistDefinitionsIdsForFilter);
  } else {
    checklistDefinitionsForFilters.push(filters?.checklistDefinitionsIds);
  }

  const currentChecklist = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  let selectedChecklistDefinitions;
  if (Array.isArray(checklistDefinitionsForFilters)) {
    selectedChecklistDefinitions = checklistDefinitionsForFilters?.reduce(
      (acc, checklistDefinitionId) => {
        if (!Object.keys(checklistDefinitionsByIds).includes(checklistDefinitionId)) return acc;
        return [...acc, checklistDefinitionsByIds[checklistDefinitionId]];
      },
      []
    );
  }
  const checklistsItems = useSelector(state => {
    if (Array.isArray(selectedChecklistDefinitions)) {
      return selectedChecklistDefinitions.map(item =>
        getChecklistDefinitionQuestionGroupsBeforeQuestions(state, item)
      );
    }
    return getChecklistDefinitionQuestionGroupsBeforeQuestions(state, currentChecklist);
  }, isEqual);

  const checklistItemsWithDouble = [].concat(...checklistsItems);
  const checklistItems = checklistItemsWithDouble.filter(
    (item, index, self) => index === self.findIndex(t => t.id === item.id && t.id === item.id)
  );

  const widgetValue = get(widgetsAnalyticsByIds, id, {});

  const checklistDefinitionAverageScore = get(
    widgetValue,
    'checklist_definition_average_score',
    []
  );

  const averageScoreByQuestions = get(widgetValue, 'average_score_by_questions', []);

  const checklistDefinitionsAverageScoresByOperators = keyBy(
    get(widgetValue, 'checklist_definition_average_score_by_operators', []),
    'id'
  );

  const checklistQuestionsAverageScoresByOperators = keyBy(
    get(widgetValue, 'checklist_question_average_scores_by_operators', []),
    'operator_id'
  );

  const checklistQuestionGroupsAverageScoresByOperators = keyBy(
    get(widgetValue, 'checklist_question_group_average_scores_by_operators', []),
    'operator_id'
  );

  const reviewsCountByOperators = keyBy(get(widgetValue, 'reviews_count_by_operators', []), 'id');

  const checklistDefinition = Array.isArray(checklistDefinitionsForFilters)
    ? checklistDefinitionsForFilters.map(id => get(checklistDefinitionsByIds, id, {}))
    : get(checklistDefinitionsByIds, checklistDefinitionsForFilters, []);

  const hasGroupable =
    (Array.isArray(checklistDefinition) && checklistDefinition?.some(item => item.isGroupable)) ||
    checklistDefinition?.isGroupable ||
    false;

  const colorZones = get(checklistDefinition, 'colorZones', {});

  const tableColumns = prepareColumns({
    filters,
    checklistItems,
    colorZones,
    checklistDefinition
  });

  const tableRows = prepareTableData({
    filters,
    colorZones,
    usersByIds,
    unitsByIds,
    checklistItems,
    averageScoreByQuestions,
    checklistDefinition,
    checklistDefinitionAverageScore,
    checklistDefinitionsAverageScoresByOperators,
    checklistQuestionsAverageScoresByOperators,
    checklistQuestionGroupsAverageScoresByOperators,
    reviewsCountByOperators
  });

  const isEmptyWidgetData = tableRows.length === 1;

  const body = {
    widgetType: type,
    widgetData: {
      columns: prepareColumns({
        filters,
        checklistItems,
        colorZones,
        checklistDefinition
      }).map(item => ({
        ...item,
        title: ['name', 'totalScore', 'reviewsCount', 'unitName'].includes(
          item.key
        )
          ? t(item?.title?.props?.i18nKey)
          : item.title.props.title
      })),
      rows: tableRows.map(item => ({
        ...item,
        name: item.key === 'footer' ? t(item.name) : item.name
      }))
    }
  };

  const { addElementExportWidget } = useContext(ContextExportDashboards);
  const prevBodyRef = useRef(null);

  useLayoutEffect(() => {
    if (prevBodyRef.current !== body && (!isEmptyWidgetData && !isWarningWidget)) {
      prevBodyRef.current = body;
      addElementExportWidget({ ...prevBodyRef.current, id });
    }
  }, [body]);

  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <SRow type="flex" justify="space-between" align="top">
              <SCol span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type,
                    showDateMode: true
                  })}
                </TimePeriod>
              </SCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e =>
                        dispatch(
                          updateDashboardWidget({
                            id,
                            type,
                            filters: { ...filters, viewMode: e.target.value }
                          })
                        )
                      }
                      value={filters.viewMode || WIDGET_VIEW_MODES.QUESTIONS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.QUESTIONS.value}>
                        {t(WIDGET_VIEW_MODES.QUESTIONS.title)}
                      </Radio.Button>
                      <Radio.Button value="groups" disabled={!hasGroupable}>
                        {t(WIDGET_VIEW_MODES.GROUPS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
                <WidgetViewLastUpdate
                  widgetData={widgetData}
                  dateLastUpdate={widgetValue?.dateLastUpdate}
                />
              </SCol>
            </SRow>
            <WidgetTable
              columns={tableColumns.filter(item => item.key !== 'unitName')}
              dataSource={tableRows}
              pagination={false}
              size="small"
              bordered
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
            />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableChecklistItemsByOperators;
