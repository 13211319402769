import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  selectedRole: {}
};

export const setSelectedRole = createAction('uiRolesAndPermissions/setSelectedRole');
export const updateSelectedRole = createAction('uiRolesAndPermissions/updateSelectedRole');

export const uiRolesAndPermissionsReducer = createReducer(defaultState, {
  [setSelectedRole]: (state, { payload }) => ({ ...state, selectedRole: payload }),
  [updateSelectedRole]: (state, { payload }) => ({
    ...state,
    selectedRole: { ...state.selectedRole, ...payload }
  })
});
