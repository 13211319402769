import createListReducer from 'core/redux/lists/listReducer';
import { phoneCallsResource } from 'redux/resources/calls';

const defaultState = {
  filters: { direction: 'all' },
  filteredUnits: []
};

const additionalReducer = {
  setUnits: (state, { payload }) => ({
    ...state,
    filteredUnits: payload
  })
};

const { actions, reducer, operations } = createListReducer({
  name: 'calls',
  defaultState,
  additionalReducer,
  rebuildQuery: true,
  loader: phoneCallsResource.operations.load
});

const callsListReducer = reducer;

export { actions, callsListReducer, operations };
