export default {
  registerPage: {
    form: {
      title: 'Registrazione',
      description: 'Puoi iniziare a utilizzare Qolio integrandoti con il CRM',
      or: 'o',
      buttons: {
        login: 'Entrare',
        bitrix: 'Continua tramite Bitrix 24',
        amo: 'Continua tramite AmoCRM',
        email: "Ottieni l'accesso tramite e-mail",
        bitrixPopover: {
          title: "Inserisci l'indirizzo del tuo portale",
          wrongUrl: "L'indirizzo del portale non e' corretto",
          continue: 'Contunuare'
        }
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Efficienza',
          description:
            "Tutti gli istrumenti per il controllo della qualita' e la valutazione della comunicazione sono raccolti su unica piattaforma"
        },
        motivation: {
          title: 'Motivazione',
          description: 'Coinvolgi, sviluppa e motiva i dipendenti'
        },
        simplicity: {
          title: "Semplicita'",
          description:
            "Elimina i fogli di calcolo e strumenti di valutazione e controllo qualita' obsoleti"
        },
        analytics: {
          title: 'Analitica',
          description: "Analizza le metriche di qualita' e trova nuove opportunita' di crescita"
        }
      }
    }
  }
};
