import { reduxTokenAuthReducer } from 'redux-token-auth';
import { combineReducers } from 'redux';

import resourcesReducers from './resourcesReducers';
import uiReducers from './uiReducers';
import entitiesReducers from './entitiesReducers';
import listsReducers from './listsReducers';
import analyticsReducers from './analyticsReducers';

const rootReducer = combineReducers({
  reduxTokenAuth: reduxTokenAuthReducer,
  ...uiReducers,
  ...resourcesReducers,
  ...listsReducers,
  ...entitiesReducers,
  ...analyticsReducers
});

export default rootReducer;
