import { Descriptions, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SCard from 'components/Standard/SCard';
import { useSelector } from 'react-redux';
import { isBoxVersion } from 'core/config';
import { isEqual, isNil, isEmpty } from 'lodash';
import { PLANS_LITERALS } from 'core/utils/constants';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons';
import SText from 'components/Standard/SText';

const { Text, Title } = Typography;

const OrganizationInfo = () => {
  const { t } = useTranslation();

  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);

  const { createdAt, usersCount, monthlyReviewsCount, planSlug } = organizationInfo;

  const isBox =
    isNil(planSlug) || isEmpty(planSlug) || !Object.keys(PLANS_LITERALS).includes(planSlug);

  const ConditionalItem = withConditionalRender({ hide: isBoxVersion }, Descriptions.Item);

  const loading = useSelector(state => state.organizationInfo.loading);

  return (
    <SCol span={24}>
      <SCard shadowed bordered loading={loading}>
        <SRow>
          <SCol span={24}>
            <Title level={5}>{t('userSettingsPage.organization.title')}</Title>
          </SCol>
          <SCol paddingTop="16px">
            <Descriptions column={{ lg: 20, xl: 24, xxl: 24 }}>
              <ConditionalItem
                span={9}
                label={
                  <SText marginTop="1px" type="secondary">
                    {t('userSettingsPage.organization.currentPlanSlug')}
                  </SText>
                }
              >
                <Text strong>
                  {isBox ? t(PLANS_LITERALS.notInstalled.name) : t(PLANS_LITERALS[planSlug].name)}
                </Text>
                <IconButton
                  tooltip={{ title: t('organizationSettingsPage.buttons.paymentPlansInfo') }}
                  button={{
                    icon: <InfoCircleOutlined />,
                    onClick: () => window.open('https://qolio.ru/annual_price'),
                    size: 'icon'
                  }}
                />
              </ConditionalItem>

              <Descriptions.Item
                span={15}
                label={
                  <Text type="secondary">
                    {t('userSettingsPage.organization.registrationDate')}
                  </Text>
                }
              >
                <Text strong>{moment(createdAt).format('DD/MM/YYYY')}</Text>
              </Descriptions.Item>

              <Descriptions.Item
                span={9}
                label={
                  <Text type="secondary">{t('userSettingsPage.organization.registeredUsers')}</Text>
                }
              >
                <Text strong>{usersCount}</Text>
              </Descriptions.Item>

              <Descriptions.Item
                span={15}
                label={
                  <Text type="secondary">
                    {t('userSettingsPage.organization.monthReviewsCount')}
                  </Text>
                }
              >
                <Text strong>{monthlyReviewsCount}</Text>
              </Descriptions.Item>
            </Descriptions>
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default OrganizationInfo;
