export default {
  userSettingsPage: {
    categories: {
      settings: 'Настройки',
      main: 'Основные'
    },
    title: 'Настройки профиля',
    tabs: {
      info: {
        tabName: 'Информация об организации',
        form: {
          registrationDate: 'Дата регистрации',
          companyName: 'Название компании',
          role: 'Роль',
          unit: 'Отдел'
        }
      },
      accountSettings: {
        tabName: 'Настройки аккаунта',
        form: {
          lastName: 'Фамилия',
          firstAndMiddleName: 'Имя',
          email: 'E-mail',
          phoneNumber: 'Номер телефона',
          locale: 'Язык интерфейса'
        },
        avatar: {
          tabName: 'Аватар',
          updaloadAvatarlabel: 'Нажмите, чтобы выбрать фото',
          avatarPreview: 'Предпросмотр: ',
          buttons: {
            loadAvatar: 'Загрузить фото',
            deleteAvatar: 'Удалить фото'
          }
        }
      },
      securitySettings: {
        tabName: 'Настройки доступа',
        form: {
          newPassword: 'Новый пароль',
          newPasswordConfirmation: 'Подтверждение пароля'
        }
      }
    },
    buttons: {
      update: 'Обновить данные'
    },
    messages: {
      dataSuccessfullyUpdated: 'Данные обновлены',
      updateDataFailed: 'Не удалось обновить данные',
      passwordSuccessfullyUpdated: 'Пароль обновлен',
      editingAnotherUsersSettingsPT1: 'Внимание! Вы редактируете настройки пользователя',
      editingAnotherUsersSettingsPT2:
        'для того, чтобы отредактировать свою страницу, перейдите в меню (в правом верхнем углу) и выберите пункт Личный кабинет.',
      form: {
        validateMessages: {
          enterLastName: 'Пожалуйста, введите фамилию',
          enterFirstAndMiddleName: 'Пожалуйста, введите имя',
          enterPassword: 'Пожалуйста, введите ваш пароль',
          enterPasswordConfirmation: 'Пожалуйста, подтвердите ваш пароль',
          passwordMismathing: 'Пароли не совпадают',
          passwordLenghtMismathing: 'Пароль должен быть более 6 символов'
        }
      }
    },
    organization: {
      title: 'Информация об использовании',
      currentPlanSlug: 'Текущий тарифный план',
      registrationDate: 'Дата регистрации',
      registeredUsers: 'Зарегистрировано пользователей',
      monthReviewsCount: 'Количество проверок за текущий месяц'
    }
  }
};
