export default {
  crmEntity: {
    responsible: {
      title: 'Responsible'
    },
    communications: {
      title: 'Communications'
    },
    dateOfApplication: 'Date of application'
  }
};
