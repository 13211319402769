import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  activeRoleId: ''
};

export const setActiveRoleId = createAction('uiNotificationSettings/setActiveRoleId');

export const uiNotificationSettingsReducer = createReducer(defaultState, {
  [setActiveRoleId]: (state, { payload }) => ({
    ...state,
    activeRoleId: payload.id || state.activeRoleId
  })
});
