import createListReducer from 'core/redux/lists/listReducer';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';

export const listParams = {
  name: 'clientInteractionsList',
  defaultState: {
    tasksIds: [],
    reviewsIds: [],
    filters: { direction: 'all', checklistDefinitionsIds: [] },
    customFieldFilters: {}
  },
  additionalReducer: {
    setDefaultState: state => ({ ...state, ...listParams.defaultState }),
    setReviewsIds: (state, { payload }) => ({
      ...state,
      reviewsIds: payload
    }),
    setTasksIds: (state, { payload }) => ({ ...state, tasksIds: payload }),
    clearList: state => ({
      ...state,
      ids: [],
      tasksIds: [],
      reviewsIds: [],
      page: { ...state.page, number: '1' }
    }),
    clearFilters: (state, { payload }) => {
      if (payload) {
        delete state.filters.payload;
      }
      return {
        ...state,
        filters: {}
      };
    },
    setCustomFieldFilters: (state, { payload }) => ({ ...state, customFieldFilters: payload }),
    updateCustomFieldFilters: (state, { payload }) => {
      const [key, value] = Object.entries(payload)[0];

      // Приведение ключа к нижнему регистру для сравнения
      const normalizedKey = key.toLowerCase();

      // Проверяем, существует ли ключ в state.customFieldFilters
      if (normalizedKey in state.customFieldFilters || key in state.customFieldFilters) {
        // Ключ существует обновляем его значение
        return {
          ...state,
          customFieldFilters: {
            ...state.customFieldFilters,
            [normalizedKey]: value
          }
        };
      }

      return {
        ...state,
        customFieldFilters: { ...state.customFieldFilters, ...payload }
      };
    },
    deleteCustomFieldFilters: (state, { payload }) => {
      if (payload) {
        delete state.customFieldFilters[payload];
      }
    }
  },
  rebuildQuery: true,
  loader: clientInteractionsResource.operations.load
};

const { actions, reducer, operations } = createListReducer(listParams);

const clientInteractionsListReducer = reducer;

export { actions, clientInteractionsListReducer, operations };
