import { Button, Col, List, Row, Skeleton, Typography } from 'antd';
import Search from 'components/Inputs/Search';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SList, { SListItem } from 'components/Standard/SList';
import UserPreview from 'components/UserPreview';
import { isEmpty, isEqual, orderBy } from 'lodash';
import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { operations, actions } from 'redux/lists/userAccountBindingsList';

const { Text } = Typography;

const ManageSipuniUserAccounts = forwardRef(
  ({ integrationId, integrationInProcess = false, onOk, okText, okButtonProps = {} }, ref) => {
    const userAccountBindingsByIds = useSelector(
      state => state.userAccountBindingsResource.byIds,
      isEqual
    );
    const { t } = useTranslation();
    const { id: userId } = useParams();
    const [loading, setLoading] = useState(false);
    const [handlingOk, setHandlingOk] = useState(false);
    const [search, setSearch] = useState('');
    const { ids } = useSelector(state => state.userAccountBindingsList, isEqual);

    const userAccounts = useMemo(
      () =>
        isEmpty(search)
          ? ids.map(id => userAccountBindingsByIds[id])
          : orderBy(
              ids.map(id => userAccountBindingsByIds[id]),
              account =>
                (account.lastName + account.firstName || '')
                  ?.toLowerCase()
                  ?.indexOf(search.toLowerCase()) >= 0,
              ['desc']
            ),
      [ids, search]
    );

    const dispatch = useDispatch();

    const loadAndPreselectUsers = async () => {
      setLoading(true);

      await dispatch(
        operations.load({
          pagination: 'false',
          include: 'user.unit,user.role',
          filters: { integrationId }
        })
      );

      setLoading(false);
    };

    useImperativeHandle(ref, () => ({
      load: loadAndPreselectUsers
    }));

    useEffect(() => {
      loadAndPreselectUsers();
      return () => dispatch(actions.clearList());
    }, []);

    const handleOk = async () => {
      setHandlingOk(true);
      try {
        if (onOk) {
          await onOk(ids);
        }
      } catch (error) {
        console.log({ error });
      }

      setHandlingOk(false);
    };

    const renderItem = userAccount => {
      return (
        <List.Item>
          <Col span={24} style={{ padding: '0' }}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={9}>
                <UserPreview user={userAccount} showAvatar disabled />
              </Col>
              <SCol span={9} display="flex" justifyContent="flex-start" flex="auto">
                <Row type="flex" style={{ maxWidth: '100%' }}>
                  <Text className="truncated">{userAccount.integrationUid}</Text>
                </Row>
              </SCol>
            </Row>
          </Col>
        </List.Item>
      );
    };

    if (loading) {
      return (
        <SCard>
          <Skeleton active />
        </SCard>
      );
    }

    return (
      <SCard>
        <Row gutter={[0, 16]} style={{ marginBottom: '-16px' }}>
          <Col span={24}>
            <Row
              align="middle"
              justify="space-between"
              gutter={[16, 16]}
              style={{ margin: '-8px' }}
            >
              <Col>
                <Row align="middle" gutter={[16, 0]}>
                  <Col>
                    <Search onSearch={setSearch} />
                  </Col>
                  <Col>
                    <Text type="secondary">
                      {`${t('integrationsSettingsPage.manageUserAccounts.totalUsers')}: `}
                    </Text>
                    <Text>{userAccounts.length}</Text>
                  </Col>
                </Row>
              </Col>
              <SCol maxWidth="55%">
                <Text>
                  {t('integrationsSettingsPage.sipuniAccountsImported')}
                  <Link to={`/user/${userId}/organization-settings/connections`}>
                    {t('organizationStructure.tabs.connections.title')}
                  </Link>
                </Text>
                {/* TODO: link to intercom */}
                {/* <a href="">Информация о подключении Sipuni к Qolio</a> */}
              </SCol>
              {integrationInProcess && (
                <Col>
                  <Button type="primary" loading={handlingOk} onClick={handleOk} {...okButtonProps}>
                    {isEmpty(okText) ? t('general.continue') : okText}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24}>
            <SListItem borderBottom="1px solid var(--gray-border)">
              <Col span={24} style={{ padding: '0' }}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={9}>
                    <Text strong>
                      {t('integrationsSettingsPage.manageUserAccounts.columns.user')}
                    </Text>
                  </Col>
                  <SCol span={9} display="flex" justifyContent="flex-start" flex="auto">
                    <Text strong>
                      {t('integrationsSettingsPage.manageUserAccounts.columns.id')}
                    </Text>
                  </SCol>
                </Row>
              </Col>
            </SListItem>

            <SList
              renderItem={renderItem}
              dataSource={userAccounts}
              maxHeight="300px"
              overflow="auto"
            />
          </Col>
        </Row>
      </SCard>
    );
  }
);

export default ManageSipuniUserAccounts;
