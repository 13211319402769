import styled from 'styled-components';
import { Col, Select } from 'antd';
import SInput from 'components/Standard/SInput';

const { Search } = SInput;

export const StyledSelect = styled(Select)`
  && {
    width: max-content;
    & .anticon-down {
      color: var(--blue_7) !important;
    }
    &:hover {
      & .ant-select-selection-item {
        & span.ant-typography:not(.ant-typography-secondary) {
          color: var(--blue_4) !important;
        }
      }
      & .anticon-down {
          color: var(--blue_4) !important;
        }
    }
    & > .ant-select-selector {
      & > .ant-select-selection-item {
        padding-right: 14px;
      }
      height: max-content !important;
      & > .ant-select-selection-item {
        &:after {
          display: none;
        }
      }
      & span.ant-typography:not(.ant-typography-secondary) {
        color: var(--blue_7);
      }
    }
    &.ant-select-show-arrow {
      & > .ant-select-arrow {
        height: 20px;
        top: 13%;
        margin-top: 0px;
      }
    }
  }
`;

export const StyledSearch = styled(Search)`
  && {
    padding: 1px;
    margin: auto 1px;
  }
`;

export const StyledCol = styled(Col)`
  &.ant-col {
    padding-top: 6px;
  }
`;
