import { post } from 'redux-bees';

const manuallyStartTranscriptionEndpoints = apiPath => ({
  manuallyStartTranscriptionTranscript: {
    method: post,
    path: `${apiPath}/transcription/transcripte`
  },
  manuallyStartTranscriptionCheck: { method: post, path: `${apiPath}/transcription/check` }
});

export default manuallyStartTranscriptionEndpoints;
