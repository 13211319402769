import { Col, message } from 'antd';
import RegisterFromIntegration from 'components/RegisterFromIntegration';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { syncDataWithAmo } from 'redux/entities/amoIntegration/operations';
import { setCurrentBackgroundJob } from 'redux/entities/backgroundJob/reducer';
import { rolesResource } from 'redux/resources/roles';
import { unitsResource } from 'redux/resources/units';
import { usersResource } from 'redux/resources/users';
import { getRootUnit } from 'redux/selectors/units';
import { getCurrentUser } from 'redux/selectors/users';
import { loadUsers } from 'redux/ui/registerFromIntegration/operations';
import {
  setCurrentStep,
  setSelectedUsersIds,
  updateUser
} from 'redux/ui/registerFromIntegration/reducer';

const SetupOrganizationPage = ({ loadUnits, loadUsers, loadRoles, syncDataWithAmo, history }) => {
  useEffect(() => {
    loadUsers({
      pagination: 'false',
      include: 'role,unit',
      'filters[with_inactive]': 'true'
    });
    loadRoles();
    loadUnits();
  }, []);

  const { t } = useTranslation();

  const onFinishRegister = () => {
    message.info('Вы можете пользоваться приложением');
    history.push('/');
  };

  return (
    <Col span={24}>
      <Helmet>
        <title>{t('pagesMeta.organizationSettingsPage.title')}</title>
      </Helmet>
      <RegisterFromIntegration
        visible
        fetchIntegrationData={syncDataWithAmo}
        onProceed={onFinishRegister}
      />
    </Col>
  );
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  rootUnit: getRootUnit(state),
  users: state.uiRegisterFromIntegration.users,
  oldUsers: state.usersResource.byIds,
  roles: state.rolesResource.byIds,
  selectedUsersIds: state.uiRegisterFromIntegration.selectedUsersIds,
  currentBackgroundJob: state.backgroundJob.currentBackgroundJob,
  currentStep: state.uiRegisterFromIntegration.currentStep,
  syncFrom: state.uiRegisterFromIntegration.syncFrom,
  loading:
    state.usersResource.updateByIdStarted ||
    state.unitsResource.loading ||
    state.unitsResource.loading
});

const mapDispatchToProps = {
  setCurrentBackgroundJob,
  updateUser,
  updateUserById: usersResource.operations.updateById,
  loadRoles: rolesResource.operations.load,
  loadUsers,
  loadUnits: unitsResource.operations.load,
  setSelectedUsersIds,
  setCurrentStep,
  syncDataWithAmo
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetupOrganizationPage));
