import qs from 'qs';

export const stringify = settings => {
  return qs.stringify(settings, {
    arrayFormat: 'comma',
    addQueryPrefix: true,
    skipNulls: true,
    encodeValuesOnly: true
  });
};

export const parse = query => {
  return qs.parse(query, { ignoreQueryPrefix: true, comma: true });
};
