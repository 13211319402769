import React from 'react';
import { ChevronDown } from 'react-feather';
import { Empty, Input, Select } from 'antd';
import styled from 'styled-components';
import SCard from 'components/Standard/SCard';

const widgetSizeToWidgetHeight = {
  small: '7.5rem',
  medium: '29rem',
  large: '29rem',
  extra_large: '35rem'
};

// * widgets, EmptyWidget, EmptyDashboard
export const WidgetCard = styled(props => <SCard rounded bordered {...props} />)`
  &.ant-card > .ant-card-body {
    height: ${widgetSizeToWidgetHeight.medium};
  }
`;

export const LargeWidgetCard = props => <SCard rounded bordered {...props} />;

export const SmallWidgetCard = styled(props => <SCard rounded bordered {...props} />)`
  &.ant-card {
    min-height: 100%;
  }
  &.ant-card > .ant-card-head {
    background-color: ${props => props.headerColor};
    border-radius: 8px 8px 0 0;
  }
`;

export const EmptyWidgetCard = styled(props => <SCard rounded bordered {...props} />)`
  &.ant-card {
    min-height: 100%;
  }
  &.ant-card > .ant-card-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: ${props => widgetSizeToWidgetHeight[props.size]};
    min-height: 100%;
  }
`;

export const StyledEmpty = styled(Empty)`
  &.ant-empty {
    display: flex;
    justify-content: center;
    margin: 0px;
    flex-direction: column;
    height: 100%;
  }
`;

export const StyledErrorMessage = styled.span`
  font-size: 24px;
  display: block;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0.5em;
`;

export const SmallValue = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: ${props => props.color || 'inherit'};
`;

export const Value = styled.span`
  font-size: 32px; // for IE
  font-size: clamp(24px, 2.5vw, 40px);
  font-weight: 500;
  color: ${props => props.color || 'inherit'};
  letter-spacing: ${({ letterSpacing = '-0.1em' }) => letterSpacing};

  ${SmallValue} {
    padding: 0 4px;
  }
`;

export const EmptyDashboardCard = props => (
  <SCard
    rounded
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...props}
  />
);

// * FilterForm, SettingsModal
export const StyledSelect = styled(Select)`
  &.ant-select {
    width: 100%;
    margin: 4px 0px 8px 0px;
  }
`;

export const SettingsButton = styled(ChevronDown)`
  &.anticon:hover {
    color: var(--primary);
  }
  &.anticon:active {
    color: #0061d0;
  }
`;

export const StyledInput = styled(Input)`
  &.ant-input {
    margin: 4px 0px 8px 0px;
  }
`;
