import React, { useEffect, useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import { downloadCallFromMediaUrl } from 'core/api';
import { Spin } from 'antd';
import SCol from '../../Standard/SCol';
import SRow from '../../Standard/SRow';

const PartContentImage = ({ htmlString, pyrusIntegration }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const href = doc.querySelector('a')?.getAttribute('href');

        const response = await fetch(downloadCallFromMediaUrl, {
          method: 'POST',
          body: JSON.stringify({ media_url: href, integration_id: pyrusIntegration?.id })
        });

        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);
        }
      } catch (error) {
        console.error('Ошибка при загрузке изображения:', error);
      }
    };

    fetchImage();
  }, [htmlString, pyrusIntegration]);

  const onClose = e => {
    e.stopPropagation();
    setVisible(false);
  };

  const show = () => {
    setVisible(true);
  };

  return (
    <SCol
      background="var(--gray_primary)"
      borderRadius="16px"
      margin="2px 2px"
      padding="6px 12px !important"
      border="1px solid #c2c4cc"
      onClick={e => {
        e.stopPropagation();
        show();
      }}
      width="fit-content"
    >
      {!imageSrc ? (
        <Spin size="large" tip="Загрузка изображения..." />
      ) : (
        <SRow display="flex" justify="space-between" align="middle" wrap={false}>
          <SCol flex="auto" width="100%" className="truncated">
            {imageSrc && (
              <img
                src={imageSrc}
                alt="Предпросмотр"
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  maxHeight: '200px',
                  objectFit: 'cover'
                }}
              />
            )}
          </SCol>
        </SRow>
      )}
      {visible && (
        <Lightbox
          images={[{ title: 'Изображение', url: imageSrc }]}
          startIndex={0}
          onClose={onClose}
        />
      )}
    </SCol>
  );
};

export default PartContentImage;
