import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { foldersResource } from 'redux/resources/folders';
import { usersResource } from 'redux/resources/users';

export const loadSessionsData = () => {
  return async dispatch => {
    await Promise.all([
      dispatch(
        checklistDefinitionsResource.operations.load({
          pagination: false,
          filters: { status: 'published' }
        })
      ),
      dispatch(usersResource.operations.load({ include: 'role', pagination: false })),
      dispatch(foldersResource.operations.load({ include: 'items.client_interaction.operator' }))
    ]);
  };
};

export const createCalibrationSession = ({ state }) => {};
export const updateCalibrationSession = ({ state }) => {};
