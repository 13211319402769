import { Col, Input, Modal, Radio, Row, Typography, Button, message, Breadcrumb } from 'antd';
import ColorPicker from 'components/Inputs/ColorPicker';
import { isEmpty, isEqual, keyBy } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useParams } from 'react-router-dom';
import { resetConditions, updateTaskDefinition } from 'redux/ui/taskDefinition/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import SRow from 'components/Standard/SRow';
import SCard from 'components/Standard/SCard';
import SText from 'components/Standard/SText';
import SBreadcrumb from 'components/Standard/SBreadcrumb';
import { StyledRadioGroup } from '../styled';

const { confirm } = Modal;
const { Text } = Typography;

// * consider moving to utitls
const fixTaskDefinitionConditionsKeys = taskDefinition => ({
  ...taskDefinition,
  conditions: keyBy(Object.values(taskDefinition.conditions), 'id')
});

const TaskDefinitionsOptions = ({ history, children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: taskDefinitionId } = useParams();

  const taskDefinition = useSelector(state => state.uiTaskDefinition, isEqual);

  const createTaskDefinition = taskDefinitionsResource.operations.create;
  const updateTaskDefinitionById = taskDefinitionsResource.operations.updateById;

  const setName = useCallback(
    ({ target }) => {
      const { value } = target;
      dispatch(updateTaskDefinition({ labelName: value }));
    },
    [dispatch]
  );

  const changeTaskDefinitionType = ({ target }) => {
    const isAllowChangeType =
      taskDefinition?.labelType === 'automatic' && !isEmpty(taskDefinition?.conditions);
    const { value } = target;
    if (isAllowChangeType) {
      confirm({
        title: t('triggersSettingsPage.editTaskDefinition.changeTagTypeConfirm.title'),
        content: t('triggersSettingsPage.editTaskDefinition.changeTagTypeConfirm.description'),
        okText: t('triggersSettingsPage.editTaskDefinition.changeTagTypeConfirm.ok'),
        okType: 'danger',
        cancelText: t('triggersSettingsPage.editTaskDefinition.changeTagTypeConfirm.cancel'),
        onOk: () => {
          dispatch(resetConditions());
          dispatch(
            updateTaskDefinition({
              labelType: value,
              checklistDefinitionId: ''
            })
          );
        }
      });
    } else {
      dispatch(updateTaskDefinition({ labelType: value }));
    }
  };

  const onSave = async () => {
    const result = taskDefinitionId
      ? await dispatch(
          updateTaskDefinitionById(
            fixTaskDefinitionConditionsKeys({
              ...taskDefinition
            })
          )
        )
      : await dispatch(
          createTaskDefinition({
            ...taskDefinition
          })
        );

    if (result) {
      message.success(
        taskDefinitionId
          ? t('triggersSettingsPage.editTaskDefinition.messages.tagSuccessfullyUpdated')
          : t('triggersSettingsPage.editTaskDefinition.messages.tagSuccessfullySaved')
      );
      history.goBack();
    }
  };

  const title = isEmpty(taskDefinition?.id)
    ? t('triggersSettingsPage.card.titles.create')
    : t('triggersSettingsPage.card.titles.edit');

  return (
    <>
      <SCard rounded={false}>
        <SBreadcrumb fontSize="14px" lineHeight="20px" fontWeight={500}>
          <SText color="#5f80ef">
            <Breadcrumb.Item href="/settings/triggers">{t('general.tags')}</Breadcrumb.Item>
          </SText>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </SBreadcrumb>

        <SRow type="  flex" align="middle" justify="space-between" gutter={[8, 0]}>
          <Col flex="auto">
            <SText ellipsis fontSize="21px" fontWeight="500">
              {title}
            </SText>
          </Col>
          <Col flex="none">
            <Button onClick={history.goBack}>
              {t('triggersSettingsPage.editTaskDefinition.buttons.cancel')}
            </Button>
          </Col>
          <Col flex="none">
            <Button
              type="primary"
              onClick={onSave}
              disabled={!taskDefinition?.labelName || !taskDefinition?.labelColor}
            >
              {t('triggersSettingsPage.editTaskDefinition.buttons.save')}
            </Button>
          </Col>
        </SRow>
      </SCard>

      <SCard margin="16px">
        <Row align="top" type="flex" justify="space-between" gutter={[8, 8]}>
          <Col span={18}>
            <Row gutter={[8, 8]}>
              <Col flex="none">
                <ColorPicker />
              </Col>

              <Col flex="auto">
                <div>{t('triggersSettingsPage.editTaskDefinition.form.name')}</div>
                <Input
                  style={{ width: 300 }}
                  placeholder={t('triggersSettingsPage.editTaskDefinition.form.namePlaceholder')}
                  onChange={setName}
                  value={taskDefinition?.labelName}
                />
              </Col>

              <Col>
                <SRow gutter={[8, 8]}>
                  <Col span={24}>
                    <div>{t('triggersSettingsPage.editTaskDefinition.form.type.title')}</div>
                    <StyledRadioGroup
                      onChange={changeTaskDefinitionType}
                      value={taskDefinition?.labelType}
                    >
                      <Radio value="automatic">
                        <Text strong>
                          {t('triggersSettingsPage.editTaskDefinition.form.type.automatic.name')}
                        </Text>
                        {t(
                          'triggersSettingsPage.editTaskDefinition.form.type.automatic.description'
                        )}
                      </Radio>
                      <Radio value="manual">
                        <Text strong>
                          {t('triggersSettingsPage.editTaskDefinition.form.type.manual.name')}
                        </Text>
                        {t('triggersSettingsPage.editTaskDefinition.form.type.manual.description')}
                      </Radio>
                    </StyledRadioGroup>
                  </Col>
                </SRow>
              </Col>
            </Row>
          </Col>
        </Row>
        {children}
      </SCard>
    </>
  );
};

export default withRouter(TaskDefinitionsOptions);
