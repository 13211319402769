import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import {
  getChecklistDefinitionQuestions,
  getChecklistDefinitionQuestionGroups
} from 'redux/selectors/checklistItems/checklistItems';
import * as actions from './reducer';

export const setEditableChecklistDefinition = ({ id }) => async (dispatch, getState) => {
  const currentChecklist =
    (await dispatch(
      checklistDefinitionsResource.operations.loadById({
        id,
        include: 'question_groups.question_to_group_bindings.question'
      })
    )) || {};

  const state = getState();
  const currentChecklistQuestionGroups = getChecklistDefinitionQuestionGroups(
    state,
    currentChecklist
  );
  const currentChecklistQuestions = getChecklistDefinitionQuestions(state, currentChecklist);

  await dispatch(
    actions.setCurrentChecklist({
      currentChecklist,
      currentChecklistQuestionGroups,
      currentChecklistQuestions
    })
  );
};
