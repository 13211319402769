export default {
  bitrixIntegrationPage: {
    clientInteractionPreview: {
      warningModal: {
        title: 'Atención!',
        description: 'Esta llamada no está ligada a ningún usuario'
      },
      buttons: { openInApp: 'Abrir en la aplicación' },
      directions: {
        incoming: 'Entrante',
        outcoming: 'Saliente',
        notInstalledType: 'Tipo no definido'
      },
      columns: {
        unistalledTime: 'El tiempo no está definido',
        reviewed: 'Evaluado',
        unReviewed: 'No evaluado'
      }
    },
    clientInteractions: {
      empty: {
        linkText: 'Detalles de la evaluación',
        description: 'En esta tarjeta no hay comunicación disponible para evaluar'
      },
      columns: {
        operator: 'Operador',
        direction: 'Dirección',
        date: 'Fecha',
        duration: 'Duración',
        status: 'Estatus'
      }
    },
    reviewPreview: {
      menu: { openInApp: 'Abrir en la aplicación' },
      directions: {
        incoming: 'Entrante',
        outcoming: 'Saliente',
        notInstalledType: 'El tipo no se ha determinado'
      },
      columns: { unistalledTime: 'La hora no se ha determinado' }
    },
    review: {
      empty: {
        linkText: 'Detalles de la evaluación',
        description: 'Usted no ha añadido la evaluación de la comunicación en esta tarjeta'
      },
      columns: {
        operator: 'Operador',
        direction: 'Dirección',
        date: 'Fecha',
        duration: 'Duración',
        comments: 'Comentario',
        score: 'Evaluación',
        author: 'Autor de la evaluación'
      }
    }
  }
};
