export const isIntegrated = () => {
  const isEmbedded = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const embedded = isEmbedded();
  const { amo } = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  return embedded && amo;
};

export const isOpenedInNote = () => {
  const { noteId } = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  return isIntegrated() && noteId;
};

const createMessageListener = (expectedCommand = '', callback = () => {}) => {
  const listener = async e => {
    try {
      const { command, params = {}, callbackId } = e.data;
      if (command === expectedCommand) {
        const callbackArgs = await callback(params);
        callbackId && e.source.postMessage({ callbackId, args: callbackArgs || {} }, '*');
      }
    } catch (error) {
      console.log(error);
    }
  };

  window[`on${expectedCommand}`] = listener;
  return listener;
};

export const initMessageListener = ({ command, callback }) => {
  window.addEventListener('message', createMessageListener(command, callback));
};

export const removeMessageListener = ({ command }) => {
  window.removeEventListener('message', window[`on${command}`]);
};

export const sendMessage = ({ command, params }) => {
  try {
    if (window.opener !== null) window.opener.postMessage({ command, params }, '*');
  } catch (error) {
    console.log('# message not sended', error);
  }
};

const AmoService = {
  isIntegrated,
  isOpenedInNote,
  initMessageListener,
  removeMessageListener,
  sendMessage
};

export default AmoService;
