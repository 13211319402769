import React from 'react';
import { Col } from 'antd';
import { get, isEmpty, isEqual, orderBy } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { useSelector } from 'react-redux';
import truncateString from 'core/utils/truncateString';
import { tooltipSetter } from '../../utils';
import { WidgetCard } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const PhoneCallsCountByUnits = ({
  widgetData,
  colors,
  keys,
  stacked,
  loadingWidgetId,
  isWarningWidget
}) => {
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const { id, type, filters, name } = widgetData;
  let widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);
  const lastItem = Array.isArray(widgetValue) ? widgetValue[widgetValue.length - 1] : null;
  const dateLastUpdate = lastItem ? lastItem.dateLastUpdate : null;

  if (Array.isArray(widgetValue) && lastItem) {
    widgetValue = widgetValue.filter((item, idx) => idx !== widgetValue.length - 1);
  }

  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={6} key={id}>
          <WidgetCard>
            <SMLWidgetHeader
              name={name}
              type={type}
              widgetData={widgetData}
              filters={filters}
              dateLastUpdate={dateLastUpdate}
            />

            <ResponsiveBar
              data={orderBy(widgetValue, 'name', 'desc')}
              keys={stacked ? keys : ['value']}
              indexBy="name"
              margin={{ top: 0, right: 10, bottom: 70, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              tooltip={tooltip => tooltipSetter(tooltip.data)}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => (d.toString().match(/\./) ? '' : d)
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 16)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default PhoneCallsCountByUnits;
