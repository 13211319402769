import { MessageFilled } from '@ant-design/icons';
import { FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import React from 'react';
import styled from 'styled-components';

const StandardComment = props => <CommentIcon {...props} />;

const CommentIcon = styled(MessageFilled)`
  &.anticon {
    color: ${FLAGS_TO_COLORS_RELATIONS.without_flag};
  }
  ${props => props.css}
`;

export default StandardComment;
