import { get, put, destroy, post } from 'redux-bees';

const folderItemsEndpoints = apiPath => ({
  getFolderItems: { method: get, path: `${apiPath}/folders/:folder_id/items` },
  createFolderItem: { method: post, path: `${apiPath}/folders/:folder_id/items` },
  deleteFolderItem: { method: destroy, path: `${apiPath}/folders/:folder_id/items/:id` },
  updateFolderItem: { method: put, path: `${apiPath}/folders/:folder_id/items/:id` }
});

export default folderItemsEndpoints;
