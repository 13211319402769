import { createAction, createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

const defaultState = {
  conditions: {},
  labelType: 'automatic'
};

export const setTaskDefinitionCondititon = createAction(
  'uiTaskDefinition/setTaskDefinitionCondititon'
);
export const updateTaskDefinitionCondititon = createAction(
  'uiTaskDefinition/updateTaskDefinitionCondititon'
);
export const updateTaskDefinition = createAction('uiTaskDefinition/updateTaskDefinition');
export const deleteTaskDefinitionCondition = createAction(
  'uiTaskDefinition/deleteTaskDefinitionCondition'
);
export const loadTaskDefinitionById = createAction('uiTaskDefinition/loadTaskDefinitionById');
export const resetUiTaskDefinition = createAction('uiTaskDefinition/resetUiTaskDefinition');
export const resetConditions = createAction('uiTaskDefinition/resetConditions');
export const setScoreFrom = createAction('uiTaskDefinition/setScoreFrom');
export const setScoreTo = createAction('uiTaskDefinition/setScoreTo');

export const uiTaskDefinitionReducer = createReducer(defaultState, {
  [loadTaskDefinitionById]: (state, { payload }) => ({
    ...state,
    ...payload,
    conditions: payload?.conditions ? keyBy(Object.values(payload.conditions), 'id') : {}
  }),
  [resetUiTaskDefinition]: () => ({
    conditions: {},
    labelType: 'automatic'
  }),
  [resetConditions]: state => ({
    ...state,
    conditions: {}
  }),
  [setTaskDefinitionCondititon]: (state, { payload }) => ({
    ...state,
    conditions: {
      ...state.conditions,
      [payload.id]: payload
    }
  }),
  [updateTaskDefinitionCondititon]: (state, { payload }) => ({
    ...state,
    conditions: {
      ...state.conditions,
      [payload.id]: { ...state.conditions[payload.id], ...payload }
    }
  }),
  [updateTaskDefinition]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [deleteTaskDefinitionCondition]: (state, { payload }) => {
    const conditions = { ...state.conditions };
    delete conditions[payload.id];
    return { ...state, conditions };
  }
});
