import { List } from 'antd';
import styled from 'styled-components';
import { flexbox, space, layout, color, border } from 'styled-system';

export default styled(List)`
&.ant-list{
    ${flexbox}
    ${space}
    ${layout}
    ${color}
}

`;

export const SListItem = styled(List.Item)`
&.ant-list-item {
    &:hover {
        ${({ _hover }) => _hover}
    } 
    ${flexbox}
    ${space}
    ${layout}
    ${color}
    ${border}
}   
`;
