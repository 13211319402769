import { isEmpty, isEqual } from 'lodash';
import { statusesResource } from 'redux/resources/status';
import * as actions from './reducer';

const createStatusFromUi = statusFromUi => async dispatch => {
  const status = await dispatch(statusesResource.operations.create(statusFromUi));
  await dispatch(actions.deleteStatus(statusFromUi));
  await dispatch(actions.addStatus(status));
};

export const saveStatuses = ({ statusesByIds }) => async (dispatch, getState) => {
  const state = getState();
  const oldStatuses = Object.values(state.statusesResource.byIds);
  const newStatusesByIds = { ...statusesByIds };

  // * running all requests in parallel
  await Promise.all([
    ...oldStatuses.map(status => {
      // * delete old comments that deleted on ui
      if (isEmpty(newStatusesByIds[status.id])) {
        delete newStatusesByIds[status.id];
        return dispatch(statusesResource.operations.deleteById({ id: status.id }));
      }

      // * update old comments that updated on ui
      const newStatus = { ...status, ...newStatusesByIds[status.id] };
      delete newStatusesByIds[status.id];
      // * check if comment needs update
      if (!isEqual(status, newStatus)) {
        dispatch(actions.updateStatus({ ...newStatus }));
        return dispatch(statusesResource.operations.updateById(newStatus));
      }
    }),
    // * create new comments
    ...Object.values(newStatusesByIds).map(status => dispatch(createStatusFromUi(status)))
  ]);
};
