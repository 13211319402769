export default {
  autoFailScoresPage: {
    buttonAddReport: {
      dynamicCriticalErrors: {
        header: 'The dynamics of auto fails',
        description:
          'Shows the number of communications, number and percentage of checks, number and percentage of critical errors over time. There are two display options: a table and a graph.'
      },
      criticalErrorsCriteria: {
        header: 'Criteria with auto fails',
        description:
          'Shows the percentage of critical errors by criteria. There are two display options: employees and departments. The report displays only those criteria for which critical errors are set in the evaluation form.'
      }
    }
  }
};
