import { Folder, PlusCircle } from 'react-feather';
import { Button, Col, Menu, Radio, Row } from 'antd';
import SCard from 'components/Standard/SCard';
import { DATE_PICKER_MODES, SESSIONS_DIRECTIONS } from 'core/utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { updateCalibrationSessionSettingsForm } from 'redux/ui/calibrationSessionSettingsModal/reducer';
import { setTimeRange } from 'redux/ui/calibrationsPage/reducer';
import styled from 'styled-components';
import uniqid from 'uniqid';
import Icon from 'components/Icon';
import CustomDatePicker from 'components/DatePicker/DatePicker';

const CalibrationsFilters = ({ tab, history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const timeRange = useSelector(state => state.uiCalibrationsPage.timeRange);

  const onChangeTab = e => {
    history.push(`${e.target.value}`);
  };

  return (
    <SCard rounded={false}>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <Row type="flex">
            <Col>
              <Radio.Group value={tab} onChange={onChangeTab}>
                {Object.values(SESSIONS_DIRECTIONS).map(item => (
                  <Radio.Button key={item.value} value={item.value}>
                    {t(item.title)}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Col>
            <Col>
              <StyledMenu mode="horizontal" selectedKeys={[tab]}>
                <Menu.Item key="folders">
                  <Link to="/calibrations/folders">
                    <Icon icon={Folder} />
                    <span>{t('calibrationsPage.buttons.viewFolderItems')}</span>
                  </Link>
                </Menu.Item>
              </StyledMenu>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end" gutter={[8, 0]} style={{ marginBottom: '-4px' }}>
            <Col span={10}>
              <CustomDatePicker
                onChange={filters => {
                  dispatch(setTimeRange({ ...filters }));
                }}
                value={{
                  symbolicTimeRange: timeRange?.calibrationSessionSymbolicTimeRange,
                  timeFrom: timeRange?.calibrationSessionTimeFrom,
                  timeTo: timeRange?.calibrationSessionTimeTo
                }}
                position="left"
                mode={DATE_PICKER_MODES.custom.calibrationSession}
                allowClear
              />
            </Col>
            <Col span={10}>
              <Button
                block
                type="primary"
                icon={<Icon icon={PlusCircle} />}
                onClick={() => dispatch(updateCalibrationSessionSettingsForm({ id: uniqid() }))}
              >
                {t('calibrationsPage.buttons.createSession')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </SCard>
  );
};

const StyledMenu = styled(Menu)`
  &&.ant-menu-horizontal {
    display: flex;
    align-items: center;
    border: none;
    line-height: 16px;
    height: 100%;

    & > .ant-menu-item-active,
    & > .ant-menu-item:hover,
    & > .ant-menu-item-selected {
      border: none !important;
    }

    & > li {
      border: transparent;
    }
  }
`;

export default withRouter(CalibrationsFilters);
