import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  prompt: {}
};

export const setEditingPrompt = createAction('uiPromptModal/editing');
export const setEditingPromptWithBinding = createAction(
  'uiPromptModal/setEditingPromptWithBinding'
);
export const updateEditingPrompt = createAction('uiPromptModal/update');
export const uiPromptModalReducer = createReducer(defaultState, {
  [setEditingPrompt]: (state, { payload }) => ({ ...state, prompt: payload }),
  [setEditingPromptWithBinding]: (state, { payload }) => ({
    ...state,
    prompt: payload.prompt
  }),
  [updateEditingPrompt]: (state, { payload }) => ({
    ...state,
    prompt: { ...state.prompt, ...payload }
  })
});

export default uiPromptModalReducer;
