import { destroy, get, post, put } from 'redux-bees';

const communicationTablesEndpoints = apiPath => ({
  getCommunicationTables: { method: get, path: `${apiPath}/communications_tables` },
  getCommunicationTable: { method: get, path: `/api/v3/communication_table/get` },
  // getCommunicationTable: { method: get, path: `/api/v2/communications_tables/:id` },
  createCommunicationTable: { method: post, path: `${apiPath}/communications_tables` },
  updateCommunicationTable: { method: put, path: `/api/v2/communications_tables/:id` },
  deleteCommunicationTable: { method: destroy, path: `${apiPath}/communications_tables/:id` },
  shareCommunicationTable: { method: post, path: `${apiPath}/communications_tables/:id/share` },
  getEmptyCommunicationTable: {
    method: get,
    path: `/api/v2/communications_tables/with_empty_communication_table`
  }
});

export default communicationTablesEndpoints;
