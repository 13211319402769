import React from 'react';
import { flatMap, head, intersectionWith, isEmpty, pickBy } from 'lodash';
import { selectSearch } from 'core/utils/selectSearch';
import { Col, Select } from 'antd';
import { getCustomFieldsByIds } from 'redux/selectors/customFields';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions } from 'redux/lists/clientInteractionsList';
import { FilterContainer, StyledSelect } from '../../ClientInteractionsPage/components/styled';

const { Option, OptGroup } = Select;

const getCustomFieldNameByKey = ({ customFieldsByIds, key }) =>
  head(Object.values(pickBy(customFieldsByIds, { key })))?.name;

const FunnelStatusAndStage = ({
  salePipelinesByIds,
  salePipelines,
  statusesByIds,
  customFieldsByIds,
  updateFilters,
  filters
}) => {
  const { t } = useTranslation();
  const salesPipelinePlaceholder =
    getCustomFieldNameByKey({
      customFieldsByIds,
      key: 'sales_pipeline'
    }) || t('clientInteractionsPage.tableFilters.salesPipelines.salesPipelinesPlaceholder');

  const filterSalePipelines = pipelinesIds => {
    const allowedPipelinesStatusesIds = flatMap(
      salePipelines.filter(({ id }) => pipelinesIds.includes(id)),
      'statusesIds'
    );

    updateFilters({
      pipelinesIds,
      pipelineStatusesIds:
        isEmpty(filters.pipelineStatusesIds) || isEmpty(pipelinesIds)
          ? filters.pipelineStatusesIds
          : filters.pipelineStatusesIds.filter(statusId => {
              return allowedPipelinesStatusesIds.includes(statusId);
            })
    });
  };

  const selectedSalePipelines = intersectionWith(
    salePipelines,
    isEmpty(filters.pipelinesIds) ? Object.keys(salePipelinesByIds) : filters.pipelinesIds,
    ({ id }, filterId) => id === filterId
  );

  const salesPipelineStatusPlaceholder =
    getCustomFieldNameByKey({
      customFieldsByIds,
      key: 'sales_pipeline_status'
    }) || t('clientInteractionsPage.tableFilters.salesPipelines.salePipelineStatusesPlaceholder');

  return (
    <Col span={24}>
      {!isEmpty(salePipelines) && (
        <FilterContainer>
          <StyledSelect
            mode="multiple"
            disabled={false}
            showSearch
            allowClear
            optionLabelProp="label"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${salesPipelinePlaceholder}: ${selectedKeys.length}`
            }
            placeholder={salesPipelinePlaceholder}
            value={filters.pipelinesIds || undefined}
            onChange={filterSalePipelines}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {salePipelines.map(({ name, id }) => (
              <Option value={id} key={id} label={name}>
                {name}
              </Option>
            ))}
          </StyledSelect>
        </FilterContainer>
      )}

      {!isEmpty(statusesByIds) && (
        <FilterContainer>
          <StyledSelect
            disabled={false}
            mode="multiple"
            style={{ width: '100%' }}
            optionLabelProp="label"
            allowClear
            placeholder={salesPipelineStatusPlaceholder}
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${salesPipelineStatusPlaceholder}: ${selectedKeys.length}`
            }
            onChange={pipelineStatusesIds => updateFilters({ pipelineStatusesIds })}
            value={filters.pipelineStatusesIds || []}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {selectedSalePipelines.map(({ id, name, statusesIds = [] }) => (
              <OptGroup key={id} label={name}>
                {statusesIds.reduce((acc, id) => {
                  const status = statusesByIds[id];
                  return status
                    ? [
                        ...acc,
                        <Option value={status.id} key={status.id} label={status.name}>
                          {status.name}
                        </Option>
                      ]
                    : acc;
                }, [])}
              </OptGroup>
            ))}
          </StyledSelect>
        </FilterContainer>
      )}
    </Col>
  );
};

const mapStateToProps = state => {
  const { filters } = state.clientInteractionsList;
  const { byIds: salePipelinesByIds } = state.salePipelinesResource;
  const { byIds: statusesByIds } = state.salesPipelineStatusesResource;
  const customFieldsByIds = getCustomFieldsByIds(state, true);

  return {
    filters,
    salePipelinesByIds,
    salePipelines: Object.values(salePipelinesByIds),
    statusesByIds,
    customFieldsByIds
  };
};

const mapDispatchToProps = {
  updateFilters: actions.updateFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelStatusAndStage);
