import { Table } from 'antd';
import styled from 'styled-components';

export default styled(Table)`
  &.ant-table-wrapper th {
    background-color: white !important;
  }
  &.ant-table-wrapper .ant-table {
    border: none;
  }
  &.ant-table-wrapper .ant-table .ant-table-header tr .ant-table-cell-scrollbar {
    display: none;
  }
  &.ant-table-wrapper .ant-table .ant-table-header table {
    background-color: white;
    border-right: 1px solid white;
  }
  &.ant-table-wrapper td {
    cursor: pointer;
    border-bottom: ${props => (props.border ? '1px solid inherit' : 'none')};
  }
  &.ant-table-wrapper .ant-table .ant-table-thead .ant-table-column-sorters {
    justify-content: flex-start;
  }

  &.ant-table-wrapper .ant-table .ant-table-thead .ant-table-column-sorters span {
    margin-right: 6px;
  }
`;
