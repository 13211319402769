import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  isVisibleEmployeeModal: false,
  isEditMode: false,
  userId: undefined
};

export const toogleVisibleEmployeeModal = createAction('uiEmployee/toogleVisibleEmployeeModal');
export const updateEmployeeModal = createAction('uiEmployee/updateEmployeeModal');
export const resetEmployeeModal = createAction('uiEmployee/resetEmployeeModal');

export const uiEmployeeModalReducer = createReducer(defaultState, {
  [toogleVisibleEmployeeModal]: state => ({
    ...state,
    isVisibleEmployeeModal: !state.isVisibleEmployeeModal
  }),

  [updateEmployeeModal]: (state, { payload }) => ({
    isVisibleEmployeeModal: true,
    ...payload
  }),

  [resetEmployeeModal]: () => ({
    isVisibleEmployeeModal: false,
    ...defaultState
  })
});
