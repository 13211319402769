import { useSelections } from 'ahooks';
import { Col, Row, Tooltip, Typography } from 'antd';
import Search from 'components/Inputs/Search';
import STable from 'components/Standard/STable';
import UserPreview from 'components/UserPreview';
import { isEmpty, orderBy, reduce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';
import { setSelectedUsersIds } from 'redux/ui/registerFromIntegration/reducer';

const { Text } = Typography;

const SelectUsers = () => {
  const dispatch = useDispatch();
  const { id: routeIntegrationId } = useParams();
  const stateIntegrationId = useSelector(state => state.uiRegisterFromIntegration.integrationId);
  const integrationId = stateIntegrationId || routeIntegrationId;
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const { selected, setSelected } = useSelections(
    accounts.map(({ id }) => id),
    []
  );
  const [matchesAndCollisions, setMatchesAndCollisions] = useState({
    userAccountBindingsIdsWithCollisionsOnCreate: [],
    userAccountBindingsIdsWithMatches: []
  });

  const loadAndPreselectAccounts = async () => {
    setLoading(true);

    const { resources: accountsByIds = {} } = await dispatch(
      userAccountBindingsResource.operations.load({
        pagination: 'false',
        include: 'user.unit,user.role',
        filters: { integrationId },
        id: integrationId
      })
    );

    const matchesAndCollisions = await dispatch(
      userAccountBindingsResource.operations.getMatchesAndCollisions({ id: integrationId }, {})
    );

    setMatchesAndCollisions(matchesAndCollisions);
    setAccounts(Object.values(accountsByIds));
    const ids = reduce(
      accountsByIds,
      (acc, userAccount) => {
        if (
          !matchesAndCollisions.userAccountBindingsIdsWithCollisionsOnCreate.includes(
            userAccount.id
          )
        ) {
          return [...acc, userAccount.id];
        }
        return acc;
      },
      []
    );
    setSelected(ids);
    dispatch(setSelectedUsersIds(ids));
    setLoading(false);
  };

  useEffect(() => {
    setAccounts(
      isEmpty(search)
        ? accounts
        : orderBy(
            accounts,
            account =>
              (account.lastName + account.firstName || '')
                ?.toLowerCase()
                ?.indexOf(search.toLowerCase()) >= 0,
            ['desc']
          )
    );
  }, [search]);

  useEffect(() => {
    loadAndPreselectAccounts();
  }, []);

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      render: (text, userAccount) => {
        if (
          matchesAndCollisions.userAccountBindingsIdsWithCollisionsOnCreate.includes(userAccount)
        ) {
          return (
            <Tooltip
              title={`Пользователь с таким email (${userAccount.email}) уже зарегистрирован в системе.`}
            >
              <Text type="danger">{text}</Text>
            </Tooltip>
          );
        }
        return <UserPreview user={userAccount} showAvatar disabled />;
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 250
    },
    {
      title: 'ID',
      dataIndex: 'integrationUid',
      width: 250
    }
  ];

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selected,
    onChange: selectedRowKeys => {
      setSelected(selectedRowKeys);
      dispatch(setSelectedUsersIds(selectedRowKeys));
    },
    getCheckboxProps: userAccount => ({
      disabled: matchesAndCollisions.userAccountBindingsIdsWithCollisionsOnCreate.includes(
        userAccount.id
      ), // Column configuration not to be checked
      name: userAccount.id
    })
  };

  return (
    <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
      <Col span={24}>
        <Search onSearch={setSearch} />
      </Col>
      <Col span={24}>
        <STable
          pagination={false}
          rowKey="id"
          loading={loading}
          dataSource={accounts}
          columns={columns}
          rowSelection={rowSelection}
        />
      </Col>
    </Row>
  );
};

export default SelectUsers;
