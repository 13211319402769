import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { dashboardRowsResource } from 'redux/resources/dashboardRows';
import { toogleVisibleRowsPreview } from 'redux/ui/dashboard/reducer';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import SIXS from './RowsPreview/SIXS';
import FOURM from './RowsPreview/FOURM';
import LMM from './RowsPreview/LMM';
import MML from './RowsPreview/MML';
import MLM from './RowsPreview/MLM';
import LL from './RowsPreview/LL';
import XL from './RowsPreview/XL';
import { CreateButton, LimitCountDashboard } from './styled';

const AddRow = ({
  createNewRow,
  activeDashboardId,
  dashboardsByIds,
  dashboardRowsByIds,
  toogleVisibleRowsPreview,
  isVisibleRowsPreview
}) => {
  const rowsCount = get(dashboardsByIds, `${activeDashboardId}.rowsIds`, []).filter(
    row => dashboardRowsByIds[row]
  );

  const { t } = useTranslation();

  const { Text } = Typography;

  return (
    <>
      {rowsCount.length >= 10 ? (
        <LimitCountDashboard
          message={
            <>
              <Text>{t('dashboardPage.dashboard.drawer.dashboards.messages.rowsLimit')}</Text>
            </>
          }
          type="info"
        />
      ) : (
        <CreateButton block onClick={() => toogleVisibleRowsPreview()}>
          {t('dashboardPage.dashboard.drawer.rows.buttons.addRow')}
        </CreateButton>
      )}

      {isVisibleRowsPreview ? (
        <>
          <SIXS
            activeDashboardId={activeDashboardId}
            createNewRow={createNewRow}
            rowsCount={rowsCount.length}
            setCollapse={toogleVisibleRowsPreview}
          />
          <FOURM
            activeDashboardId={activeDashboardId}
            createNewRow={createNewRow}
            rowsCount={rowsCount.length}
            setCollapse={toogleVisibleRowsPreview}
          />
          <LMM
            activeDashboardId={activeDashboardId}
            createNewRow={createNewRow}
            rowsCount={rowsCount.length}
            setCollapse={toogleVisibleRowsPreview}
          />
          <MML
            activeDashboardId={activeDashboardId}
            createNewRow={createNewRow}
            rowsCount={rowsCount.length}
            setCollapse={toogleVisibleRowsPreview}
          />
          <MLM
            activeDashboardId={activeDashboardId}
            createNewRow={createNewRow}
            rowsCount={rowsCount.length}
            setCollapse={toogleVisibleRowsPreview}
          />
          <LL
            activeDashboardId={activeDashboardId}
            createNewRow={createNewRow}
            rowsCount={rowsCount.length}
            setCollapse={toogleVisibleRowsPreview}
          />
          <XL
            activeDashboardId={activeDashboardId}
            createNewRow={createNewRow}
            rowsCount={rowsCount.length}
            setCollapse={toogleVisibleRowsPreview}
          />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  return {
    activeDashboardId: state.uiDashboard.activeDashboardId,
    isVisibleRowsPreview: state.uiDashboard.isVisibleRowsPreview,
    dashboardsByIds: state.dashboardsResource.byIds,
    dashboardRowsByIds: state.dashboardRowsResource.byIds
  };
};

const mapDispatchToProps = {
  createNewRow: dashboardRowsResource.operations.createNewRow,
  toogleVisibleRowsPreview
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRow);
