import camelcaseKeys from 'camelcase-keys-deep';
import { normalizeById } from 'core/utils';
import { get, has, isArray, isNull, omit, transform } from 'lodash';
// processJsonApiObject()

// export processJsonApiObject = (json)

const nullOrId = value => (isNull(value) ? null : value.id);

export const processJsonApiRelationships = relationships => {
  return transform(
    camelcaseKeys(relationships),
    (result, value, key) => {
      const data = get(value, 'data', {});
      const newKey = isArray(data) ? `${key}Ids` : `${key}Id`;
      const newValue = isArray(data) ? data.map(nullOrId) : nullOrId(data);
      result[newKey] = newValue;
      return result;
    },
    {}
  );
};

export const loadIncludedObjects = ({ included, relationshipLoaders, dispatch }) => {
  for (const relationshipName in relationshipLoaders) {
    const objects = processJsonApiIncludedObjects({
      included,
      type: relationshipName
    });
    dispatch(relationshipLoaders[relationshipName](objects));
  }
};

export const processJsonApiCollection = (entities, options = {}) => {
  const idToEntitiesMapping = normalizeById(entities, processJsonApiObject);
  return idToEntitiesMapping;
};

export const processJsonApiIncludedObjects = ({ type, included }) => {
  const includedObjects = included || [];
  const filteredObjects = includedObjects.filter(object => object.type === type);
  return processJsonApiCollection(filteredObjects);
};

const getCustomFieldFiltersPath = attributes => {
  if (has(attributes, 'custom_field_filters')) return 'custom_field_filters';
  if (has(attributes, 'custom-field-filters')) return 'custom-field-filters';
  return null;
};

const getCustomFieldsPath = attributes => {
  if (has(attributes, 'custom_fields')) return 'custom_fields';
  if (has(attributes, 'custom-fields')) return 'custom-fields';
  return null;
};

const getPropsLikeCustomField = attributes => {
  const path = getCustomFieldFiltersPath(attributes);

  const customFieldFiltersTmp = get(attributes, path, !isNull(path) ? {} : null);

  const isJsonStr = str => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  let customFieldFilters = null;
  let parsedString = null;
  if (isJsonStr(customFieldFiltersTmp)) {
    parsedString = JSON.parse(customFieldFiltersTmp);
  }

  if (!isNull(customFieldFiltersTmp))
    customFieldFilters = Object.keys(parsedString || customFieldFiltersTmp)?.reduce(
      (prevObject, fieldName) => {
        return {
          ...prevObject,
          [fieldName]: camelcaseKeys(customFieldFiltersTmp[fieldName])
        };
      },
      {}
    );

  const customFieldsPath = getCustomFieldsPath(attributes);

  const customFields = get(
    attributes,
    customFieldsPath,
    has(attributes, customFieldsPath) ? {} : null
  );

  return {
    customFieldFilters,
    customFields
  };
};

export const processJsonApiObject = object => {
  const attributes = get(object, 'attributes', {});
  /*
    src/core/redux/resources/createResourceOperations.js
    loadById send object with other key
  */
  const { customFieldFilters, customFields } = getPropsLikeCustomField(attributes);
  const pathCustomFieldFilters = getCustomFieldFiltersPath(attributes);
  const pathCustomFields = getCustomFieldsPath(attributes);
  const attributesWithoutCustomFieldFilters = omit(attributes, [
    pathCustomFieldFilters,
    pathCustomFields
  ]);

  const result = camelcaseKeys(attributesWithoutCustomFieldFilters);
  const relationships = processJsonApiRelationships(get(object, 'relationships', {}));

  let processJsonResult = {
    id: object.id,
    type: object.type,
    ...result,
    ...relationships
  };

  if (!isNull(customFieldFilters)) {
    processJsonResult = {
      ...processJsonResult,
      customFieldFilters
    };
  }

  if (!isNull(customFields)) {
    processJsonResult = {
      ...processJsonResult,
      customFields
    };
  }

  return processJsonResult;
};

export const processJsonApiResponse = (data, options = {}) => {
  if (Array.isArray(data)) return processJsonApiCollection(data, options);
  return processJsonApiObject(data);
};
