import { post } from 'redux-bees';

const bitrixEndpoints = apiPath => ({
  bitrixRegisterOrganization: {
    method: post,
    path: `${apiPath}/integrations/bitrix/register_organization`
  },
  bitrixConnectOrganization: { method: post, path: `${apiPath}/bitrix/connect_organization/:id` },
  bitrixSyncDataByPeriod: { method: post, path: `${apiPath}/integrations/bitrix/:id/fetch_data` },
  bitrixReinstallIntegration: {
    method: post,
    path: `${apiPath}/integrations/bitrix/reinstall_integration`
  },
  bitrixConnectToOrganization: {
    method: post,
    path: `${apiPath}/integrations/bitrix/connect_to_organization`
  }
});

export default bitrixEndpoints;
