import { get, post } from 'redux-bees';

const userCustomFieldsEndpoints = apiPath => ({
  getUserCustomFields: { method: get, path: `${apiPath}/customfields/list` },
  createUserCustomField: { method: post, path: `${apiPath}/customfields/add` },
  updateUserCustomField: { method: post, path: `${apiPath}/customfields/update` },
  deleteUserCustomField: { method: post, path: `${apiPath}/customfields/delete` }
});

export default userCustomFieldsEndpoints;
