import React, { useState } from 'react';
import RedesignWidgetTable from 'components/Dashboard/RedesignWidgetTable';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { IconButton } from 'components/Buttons';
import { Download, Settings } from 'react-feather';
import { get, isEqual, keyBy, compact } from 'lodash';
import { widgetPeriodSetter } from 'pages/DashboardPage/utils';
import { Empty, Radio, Tooltip } from 'antd';
import { WIDGET_VIEW_MODES, AUTO_FAIL_TYPES } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateDashboardPageWidget } from 'redux/entities/dashboardPages/operations';
import {
  getChecklistDefinitionQuestionGroupsBeforeQuestions,
  getChecklistDefinitionBindings
} from 'redux/selectors/checklistItems/checklistItems';
import { StyledEmpty, StyledErrorMessage } from '../../../../../DashboardPage/styled';
import {
  onTableChange,
  prepareCriticalErrorsFooterRow,
  prepareCriticalErrorsTableColumns,
  prepareCriticalErrorsTableData,
  startCriticalErrorExport
} from './utils';

const CriticalErrorsAnalytics = ({ setVisibleWidgetId, widgetData = {} }) => {
  const { t } = useTranslation();
  const [loadingExport, setLoadingExport] = useState();
  const dispatch = useDispatch();

  const autoFailsDashboard = useSelector(
    state => state.dashboardPages.dashboardsByTypes?.autofail_scores,
    isEqual
  );
  const { id, name } = widgetData;

  const loading = useSelector(state => state.dashboardPages.loading);
  const loadingWidgetId = useSelector(state => state.dashboardPages.loadingWidgetId);
  const loadingTable = loadingWidgetId === id || loadingWidgetId === 'filters' || loadingExport;
  const filters = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.filters, isEqual);
  const type = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.type, isEqual);
  const page = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.page, isEqual);
  const [initialState, setInitialState] = useState(page);
  const widgetAnalytics = useSelector(
    state => get(state.dashboardAnalytics.byIds, id, {}),
    isEqual
  );
  const meta = get(widgetAnalytics, 'meta', {});
  const dashboardPageFilters = useSelector(
    state => state.dashboardPages.dashboardsByTypes?.autofail_scores?.filters,
    isEqual
  );
  const checklistDefinitionsByIds = useSelector(state => state.checklistDefinitionsResource.byIds);
  const checklistDefinition = get(
    checklistDefinitionsByIds,
    dashboardPageFilters?.checklistDefinitionsIds,
    {}
  );
  const currentChecklistBindingsByQuestionsIds = useSelector(
    state => keyBy(getChecklistDefinitionBindings(state, checklistDefinition), 'questionId'),
    isEqual
  );
  const questions = useSelector(
    state =>
      getChecklistDefinitionQuestionGroupsBeforeQuestions(state, checklistDefinition)?.filter(
        question => {
          return (
            currentChecklistBindingsByQuestionsIds[question.id]?.checklistAutofailEnabled ||
            currentChecklistBindingsByQuestionsIds[question.id]?.groupAutofailEnabled
          );
        }
      ),
    isEqual
  );

  const users = useSelector(state => state.usersResource.byIds);

  const typeToViewMode = {
    [AUTO_FAIL_TYPES.CRITICAL_ERRORS_BY_UNITS]: WIDGET_VIEW_MODES.UNITS.value,
    [AUTO_FAIL_TYPES.CRITICAL_ERRORS_BY_OPERATORS]: WIDGET_VIEW_MODES.OPERATORS.value
  };

  const viewModeToType = {
    [WIDGET_VIEW_MODES.UNITS.value]: AUTO_FAIL_TYPES.CRITICAL_ERRORS_BY_UNITS,
    [WIDGET_VIEW_MODES.OPERATORS.value]: AUTO_FAIL_TYPES.CRITICAL_ERRORS_BY_OPERATORS
  };

  const onChange = e => {
    dispatch(
      updateDashboardPageWidget({
        id,
        filters: {},
        type: viewModeToType[e.target.value],
        page: {
          number: 1,
          size: page?.size
        }
      })
    );
  };

  const isUnitsMode = type === AUTO_FAIL_TYPES.CRITICAL_ERRORS_BY_UNITS;

  const tableData = compact([
    ...prepareCriticalErrorsTableData({
      widgetAnalytics,
      questions,
      users,
      isUnitsMode
    }),
    prepareCriticalErrorsFooterRow({ widgetAnalytics, questions, checklistDefinition })
  ]);

  const tableColumns = prepareCriticalErrorsTableColumns({ questions, isUnitsMode });

  const renderDescription = () => {
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPage.widget.checkFilters')}
        </StyledErrorMessage>
        <SText type="secondary">{t('dashboardPage.widget.emptyFiltersData')}</SText>
      </>
    );
  };

  const renderDescriptionForEmptyQuestions = () => {
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPage.widget.checkFilters')}
        </StyledErrorMessage>
        <SText type="secondary">В выбранной форме оценки нет ни одного критерия с автофейлом</SText>
      </>
    );
  };

  const isEmptyWidget = widgetAnalytics?.data?.length === 0;
  const isEmptyQuestions = questions.length === 0;

  const tableHeaderName = !name
    ? t('dashboardPages.autoFailScores.criticalErrorsCriteriaWidgetTitle')
    : name;

  return (
    <SCol span={24} marginBottom={15}>
      <SCard bordered shadowed rounded="4px" bodyPadding="12px 20px 6px 20px">
        <SRow>
          <SCol span={24}>
            <SRow type="flex" justify="space-between">
              <SCol span={8}>
                <SRow>
                  <SCol>
                    <Tooltip title={tableHeaderName}>
                      <SText ellipsis width="100%" fontSize="24px" strong>
                        {tableHeaderName}
                      </SText>
                    </Tooltip>
                  </SCol>
                  <SCol span={24}>
                    <SText type="secondary" strong>
                      {widgetPeriodSetter({
                        widgetsFilters: get(autoFailsDashboard, 'filters', {}),
                        widgetType: 'auto_fail_counts_by'
                      })}
                    </SText>
                  </SCol>
                </SRow>
              </SCol>
              <SCol span={8} display="flex" justifyContent="center" marginTop="6px">
                <Radio.Group
                  onChange={onChange}
                  value={typeToViewMode[type] || WIDGET_VIEW_MODES.OPERATORS.value}
                >
                  <Radio.Button value={WIDGET_VIEW_MODES.OPERATORS.value}>
                    {t(WIDGET_VIEW_MODES.OPERATORS.title)}
                  </Radio.Button>
                  <Radio.Button value={WIDGET_VIEW_MODES.UNITS.value}>
                    {t(WIDGET_VIEW_MODES.UNITS.title)}
                  </Radio.Button>
                </Radio.Group>
              </SCol>
              <SCol span={8} display="flex" justifyContent="flex-end">
                <IconButton
                  button={{
                    icon: <Download />,
                    color: 'var(--black_4)',
                    marginRight: '6px',
                    onClick: () =>
                      startCriticalErrorExport({
                        id,
                        meta,
                        type,
                        users,
                        filters,
                        dispatch,
                        questions,
                        isUnitsMode,
                        initialState,
                        setLoadingExport
                      })
                  }}
                />
                <IconButton
                  button={{
                    icon: <Settings />,
                    color: 'var(--black_4)',
                    onClick: () => setVisibleWidgetId(id)
                  }}
                />
              </SCol>
            </SRow>
          </SCol>
          <SCol marginTop="10px" span={24}>
            {isEmptyWidget && (
              <SCol marginBottom="40px">
                <StyledEmpty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={renderDescription()}
                />
              </SCol>
            )}
            {isEmptyQuestions && (
              <SCol marginBottom="40px">
                <StyledEmpty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={renderDescriptionForEmptyQuestions()}
                />
              </SCol>
            )}
            {!isEmptyWidget && !isEmptyQuestions && (
              <RedesignWidgetTable
                dataSource={widgetAnalytics?.data?.length === 0 ? [] : tableData}
                columns={loading || widgetAnalytics?.data?.length === 0 ? [] : tableColumns}
                loading={loadingTable}
                size="small"
                showSorterTooltip={false}
                scroll={{ x: 1500, y: 575 }}
                pagination={{
                  position: 'bottomLeft',
                  showSizeChanger: true,
                  total: meta?.total_count,
                  current: meta?.page,
                  pageSize: page?.size + 1,
                  pageSizeOptions: ['10', '25', '50']
                }}
                onChange={(pagination, _, sorter) =>
                  onTableChange({
                    pagination,
                    sorter,
                    widgetId: id,
                    tableColumns,
                    filters,
                    dispatch,
                    setInitialState
                  })
                }
              />
            )}
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default CriticalErrorsAnalytics;
