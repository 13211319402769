import { Collapse } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;

export const QuestionGroup = styled.div`
  margin: 16px -8px;
  padding: 8px;
`;

export const ChecklistDefinitionContainer = styled.div`
  background: #f5f5f5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 44px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 0 0 8px;
`;

export const ScoreContainer = styled.span`
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const StyledCollapse = styled(Collapse)`
  margin: 8px 0px !important;
  width: 100%;
`;

export const StyledPanel = styled(Panel)`
  && .ant-collapse-header {
    border-radius: 4px !important;
    background: var(--gray-light);
    & > .ant-collapse-arrow {
      top: 50%;
      transform: translate(0, -50%);
    }
  }
`;

export const Score = styled.span`
  display: flex;
  justify-content: center;
  margin-left: 8px;
`;
