import { Dropdown, Menu } from 'antd';
import SButton from 'components/Standard/SButton';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import Icon from 'components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FundOutlined, DownOutlined } from '@ant-design/icons';
import {
  CLIENT_INTERACTIONS_TYPES,
  CLIENT_INTERACTIONS_TYPES_LITERALS
} from 'core/utils/constants';

export const AUTO_FAIL_TYPES = {
  HISTORY_REPORT: 'autofails_history_report',
  CRITICAL_ERRORS_BY_OPERATORS: 'autofail_counts_by_operators'
};

const CreateNewReportButton = ({ onAdd }) => {
  const { t } = useTranslation();

  const communicationTypesWithDescription = [
    {
      key: CLIENT_INTERACTIONS_TYPES.PHONE_CALL,
      title: CLIENT_INTERACTIONS_TYPES_LITERALS.phone_call,
      description: 'Показывает результат проверок по отделам, только по звонкам'
    },
    {
      key: CLIENT_INTERACTIONS_TYPES.EMAIL,
      title: CLIENT_INTERACTIONS_TYPES_LITERALS.email,
      description: 'Показывает результат проверок по отделам, только по email'
    },
    {
      key: CLIENT_INTERACTIONS_TYPES.CHAT,
      title: CLIENT_INTERACTIONS_TYPES_LITERALS.chat,
      description: 'Показывает результат проверок по отделам, только по чатам'
    },
    {
      key: CLIENT_INTERACTIONS_TYPES.TICKET,
      title: CLIENT_INTERACTIONS_TYPES_LITERALS.ticket,
      description: 'Показывает результат проверок по отделам, только по тикетам'
    }
  ];

  const menuItems = communicationTypesWithDescription.map(communicationType => (
    <Menu.Item key={communicationType.key}>
      <SRow padding="8px">
        <SCol
          borderRadius={40}
          height={80}
          width={80}
          margin="auto 8px"
          background="var(--blue_4)"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Icon icon={FundOutlined} style={{ fontSize: '50px' }} color="var(--blue_6)" />
        </SCol>
        <SCol maxWidth="411px" marginLeft="4px">
          <SRow>{t(communicationType.title)}</SRow>
          <SRow color="var(--black_6)" style={{ whiteSpace: 'break-spaces' }}>
            {t(communicationType.description)}
          </SRow>
        </SCol>
      </SRow>
    </Menu.Item>
  ));

  return (
    <Dropdown
      overlay={<Menu onClick={({ key }) => onAdd({ communicationTypes: key })}>{menuItems}</Menu>}
      placement="bottomRight"
    >
      <SButton type="primary">
        {t('dashboardPages.addReport')}
        <DownOutlined />
      </SButton>
    </Dropdown>
  );
};

export default React.memo(CreateNewReportButton);
