import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  selectedManagerUserId: null,
  selectingManager: false,
  selectedEmployeeUserId: null,
  selectingEmployee: false,
  selectedSupervisorUserId: null,
  selectingSupervisor: false,
  addingEmployee: false,
  creatingUnit: false,
  newUnitName: null,
  selectedUnit: {}
};

export const selectUnit = createAction('uiOrganizationPage/selectUnit');
export const updateSelectedUnit = createAction('uiOrganizationPage/updateSelectedUnit');
export const removeSelectedUnit = createAction('uiOrganizationPage/removeSelectedUnit');
export const saveEditingUnit = createAction('uiOrganizationPage/saveEditingUnit');

export const dropSelectedUnitId = createAction('uiOrganizationPage/dropSelectedUnitId');
export const toggleSelectingManager = createAction('uiOrganizationPage/toggleSelectingManager');
export const setSelectingManager = createAction('uiOrganizationPage/setSelectingManager');
export const selectManagerUserId = createAction('uiOrganizationPage/selectManagerUserId');
export const dropSelectedManagerUserId = createAction(
  'uiOrganizationPage/dropSelectedManagerUserId'
);
export const setAddingEmployee = createAction('uiOrganizationPage/setAddingEmployee');
export const setCreatingUnit = createAction('uiOrganizationPage/setCreatingUnit');
export const setNewUnitName = createAction('uiOrganizationPage/setNewUnitName');
export const setSelectingEmployee = createAction('uiOrganizationPage/setSelectingEmployee');
export const selectEmployeeUserId = createAction('uiOrganizationPage/selectEmployeeUserId');
export const dropSelectedEmployeeUserId = createAction(
  'uiOrganizationPage/dropSelectedEmployeeUserId'
);
export const setSelectingSupervisor = createAction('uiOrganizationPage/setSelectingSupervisor');
export const selectSupervisorUserId = createAction('uiOrganizationPage/selectSupervisorUserId');
export const dropSelectedSupervisorUserId = createAction(
  'uiOrganizationPage/dropSelectedSupervisorUserId'
);

export const organizationPageReducer = createReducer(defaultState, {
  [selectUnit]: (state, { payload }) => ({
    ...state,
    selectedUnit: payload || defaultState.selectedUnit
  }),
  [updateSelectedUnit]: (state, { payload }) => ({
    ...state,
    selectedUnit: { ...state.selectedUnit, ...payload }
  }),
  [removeSelectedUnit]: state => ({
    ...state,
    selectedUnit: {}
  }),
  [dropSelectedUnitId]: state => ({ ...state, selectedUnitId: null, selectingManager: false }),
  [toggleSelectingManager]: state => ({
    ...state,
    selectingManager: !state.selectingManager
  }),
  [setSelectingManager]: (state, { payload }) => ({
    ...state,
    selectingManager: payload
  }),
  [selectManagerUserId]: (state, { payload }) => ({
    ...state,
    selectedManagerUserId: payload
  }),
  [dropSelectedManagerUserId]: state => ({
    ...state,
    selectedManagerUserId: null,
    selectingManager: false
  }),
  [setAddingEmployee]: (state, { payload }) => ({ ...state, addingEmployee: payload }),
  [setCreatingUnit]: (state, { payload }) => ({ ...state, creatingUnit: payload }),
  [setNewUnitName]: (state, { payload }) => ({ ...state, newUnitName: payload }),
  [setSelectingEmployee]: (state, { payload }) => ({ ...state, selectingEmployee: payload }),
  [selectEmployeeUserId]: (state, { payload }) => ({ ...state, selectedEmployeeUserId: payload }),
  [dropSelectedEmployeeUserId]: state => ({
    ...state,
    selectedEmployeeUserId: null,
    selectingEmployee: false
  }),
  [setSelectingSupervisor]: (state, { payload }) => ({ ...state, selectingSupervisor: payload }),
  [selectSupervisorUserId]: (state, { payload }) => ({
    ...state,
    selectedSupervisorUserId: payload
  }),
  [dropSelectedSupervisorUserId]: state => ({
    ...state,
    selectedSupervisorUserId: null,
    selectingSupervisor: false
  })
});
