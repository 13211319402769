export default {
  infoPage: {
    drawer: {
      title: 'Informazione',
      cards: {
        wiki: {
          title: 'Qolio Wiki',
          description: "Aiuto nell'utilizzo dell'applicazione"
        },
        supportRequest: {
          title: 'Scrivi al supporto tecnico',
          description: "Ottieni risposte rapide alle domande sull'applicazione"
        }
      }
    },
    feedBackForm: {
      messages: {
        requestSuccessfullySended: 'Messaggio inviato',
        sendRequestfailed: 'Impossibile inviare il messaggio'
      },
      form: {
        fields: {
          name: 'Nome',
          namePlaceholder: 'Inserisci il tuo nome',
          email: 'Email',
          emailPlaceholder: 'Inserisci la tua email',
          topic: 'Tema',
          topicPlaceholder: "Seleziona l'oggetto della domanda",
          message: 'Messaggio',
          messagePlaceholder: "Inserisci l'oggetto del messaggio"
        },
        messages: {
          enterName: 'Per favore inserisci il tuo nome',
          enterEmail: 'Per favore inserisci la tua email',
          wrongEmail: "L'email non corrisponde al formato",
          enterTopic: 'Per favore seleziona il tema del messaggio',
          enterTopicMessage: 'Per favore inserisci il tuo messaggio'
        },
        topicOptions: {
          ask_question: 'Fare una domanda',
          bug_report: 'Informare di un errore',
          offer_feature: 'Suggerire miglioramenti',
          other: 'Altro'
        },
        buttons: { send: 'Inviare ' }
      }
    }
  }
};
