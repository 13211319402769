import React from 'react';

const Expert = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.84375 11.2812V14.0938H13.1562V11.2812H2.84375Z" fill="#FF9F00" />
      <path d="M8 11.2812H13.1562V14.0938H8V11.2812Z" fill="#FF7816" />
      <path
        d="M13.2031 7.19373L12.4437 8.67493H3.55627L2.79688 7.19373C3.91248 5.64685 5.85315 4.71875 8 4.71875C10.1469 4.71875 12.0875 5.64685 13.2031 7.19373Z"
        fill="#FF4B00"
      />
      <path
        d="M13.2031 7.19373L12.4437 8.67493H8V4.71875C10.1469 4.71875 12.0875 5.64685 13.2031 7.19373Z"
        fill="#CC1E0D"
      />
      <path
        d="M14.9094 6.25623L13.1562 12.2188H2.84375L1.09058 6.25623L2.27185 5.80627C2.80627 6.82812 3.82812 7.4281 4.66248 7.53125H5.15002C6.22815 7.36255 7.0343 5.71252 7.36255 4.13745H8.63757C8.9657 5.71252 9.77197 7.36255 10.85 7.53125H11.3375C12.1719 7.4281 13.1937 6.82812 13.7281 5.80627L14.9094 6.25623Z"
        fill="#FDBF00"
      />
      <path
        d="M14.9094 6.25623L13.1562 12.2188H8V4.13745H8.63745C8.9657 5.71252 9.77185 7.36255 10.85 7.53125H11.3375C12.1719 7.4281 13.1937 6.82812 13.7281 5.80627L14.9094 6.25623Z"
        fill="#FF9F00"
      />
      <path
        d="M1.4375 6.59375C0.662109 6.59375 0 5.96289 0 5.1875C0 4.41211 0.662109 3.78125 1.4375 3.78125C2.21289 3.78125 2.84375 4.41211 2.84375 5.1875C2.84375 5.96289 2.21289 6.59375 1.4375 6.59375Z"
        fill="#FF9F00"
      />
      <path
        d="M14.5625 6.59375C13.7871 6.59375 13.1562 5.96289 13.1562 5.1875C13.1562 4.41211 13.7871 3.78125 14.5625 3.78125C15.3379 3.78125 16 4.41211 16 5.1875C16 5.96289 15.3379 6.59375 14.5625 6.59375Z"
        fill="#FF7816"
      />
      <path
        d="M8 1.90625C7.2218 1.90625 6.59375 2.5343 6.59375 3.3125C6.59375 4.09058 7.2218 4.71875 8 4.71875C8.7782 4.71875 9.40625 4.09058 9.40625 3.3125C9.40625 2.5343 8.7782 1.90625 8 1.90625Z"
        fill="#FF9F00"
      />
      <path d="M8 7.33423L6.39685 8.93738L8 10.5405L9.60315 8.93738L8 7.33423Z" fill="#FF4B00" />
      <path
        d="M10.8125 9.21204L11.4753 9.87488L10.8125 10.5377L10.1497 9.87488L10.8125 9.21204Z"
        fill="#CC1E0D"
      />
      <path
        d="M5.1875 9.21204L5.85034 9.87488L5.1875 10.5377L4.52466 9.87488L5.1875 9.21204Z"
        fill="#FF4B00"
      />
      <path
        d="M8 4.71875V1.90625C8.7782 1.90625 9.40625 2.5343 9.40625 3.3125C9.40625 4.09058 8.7782 4.71875 8 4.71875Z"
        fill="#FF7816"
      />
      <path d="M9.60315 8.93738L8 10.5405V7.33423L9.60315 8.93738Z" fill="#CC1E0D" />
    </svg>
  );
};

export default Expert;
