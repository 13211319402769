import { Col } from 'antd';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { questionsResource } from 'redux/resources/questions';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import { unitsResource } from 'redux/resources/units';
import { setEditableChecklistDefinition } from 'redux/ui/checklistEditor/operations';
import { setEditingQuestion } from 'redux/ui/questionModal/reducer';
import { setCurrentChecklist } from 'redux/ui/checklistEditor/reducer';
import { usersResource } from 'redux/resources/users';
import { operations as promptsListOperations } from 'redux/lists/promptsList';
import ChecklistInfo from './components/ChecklistInfo';
import ChecklistItems from './components/ChecklistItems';

const ChecklistEditorPage = ({
  setEditableChecklistDefinition,
  loadUnits,
  checklistId,
  currentChecklist,
  loadChecklistDefinitions,
  loadPrompts,
  loading,
  loadUsers
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  useEffect(() => {
    setEditableChecklistDefinition({ id: checklistId });
    loadUnits();
    // * нужно для информации о других чек-листах вопросов, можно удалить с уходом библиотеки вопросов
    loadChecklistDefinitions({ include: 'question_groups.question_to_group_bindings.question' });
    loadPrompts({
      organization_id: organizationId
    });
    loadUsers({
      pagination: 'false',
      include: 'unit,role.levels'
    });
    return () => {
      dispatch(setCurrentChecklist({ currentChecklist: {} }));
    };
  }, []);

  const { t } = useTranslation();

  return (
    <SRow gutter={[0, 8]}>
      <Helmet>
        <title>{t('pagesMeta.checklistQuestionsEditor.title')}</title>
      </Helmet>
      <Col span={24}>
        <ChecklistInfo currentChecklist={currentChecklist} loadingChecklist={loading} />
      </Col>
      <SCol span={24}>
        <ChecklistItems currentChecklist={currentChecklist} loadingChecklist={loading} />
      </SCol>
    </SRow>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { currentChecklist } = state.uiChecklistEditor;
  const { question, questionToGroupBinding } = state.uiQuestionModal;
  const checklistId = get(ownProps, 'match.params.id', '');
  const loading = get(state, 'checklistDefinitionsResource.loadByIdStarted', true);
  return {
    editingQuestion: question,
    editingQuestionToGroupBinding: questionToGroupBinding,
    checklistId,
    currentChecklist,
    loading,
    questionsByIds: state.questionsResource.byIds,
    bindingsByIds: state.questionToGroupBindingsResource.byIds
  };
};

const mapDispatchToProps = {
  setEditableChecklistDefinition,
  setEditingQuestion,
  loadChecklistDefinitions: checklistDefinitionsResource.operations.load,
  loadPrompts: promptsListOperations.load,
  createQuestion: questionsResource.operations.create,
  updateQuestion: questionsResource.operations.updateById,
  createBinding: questionToGroupBindingsResource.operations.create,
  updateBinding: questionToGroupBindingsResource.operations.updateById,
  loadUnits: unitsResource.operations.load,
  loadUsers: usersResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistEditorPage);
