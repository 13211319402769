import Call from 'components/CallContent';
import TextCommunication from 'components/TextCommunicationContent';
import CrmEntity from 'components/CrmEntityContent';

const ContentTypeToComponent = {
  phoneCall: Call,
  textCommunication: TextCommunication,
  chat: TextCommunication,
  crmEntity: CrmEntity
};

export default contentType => {
  return ContentTypeToComponent[contentType];
};
