import { Col, Row, Select } from 'antd';
import { COLOR_PALETTE_7, transformToDarkerColor } from 'core/utils/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskDefinition } from 'redux/ui/taskDefinition/reducer';
import styled from 'styled-components';
import { compact, uniq } from 'lodash';

export const ColorSquare = styled.div`
  width: 20px;
  height: 20px;
  margin: 4px;
  background-color: ${props => props.color};
`;

const { Option } = Select;

const colors = ({ labelColor }) => {
  const standardColors = transformToDarkerColor(Object.values(COLOR_PALETTE_7));
  let availableColors = compact([...standardColors, labelColor]);

  availableColors = transformToDarkerColor(availableColors);

  return uniq(labelColor ? availableColors : standardColors);
};

const ColorPicker = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { labelColor } = useSelector(state => state.uiTaskDefinition);

  const changeColor = value => {
    dispatch(updateTaskDefinition({ labelColor: value }));
  };

  return (
    <Row>
      <Col span={24}>
        <span>{t('components.colorPicker.name')}</span>
      </Col>
      <Col span={24} style={{ height: 32 }}>
        <Select
          onChange={changeColor}
          placeholder={t('components.colorPicker.namePlaceholder')}
          value={transformToDarkerColor(labelColor) || ''}
          style={{ width: 70 }}
        >
          {colors({ labelColor }).map(color => (
            <Option value={color} color={color} key={color}>
              <ColorSquare color={color} />
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default ColorPicker;
