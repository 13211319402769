import api, { exportToExcelTableCommunicationEndpoint, getLogsAiTaskEndpoint } from 'core/api';
import decamilize from 'decamelize-keys-deep';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { widgetNames } from 'redux/ui/teamAnalyticsPage/operations';
import { keyBy, reduce } from 'lodash';
import axios from 'axios';
import { message } from 'antd';
import { Trans } from 'react-i18next';
import fileDownload from 'js-file-download';
import React from 'react';

export const exportTeamPage = exportParams => {
  return async () => {
    const response = await api.loadAnalyticsWidgets(
      parseOptionsForApi(decamilize({ widgetNames, ...exportParams }))
    );

    return reduce(
      response.body,
      (result, widget, widgetName) => ({ ...result, [widgetName]: keyBy(widget, 'id') }),
      {}
    );
  };
};

export const exportSendEmail = async ({ body, successMessageKey, errorMessageKey }) => {
  try {
    await axios.post(`${exportToExcelTableCommunicationEndpoint}/send_mail`, body, {
      headers: {
        uid: localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client')
      }
    });
    message.success(<Trans i18nKey={successMessageKey} />);
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
    throw error;
  }
};

export const getRequestID = async ({ body, errorMessageKey }) => {
  try {
    const result = await axios.post(exportToExcelTableCommunicationEndpoint, body, {
      headers: {
        uid: localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client')
      }
    });
    return result?.data?.requestId;
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
    throw error;
  }
};

export const getLogsAiTask = async ({ taskId }) => {
  const body = {
    task_id: taskId
  };
  try {
    const response = await axios.post(getLogsAiTaskEndpoint, body, { responseType: 'blob' });
    // Создаём и скачиваем файл с заданным расширением
    fileDownload(response.data, 'logs.csv');
    return response.data;
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey="errorPages.internalServerError.title" />);
    throw error;
  }
};

export const checkExportStatus = async ({ body, errorMessageKey }) => {
  try {
    const result = await axios.post(`${exportToExcelTableCommunicationEndpoint}_status`, body);
    return result.data;
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
    throw error;
  }
};

export const downloadFileAfterExport = async ({ body, errorMessageKey }) => {
  try {
    const response = await axios.post(
      `${exportToExcelTableCommunicationEndpoint}_status/get_result`,
      body,
      { responseType: 'blob' }
    );
    fileDownload(response.data, 'export.xlsx');
    return response.data;
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
    throw error;
  }
};
