import React from 'react';
import { Typography, Checkbox, Form } from 'antd';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { PERMISSIONS, PERMISSIONS_DESCRIPTIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';

export const CommonPermissions = ({ onSignInRuleChange }) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <SRow gutter={[8, 8]} type="flex">
      <SCol span={24}>
        <Text strong>{t('organizationStructure.tabs.roles.modal.form.generalCategory')}</Text>
      </SCol>

      <SCol span={24}>
        <SRow>
          <Form.Item
            name={PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>{t(PERMISSIONS_DESCRIPTIONS.CAN_PERFORM_CLIENT_INTERACTION.title)}</Checkbox>
          </Form.Item>
        </SRow>
        <SRow>
          <Form.Item name={PERMISSIONS.CAN_SIGN_IN} valuePropName="checked" noStyle>
            <Checkbox onChange={onSignInRuleChange}>
              {t(PERMISSIONS_DESCRIPTIONS.CAN_SIGN_IN.title)}
            </Checkbox>
          </Form.Item>
        </SRow>
        <SRow>
          <Form.Item
            name={PERMISSIONS.CAN_AI_TRANSCRIBE_CLIENT_INTERACTIONS}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>
              {t(PERMISSIONS_DESCRIPTIONS.CAN_AI_TRANSCRIBE_CLIENT_INTERACTIONS.title)}
            </Checkbox>
          </Form.Item>
        </SRow>
        <SRow>
          <Form.Item
            name={PERMISSIONS.CAN_AI_ANALYZE_CLIENT_INTERACTIONS}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>
              {t(PERMISSIONS_DESCRIPTIONS.CAN_AI_ANALYZE_CLIENT_INTERACTIONS.title)}
            </Checkbox>
          </Form.Item>
        </SRow>
      </SCol>
    </SRow>
  );
};
