import { put, post, destroy } from 'redux-bees';

const questionToGroupBindingsEndpoints = apiPath => ({
  createQuestionToGroupBinding: {
    method: post,
    path: `${apiPath}/checklist/question_to_group_bindings`
  },
  updateQuestionToGroupBinding: {
    method: put,
    path: `${apiPath}/checklist/question_to_group_bindings/:id`
  },
  deleteQuestionToGroupBinding: {
    method: destroy,
    path: `${apiPath}/checklist/question_to_group_bindings/:id`
  }
});

export default questionToGroupBindingsEndpoints;
