import React from 'react';
import { useSelector } from 'react-redux';
import { compact, get } from 'lodash';
import { TagsDisplay } from 'components/Tags/TagsDisplay';

const TasksSection = ({ truncate }) => {
  const taskDefinitionsByIds = useSelector(state => state.taskDefinitionsResource.byIds);
  const currentReviewTaskDefinitionsIds = compact(
    useSelector(state => state.uiClientInteractionPage.tasks.taskDefinitionsIds)
  );

  const tasks = currentReviewTaskDefinitionsIds.map(taskDefinitionId => ({
    taskDefinition: get(taskDefinitionsByIds, taskDefinitionId)
  }));

  return <TagsDisplay tasks={tasks} truncate={truncate} />;
};

export default TasksSection;
