import { get, post, put } from 'redux-bees';

const pyrusEndpoints = apiPath => ({
  createPyrusIntegration: {
    method: post,
    path: `${apiPath}/pyrus`
  },
  updateS2Integration: {
    method: put,
    path: `${apiPath}/:id`
  },
  getEntityProjectPyrus: {
    method: get,
    path: `${apiPath}/:id/subIntegrations`
  },
  connectEntityProjectPyrus: {
    method: post,
    path: `${apiPath}/:id/connect/subIntegration`
  },
  connectEntityStatusPyrus: {
    method: post,
    path: `${apiPath}/:id/connect/context`
  },
  discconnectEntityStatusPyrus: {
    method: post,
    path: `${apiPath}/:id/unconnect/context`
  },
  connectAllStatusPyrus: {
    method: post,
    path: `${apiPath}/:id/connect/context/bulk`
  },
  disconnectEntityProjectPyrus: {
    method: post,
    path: `${apiPath}/:id/unconnect/subIntegration`
  },
  getEntityCustomFieldsPyrus: {
    method: get,
    path: `${apiPath}/:id/customFields`
  },
  connectEntityCustomFieldsPyrus: {
    method: post,
    path: `${apiPath}/:id/connect/custom_field`
  },
  disconnectEntityCustomFieldsPyrus: {
    method: post,
    path: `${apiPath}/:id/unconnect/custom_field`
  },
  connectAllEntityCustomFieldsPyrus: {
    method: post,
    path: `${apiPath}/:id/connect/custom_field/bulk`
  },
  connectAllProjectsPyrus: {
    method: post,
    path: `${apiPath}/:id/connect/subIntegration/bulk`
  },
  getEntityStatusPyrus: {
    method: get,
    path: `${apiPath}/:id/context`
  },
  refreshEntityCustomFieldsPyrus: {
    method: post,
    path: `${apiPath}/:id/customFields/refresh`
  }
});

export default pyrusEndpoints;
