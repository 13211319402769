export default {
  workPlanTaskConfigurationEditor: {
    newTaskTitle: 'Nuevo reto',
    taskStarted: 'Tarea iniciada',
    taskSaved: 'Tarea guardada',
    deleteTask: 'Eliminar',
    saveTask: 'Guardar',
    startTask: 'Correr',
    copyTask: 'Crear una copia',
    taskInfo: {
      totalCommunications: 'total communications',
      checkedOperators: 'personal controlado',
      reviewers: 'revisores'
    },
    delete: {
      title: '¿Eliminar tarea?',
      taskDeleted: 'Tarea eliminada',
      cantDeleteTask: 'No se pudo borrar la tarea'
    },
    schedule: {
      taskConfig: 'Parámetros de la tarea',
      taskName: 'Nombre de la tarea',
      distribution: 'Distribución',
      distributionSettings: {
        communicationsNumberByEachOperator: 'Establecer número por empleado',
        communicationsPercentageByEachOperator: 'Establecer porcentaje por empleado',
        communicationsNumberByOperators: 'Establecer número por grupo de empleados'
      },
      period: 'Período',
      timeFrom: 'La fecha del comienzo',
      timeTo: 'Fecha de realización',
      repeatEvery: 'Repite cada',
      repeat: 'Repeticiones',
      toRepeat: 'Repetir',
      dayTimeFrom: 'Hora de inicio',
      dayTimeTo: 'Tiempo de finalización',
      periodForTask: 'Período de ejecución de la tarea',
      periodTask: 'Periodo de ejecución',
      date: 'Fecha',
      time: 'Tiempo',
      start: 'Empezar',
      end: 'Terminar en',
      stopTask: 'Detener tarea',
      customPeriod: 'Periodo personalizable',
      scheduleChangePeriodTask:
        'Si se cambia el periodo de ejecución, el tipo de repetición se restablecerá a "No repetir"',
      info: {
        schedulePeriodTask: 'Periodo para el que es necesario comprobar la tarea',
        scheduleStopTask: 'Seleccione la fecha en la que desea dejar de repetir la tarea'
      }
    },
    filters: {
      deleteFilters: 'Quitar filtros',
      maxCount: 'Cantidad de evaluaciones',
      clientInteractionType: 'Tipo de comunicación',
      addUsers: 'Agragar usuario',
      add: 'Añadir',
      usersAdded: 'Usuarios',
      addFilters: 'Agregar tipo de comunicación',
      datesRange: 'Comprobar fechas',
      invalid:
        'Para configurar los verificadores, debe: añadir el tipo de comunicación, seleccionar el número de comunicaciones a verificar y seleccionar los empleados a verificar',
      valid: 'Para iniciar la tarea, debe seleccionar las fichas'
    },
    clientInteractionFilters: {
      operators: 'Empleados',
      addFilter: 'Añadir filtro',
      totalCountForAll: 'Total para todos',
      setConfiguration: {
        operatorsToCheck: 'Seleccione los empleados a comprobar',
        communicationsToCheck: 'Configurar las comunicaciones para comprobar',
        directionOfCommunications: 'Establecer la dirección de las comunicaciones',
        durationOfCommunications: 'Establecer la duración de la comunicación',
        communicationPartsCount: 'Establecer el número de mensajes'
      },
      deleteFiltersModalTitle: '¿Borrar los filtros junto con el tipo de comunicación?',
      quantityByOperator: 'Número por empleado',
      percentageByOperator: '% por empleado',
      operatorsToReview: 'Empleados para comprobar',
      searchFilter: 'Introduzca el nombre del filtro'
    },
    reviewersWithoutPermissions:
      'Un verificador no puede ser añadido a una tarea porque no tiene acceso a uno o más registros de operador.',
    changeTypeDistribution:
      'Si se cambia el tipo de asignación, se restablecerán los valores de las asignaciones de los controladores establecidos manualmente. ¿Continuar?',
    distribution: {
      addReviewer: 'Agregar revisor',
      manual: 'Manualmente',
      auto: 'Igualmente'
    },
    addUsersModal: {
      users: 'Empleados',
      unit: 'Departamento',
      role: 'Papel'
    }
  }
};
