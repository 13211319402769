import React from 'react';
import { Empty, Button, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { createDashboard } from 'redux/ui/dashboard/operations';
import { useTranslation } from 'react-i18next';
import { EmptyDashboardCard } from '../styled';

const EmptyDashboard = ({ createDashboard, loading }) => {
  const { t } = useTranslation();
  const createNewDashboard = async () => {
    try {
      await createDashboard({
        name: t('dashboardPage.dashboard.newDashboard'),
        shared: false
      });
      message.success(t('dashboardPage.dashboard.messages.dashboardSuccessfullyCreated'));
    } catch (error) {
      console.log(error);
      message.error(t('dashboardPage.dashboard.messages.createDashboardFailed'));
    }
  };

  return (
    <EmptyDashboardCard>
      <Spin spinning={loading}>
        <Empty
          imageStyle={{
            height: 60
          }}
          description={t('dashboardPage.dashboard.emptyDashboard')}
        >
          <Button type="primary" onClick={() => createNewDashboard()}>
            {t('dashboardPage.dashboard.buttons.create')}
          </Button>
        </Empty>
      </Spin>
    </EmptyDashboardCard>
  );
};

const mapDispatchToProps = {
  createDashboard
};

export default connect(null, mapDispatchToProps)(EmptyDashboard);
