import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Row, Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import { useSelections } from 'ahooks';
import { SListItem } from 'components/Standard/SList';
import SCard from 'components/Standard/SCard';
import { useDispatch } from 'react-redux';
import {
  connectAllStatusPyrus,
  connectStatusById,
  disconnectStatusById,
  getEntityStatusPyrus
} from '../../../../redux/entities/pyrus/operations';
import Search from '../../../../components/Inputs/Search';

const { Text } = Typography;

const StatusManager = ({ integrationId, onOk = null, okText }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const {
    allSelected,
    selected,
    isSelected,
    toggle,
    partiallySelected,
    setSelected
  } = useSelections(projects, []);

  const [isAllSelected, setIsAllSelected] = useState(allSelected);
  useEffect(() => {
    if (integrationId && isEmpty(projects)) {
      setLoading(true);
      getEntityStatusPyrus(integrationId).then(res => {
        setProjects(res);
        setSelected(
          Object.values(res)
            .filter(field => field.attributes.connected)
            .map(field => field.id)
        );
      });
      setLoading(false);
    }
  }, [integrationId]);

  const toggleAll = async () => {
    await dispatch(connectAllStatusPyrus(integrationId, !isAllSelected));
    if (isAllSelected) {
      setSelected([]);
    } else {
      setSelected(projects.map(project => project.id));
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleSelected = async id => {
    if (isSelected(id)) {
      await dispatch(connectStatusById(id, integrationId));
    } else {
      await dispatch(disconnectStatusById(id, integrationId));
    }
  };
  const filteredProjects = projects.filter(project =>
    project.attributes.name.toLowerCase().includes(search.toLowerCase())
  );

  const renderItem = (projectItem, style) => (
    <SListItem style={style} borderBottom="1px solid var(--gray-border)">
      <Col span={24} style={{ padding: 0 }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={1}>
            <Tooltip>
              <Checkbox
                checked={isSelected(projectItem.id)}
                onClick={() => {
                  toggle(projectItem.id);
                  handleSelected(projectItem.id);
                }}
              />
            </Tooltip>
          </Col>
          <Col flex="auto">
            <Text className="truncated">{projectItem.attributes.name}</Text>
          </Col>
        </Row>
      </Col>
    </SListItem>
  );

  if (loading) {
    return <SCard padding="16px" loading={loading} />;
  }

  return (
    <SCard>
      <Row gutter={[0, 16]} style={{ marginBottom: '-16px' }}>
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <Row align="middle" gutter={[16, 0]}>
                <Col>
                  <Search onSearch={setSearch} />
                </Col>
                <Col>
                  <Text type="secondary">Статусов </Text>
                  <Text>{projects.length}</Text>
                </Col>
                <Col>
                  <Checkbox
                    checked={isAllSelected}
                    onClick={toggleAll}
                    indeterminate={partiallySelected}
                  >
                    {t('integrationsSettingsPage.manageUserAccounts.selectAll')}
                  </Checkbox>
                </Col>
                <Col>
                  <Text type="secondary">{`${t('general.selected')}: ${selected.length}`}</Text>
                </Col>
              </Row>
            </Col>
            <Col>
              {onOk && (
                <Button type="primary" onClick={() => onOk()}>
                  {isEmpty(okText) ? t('general.continue') : okText}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <SListItem borderBottom="1px solid var(--gray-border)">
            <Col span={24} style={{ padding: '0' }}>
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <Text strong>статус</Text>
                </Col>
              </Row>
            </Col>
          </SListItem>
          <FixedSizeList itemSize={56} itemCount={filteredProjects.length} height={500}>
            {({ index, style }) => renderItem(filteredProjects[index], style)}
          </FixedSizeList>
        </Col>
      </Row>
    </SCard>
  );
};

export default StatusManager;
