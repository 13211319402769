import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import CommentsPreview from 'components/Comments/CommentsPreview';
import { beatifyFloat } from 'core/utils/convertFloat';
import { Trans } from 'react-i18next';
import AnswerValueCell from './AnswerValueCell';

const AVERAGE_VIEW_COLUMNS = 6;

export const prepareTableColumns = ({ members }) => {
  const { Text } = Typography;
  const isFixedColumns = members.length > AVERAGE_VIEW_COLUMNS;

  const membersToColumns = members.map(user => ({
    title: (
      <Link style={isFixedColumns ? { marginRight: 27 } : {}} to={`/reviews/${user.review.id}`}>
        {user.name}
      </Link>
    ),
    dataIndex: user.id,
    align: 'center',
    ellipsis: true,
    width: 200,
    key: user.id,
    render: (text, record) => {
      if (record.key === 'header') {
        return (
          <CommentsPreview
            comments={record[user.id] || []}
            checklistData={{
              checklist: {
                comment: user?.checklist?.comment
              }
            }}
            style={{ justifyContent: 'center' }}
          />
        );
      }
      if (record.key === 'footer') {
        return <Text>{beatifyFloat(record[user.id])}</Text>;
      }
      return (
        <AnswerValueCell
          value={record[user.id]?.value}
          question={record[user.id]?.question}
          comments={record[user.id]?.comments}
          isFixedColumns={isFixedColumns}
        />
      );
    }
  }));

  return [
    {
      title: <Trans i18nKey="calibrationResultPage.table.columns.gradedPoints" />,
      dataIndex: 'name',
      fixed: 'left',
      width: 270,
      ellipsis: true,
      key: 'name',
      render: (text, record) => {
        return record.key === 'footer' ? (
          <Trans i18nKey={record.name} />
        ) : (
          <Text
            strong={record.type === 'checklist-question-groups'}
            type={record.type === 'checklist-question-groups' ? 'secondary' : ''}
          >
            {text}
          </Text>
        );
      }
    },
    {
      title: <Trans i18nKey="calibrationResultPage.table.columns.coincidence" />,
      dataIndex: 'coincidence',
      key: 'coincidence',
      align: 'center',
      fixed: 'left',
      width: 100
    },
    ...membersToColumns
  ];
};
