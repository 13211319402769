import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: true
};

export const loadPriorityStarted = createAction('taskPriority/loadPriorityStarted');
export const loadPrioritySucceed = createAction('taskPriority/loadPrioritySucceed');

export const updatePriorityStarted = createAction('taskPriority/updatePriorityStarted');
export const updatePrioritySucceed = createAction('taskPriority/updatePrioritySucceed');

export const taskPriorityReducer = createReducer(defaultState, {
  [loadPriorityStarted]: () => ({
    loading: true
  }),
  [loadPrioritySucceed]: (state, { payload }) => ({
    ...state,
    ...payload,
    loading: false
  }),

  [updatePriorityStarted]: () => ({
    loading: true
  }),
  [updatePrioritySucceed]: (state, { payload }) => ({
    ...state,
    ...payload,
    loading: false
  })
});

export default taskPriorityReducer;
