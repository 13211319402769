import React from 'react';
import SRow from 'components/Standard/SRow';
import { FlagOutlined, FlagFilled } from '@ant-design/icons';
import SButton from 'components/Standard/SButton';
import { FLAGS_COLORS_VALUES, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import styled from 'styled-components';

const Flags = ({ templateState, setTemplateState }) => {
  const onChangeFlag = ({ ratingFlag }) => {
    setTemplateState({
      ...templateState,
      metadata: {
        ...templateState?.metadata,
        ratingFlag: ratingFlag === templateState?.metadata?.ratingFlag ? '' : ratingFlag
      }
    });
  };

  return (
    <SRow type="flex" align="middle" justify="space-between" width="84px" wrap={false}>
      <StyledButton
        size="icon"
        icon={
          templateState?.metadata?.ratingFlag === FLAGS_COLORS_VALUES.red ? (
            <FlagFilled />
          ) : (
            <FlagOutlined />
          )
        }
        style={{
          color: FLAGS_TO_COLORS_RELATIONS.red
        }}
        onClick={() => onChangeFlag({ ratingFlag: FLAGS_COLORS_VALUES.red })}
      />
      <StyledButton
        size="icon"
        icon={
          templateState?.metadata?.ratingFlag === FLAGS_COLORS_VALUES.yellow ? (
            <FlagFilled />
          ) : (
            <FlagOutlined />
          )
        }
        style={{
          color: FLAGS_TO_COLORS_RELATIONS.yellow
        }}
        onClick={() => onChangeFlag({ ratingFlag: FLAGS_COLORS_VALUES.yellow })}
      />
      <StyledButton
        size="icon"
        icon={
          templateState?.metadata?.ratingFlag === FLAGS_COLORS_VALUES.green ? (
            <FlagFilled />
          ) : (
            <FlagOutlined />
          )
        }
        style={{
          color: FLAGS_TO_COLORS_RELATIONS.green
        }}
        onClick={() => onChangeFlag({ ratingFlag: FLAGS_COLORS_VALUES.green })}
      />
    </SRow>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }

  &.ant-btn:focus {
    background-color: transparent;
  }

  &.ant-btn-icon-only:active:not(:hover),
  .ant-btn-icon-only:focus:not(:hover) {
    color: unset;
  }
`;

export default Flags;
