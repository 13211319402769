import createListReducer from 'core/redux/lists/listReducer';
import { reviewsResource } from 'redux/resources/reviews';

export const userReviewsListParams = {
  name: 'userReviews',
  defaultState: {
    filters: { direction: 'all' }
  },
  rebuildQuery: true,
  loader: reviewsResource.operations.load
};

const { actions, reducer, operations } = createListReducer(userReviewsListParams);

const userReviewsListReducer = reducer;

export { actions, userReviewsListReducer, operations };
