import { INTEGRATIONS } from 'core/utils/constants';
import { integrationsResource } from 'redux/resources/integrations';
import { saveBackgroundJob } from '../backgroundJob/operations';

export const createMangoIntegration = ({ name, clientSecret, clientId }) => async dispatch => {
  try {
    const integration = await dispatch(
      integrationsResource.operations.create({
        name,
        clientSecret,
        clientId,
        endpoint: 'https://app.mango-office.ru/vpbx/',
        integrationType: INTEGRATIONS.mango.type
      })
    );
    dispatch(saveBackgroundJob(integration?.currentBackgroundJob));

    return integration?.id;
  } catch (error) {
    console.log(error);
  }
};
