export default {
  bitrixIntegrationPage: {
    clientInteractionPreview: {
      warningModal: {
        title: 'Attenzione!',
        description: 'Questa chiamata non ha operatore installato'
      },
      buttons: { openInApp: "Apri nell'applicazione" },
      directions: {
        incoming: 'In arrivo',
        outcoming: 'In uscita',
        notInstalledType: 'Tipo non definito'
      },
      columns: {
        unistalledTime: "Il tempo e' indefinito",
        reviewed: 'Valutato',
        unReviewed: 'Non valutato'
      }
    },
    clientInteractions: {
      empty: {
        linkText: 'Maggiori informazioni sulla valutazione',
        description: 'Non ci sono comunicazioni disponibili per la valutazione in questa scheda'
      },
      columns: {
        operator: 'Operatore',
        direction: 'Direzione',
        date: 'Data',
        duration: 'Durata',
        status: 'Stato'
      }
    },
    reviewPreview: {
      menu: { openInApp: "Apri nell'applicazione" },
      directions: {
        incoming: 'In arrivo',
        outcoming: 'In uscita',
        notInstalledType: 'Tipo non definito'
      },
      columns: { unistalledTime: "Il tempo e' indefinito" }
    },
    review: {
      empty: {
        linkText: 'Maggiori informazioni sulla valutazione',
        description:
          'Fino a quando non hai aggiunto valutazioni alle comunicazioni in questa scheda'
      },
      columns: {
        operator: 'Operatore',
        direction: 'Direzione',
        date: 'Data',
        duration: 'Durata',
        comments: 'Commenti',
        score: 'Valutazione',
        author: 'Autore della valutazione'
      }
    }
  }
};
