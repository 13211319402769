import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { HelpCircle } from 'react-feather';
import Text from 'antd/lib/typography/Text';
import { Info } from 'components/Info';

export const ButtonStyledCurrentPage = styled(Button)`
  &.ant-btn {
    font-weight: bold;
  }
  display: flex;
  align-items: center;
`;

export const ButtonStyledActiveFilter = styled(Button)`
  &.ant-btn {
    opacity: 0.7;
    border: none;
  }
  display: flex;
  align-items: center;
`;

export const ModalTitle = styled.p`
  margin-top: 20px;
  font-size: 16px;
`;

export const SModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  width: 550px !important;
`;

const CheckboxModal = ({
  showCheckboxModal,
  closeCheckboxModal,
  handleSelectCurrentPage,
  handleSelectActiveFilter
}) => {
  return (
    <SModal
      visible={showCheckboxModal}
      maskClosable
      destroyOnClose
      onCancel={closeCheckboxModal}
      footer={[
        <>
          <ButtonStyledCurrentPage onClick={handleSelectCurrentPage}>
            <Text>Только для текущей страницы</Text>
            <Info
              iconProps={{ marginLeft: '4px' }}
              icon={HelpCircle}
              tooltip={{
                title:
                  'Выделение первых 10 сотрудников и последующих, загруженных сотрудников на странице.'
              }}
            />
          </ButtonStyledCurrentPage>
          <ButtonStyledActiveFilter onClick={handleSelectActiveFilter}>
            <Text>Для активного фильтра</Text>
            <Info
              iconProps={{ marginLeft: '4px' }}
              icon={HelpCircle}
              tooltip={{
                title:
                  'Выделение всех сотрудников, соответствующих заданным фильтрам (роль, отдел, поиск).'
              }}
            />
          </ButtonStyledActiveFilter>
        </>
      ]}
    >
      <ModalTitle>
        Применить действие для всех сотрудников по активному фильтру или только для текущей
        страницы?
      </ModalTitle>
    </SModal>
  );
};

export default CheckboxModal;
