export default {
  teamPage: {
    unitTable: {
      empty: 'Non ci sono dati',
      infoIcons: {
        info: 'Questa tabella mostra le analisi di riepilogo per tutte le valutazioni',
        scoreInfo:
          'Punteggio medio tra tutte le valutazioni per il modulo di valutazione selezionato',
        scoreInfoPrevious:
          'Punteggio medio tra tutti i voti, per la forma di valutazione scelta rispetto al periodo passato',
        reviewsCountInfo:
          'Il numero di tutte le valutazioni per i moduli di valutazioni selezionati',
        greenZoneCountInfo:
          'Percentuale di valutazioni che rientrano nella zona verde per i moduli di valutazione selezionati',
        greenZoneCountInfoPrevious:
          "Percentuale di controlli che rientrano nell'area verde per i moduli di valutazione selezionati rispetto al periodo passato",
        allScoresInfo:
          'Tutte le valutazioni nel contesto delle zone di colore per i moduli di valutazione selezionati',
        previousResultInfo: 'Risultato relativo al periodo passato'
      },
      columns: {
        employee: 'Dipendente',
        unit: 'Dipartimento',
        score: 'Punteggio',
        reviewsCount: 'Valutazioni',
        greenZoneCount: 'Eccellente',
        allScores: 'Tutte le valutazioni',
        flags: 'Bandiere',
        previous: 'Periodo passato'
      },
      differenceArrowScoreLiteral: 'punteggio'
    },
    exportTable: {
      columns: {
        employee: 'Dipendente',
        unit: 'Dipartimento',
        averageScore: 'Punteggio medio',
        averageScorePrevious: 'GPA rispetto al periodo passato',
        totalCountReviews: 'Conteggio totale recensioni',
        greenZonePercentage: 'Percentuale di chiamate nella zona verde',
        greenZonePercentagePrevious:
          "Percentuale di chiamate nell'area verde rispetto al periodo passato",
        grayColorZone: 'Zona grigia (non classificata)',
        redColorZone: 'Valutazioni in zona rossa',
        yellowColorZone: 'Valutazioni in zona gialla',
        greenColorZone: 'Valutazioni nella zona verde',
        countCommentsWithoutFlag: 'Numero di commenti senza flag',
        redComments: 'Commenti rossi',
        yellowComments: 'Commenti gialli',
        greenComments: 'Commenti verdi'
      }
    },
    buttons: {
      exportTeamPageAnalytics: 'Esportare in Excel',
      units: 'Dipartimenti',
      employees: 'Dipendenti'
    },
    pageFilters: {
      exportModal: {
        title: 'Analitica esportata',
        description1: "Il risultato dell'esportazione arrivera' alla",
        description2: 'in pochi minuti'
      },
      messages: { exportTableFailed: "Impossibile esportare l'analisi" },
      filters: {
        unitPlaceholder: 'Seleziona reparto',
        unitPlaceholderMax: 'Selezionato',
        unitPlaceholderMax2: 'dipartimento'
      }
    }
  }
};
