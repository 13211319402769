import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { get, isEmpty, isEqual, orderBy } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import { getUserName } from 'components/UserPreview/getUserName';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { prepareData, prepareKeys, searchMaxValue } from './prepareFunctions';
import { WidgetCard } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const PhoneCallsAverageDurationByOperatorsHistory = ({
  widgetData,
  loadingWidgetId,
  colors,
  isWarningWidget
}) => {
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  let widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);
  const lastItem = Array.isArray(widgetValue) ? widgetValue[widgetValue.length - 1] : null;
  const dateLastUpdate = lastItem ? lastItem.dateLastUpdate : null;

  if (Array.isArray(widgetValue) && lastItem) {
    widgetValue = widgetValue.filter((item, idx) => idx !== widgetValue.length - 1);
  }
  const { id, type, filters, name } = widgetData;

  const widgetHistory = widgetValue.filter(item => !isEmpty(item.history));

  return (
    <>
      {isEmpty(widgetValue) ||
      isEmpty(widgetHistory) ||
      loadingWidgetId === id ||
      isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={12} key={id}>
          <WidgetCard>
            <SMLWidgetHeader
              name={name}
              type={type}
              widgetData={widgetData}
              filters={filters}
              dateLastUpdate={dateLastUpdate}
            />

            <ResponsiveBar
              data={orderBy(prepareData({ widgetValue }), 'period')}
              keys={prepareKeys({ widgetValue })}
              indexBy="period"
              margin={{ top: 30, right: 20, bottom: 110, left: 45 }}
              padding={0.3}
              maxValue={searchMaxValue({ widgetValue })}
              groupMode="grouped"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              tooltip={tooltip => (
                <>
                  <span>
                    {`${getUserName({ user: usersByIds[tooltip.id] })} - ${tooltip.data.period}: `}
                  </span>
                  {tooltip.data[tooltip.id] > 3600 ? (
                    <strong>
                      {moment.utc(tooltip.data[tooltip.id] * 1000).format('hh:mm:ss')}
                    </strong>
                  ) : (
                    <strong>{moment.utc(tooltip.data[tooltip.id] * 1000).format('mm:ss')}</strong>
                  )}
                </>
              )}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                format: d => moment(d).format('DD.MM.YY')
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => `${moment.duration(d, 'seconds').format('mm:ss')}`
              }}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default PhoneCallsAverageDurationByOperatorsHistory;
