import RedesignWidgetTable from 'components/Dashboard/RedesignWidgetTable';
import React from 'react';
import SText from 'components/Standard/SText';
import styled from 'styled-components';

const Cell = ({ children, strong, ...props }) => (
  <RedesignWidgetTable.Summary.Cell align="center" {...props}>
    <SText strong={strong}>{children}</SText>
  </RedesignWidgetTable.Summary.Cell>
);

export default styled(Cell)`
  & {
    background: ${({ background }) => background || 'var(--gray_4)'};
    border: 2px solid white;
    border-radius: 4px;
    padding: 5px;
  }
  &.ant-table-cell-fix-left {
    background: ${({ background }) => background || 'var(--gray_4)'};
  }
`;
