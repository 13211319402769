import api from 'core/api';
import decamelize from 'decamelize-keys-deep';

export const passwordResetRequest = ({ ...params }) => {
  return async () => {
    try {
      const decamelizedParams = decamelize({ ...params });
      const response = await api.passwordReset({
        ...decamelizedParams
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const passwordSetRequest = ({ ...params }) => {
  return async () => {
    try {
      const decamelizedParams = decamelize({ ...params });
      const response = await api.passwordSet({
        ...decamelizedParams
      });
      return response.body;
    } catch (error) {
      console.log(error);
    }
  };
};
