import { BarChart2, Trash2, Edit, MoreVertical, Settings } from 'react-feather';
import { Button, Col, Dropdown, Empty, Menu, Modal, Row, Tooltip, Typography } from 'antd';
import List from 'components/List';
import UserPreview from 'components/UserPreview';
import truncateString from 'core/utils/truncateString';
import { compact, get, isEmpty, throttle } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/calibrationSessionsListReducer';
import { calibrationSessionsResource } from 'redux/resources/calibrationSessions';
import { getCurrentUser } from 'redux/selectors/users';
import { updateCalibrationSessionSettingsForm } from 'redux/ui/calibrationSessionSettingsModal/reducer';
import Icon from 'components/Icon';

const CalibrationsList = ({
  calibrationSessionsByIds,
  calibrationSessions,
  history,
  deleteSession,
  updateCalibrationSessionSettingsForm,
  page,
  clearList,
  loadSessions,
  hasNextPage,
  updatePage,
  loading,
  tab,
  timeRange,
  onSessionDelete,
  currentUserId
}) => {
  const { Text } = Typography;
  useEffect(() => {
    clearList();
    return clearList;
  }, []);
  const { t } = useTranslation();

  useEffect(() => {
    clearList();
  }, [tab, timeRange]);

  useEffect(() => {
    loadSessions({
      page,
      filters: {
        status: tab,
        ...timeRange
      },
      include: 'author',
      sort: '-created_at'
    });
  }, [tab, page.number, timeRange]);

  const handleMenuClick = ({ key, id, checklistDefinitionId }) => {
    const actions = {
      view: () => history.push(`/calibration/${id}?checklistDefinitionId=${checklistDefinitionId}`),
      setting: () => updateCalibrationSessionSettingsForm(calibrationSessionsByIds[id]),
      result: () => history.push(`/calibration-result/${id}`),
      delete: () =>
        Modal.confirm({
          title: t('calibrationsPage.calibrationsList.item.confirmDelete.title'),
          okText: t('calibrationsPage.calibrationsList.item.confirmDelete.ok'),
          cancelText: t('calibrationsPage.calibrationsList.item.confirmDelete.cancel'),
          okType: 'danger',
          onOk: async () => {
            const result = await deleteSession({ id });
            if (result) onSessionDelete({ id });
          }
        })
    };
    actions[key]();
  };

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      updatePage({
        number: newPage
      });
    }
  }, 250);

  const isAbleToEditSession = ({ id }) =>
    get(calibrationSessionsByIds, `${id}.authorId`) === currentUserId;

  const isVisibleResultButton = ({ id }) =>
    get(calibrationSessionsByIds, `${id}.status`) === 'done' ||
    get(calibrationSessionsByIds, `${id}.authorId`) === currentUserId;

  const getMenu = ({ id, checklistDefinitionId }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id, checklistDefinitionId })}>
      <Menu.Item key="view">
        <Icon icon={Edit} />
        <span>{t('calibrationsPage.calibrationsList.item.menu.view')}</span>
      </Menu.Item>

      {isAbleToEditSession({ id }) && (
        <Menu.Item key="setting">
          <Icon icon={Settings} />
          <span>{t('calibrationsPage.calibrationsList.item.menu.setting')}</span>
        </Menu.Item>
      )}

      {isVisibleResultButton({ id }) && (
        <Menu.Item key="result">
          <Icon icon={BarChart2} />
          <span>{t('calibrationsPage.calibrationsList.item.menu.result')}</span>
        </Menu.Item>
      )}

      {isAbleToEditSession({ id }) && (
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('calibrationsPage.calibrationsList.item.menu.delete')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = {
    name: {
      render: name => (
        <Row type="flex" align="middle" gutter={[4, 4]}>
          <Col>
            <Text strong>{t('calibrationsPage.calibrationsList.item.name')}</Text>
          </Col>
          <Col>
            <Tooltip title={name}>
              <Text>{truncateString(name, 25)}</Text>
            </Tooltip>
          </Col>
        </Row>
      ),
      col: { span: 5 }
    },
    authorId: {
      render: authorId => (
        <Row type="flex" align="middle" gutter={[4, 4]}>
          <Col>
            <Text strong>{t('calibrationsPage.calibrationsList.item.organizer')}</Text>
          </Col>
          <Col>
            <UserPreview disabled userId={authorId} showAvatar truncateSize={25} />
          </Col>
        </Row>
      ),
      col: { span: 8 }
    },
    scheduledTime: {
      render: scheduledTime => (
        <>
          <Text strong>{t('calibrationsPage.calibrationsList.item.scheduledTime')}</Text>
          <Text>{moment(scheduledTime).format('DD/MM/YYYY')}</Text>
        </>
      ),
      col: { span: 4 }
    },
    status: {
      render: (status, { reviewsIds, folderItemsIds, membersIds }) => (
        <>
          <Text strong>{t('calibrationsPage.calibrationsList.item.reviewedItems')}</Text>
          <Text>{`${reviewsIds.length}/${folderItemsIds.length * membersIds.length}`}</Text>
        </>
      ),
      col: { span: 5 }
    },
    menu: {
      render: (calibrationSession, { id, checklistDefinitionId }) => (
        <Row type="flex" align="middle" justify="end">
          <Dropdown overlay={getMenu({ id, checklistDefinitionId })} trigger={['click']}>
            <Button icon={<Icon icon={MoreVertical} />} />
          </Dropdown>
        </Row>
      ),
      col: { span: 2 }
    }
  };

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        className="list-scroll-container"
      >
        <List dataSource={calibrationSessions} columns={columns} />
        {loading && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">{t('calibrationsPage.calibrationsList.item.loading')}</Text>
              </Col>
            </Row>
          </Col>
        )}
        {!loading && !hasNextPage && isEmpty(calibrationSessions) && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Empty
                  description={
                    <Text type="secondary">
                      {t('calibrationsPage.calibrationsList.item.empty')}
                    </Text>
                  }
                />
              </Col>
            </Row>
          </Col>
        )}

        {!loading && !hasNextPage && !isEmpty(calibrationSessions) && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">{t('calibrationsPage.calibrationsList.item.all')}</Text>
              </Col>
            </Row>
          </Col>
        )}
      </PerfectScrollbar>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ids, loading, page, totalPages } = state.calibrationSessionsList;
  const { timeRange } = state.uiCalibrationsPage;
  const { byIds } = state.calibrationSessionsResource;
  const usersByIds = state.usersResource.byIds;
  const calibrationSessions = compact(ids.map(id => byIds[id]));
  const currentUserId = get(getCurrentUser(state), 'id', '');

  return {
    calibrationSessionsByIds: byIds,
    calibrationSessions,
    usersByIds,
    loading,
    page,
    hasNextPage: totalPages > page.number,
    timeRange,
    currentUserId
  };
};
const mapDispatchToProps = {
  onSessionDelete: actions.onSessionDelete,
  updatePage: actions.updatePage,
  clearList: actions.clearList,
  loadSessions: operations.load,
  deleteSession: calibrationSessionsResource.operations.deleteById,
  updateCalibrationSessionSettingsForm
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalibrationsList));
