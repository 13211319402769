export default {
  errorPages: {
    forbidden: { title: 'Acceso a la página prohibido. Diríjase al admoinistrador del portal.' },
    internalServerError: { title: 'Algo salió mal. Pruebe reiniciar la página.' },
    loadingPage: { title: 'Descargar datos...' },
    mobileBlocked: {
      title: 'Uff...',
      description: 'La versión mobil aún se está elaborando.'
    },
    crashApp: {
      title: 'Algo salió mal.',
      description:
        'Por favor, póngase en contacto con el soporte técnico para solucionar este problema.'
    },
    notFound: { title: 'Esa dirección no existe.' },
    unauthorized: { title: 'Para seguir trabajando hay que entrar' },
    buttons: {
      login: 'Entrar',
      goBack: 'Atrás',
      goToHomePage: 'A la página principal'
    }
  }
};
