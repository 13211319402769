import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import React from 'react';
import moment from 'moment';
import { get, isEqual } from 'lodash';
import { COMMENTS_FLAGS_COLORS_RELATIONS, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import UserPreview from 'components/UserPreview';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { InlineCommentText } from 'components/Comments/InlineCommentText';

const ObjectionComment = ({ appeal, comment }) => {
  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds);

  const uploadedFiles = get(comment, 'uploadedFilesIds', []).map(
    fileId => uploadedFilesByIds[fileId]
  );

  const review = useSelector(state => state.reviewsResource.byIds[appeal?.reviewId], isEqual);

  return (
    <SCard
      shadowed={false}
      bordered={`1px solid ${
        comment?.commentType === 'appellant_comment'
          ? '#839df2'
          : FLAGS_TO_COLORS_RELATIONS[comment?.metadata?.ratingFlag] ||
            FLAGS_TO_COLORS_RELATIONS.without_flag
      }`}
      background={
        comment?.commentType === 'appellant_comment'
          ? 'var(--gray_primary)'
          : COMMENTS_FLAGS_COLORS_RELATIONS[comment?.metadata?.ratingFlag] ||
            COMMENTS_FLAGS_COLORS_RELATIONS.without_flag
      }
    >
      <SRow type="flex" align="middle" wrap={false}>
        <SCol flex="auto">
          <UserPreview
            userId={comment?.authorId}
            showAvatar
            size="extraSmall"
            disabled
            hidden={comment?.authorId === review?.reviewerId}
          />
        </SCol>
        <SCol flex="none">
          <SText type="secondary" fontSize="12px" marginLeft="6px">
            {moment(comment?.createdAt).format('DD/MM/YYYY, HH:mm')}
          </SText>
        </SCol>
      </SRow>
      <SRow>
        <SCol span={24}>
          <InlineCommentText
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(comment?.text, {
                ALLOWED_ATTR: ['target', 'href']
              })
            }}
          />
          {uploadedFiles.map(file => (
            <UploadedFile key={file?.id} uploadedFile={file} allFiles={uploadedFiles} />
          ))}
        </SCol>
      </SRow>
    </SCard>
  );
};

export default ObjectionComment;
