import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  errors: []
};

export const setLoading = createAction('search/setLoading');
export const catchError = createAction('search/catchError');

export const customCommunicationReducer = createReducer(defaultState, {
  [setLoading]: (state, { payload }) => ({ ...state, loading: payload }),
  [catchError]: (state, { payload }) => ({ ...state, erros: [...state.errors, payload] })
});
