import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation';
import translationRU from './locales/ru/translation';
import translationES from './locales/es/translation';
import translationIT from './locales/it/translation';

const fallbackLng = ['ru'];

const resources = {
  ru: {
    translation: translationRU
  },
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  it: {
    translation: translationIT
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng,
    resources,
    debug: false,
    whitelist: ['ru', 'en', 'es', 'it'],
    nsSeparator: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
