import React from 'react';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { layout, space, color, position, flexbox } from 'styled-system';

const StyledIcon = styled.span`
&&{
  ${flexbox}
  ${space}
  ${position}
  ${color}
  ${layout}

}

&&.ant-tooltip-open {
  line-height: 0px;
}

`;

const CustomIcon = ({ icon, size = '16px', ...props }) => {
  const Component = icon;

  return (
    <StyledIcon className="anticon" role="img" {...props}>
      {Component?.type === 'svg' ? (
        <Icon component={icon} {...props} style={{ fontSize: size }} />
      ) : (
        <Component size={size} {...props} />
      )}
    </StyledIcon>
  );
};

export default CustomIcon;
