import { PERMISSIONS } from 'core/utils/constants';
import { doesAnyParentHasProperty } from 'core/utils/requestMethods';

import { keyBy } from 'lodash';

export const isParentHidden = ({ commentsByIds, commentId }) =>
  doesAnyParentHasProperty({
    nodesByIds: commentsByIds,
    nodeId: commentId,
    propertySelector: comment => {
      return comment.hidden;
    }
  });

// returns array of nodes that has no hidden parrent
const getCommentsWithoutHiddenParrents = ({ comments }) => {
  const commentsByIds = keyBy(comments, 'id');
  return comments.filter(
    comment => !(comment.hidden || isParentHidden({ commentsByIds, commentId: comment.id }))
  );
};

export const getCommentsFilteredByPermission = ({ currentUserPermissions = [], comments }) => {
  if (currentUserPermissions.includes(PERMISSIONS.CAN_SEE_AND_MANAGE_HIDDEN_COMMENTS)) {
    return comments;
  }

  return getCommentsWithoutHiddenParrents({ comments });
};
