import { get, put, destroy } from 'redux-bees';

const phoneCallsEndpoints = apiPath => ({
  getPhoneCalls: { method: get, path: `${apiPath}/calls` },
  getMediaUrl: { method: get, path: `${apiPath}/calls/get_calls_media_url?id=:id` },
  getPhoneCall: { method: get, path: `${apiPath}/calls/:id` },
  updatePhoneCall: { method: put, path: `${apiPath}/calls/:id` },
  deletePhoneCall: { method: destroy, path: `${apiPath}/calls/:id` }
});

export default phoneCallsEndpoints;
