import { get, post, put } from 'redux-bees';

const organizationEndpoints = apiPath => ({
  getCurrentOrganization: { method: get, path: `${apiPath}/organization/units` },
  getOrganizationInfo: { method: get, path: `${apiPath}/organization` },
  updateOrganizationInfo: { method: put, path: `${apiPath}/organization` },
  getUnitsIdsCommonForUsers: {
    method: post,
    path: `${apiPath}/organization/units/common_for_users`
  },
  addUsersByUnit: { method: get, path: `${apiPath}/organization/units/common_for_units` }
});

export default organizationEndpoints;
