import createListReducer from 'core/redux/lists/listReducer';
import { promptsQuestionsResource } from '../resources/promptsQuestions';

export const listParams = {
  name: 'promptsQuestionsList',
  loader: promptsQuestionsResource.operations.load,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const promptsQuestionsListReducer = reducer;

export { actions, promptsQuestionsListReducer, operations };
