import { FlagFilled } from '@ant-design/icons';
import { FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import React from 'react';
import styled from 'styled-components';

const YellowFlag = props => <StyledFlag {...props} />;

const StyledFlag = styled(FlagFilled)`
  &.anticon {
    color: ${FLAGS_TO_COLORS_RELATIONS.yellow};
  }
  ${props => props.css}
`;

export default YellowFlag;
