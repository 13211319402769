import autofailsWidgets from 'pages/DashboardPages/AutofailScoresPage/autofailsWidgets';
import unitsAnalyticsWidgets from 'pages/DashboardPages/UnitsAnalyticsPage/unitsAnalyticsWidgets';
import smallWidgets from './smallWidgets';
import mediumWidgets from './mediumWidgets';
import largeWidgets from './largeWidgets';
import extraLargeWidgets from './extraLargeWidgets';

export default {
  ...smallWidgets,
  ...mediumWidgets,
  ...largeWidgets,
  ...extraLargeWidgets,
  ...autofailsWidgets,
  ...unitsAnalyticsWidgets
};
