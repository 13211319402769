import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { get, intersection } from 'lodash';
import { configureStore } from './configureStore';
import Forbidden from '../../pages/ErrorPages/Forbidden';

export const buildRequireConditionProcessor = conditionCallback => {
  return compose(
    connect(state => ({
      currentUser: get(state, 'reduxTokenAuth.currentUser', {}),
      isLoading: get(state, 'reduxTokenAuth.currentUser.isLoading', true)
    }))(conditionCallback)
  );
};

export const requireOrganizationSetting = (ComponentToRender, settingsPath) => {
  return buildRequireConditionProcessor(({ currentUser, isLoading, ...props }) => {
    const isSettingEnabled = get(currentUser, settingsPath, false);

    return isSettingEnabled ? (
      <ComponentToRender {...props} />
    ) : (
      <Forbidden isLoading={isLoading} />
    );
  });
};

export const requirePermissions = ({ ComponentToRender, accessPermission, accessPermissions }) => {
  return buildRequireConditionProcessor(({ currentUser, isLoading, ...props }) => {
    const currentUserPermissions = get(currentUser, 'attributes.user.role.permissions', []);

    if (currentUserPermissions === null) return <Forbidden />;

    return currentUserPermissions.includes(accessPermission) ||
      intersection(currentUserPermissions, accessPermissions).length !== 0 ? (
      <ComponentToRender {...props} />
    ) : (
      <Forbidden isLoading={isLoading} />
    );
  });
};

export const requireSignIn = RenderComponent => {
  return compose(
    withRouter,
    connect(state => ({ authUser: state.reduxTokenAuth.currentUser }))
  )(({ authUser, ...props }) => {
    useEffect(() => {
      if (!authUser.isSignedIn && !authUser.isLoading) props.history.push('/login');
    }, [authUser, props.history]);
    return authUser.isSignedIn ? <RenderComponent {...props} /> : null;
  });
};

export const requireSignInAndPermission = params => requireSignIn(requirePermissions(params));

export const initialState = {
  reduxTokenAuth: {
    currentUser: {
      isLoading: false,
      isSignedIn: false,
      hasVerificationBeenAttempted: false,
      attributes: {
        username: 'username',
        fullname: 'fullname',
        user: {
          roles: [],
          organization: {}
        }
      }
    }
  }
};

export const store = configureStore({ initialState });
