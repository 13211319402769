export default {
  triggersSettingsPage: {
    loading: 'Загрузка',
    card: {
      titles: {
        create: 'Создать тег',
        edit: 'Редактировать тег'
      },
      buttons: {
        enable: 'Активировать',
        disable: 'Деактивировать',
        settings: 'Перейти к настройкам тега',
        delete: 'Удалить тег',
        createTaskDefinitionCard: 'Создать тег'
      },
      confirmDelete: {
        title: 'Вы уверены, что хотите удалить тег?',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      info: {
        type: 'Тип: ',
        author: 'Автор',
        status: {
          title: 'Статус',
          enable: 'Активен',
          disable: 'Не активен'
        },
        checklistDefinition: 'Форма оценки',
        createdAt: 'Cоздан'
      },
      loading: 'Загружаем теги...😤',
      listLoaded: 'Это все! 😊'
    },
    editTaskDefinition: {
      messages: {
        tagSuccessfullyUpdated: 'Тег обновлен',
        tagSuccessfullySaved: 'Тег сохранен',
        tagSuccessfullyDeleted: 'Тег удален',
        addSettingsToAutomaticTag: 'Добавьте настройки для вашего автоматического тега:'
      },
      buttons: {
        cancel: 'Отменить',
        save: 'Сохранить'
      },
      text: {
        form: 'от:',
        to: 'до:'
      },
      empty: {
        checklistDefinition: 'Не выбрана форма оценки или в форме оценки нет ни одного критерия',
        checklistDefinitionItemName: 'Не задано название'
      },
      form: {
        name: 'Название',
        namePlaceholder: 'Введите название',
        checklistDefinition: 'Форма оценки',
        type: {
          title: 'Тип',
          automatic: {
            name: 'Автоматический ',
            description: '(добавляется к звонку по итогам заполнения формы оценки)'
          },
          manual: {
            name: 'Ручной ',
            description: '(можно самостоятельно добавить к звонку)'
          }
        }
      },
      changeTagTypeConfirm: {
        title: 'Поменять тип тега?',
        description: 'Все несохраненные данные будут удалены',
        ok: 'Да',
        cancel: 'Нет'
      },
      changeChecklistDefinitionConfirm: {
        title: 'Поменять форму оценки?',
        description: 'Все несохраненные данные будут удалены',
        ok: 'Да',
        cancel: 'Нет'
      },
      resetChecklistDefinitionConditionsConfirm: {
        title: 'Условие не может включать в себя критерии или группы критериев',
        description: 'Сбросить условия для всей формы оценки?',
        ok: 'Да',
        cancel: 'Нет'
      },
      resetChecklistDefinitionQuestionsConditionsConfirm: {
        title: 'Условие не может включать в себя критерии',
        description: 'Сбросить условия для группы критериев?',
        ok: 'Да',
        cancel: 'Нет'
      }
    }
  }
};
