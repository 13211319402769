import { flatMap, flatten, get, find, mapValues, keyBy, uniq } from 'lodash';

export const prepareData = ({ widgetValue, usersByIds }) => {
  return Object.keys(widgetValue).map(userId => ({
    name: usersByIds[userId]?.name,
    ...mapValues(keyBy(widgetValue[userId], 'name'), 'value')
  }));
};

export const prepareKeys = ({ widgetValue }) => {
  const value = Array.isArray(widgetValue) ? widgetValue : Object.keys(widgetValue);
  return uniq(flatMap(value.map(userId => get(widgetValue, userId, []).map(item => item.name))));
};

export const prepareColors = ({ widgetValue, keys }) => {
  const labelObjects = flatten(Object.values(widgetValue));
  return keys.map(name => get(find(labelObjects, ['name', name]), 'color', ''));
};
