import { Alert, Modal } from 'antd';
import QuestionsList from 'components/Questions/QuestionsList';
import { SCALE_TYPES } from 'core/utils/constants';
import { basePosition } from 'core/utils/dnd';
import { get, keyBy, last, sortBy } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getQuestionGroupBindings } from 'redux/selectors/checklistItems/checklistItems';
import {
  setAddingQuestionToGroup,
  setSelectedQuestionsFromSaved,
  setSelectingQuestionsFromSaved
} from 'redux/ui/checklistEditor/reducer';
import uniqid from 'uniqid';

const QuestionsFromLibraryModal = ({
  selectingQuestionsFromSaved,
  selectedQuestionsFromSaved,
  setSelectedQuestionsFromSaved,
  setSelectingQuestionsFromSaved,
  onSubmit,
  addingQuestionToGroup,
  questionGroupBindings = [],
  questionsByIds,
  currentChecklist,
  loading,
  setAddingQuestionToGroup
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const onCancel = () => {
    setAddingQuestionToGroup({});
    setSubmitting(false);
    setSelectingQuestionsFromSaved(false);
  };

  const rowSelection = {
    onSelect: setSelectedQuestionsFromSaved,
    selected: selectedQuestionsFromSaved || []
  };

  const onAddQuestions = async () => {
    const { questionGroup } = addingQuestionToGroup;
    const startPosition = get(last(questionGroupBindings), 'position', 0) + basePosition;
    const questionToGroupBindings = selectedQuestionsFromSaved.map((questionId, i) => ({
      id: uniqid(),
      questionId,
      percentage: 1,
      position: startPosition + i * basePosition,
      questionGroupId: questionGroup.id
    }));

    setSubmitting(true);
    await onSubmit({
      questions: keyBy(
        selectedQuestionsFromSaved.map(id => questionsByIds[id]),
        'id'
      ),
      questionToGroupBindings
    });

    return onCancel();
  };

  return (
    <Modal
      visible={selectingQuestionsFromSaved}
      onCancel={onCancel}
      width={900}
      okText={t('checklistsPage.questionsFromLibraryModal.ok')}
      cancelText={t('checklistsPage.questionsFromLibraryModal.cancel')}
      onOk={onAddQuestions}
      okButtonProps={{ loading: submitting || loading }}
      title={t('checklistsPage.questionsFromLibraryModal.title')}
    >
      <Alert message={t('checklistsPage.questionsFromLibraryModal.alert.title')} type="info" />
      <QuestionsList
        listStyles={{ maxHeight: '36vh' }}
        rowSelection={rowSelection}
        filters={{
          scaleType:
            currentChecklist.scaleType === SCALE_TYPES.custom.type
              ? undefined
              : currentChecklist.scaleType
        }}
        allowActions={false}
        currentChecklist={currentChecklist}
      />
    </Modal>
  );
};

const mapStateToProps = state => ({
  currentChecklist: state.uiChecklistEditor.currentChecklist,
  selectingQuestionsFromSaved: state.uiChecklistEditor.selectingQuestionsFromSaved,
  selectedQuestionsFromSaved: state.uiChecklistEditor.selectedQuestionsFromSaved,
  questionsByIds: state.questionsResource.byIds,
  addingQuestionToGroup: state.uiChecklistEditor.addingQuestionToGroup,
  loading: state.questionToGroupBindingsResource.createStarted,
  questionGroupBindings: sortBy(
    getQuestionGroupBindings(
      state,
      get(state.uiChecklistEditor.addingQuestionToGroup, 'questionGroup', {})
    ),
    'position'
  )
});

const mapDispatchToProps = {
  setAddingQuestionToGroup,
  setSelectedQuestionsFromSaved,
  setSelectingQuestionsFromSaved
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsFromLibraryModal);
