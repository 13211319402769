import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  isQuestionDetailsModalVisible: false,
  openQuestionId: '',
  openQuestionData: []
};

export const toggleQuestionDetailsModal = createAction(
  'uiQuestionAnswersHiModal/toggleQuestionDetailsModal'
);

export const uiQuestionDetailsModalReducer = createReducer(defaultState, {
  [toggleQuestionDetailsModal]: state => ({
    ...state,
    isQuestionDetailsModalVisible: !state.isQuestionDetailsModalVisible
  })
});
