import { createAction, createReducer } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

const defaultState = {
  isVisibleDrawer: false,
  isVisibleRowsPreview: false,
  activeDashboardId: '',
  activeDashboardName: '',
  chartsView: {
    widgets: {}
  }
};

export const setActiveDashboard = createAction('uiDashboard/setActiveDashboard');
export const toogleVisibleDrawer = createAction('uiDashboard/toogleVisibleDrawer');
export const toogleVisibleRowsPreview = createAction('uiDashboard/toogleVisibleRowsPreview');
export const updateActiveChartsViewItem = createAction('uiDashboard/updateActiveChartsViewItem');

export const uiDashboardReducer = createReducer(defaultState, {
  [setActiveDashboard]: (state, { payload }) => ({
    ...state,
    activeDashboardId: payload?.id || state.activeDashboardId,
    activeDashboardName: isNil(payload?.name) ? state.activeDashboardName : payload.name
  }),
  [toogleVisibleDrawer]: state => ({
    ...state,
    isVisibleDrawer: !state.isVisibleDrawer
  }),
  [toogleVisibleRowsPreview]: state => ({
    ...state,
    isVisibleRowsPreview: !state.isVisibleRowsPreview
  }),
  [updateActiveChartsViewItem]: (state, { payload }) => ({
    ...state,
    chartsView: {
      widgets: {
        ...state.chartsView.widgets,
        ...payload
      }
    }
  })
});
