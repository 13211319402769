export default {
  kpi: {
    condition: {
      modalTitle: 'Настройки отчета',
      conditions: 'Условия',
      if: 'Если оценка от',
      to: 'до',
      then: 'то KPI',
      add: 'Новое условие',
      reportTitle: 'Название отчета',
      dep: 'Отдел',
      operator: 'Оператор',
      remove: 'Удалить условие'
    }
  }
};
