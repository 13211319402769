import { fbPixelId } from 'core/config';
import { useEffect } from 'react';
import Pixel from 'react-facebook-pixel';

const FacebookPixel = ({ history }) => {
  useEffect(() => {
    if (!fbPixelId) return;
    Pixel.init(fbPixelId);

    history.listen(location => {
      Pixel.pageView();
    });
  }, []);

  if (!fbPixelId) return null;

  return null;
};

export default FacebookPixel;
