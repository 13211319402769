import { createSelector } from '@reduxjs/toolkit';
import { reduce, get } from 'lodash';
import { getClientInteractionsByIds } from './clientInteractions';

export const getFoldersByIds = state => state.foldersResource.byIds;
export const getFolderItemsByIds = state => state.folderItemsResource.byIds;

export const getFolderItemsWithClientInteractionsByIds = createSelector(
  getFolderItemsByIds,
  getClientInteractionsByIds,
  (itemsByIds, clientInteractionsByIds) =>
    reduce(
      itemsByIds,
      (acc, curr) => ({
        ...acc,
        [curr.id]: {
          ...curr,
          clientInteraction: get(clientInteractionsByIds, curr.clientInteractionId, {})
        }
      }),
      {}
    )
);
