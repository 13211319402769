export default {
  workPlanTasks: {
    taskPlaceholder: 'Task',
    colorizedNumbers: {
      completed: 'Completed reviews count',
      incompleted: 'Incompleted reviews count',
      total: 'Total reviews count'
    },
    tableColumns: {
      reviewsCount: 'Evaluate',
      reviewsCount2: 'Reviews',
      completed: 'Completed',
      incompleted: 'Incompleted',
      closeTask: 'Close task',
      stopRepeating: 'Stop repetitions',
      name: 'Task name',
      lastTaskTimeFrom: 'Start Date',
      lastTaskTimeTo: 'End date',
      creator: 'Creator',
      schedule: 'Schedule',
      repeatedCount: 'Executions',
      reviewCreatedAt: 'Date and time of review',
      progress: 'Progress',
      repeat: 'Repeat'
    },
    assignments: {
      closeTask: 'Close task',
      close: 'Finish',
      taskStartedAt: 'Launch date',
      assignmentsCount: 'Number of reviewers',
      completedTaskItemsCount: 'Evaluations assigned'
    },
    filters: {
      createTask: 'Create task'
    },
    confirmStopRepeating: 'Stop repetitions?',
    confirmClosing: 'Close task?',
    confirmDelete: 'Delete Configuration?',
    tasksStartedAt: 'First launch date',
    reviewersCount: 'Reviewers count',
    operatorsCount: 'Operators count',
    reviewedOperator: 'Reviewed operator',
    communicationsNotFound: 'No communications found',
    nextCommunicationDate: 'Communications will be added in the next run, which will be',
    checkCommunication: 'Check the task settings',
    howToBlock: {
      howToButton: 'How to use',
      watchVideo: 'Watch the video',
      readManual: 'Read full instructions',
      startTour: 'Interactive tour'
    },
    menu: {
      inProgress: 'In progress',
      pending: 'Pending',
      total: 'General execution'
    },
    tooltips: {
      configurationProgress:
        'The ratio of the number of verified communications to the total number in the sample. Progress information indicates the last task.',
      progress:
        'The ratio of the number of verified communications to the total number in the sample.',
      schedule:
        'Communication sampling repetition period. The sample can be automatically updated and run daily, weekly, or monthly.',
      repeatedCount: 'The number of executions of tasks to check the selection.'
    },
    repeat: 'repeat',
    confirmModal: {
      title: 'You are going to complete the task. Continue?',
      content: 'All its repetitions in the future will also be completed.'
    }
  }
};
