import { post } from 'redux-bees';

const brightPatternEndpoints = apiPath => ({
  brightPatternSyncDataByPeriod: {
    method: post,
    path: `${apiPath}/integrations/bright_pattern/:id/fetch_data`
  }
});

export default brightPatternEndpoints;
