import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import SCard from 'components/Standard/SCard';
import { getUsersByUnitId } from 'redux/selectors/users';
import STable from 'components/Standard/STable';
import CardTableHeader from 'pages/DashboardPages/TeamPage/Components/CardTableHeader';
import { getDataFromWidget } from 'pages/DashboardPages/TeamPage/Components/utils';
import tableRows from './tableRows';
import tableColumns from './tableColumns';

const UsersTable = ({
  unit,
  widgets,
  filters,
  currentUserId,
  currentUserPermissions,
  checklistDefinitionsByIds,
  loading
}) => {
  const unitId = unit?.id;
  const users = useSelector(state => Object.values(getUsersByUnitId(state, { unitId })), isEqual);

  const { name } = unit;

  const {
    checklistColorZoneData,
    previousChecklistColorZoneData,
    averageScoreData,
    previousPeriodAverageScoreData,
    reviewsCountData,
    previousReviewsCountData,
    ratingFlagsData
  } = getDataFromWidget({ widgets });

  return (
    <SCard loading={loading} bordered shadowed>
      <CardTableHeader name={name} />

      <STable
        columns={tableColumns({
          currentUserPermissions,
          currentUserId,
          filters,
          checklistDefinitionsByIds
        })}
        dataSource={tableRows({
          checklistColorZoneData,
          previousChecklistColorZoneData,
          reviewsCountData,
          previousReviewsCountData,
          averageScoreData,
          previousPeriodAverageScoreData,
          users,
          ratingFlagsData
        })}
        showSorterTooltip={false}
        pagination={false}
        tableLayout="fixed"
        fixSorterColumns
      />
    </SCard>
  );
};

export default UsersTable;
