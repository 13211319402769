import { Col, Row } from 'antd';
import { reduce, sortedIndexBy } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ClientMessage, OperatorMessage } from './styled';

const MessagesBoxes = ({ messages, duration, onMessageClick, playedSeconds }) => {
  const { clientMessages, operatorMessages } = useMemo(
    () =>
      reduce(
        messages,
        ({ clientMessages, operatorMessages }, message) => {
          if (message.role !== 'operator') {
            const index = sortedIndexBy(clientMessages, message, 'startedAt');
            const newMessages = [...clientMessages];
            newMessages.splice(index, 0, message);
            return { operatorMessages, clientMessages: newMessages };
          }

          const index = sortedIndexBy(operatorMessages, message, 'startedAt');
          const newMessages = [...operatorMessages];
          newMessages.splice(index, 0, message);
          return { clientMessages, operatorMessages: newMessages };
        },
        { clientMessages: [], operatorMessages: [] }
      ),
    [messages]
  );

  useEffect(() => {
    const playerTime = parseInt(playedSeconds);
    operatorMessages.forEach(item => {
      const msgTime = parseInt(item?.startTime);
      if (msgTime === playerTime) {
        onMessageClick(item.id);
      }
    });
    clientMessages.forEach(item => {
      const msgTime = parseInt(item?.startTime);
      if (msgTime === playerTime) {
        onMessageClick(item.id);
      }
    });
  }, [playedSeconds]);

  const renderMessageBlock = useCallback(
    ({ role, startTime, endTime, id }) => {
      const offset = (startTime * 100) / duration;
      const width = ((endTime - startTime) * 100) / duration;

      return role !== 'operator' ? (
        <ClientMessage offset={offset} width={width} onClick={() => onMessageClick(id)} />
      ) : (
        <OperatorMessage offset={offset} width={width} onClick={() => onMessageClick(id)} />
      );
    },
    [onMessageClick, duration]
  );

  return (
    <Row style={{ height: '100%', position: 'absolute', width: '100%' }}>
      <Col span={24} style={{ borderBottom: '1px solid var(--gray-border)' }}>
        {operatorMessages.map(renderMessageBlock)}
      </Col>
      <Col span={24}>{clientMessages.map(renderMessageBlock)}</Col>
    </Row>
  );
};

export default MessagesBoxes;
