import SButton from 'components/Standard/SButton';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAppealId,
  setQuestionIdsToAppealObjections,
  setQuestionIdsToAppealComments,
  setReviewState
} from 'redux/ui/checklistManager/reducer';
import { OutlinedButton } from 'components/Buttons';
import { every, find, get, isEmpty, isEqual, isNil, pick, pickBy, reduce } from 'lodash';
import { getCurrentUser, getCurrentUserPermissions } from 'redux/selectors/users';
import { PERMISSIONS } from 'core/utils/constants';
import { appellationsResource } from 'redux/resources/appellations';
import {
  getAnswersByIds,
  getChecklistsByIds,
  getChecklistsDefinitionsByIds
} from 'redux/selectors/checklists';
import { updateResource } from 'core/redux/resources/updateResource';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setCommunication } from 'redux/ui/clientInteractionDrawer/reducer';
import { getChecklistDefinitionQuestions } from 'redux/selectors/checklistItems/checklistItems';
import SRow from '../../../Standard/SRow';

const loadAppeal = ({ reviewId }) => async (dispatch, getState) => {
  try {
    const response = await dispatch(
      appellationsResource.operations.load({
        filters: {
          reviewId
        },
        include: ['comments.uploaded-files', 'comments.author', 'objections'].join(',')
      })
    );

    if (!isEmpty(response?.resources)) {
      const appeal = Object.values(response?.resources)[0];
      dispatch(setAppealId(appeal.id));
      const state = getState();
      const comments = reduce(
        state.appellationCommentsResource.byIds,
        (acc, comment) => {
          if (comment.appellationId !== appeal?.id) return acc;
          const key = comment.questionId;
          return {
            ...acc,
            [key]: {
              ...comment,
              uploadedFiles: comment.uploadedFilesIds.map(
                id => state.uploadedFilesResource.byIds[id]
              ),
              saved: true
            }
          };
        },
        {}
      );
      const objections = reduce(
        appeal.objectionsIds,
        (acc, id) => {
          const objection = state.appellationObjectionsResource.byIds[id];

          if (objection) {
            return { ...acc, [objection.questionId]: objection };
          }

          return acc;
        },
        {}
      );
      dispatch(setQuestionIdsToAppealComments(comments));
      dispatch(setQuestionIdsToAppealObjections(objections));
      return appeal;
    }
  } catch (error) {
    console.log(error);
  }
};

export const AppealHead = ({ reviewId, isCustomCommunication = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const appealId = useSelector(state => state.uiChecklistManager.appealId);
  const appeal = useSelector(state => state.appellationsResource.byIds[appealId], isEqual);
  const questionIdToObjection = useSelector(
    state => state.uiChecklistManager.questionIdToAppealObjection,
    isEqual
  );
  const review = useSelector(state => state.reviewsResource.byIds[reviewId], isEqual);
  const checklist = useSelector(state => getChecklistsByIds(state)[review?.checklistId], isEqual);

  const checklistDefinitionsByIds = useSelector(
    state => getChecklistsDefinitionsByIds(state),
    isEqual
  );
  const checklistAnswers = useSelector(
    state => pickBy(getAnswersByIds(state), { checklistId: checklist?.id }),
    isEqual
  );
  const checklistDefinitionId = get(checklist, 'checklistDefinitionId');
  const checklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistQuestions = useSelector(
    state => getChecklistDefinitionQuestions(state, checklistDefinition),
    isEqual
  );

  const currentUser = useSelector(getCurrentUser, isEqual);
  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);
  const questionIdToAppealComment = useSelector(
    state => state.uiChecklistManager.questionIdToAppealComment,
    isEqual
  );
  const questionIdToAnswer = useSelector(
    state => state.uiChecklistManager.questionIdToAnswerValue,
    isEqual
  );

  useEffect(() => {
    if (review) dispatch(loadAppeal({ reviewId }));
  }, [reviewId]);

  useEffect(() => {
    if (appeal?.questionObjectionsProcessed) {
      dispatch(setReviewState({ checklist }));
    }
  }, [appeal?.questionObjectionsProcessed]);

  const onNewAppeal = useCallback(() => {
    dispatch(setAppealId('new'));
  }, [dispatch]);

  const onSaveAppeal = async () => {
    try {
      Promise.all([
        await dispatch(
          appellationsResource.operations.create({
            reviewId,
            commentsAttributes: reduce(
              questionIdToAppealComment,
              (acc, comment, questionId) => [
                ...acc,
                {
                  text: comment.text,
                  uploadedFilesIds: comment.uploadedFilesIds,
                  questionId:
                    find(checklistAnswers, { questionId })?.id === undefined
                      ? find(checklistQuestions, { id: questionId })?.id
                      : null,
                  answerId: find(checklistAnswers, { questionId })?.id || null
                }
              ],
              []
            )
          })
        ),
        await dispatch(loadAppeal({ reviewId }))
      ]);
      message.success(t('messages.success.appealSended'));
    } catch (error) {
      console.log(error);
    }
  };

  const onCancelAppeal = useCallback(async () => {
    try {
      await dispatch(appellationsResource.operations.deleteById({ id: appealId }));
      dispatch(setAppealId(null));
      dispatch(setQuestionIdsToAppealObjections({}));
      dispatch(setQuestionIdsToAppealComments({}));
      message.success(t('messages.success.appealCanceled'));
      history.replace(`/user/${currentUser.id}/appeals`);
      dispatch(setCommunication({}));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, appealId]);

  const onProccessObjections = async () => {
    try {
      await dispatch(
        appellationsResource.operations.processObjections({
          id: appealId,
          objectionsAttributes: reduce(
            questionIdToObjection,
            (acc, objection, questionId) => [
              ...acc,
              {
                ...pick(objection, ['id', 'accepted']),
                answerAfter: questionIdToAnswer[questionId]
              }
            ],
            []
          )
        })
      );
      message.success(t('messages.success.processingCompleted'));
    } catch (error) {
      console.log(error);
    }
  };

  const onEditObjections = async () => {
    try {
      updateResource({
        resource: { ...appeal, questionObjectionsProcessed: false },
        dispatch,
        type: 'appellations'
      });
    } catch (error) {
      console.log(error);
    }
  };

  const showAppealHead =
    review &&
    (currentUserPermissions.includes(PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS) ||
      currentUserPermissions.includes(PERMISSIONS.CAN_CREATE_APPELLATIONS));

  const canSendAppeal =
    !isEmpty(questionIdToObjection) &&
    !isEmpty(questionIdToAppealComment) &&
    every(questionIdToAppealComment, comment => comment?.saved);

  if (!showAppealHead) return null;

  const canProccessObjections =
    !isEmpty(questionIdToObjection) &&
    every(questionIdToObjection, objection => !isNil(objection?.accepted));

  if (appealId === 'new') {
    // * save appeal
    return (
      <OutlinedButton
        showLoad
        onClick={onSaveAppeal}
        disabled={!canSendAppeal}
        size="big"
        width="auto"
        fontSize="14px"
      >
        {t('components.appealHead.sendAppeal')}
      </OutlinedButton>
    );
  }

  if (
    appealId &&
    appeal?.appellantId === currentUser?.id &&
    appeal?.assignedToId === currentUser?.id
  ) {
    return (
      <>
        <SRow width="100%" gutter={[4, 4]} type="flex" flexDirection="row">
          <SButton
            type="danger"
            marginTop={isCustomCommunication ? 15 : 0}
            showLoad
            onClick={onCancelAppeal}
            size="big"
            width="auto"
            fontSize="14px"
          >
            {t('components.appealHead.cancelAppeal')}
          </SButton>
          {appeal?.questionObjectionsProcessed ? (
            <SButton
              marginTop={isCustomCommunication ? 15 : 0}
              type="primary"
              onClick={onEditObjections}
              // width={isCustomCommunication ? '210px' : '100%'}
              size="big"
              width="auto"
              fontSize="14px"
            >
              {t('components.appealHead.editAppeal')}
            </SButton>
          ) : (
            <OutlinedButton
              marginTop={isCustomCommunication ? 15 : 0}
              showLoad
              onClick={onProccessObjections}
              disabled={!canProccessObjections}
              size="big"
              width="auto"
              fontSize="14px"
            >
              {t('components.appealHead.closeAppeal')}
            </OutlinedButton>
          )}
        </SRow>
      </>
    );
  }

  if (appealId && appeal?.appellantId === currentUser?.id) {
    // * cancel appeal
    return (
      <SButton
        type="danger"
        showLoad
        onClick={onCancelAppeal}
        size="big"
        width="auto"
        fontSize="14px"
      >
        {t('components.appealHead.cancelAppeal')}
      </SButton>
    );
  }

  // * appeal processing
  if (
    appealId &&
    appeal?.assignedToId === currentUser?.id &&
    !appeal?.questionObjectionsProcessed
  ) {
    // * review appeal
    return (
      <OutlinedButton
        showLoad
        onClick={onProccessObjections}
        disabled={!canProccessObjections}
        size="big"
        width="auto"
        fontSize="14px"
      >
        {t('components.appealHead.closeAppeal')}
      </OutlinedButton>
    );
  }

  if (appealId && appeal?.assignedToId === currentUser?.id && appeal?.questionObjectionsProcessed) {
    // * review appeal
    return (
      <SButton type="primary" onClick={onEditObjections} size="big" width="auto" fontSize="14px">
        {t('components.appealHead.editAppeal')}
      </SButton>
    );
  }

  //   * create appeal
  if (
    currentUserPermissions.includes(PERMISSIONS.CAN_CREATE_APPELLATIONS) &&
    !appealId &&
    review.reviewerId !== currentUser.id
  )
    return (
      <SButton type="primary" onClick={onNewAppeal} size="big" width="auto" fontSize="14px">
        {t('components.appealHead.createAppeal')}
      </SButton>
    );

  return null;
};
