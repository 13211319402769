import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Helmet } from 'react-helmet';
import {
  loadUnitsByTypeDashboard,
  deleteUnitsByTypeDashboardPageWidget
} from 'redux/entities/dashboardPages/unitsByTypeDashboardPage/operations';
import LoadingDashboard from 'pages/DashboardPage/Components/LoadingDashboard';
import { isEqual, orderBy, get } from 'lodash';
import { Empty, Pagination } from 'antd';
import ReportSettingsModal from 'components/ReportSettingsModal';
import { onAdd } from './Components/Widgets/utils';
import Filters from './Components/Filters';
import UnitsAnalytics from './Components/Widgets/UnitsByTypeAnalytics';
import SText from '../../../components/Standard/SText';
import { StyledEmpty, StyledErrorMessage } from '../../DashboardPage/styled';
import SCard from '../../../components/Standard/SCard';

const Units = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleWidgetId, setVisibleWidgetId] = useState();
  const dashboardPage = useSelector(
    state =>
      get(state, 'dashboardPages.dashboardsByTypes.scores_by_units_by_communication_type', {}),
    isEqual
  );
  const { page } = dashboardPage;

  useEffect(() => {
    dispatch(
      loadUnitsByTypeDashboard({
        page: {
          number: 1,
          size: 5
        }
      })
    );
  }, []);

  const unitsByTypeDashboardWidgetsIds = useSelector(
    state =>
      get(
        state,
        'dashboardPages.dashboardsByTypes.scores_by_units_by_communication_type.widgetsIds',
        []
      ),
    isEqual
  );

  const widgetsByIds = useSelector(state => state.dashboardWidgetsResource.byIds, isEqual);

  const orderedWidgets = orderBy(
    unitsByTypeDashboardWidgetsIds.map(widgetId => widgetsByIds[widgetId]),
    'createdAt',
    'desc'
  );

  const loading = useSelector(state => state.dashboardPages.loading);

  const renderDescription = () => {
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPages.noCreatedReports')}
        </StyledErrorMessage>
        <SText type="secondary">{t('dashboardPages.clickAddReportButton')}</SText>
      </>
    );
  };

  const onPaginationChange = pageNumber => {
    dispatch(
      loadUnitsByTypeDashboard({
        page: {
          number: pageNumber,
          size: 5
        }
      })
    );
  };

  return (
    <SRow>
      <Helmet>
        <title>Динамика оценок по отделам по типам коммуникаций</title>
      </Helmet>
      <SCol span={24}>
        <Filters />
      </SCol>
      <SCol span={24} padding="16px">
        {loading && <LoadingDashboard />}
        {!loading && orderedWidgets.length > 0 && (
          <>
            {orderedWidgets.map(widgetData => (
              <UnitsAnalytics
                key={widgetData.id}
                widgetData={widgetData}
                setVisibleWidgetId={setVisibleWidgetId}
              />
            ))}
            <SRow type="flex" justify="end" style={{ marginTop: '20px' }} gutter={[0, 16]}>
              <Pagination
                size="small"
                current={page?.page}
                total={page?.totalCount}
                pageSize={5}
                onChange={onPaginationChange}
              />
            </SRow>
          </>
        )}
        {!loading && orderedWidgets.length === 0 && (
          <SCard bordered shadowed bodyPadding="50px" headPadding="50px">
            <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={renderDescription()} />
          </SCard>
        )}
      </SCol>
      <ReportSettingsModal
        id={visibleWidgetId}
        setVisibleFilterModal={setVisibleWidgetId}
        onCancel={() => setVisibleWidgetId(false)}
        visible={visibleWidgetId}
        onAdd={onAdd}
        deleteDashboardPageWidget={deleteUnitsByTypeDashboardPageWidget}
      />
    </SRow>
  );
};
export default Units;
