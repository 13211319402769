import { Radio, Select } from 'antd';
import RangeInputs from 'components/Inputs/RangeInputs';
import SInput from 'components/Standard/SInput';
import SSelect from 'components/Standard/SSelect';
import { CUSTOM_FIELDS_TYPES, DATE_PICKER_MODES } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { find, isEmpty, isNil, some, sortBy, intersectionWith, isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DurationPicker from 'components/Inputs/DurationPicker';
import { useSelector } from 'react-redux';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import CustomDatePicker from 'components/DatePicker/DatePicker';

const { Option, OptGroup } = Select;

const CustomFieldFilter = ({
  customField,
  disabled,
  onChange,
  value,
  customFieldsByIds,
  customFieldFiltersByKeys
}) => {
  const { id, fieldType, key, name, options, dependsOnId, settings } = customField;
  const { t } = useTranslation();
  const salesPipelinesByIds = useSelector(state => state.salePipelinesResource.byIds, isEqual);
  const salesPipelinesStatusesByIds = useSelector(
    state => state.salesPipelineStatusesResource.byIds,
    isEqual
  );
  const selectedSalesPipelines = intersectionWith(
    Object.values(salesPipelinesByIds),
    isEmpty(value.pipelinesIds) ? Object.keys(salesPipelinesByIds) : value.pipelinesIds,
    ({ id }, filterId) => id === filterId
  );

  if (fieldType === CUSTOM_FIELDS_TYPES.DURATION) {
    return (
      <DurationPicker
        from={value?.from}
        to={value?.to}
        disabled={false}
        size="large"
        style={{ width: '260px' }}
        allowClear
        onChange={({ from, to }) => {
          if (!from && !to) return onChange({ from: '0', to: '300' });
          return onChange({
            from: isNil(from) ? '0' : `${from}`,
            to: isNil(to) ? '300' : `${to}`
          });
        }}
      />
    );
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.STRING || fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY) {
    return (
      <SInput
        disabled={disabled}
        value={value?.contains || undefined}
        onChange={e => onChange({ contains: e.target.value })}
        placeholder={name}
        size="large"
        width="260px"
      />
    );
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.NUMBER || fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY) {
    return (
      <RangeInputs
        value={value}
        precision={0}
        onChange={({ from, to }) => onChange({ from, to })}
        size="large"
        style={{ width: '260px' }}
      />
    );
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
    return (
      <SCol width="260px">
        <CustomDatePicker
          onChange={filters =>
            onChange({
              contains: { ...filters }
            })
          }
          value={{
            symbolicTimeRange: value?.contains?.symbolicTimeRange,
            timeFrom: value?.contains?.timeFrom,
            timeTo: value?.contains?.timeTo
          }}
          height="40px"
          top={42}
          mode={DATE_PICKER_MODES.custom.customFields}
        />
      </SCol>
    );
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.BOOLEAN) {
    return (
      <Radio.Group onChange={e => onChange({ contains: e.target.value })} value={value.contains}>
        <Radio value="true">{t('general.yes')}</Radio>
        <Radio value="false">{t('general.no')}</Radio>
      </Radio.Group>
    );
  }

  if (!isEmpty(settings)) {
    // id, fieldType, key, name, options, dependsOnId, settings
    const parentKey = customFieldsByIds[dependsOnId]?.key;
    const parentSelectedValue = customFieldFiltersByKeys[parentKey]?.eq;

    // * allow options on parent key or allow all
    const options = parentSelectedValue
      ? settings.filter(({ parentKey }) => parentKey === parentSelectedValue)
      : settings;

    const setControlledValueToUndefined = () => {
      if (customFieldFiltersByKeys[key]) {
        onChange(undefined);
      }

      const childKey = find(customFieldsByIds, field => field.dependsOnId === id)?.key;
      if (childKey && customFieldFiltersByKeys[childKey]) {
        onChange({ [childKey]: { eq: undefined } });
      }

      return undefined;
    };

    // * if value should be controlled - check if that option exists. if not - set to undefined
    const controlledValue =
      some(options, ({ key: allowedKey }) => allowedKey === customFieldFiltersByKeys[key]) ||
      !parentKey
        ? customFieldFiltersByKeys[key]?.eq
        : undefined;

    if (controlledValue !== customFieldFiltersByKeys[key]?.eq) {
      setControlledValueToUndefined();
    }

    return (
      <SSelect
        disabled={disabled}
        showSearch
        allowClear
        optionLabelProp="label"
        placeholder={name}
        value={
          parentSelectedValue
            ? controlledValue
            : customFieldFiltersByKeys[key]?.eq || value.eq || value.contains || undefined
        }
        onChange={value => onChange({ eq: value })}
        filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        size="large"
        width="260px"
      >
        {sortBy(options, ['text']).map(({ key, text }) => (
          <Option key={key} value={key} label={text}>
            {text}
          </Option>
        ))}
      </SSelect>
    );
  }

  if (!isEmpty(options)) {
    return (
      <SSelect
        disabled={disabled}
        showSearch
        allowClear
        optionLabelProp="label"
        placeholder={name}
        value={customFieldFiltersByKeys[key]?.eq || value.eq || value.contains || undefined}
        onChange={value => onChange({ eq: value })}
        filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        size="large"
        width="260px"
      >
        {options.map(option => (
          <Option key={option} value={option} label={option}>
            {option}
          </Option>
        ))}
      </SSelect>
    );
  }

  if (!isEmpty(salesPipelinesByIds) && key === 'sales_pipeline') {
    return (
      <SRow gutter={[16, 0]}>
        <SCol>
          <SSelect
            mode="multiple"
            disabled={disabled}
            showSearch
            allowClear
            optionLabelProp="label"
            maxTagCount={0}
            size="large"
            maxTagPlaceholder={selectedKeys =>
              `${t(
                'clientInteractionsPage.tableFilters.salesPipelines.salesPipelinesPlaceholder'
              )}: ${selectedKeys.length}`
            }
            placeholder={
              name ||
              t('clientInteractionsPage.tableFilters.salesPipelines.salesPipelinesPlaceholder')
            }
            width="260px"
            value={value.pipelinesIds}
            onChange={pipelineId =>
              onChange({
                pipelinesIds: pipelineId,
                pipelineStatusesIds: value.pipelineStatusesIds || []
              })
            }
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {Object.values(salesPipelinesByIds).map(({ name, id }) => (
              <Option value={id} key={id} label={name}>
                {name}
              </Option>
            ))}
          </SSelect>
        </SCol>
        <SCol>
          <SSelect
            disabled={disabled}
            mode="multiple"
            optionLabelProp="label"
            allowClear
            size="large"
            placeholder={
              name ||
              t(
                'clientInteractionsPage.tableFilters.salesPipelines.salePipelineStatusesPlaceholder'
              )
            }
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t(
                'clientInteractionsPage.tableFilters.salesPipelines.salePipelineStatusesPlaceholder'
              )}: ${selectedKeys.length}`
            }
            width="260px"
            onChange={statusId =>
              onChange({
                pipelinesIds: value.pipelinesIds || [],
                pipelineStatusesIds: statusId
              })
            }
            value={value.pipelineStatusesIds}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {selectedSalesPipelines.map(({ id, name, statusesIds = [] }) => {
              return (
                <OptGroup key={id} label={name}>
                  {statusesIds.reduce((acc, id) => {
                    const status = salesPipelinesStatusesByIds[id];
                    if (!status) return acc;
                    const component = (
                      <Option value={status.id} key={status.id} label={status.name}>
                        {status.name}
                      </Option>
                    );
                    return [...acc, component];
                  }, [])}
                </OptGroup>
              );
            })}
          </SSelect>
        </SCol>
      </SRow>
    );
  }

  return (
    <SInput
      disabled={disabled}
      value={value || undefined}
      onChange={e => onChange(e.target.value)}
      placeholder={name}
      size="large"
      width="260px"
    />
  );
};

export default React.memo(CustomFieldFilter);
