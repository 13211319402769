import React, { useState } from 'react';
import Icon from 'components/Icon';
import { MessageSquare } from 'react-feather';
import SButton from 'components/Standard/SButton';
import SModal from 'components/Standard/SModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { actions } from 'redux/lists/commentTemplatesListReducer';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import TemplateSelector from './Components/TemplateSelector';

const { updateFilters } = actions;

const CommentTemplates = ({ setCommentState, commentState, setEditorState }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isVisibleSelector, setVisibleSelector] = useState(false);
  const [buttonPosition, setButtonPosition] = useState(false);

  return (
    <>
      <StyledModal
        visible={isVisibleSelector}
        width={535}
        footer={null}
        onCancel={() => {
          dispatch(updateFilters({ commentsRatingFlags: '' }));
          setVisibleSelector(false);
        }}
        closable={false}
      >
        <TemplateSelector
          commentState={commentState}
          setCommentState={setCommentState}
          setEditorState={setEditorState}
          setVisibleSelector={setVisibleSelector}
        />
      </StyledModal>
      <Tooltip
        placement="bottom"
        title={t('templates.addTemplate')}
        overlayInnerStyle={{ fontSize: '12px' }}
      >
        <SButton
          ref={el => {
            if (el && !buttonPosition) return setButtonPosition(el.getBoundingClientRect());
          }}
          onClick={() => {
            setVisibleSelector(!isVisibleSelector);
          }}
          className="control-item button upload-button"
          border="none"
          boxShadow="none"
          backgroundColor="transparent"
          data-title={t('templates.addTemplate')}
          _hover="background-color: transparent"
        >
          <Icon icon={MessageSquare} color="#6A6F7B" />
        </SButton>
      </Tooltip>
    </>
  );
};

const StyledModal = styled(SModal)`
  &.ant-modal .ant-modal-body {
    min-height: 511px;
  }
`;

export default CommentTemplates;
