import createListReducer from 'core/redux/lists/listReducer';
import { calibrationSessionsResource } from 'redux/resources/calibrationSessions';

const defaultState = {
  page: {
    size: '15',
    number: '1'
  },
  filters: {
    status: 'pending'
  }
};

const additionalReducer = {
  onSessionCreate: (state, { payload }) => {
    const { id } = payload;
    return state.ids.includes(id) ? state : { ...state, ids: [id, ...state.ids] };
  },
  onSessionDelete: (state, { payload }) => {
    const { id } = payload;
    return state.ids.includes(id)
      ? { ...state, ids: [...state.ids.filter(sessionId => sessionId !== id)] }
      : state;
  }
};

const { actions, reducer, operations } = createListReducer({
  additionalReducer,
  name: 'calibrationsSessions',
  loader: calibrationSessionsResource.operations.load,
  defaultState,
  rebuildQuery: true,
  keepResults: true
});

const calibrationSessionsListReducer = reducer;

export { actions, calibrationSessionsListReducer, operations };
