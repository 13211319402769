import React from 'react';
import { Row } from 'antd';
import { ResponsiveBar } from '@nivo/bar';
import { ZONE_TO_COLOR_RELATIONS, COLOR_ZONE_LITERALS } from 'core/utils/constants';
import { tooltipSetter } from 'pages/DashboardPage/utils';
import styled from 'styled-components';
import { sum, get } from 'lodash';
import { beatifyFloat } from 'core/utils/convertFloat';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';

const ColorZonesBar = ({ data, reviewsCount, onClickHandler }) => {
  const history = useHistory();
  const { t } = useTranslation();
  // if (every([data], { low: 0, medium: 0, high: 0 })) return null;

  const low = get(data, 'low', 0);
  const medium = get(data, 'medium', 0);
  const high = get(data, 'high', 0);
  const empty = reviewsCount - sum([low, medium, high]);

  const percentageValues = {
    empty: (100 / reviewsCount) * empty,
    low: (100 / reviewsCount) * low,
    medium: (100 / reviewsCount) * medium,
    high: (100 / reviewsCount) * high
  };

  const preparedData = {
    id: uniqid(),
    low,
    medium,
    high,
    empty
  };

  return (
    <StyledRow>
      <ResponsiveBar
        data={[preparedData]}
        keys={['empty', 'low', 'medium', 'high']}
        indexBy="id"
        margin={{ top: -4, right: 0, bottom: -4, left: 0 }}
        layout="horizontal"
        colors={bar => ZONE_TO_COLOR_RELATIONS[bar.id]}
        tooltip={tooltip =>
          tooltipSetter({
            name: t(COLOR_ZONE_LITERALS[tooltip.id]),
            value: `${beatifyFloat(percentageValues[tooltip.id])}%`
          })
        }
        labelTextColor="var(--black_primary)"
        labelSkipWidth={12}
        labelSkipHeight={12}
        animate={false}
        motionStiffness={0}
        motionDamping={0}
        onClick={
          onClickHandler ? (node, e) => onClickHandler({ e, history, nodeEvent: node }) : undefined
        }
      />
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  &.ant-row {
    height: 36px !important;
    background-color: white;
    border-radius: 4px;
    //overflow: hidden;
    cursor: pointer;
  }
`;

export default ColorZonesBar;
