import styled from 'styled-components';

const FilterName = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size);
  line-height: 22px;

  display: flex;
  align-items: center;

  color: var(--text-light);
`;

export default FilterName;
