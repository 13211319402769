import React, { useCallback } from 'react';
import { Typography, List } from 'antd';
import SCard from 'components/Standard/SCard';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { sortBy, isEqual, orderBy } from 'lodash';
import SButton from 'components/Standard/SButton';
import { useSelector, useDispatch } from 'react-redux';
import { setLevelsRoleId } from 'redux/ui/levelsPage/reducer';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import LevelsModal from './Components/LevelsModal';

const Levels = () => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const dispatch = useDispatch();

  const roles = useSelector(
    state => orderBy(Object.values(state.rolesResource.byIds), 'createdAt'),
    isEqual
  );
  const loading = useSelector(state => state.rolesResource.loading);
  const levelsByIds = useSelector(state => state.levelsResource.byIds, isEqual);

  const renderItem = useCallback(
    role => {
      const roleLevels = sortBy(role.levelsIds.map(id => levelsByIds[id]).filter(Boolean), 'rank');
      const levelsString = `${roleLevels.map(({ name }) => name).join(', ')}`;

      return (
        <List.Item
          actions={[
            <SButton type="link" onClick={() => dispatch(setLevelsRoleId(role.id))}>
              {t('organizationStructure.tabs.levels.item.buttons.setting')}
            </SButton>
          ]}
        >
          <List.Item.Meta title={<Text strong>{role.name}</Text>} description={levelsString} />
        </List.Item>
      );
    },
    [dispatch, levelsByIds]
  );

  return (
    <SCol sm={24} lg={24} xl={12}>
      <SCard bordered loading={loading}>
        <SRow>
          <SCol span={24}>
            <Title level={5}>{t('organizationStructure.tabs.levels.title')}</Title>
          </SCol>
          <SCol span={24}>
            <PerfectScrollbar
              style={{ maxHeight: 'calc(100vh - 56px)' }}
              options={{ suppressScrollX: true }}
            >
              <List
                bordered={false}
                loading={loading}
                header={<Text>{t('organizationStructure.tabs.levels.item.header')}</Text>}
                dataSource={roles}
                renderItem={renderItem}
              />
            </PerfectScrollbar>
            <LevelsModal />
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default Levels;
