export default {
  calibrationsPage: {
    messages: {
      sessionSuccessfullyCreated: 'Session successfully created',
      sessionSuccessfullyUpdated: 'Session successfully updated',
      createSessionFailed: 'Failed to create session',
      updateSessionFailed: 'Failed to update session'
    },
    buttons: {
      viewFolderItems: 'Communications to be evaluated',
      createSession: 'Create session'
    },
    sessionInfo: {
      breadcrumbs: {
        allSessions: 'All sessions',
        currentSession: 'Current session'
      },
      buttons: { viewSessionResult: 'View session results' },
      infoItems: {
        organizer: 'Organizer: ',
        scheduledTime: 'Date: ',
        checklistDefinition: 'Scorecard: ',
        members: 'Session members: ',
        description: 'Session description: ',
        place: 'Location: ',
        totalReviewedItems: 'Overall readiness: '
      },
      tooltip: 'Displays the total number of evaluated communications in the current session'
    },
    userReviewStatusTable: {
      tooltip:
        'Displays the number of rated communications of a particular user in the current session',
      reviewedItems: 'Readiness: ',
      table: {
        columns: {
          operator: 'Full name',
          name: 'Name',
          startedAt: 'Scheduled time',
          clientPhoneNumber: 'Phone number',
          isReviewed: 'Status'
        }
      }
    },
    calibrationsList: {
      item: {
        confirmDelete: {
          title: 'Are you sure you want to delete the calibration session??',
          ok: 'Delete',
          cancel: 'Cancel'
        },
        menu: {
          view: 'View session content',
          setting: 'Go to session settings',
          result: 'Go to the results',
          delete: 'Delete session'
        },
        name: 'Name: ',
        organizer: 'Organizer: ',
        reviewedItems: 'Readiness: ',
        scheduledTime: 'Date: ',
        loading: 'Uploading sessions...😤',
        empty: 'No sessions here for now 😟',
        all: 'That is it! 😊'
      }
    },
    folders: {
      messages: {
        fileSuccessfullyDeleted: 'File successfully deleted',
        groupSuccessfullyDeleted: 'Group successfully deleted '
      },
      buttons: {
        deleteFile: 'Delete file',
        deleteFolder: 'Delete folder'
      },
      table: {
        columns: {
          clientInteraction: 'Type',
          operator: 'Full name',
          name: 'Name',
          startedAt: 'Date and time',
          phoneNumber: 'Phone number'
        }
      },
      confirmDeleteFolder: {
        title: 'Delete folder?',
        description:
          'All records attached to this folder will also be deleted from all calibration sessions',
        ok: 'Delete',
        cancel: 'Cancel'
      },
      confirmDeleteFolderItem: {
        title: 'Delete record?',
        description: 'The recording will be deleted from all calibration sessions',
        ok: 'Delete',
        cancel: 'Cancel'
      },
      loading: 'Uploading groups...🧐',
      empty: 'No groups were found by current filters 😟'
    },
    modal: {
      title: 'Setting up the calibration session',
      buttons: {
        save: 'Save',
        cancel: 'Cancel'
      },
      form: {
        communicationsTitle: 'Communications to be evaluated:',
        fields: {
          organizer: 'Organizer',
          name: 'Session name',
          namePlaceholder: 'Enter session name',
          scheduledTime: 'Session scheduled time',
          place: 'Location',
          placePlaceholder: 'Enter session location',
          description: 'Session description',
          checklistDefinition: 'Scorecard',
          members: 'Session members',
          membersPlaceholder: 'Choose session members',
          folder: 'Communications group',
          folderPlaceholder: 'Choose communications group',
          folderItems: 'Communications',
          folderItemsPlaceholder: 'Choose communications',
          folderItemsMax: 'Communications chosen: '
        },
        messages: {
          requiredName: 'Please enter session name',
          requiredScheduledTime: 'Please enter scheduled time',
          requiredСhecklistDefinition: 'Please choose scorecard',
          requiredMembers: 'Please enter session members',
          requiredFolder: 'Please choose the communication group',
          requiredFolderItems: 'Please choose communications'
        }
      }
    }
  }
};
