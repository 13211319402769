import { Col } from 'antd';
import styled from 'styled-components';

export const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormContainer = styled(Col)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-card {
      width: 80%;
      max-width: 560px;
    }
  }
`;
