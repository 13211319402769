import createListReducer from 'core/redux/lists/listReducer';
import { aiSettingsResource } from '../resources/aiSettings';

const additionalReducer = {
  setSearch: (state, { payload }) => ({
    ...state,
    search: payload,
    page: { ...state.page, number: '1' }
  }),
  setStatus: (state, { payload }) => ({
    ...state,
    status: payload,
    page: { ...state.page, number: '1' }
  })
};

export const listParams = {
  name: 'aiSettingsList',
  loader: aiSettingsResource.operations.load,
  additionalReducer,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const aiSettingsListReducer = reducer;

export { actions, aiSettingsListReducer, operations };
