export default {
  backgroundJob: {
    title: 'Идёт установка приложения и синхронизация базы данных',
    description: 'Это может занять несколько минут',
    messages: {
      error: 'Возникла ошибка. Попробуйте снова'
    },
    status: {
      process: 'В процессе'
    }
  }
};
