import React from 'react';
import { Col, Spin, Row } from 'antd';
import { useSelector } from 'react-redux';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { widgetValueTypeSetter } from '../utils';
import { SmallWidgetCard, Value } from '../styled';
import EmptyWidget from '../Components/EmptyWidget';

const TimeWidget = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { id, type, filters, name } = widgetData;

  const widgetValue = useSelector(state => state.dashboardAnalytics.byIds[id]);
  return (
    <>
      {isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={4} key={id}>
          <SmallWidgetCard>
            <SMLWidgetHeader
              name={name}
              type={type}
              widgetData={widgetData}
              filters={filters}
              dateLastUpdate={widgetValue?.dateLastUpdate}
            />

            <Spin spinning={loadingWidgetId === id}>
              <Row>
                <Value color="var(--blue_6)" secondsCount={widgetValue?.value ?? widgetValue ?? 0}>
                  {widgetValue
                    ? widgetValueTypeSetter[type](widgetValue?.value ?? widgetValue ?? 0)
                    : '00:00'}
                </Value>
              </Row>
            </Spin>
          </SmallWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TimeWidget;
