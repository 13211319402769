export default {
  infoPage: {
    drawer: {
      title: 'Information',
      cards: {
        wiki: {
          title: 'qolio wiki',
          description: 'Learn how to install, configure and use qolio'
        },
        supportRequest: {
          title: 'Create issue',
          description: 'Get a quick answer to your questions about the qolio.'
        }
      }
    },
    feedBackForm: {
      messages: {
        requestSuccessfullySended: 'Issue сreated',
        sendRequestfailed: 'Create issue failed'
      },
      form: {
        fields: {
          name: 'Name',
          namePlaceholder: 'Enter name',
          email: 'Email',
          emailPlaceholder: 'Enter email',
          topic: 'Subject',
          topicPlaceholder: 'Choose subject',
          message: 'Issue description',
          messagePlaceholder: 'Enter issue description'
        },
        messages: {
          enterName: 'Please, enter name',
          enterEmail: 'Please, enter email',
          wrongEmail: 'Wrong email format',
          enterTopic: "Please, choose issue's subject",
          enterTopicMessage: "Please enter issue's description"
        },
        topicOptions: {
          ask_question: 'Ask a question',
          bug_report: 'Report bug',
          offer_feature: 'Suggest improvement',
          other: 'Other'
        },
        buttons: { send: 'Send ' }
      }
    }
  }
};
