import styled from 'styled-components';
import {
  color,
  space,
  layout,
  flexbox,
  background,
  shadow,
  position,
  typography
} from 'styled-system';
import { Form } from 'antd';

export const SFormItem = styled(Form.Item)`
&.ant-form-item {
  ${space}
  ${layout}
  ${flexbox}
  ${background}
  ${shadow}
  ${position}
  ${typography}
  ${color}
}
`;
