export default {
  dashboardPages: {
    unitsAnalyticsWidgetTitle: 'Analisi per reparto',
    reportSettings: 'Impostazioni report',
    reportName: 'Titolo del rapporto',
    changeUnit: 'Seleziona i dipartimenti',
    changeUser: 'Seleziona i dipendenti',
    addReport: 'Aggiungi rapporto',
    selectAll: 'Seleziona tutto',
    deleteReport: 'Elimina rapporto',
    deleteWidgetConfirmModalTitle: 'Eliminare il widget?',
    deleteWidgetConfirmModalContent: 'Non è possibile annullare questa azione',
    noCreatedReports: 'Non è stato creato alcun report',
    clickAddReportButton: 'Fare clic sul pulsante "Aggiungi rapporto" per visualizzare i dati',
    conflicts: {
      unitsAnalyticsPage: {
        checklistsConflict: {
          title: 'Nessun accesso al modulo di valutazione',
          description1:
            "A causa di un cambiamento nelle impostazioni del profilo, hai perso l'accesso al modulo di valutazione precedentemente selezionato.",
          description2:
            "Per aggiungere o visualizzare il rapporto, seleziona un altro modulo di valutazione. Per riottenere l'accesso al suo modulo di valutazione precedentemente selezionato, si prega di contattare l'Admin."
        }
      },
      appealsAnalyticsPage: {
        checklistsConflict: {
          title: 'Nessun accesso ai moduli di valutazione',
          description1:
            "A causa di un cambiamento nelle impostazioni del profilo, hai perso l'accesso a uno o più moduli di valutazione precedentemente selezionati.",
          description2:
            "Seleziona altri moduli di valutazione per visualizzare il rapporto. Per riottenere l'accesso ai moduli di valutazione precedentemente selezionati, si prega di contattare l'Admin."
        }
      },
      unitsConflict: {
        title: 'Le impostazioni installate non sono disponibili',
        description1:
          "A causa di un cambiamento nelle impostazioni del profilo, hai perso l'accesso ai dati di uno o più reparti impostati nelle impostazioni del rapporto.",
        description2:
          "Per cambiare i filtri, seleziona i nuovi reparti nelle impostazioni del rapporto. Per riottenere l'accesso alle impostazioni precedentemente impostate, contattate l'Admin."
      },
      usersConflict: {
        title: 'Le impostazioni installate non sono disponibili',
        description1:
          "A causa di un cambiamento nelle impostazioni del profilo, hai perso l'accesso ai dati di uno o più dipendenti impostati nelle impostazioni del rapporto.",
        description2:
          "Per cambiare i filtri, seleziona altri dipendenti nelle impostazioni del rapporto. Per riottenere l'accesso alle impostazioni precedentemente impostate, contattate l'Admin."
      }
    },

    autoFailScores: {
      unitsAnalyticsWidgetTitle: 'La dinamica degli errori critici',
      columns: {
        date: 'Periodo',
        reviewsCountHistory: 'Numero di ispezioni',
        percentOfClientInteractions: '% di ispezioni',
        anyTypeAutofailsCountHistory: 'Errori critici totali',
        clientInteractionsCountHistory: 'Comunicazioni totali',
        reviewsWithAnyTypeAutofailCount: 'Ispezioni con errori critici',
        checklistDefinitionAverageScoreHistory: 'Punteggio medio',
        percentOfReviewsWithAnyTypeAutofailCount: '% di ispezioni con errori critici'
      },
      footer: {
        total: 'Totale'
      }
    }
  }
};
