import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import CardTableHeader from 'pages/DashboardPages/TeamPage/Components/CardTableHeader';
import { getDataFromWidget } from 'pages/DashboardPages/TeamPage/Components/utils';
import tableColumns from './tableColumns';
import tableRows from './tableRows';

const UnitTable = ({
  widgets,
  filters,
  currentUserId,
  currentUserPermissions,
  checklistDefinitionsByIds,
  loading,
  units
}) => {
  const organizationName = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user.organization.name
  );
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  const {
    checklistColorZoneData,
    previousChecklistColorZoneData,
    averageScoreData,
    previousPeriodAverageScoreData,
    reviewsCountData,
    previousReviewsCountData,
    ratingFlagsData
  } = getDataFromWidget({ widgets });

  const dataSource = tableRows({
    checklistColorZoneData,
    previousChecklistColorZoneData,
    reviewsCountData,
    previousReviewsCountData,
    averageScoreData,
    previousPeriodAverageScoreData,
    usersByIds,
    units,
    ratingFlagsData,
    filters
  });

  return (
    <SCard loading={loading} bordered shadowed>
      <CardTableHeader name={organizationName} />

      <STable
        rowKey="key"
        columns={tableColumns({
          currentUserPermissions,
          currentUserId,
          filters,
          checklistDefinitionsByIds
        })}
        dataSource={dataSource}
        showSorterTooltip={false}
        pagination={false}
        tableLayout="fixed"
        fixSorterColumns
      />
    </SCard>
  );
};

export default UnitTable;
