import { BarsOutlined, FileDoneOutlined, SwapOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip, Typography } from 'antd';
import { COLUMN_TYPES } from 'core/utils/constants';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

const defaultCustomFields = {};
// TODO: prefix
const ColumnTitle = ({
  columnType,
  customFieldsByKeys = defaultCustomFields,
  checklistDefinitionsIds = {},
  questionsChecklist = {},
  tableView,
  width
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const Icon = questionsChecklist[columnType]
    ? FileDoneOutlined
    : get(
        COLUMN_TYPES,
        `${columnType}.icon`,
        checklistDefinitionsIds[columnType] ? BarsOutlined : SwapOutlined
      );

  const columnTitle = questionsChecklist[columnType]
    ? questionsChecklist[columnType]?.name
    : get(
        COLUMN_TYPES,
        `${columnType}.name`,
        customFieldsByKeys[columnType]?.name || checklistDefinitionsIds[columnType]?.name || ''
      );

  return (
    <Row
      type="flex"
      align="middle"
      gutter={[4, 0]}
      style={{ flexWrap: 'nowrap', padding: '8px', width }}
    >
      <Col>
        <Icon style={{ marginTop: 4 }} />
      </Col>
      <Col>
        <Tooltip
          title={
            tableView
              ? t(get(COLUMN_TYPES, `${columnType}.columnName`, columnTitle))
              : t(columnTitle)
          }
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%'
            }}
          >
            <Text>
              {tableView
                ? t(get(COLUMN_TYPES, `${columnType}.columnName`, columnTitle))
                : t(columnTitle)}
            </Text>
          </div>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default ColumnTitle;
