import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SSelect from 'components/Standard/SSelect';
import { actions } from 'redux/lists/appellationsListReducer';
import {
  APPEAL_PERSON_FILTER_VALUES,
  APPEALS_STATUSES,
  SYMBOLIC_TIME_RANGE,
  DATE_PICKER_MODES
} from 'core/utils/constants';
import UserPreview from 'components/UserPreview';
import { selectSearch } from 'core/utils/selectSearch';
import { getUserName } from 'components/UserPreview/getUserName';
import { isEqual, keyBy } from 'lodash';
import { getActiveUsers } from 'redux/selectors/users';
import CustomDatePicker from 'components/DatePicker/DatePicker';

const Filters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;

  const users = useSelector(state => getActiveUsers(state), isEqual);

  const usersByIds = keyBy(users, 'id');

  const { filters } = useSelector(state => state.appellationsList);

  useEffect(() => {
    dispatch(
      actions.updateFilters({
        appellationCreatedAtSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH
      })
    );
  }, []);

  return (
    <SCard rounded={false}>
      <SRow type="flex" justify="space-between">
        <SCol span={24}>
          <SRow gutter={[8, 0]}>
            <SCol span={6}>
              <SSelect
                width="100%"
                placeholder={t('general.status')}
                onChange={status => dispatch(actions.updateFilters({ statuses: status }))}
                allowClear
                mode="multiple"
                showArrow
                maxTagCount={0}
                value={filters?.statuses}
                maxTagPlaceholder={selectedKeys => `Статусы: ${selectedKeys.length}`}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
              >
                {Object.values(APPEALS_STATUSES).map(status => (
                  <Option key={status.value} value={status.value}>
                    {t(status.title)}
                  </Option>
                ))}
              </SSelect>
            </SCol>

            <SCol span={6}>
              <SSelect
                width="100%"
                placeholder={t('general.applicator')}
                allowClear
                mode="multiple"
                showArrow
                maxTagCount={0}
                value={filters.appellantsIds}
                maxTagPlaceholder={selectedKeys => `${t('general.users')}: ${selectedKeys.length}`}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'label' })
                }
                onChange={id => dispatch(actions.updateFilters({ appellantsIds: id }))}
              >
                {users.map(user => (
                  <Option
                    key={user.id}
                    value={user.id}
                    label={getUserName({ user: usersByIds[user.id] })}
                  >
                    <UserPreview disabled userId={user.id} />
                  </Option>
                ))}
              </SSelect>
            </SCol>

            <SCol span={6}>
              <SSelect
                width="100%"
                placeholder={`${t('appealsPage.filters.withCurrentUserRoles')}:`}
                allowClear
                mode="multiple"
                showArrow
                maxTagCount={0}
                value={filters.withUserAsMemberRoles}
                maxTagPlaceholder={selectedKeys => `${t('general.roles')}: ${selectedKeys.length}`}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
                onChange={roleName =>
                  dispatch(actions.updateFilters({ withUserAsMemberRoles: roleName }))
                }
              >
                {Object.values(APPEAL_PERSON_FILTER_VALUES).map(filterValue => (
                  <Option key={filterValue.value} value={filterValue.value}>
                    {t(filterValue.title)}
                  </Option>
                ))}
              </SSelect>
            </SCol>

            <SCol span={6}>
              <CustomDatePicker
                onChange={filters => dispatch(actions.updateFilters({ ...filters }))}
                value={{
                  symbolicTimeRange: filters?.appellationCreatedAtSymbolicTimeRange,
                  timeFrom: filters?.appellationCreatedAtTimeFrom,
                  timeTo: filters?.appellationCreatedAtTimeTo
                }}
                position="left"
                mode={DATE_PICKER_MODES.custom.appellation}
              />
            </SCol>
          </SRow>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default Filters;
