import { Col, Collapse, Row, Input } from 'antd';
import ColumnTitle from 'components/ColumnTitle';
import { COLUMN_TYPES } from 'core/utils/constants';
import { difference } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import { addColumn } from 'redux/ui/clientInteractions/reducer';
import { useTranslation } from 'react-i18next';
import { AddColumnIcon, ColumnPreview, SettingsRow } from './styled';
import QuestionColumn from '../../../../components/QuestionColumn';

const { Panel } = Collapse;
const { Search } = Input;

const AddColumn = ({
  tableColumns,
  addColumn,
  customFieldsByKeys = {},
  checklistDefinitionsIds = {},
  questionsChecklist = {}
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();
  const allColumnKeys = [
    ...Object.keys(COLUMN_TYPES),
    ...Object.keys(customFieldsByKeys),
    ...Object.keys(checklistDefinitionsIds)
  ];

  const availableColumns = difference(allColumnKeys, tableColumns);

  const getColumnTitle = column => {
    return questionsChecklist[column]
      ? questionsChecklist[column]?.name
      : t(COLUMN_TYPES[column]?.name) ||
          customFieldsByKeys[column]?.name ||
          checklistDefinitionsIds[column]?.name ||
          '';
  };
  const filteredColumns = availableColumns.filter(column =>
    getColumnTitle(column)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <SettingsRow>
      <Col span={24}>
        <Search
          placeholder="Поиск по именам столбцов"
          onChange={e => setSearchQuery(e.target.value)}
          style={{ marginBottom: '16px' }}
        />

        {filteredColumns.map(column => (
          <Row key={column} gutter={[8, 8]} style={{ margin: '-4px 0' }}>
            {checklistDefinitionsIds[column] ? (
              <Collapse
                defaultActiveKey={['1']}
                style={{ width: '100%', margin: '4px 0' }}
                expandIconPosition="right"
              >
                <Panel
                  key={column}
                  header={
                    <ColumnTitle
                      columnType={column}
                      customFieldsByKeys={customFieldsByKeys}
                      checklistDefinitionsIds={checklistDefinitionsIds}
                      questionsChecklist={questionsChecklist}
                    />
                  }
                >
                  {checklistDefinitionsIds[column] && (
                    <QuestionColumn
                      checklistDefinition={checklistDefinitionsIds[column]}
                      addColumn={addColumn}
                      tableColumns={tableColumns}
                    />
                  )}
                </Panel>
              </Collapse>
            ) : (
              <ColumnPreview span={24} onClick={() => addColumn(column)}>
                <ColumnTitle
                  columnType={column}
                  customFieldsByKeys={customFieldsByKeys}
                  checklistDefinitionsIds={checklistDefinitionsIds}
                  questionsChecklist={questionsChecklist}
                  width="80%"
                />
                <AddColumnIcon />
              </ColumnPreview>
            )}
          </Row>
        ))}
      </Col>
    </SettingsRow>
  );
};

const mapStateToProps = state => {
  return {
    customFieldsByKeys: getCustomFieldsByKeysForReviews(state, true),
    tableColumns: state.uiClientInteractions.tableColumns,
    checklistDefinitionsIds: state.checklistDefinitionsResource.byIds,
    questionsChecklist: state.questionsChecklist // Предполагается, что у вас есть этот селектор
  };
};

const mapDispatchToProps = {
  addColumn
};

export default connect(mapStateToProps, mapDispatchToProps)(AddColumn);
