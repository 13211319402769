import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  callsByIds: {},
  massAction: false
};

export const startEditingCalls = createAction('startEditingCalls');
export const massEditingCalls = createAction('massEditingCalls');
export const cancelEditingCalls = createAction('cancelEditingCalls');

export const uploadCallsPageReducer = createReducer(defaultState, {
  [startEditingCalls]: (state, { payload }) => {
    const { callId, ...props } = payload;

    if (callId && state.callsByIds[callId]) {
      const call = { ...state.callsByIds[callId], ...props };
      return { ...state, callsByIds: { ...state.callsByIds, [callId]: call } };
    }

    const call = { ...state.callsByIds.callId, ...props };
    return { ...state, callsByIds: { ...state.callsByIds, [callId]: call } };
  },
  [massEditingCalls]: (state, { payload }) => {
    const { callsIds, ...props } = payload;
    let updatedCallsByIds = { ...state.callsByIds };

    if (callsIds) {
      callsIds.forEach(callId => {
        if (callId && updatedCallsByIds[callId]) {
          updatedCallsByIds = {
            ...updatedCallsByIds,
            [callId]: { ...updatedCallsByIds[callId], ...props }
          };
        } else {
          updatedCallsByIds = {
            ...updatedCallsByIds,
            [callId]: { ...props }
          };
        }
      });
    }

    return { ...state, callsByIds: updatedCallsByIds, massAction: true };
  },
  [cancelEditingCalls]: state => {
    return { ...state, callsByIds: {}, massAction: false };
  }
});
