import { Divider, Select, Typography } from 'antd';
import { getUserName } from 'components/UserPreview/getUserName';
import { concat, get, isEmpty, uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserPreview from 'components/UserPreview';
import styled from 'styled-components';
import SSelect from '../Standard/SSelect';
import SText from '../Standard/SText';

const { Option } = Select;

const CustomOption = styled.div`
  && {
    line-height: 22px;
    padding: 5px 12px;
    color: var(--text);

    &:hover {
      cursor: pointer;
    }
  }
`;

const defaultValue = [];

const searchInnactiveUsersIds = ({ value, usersByIds }) => {
  return value.filter(userId => !get(usersByIds, `${userId}.active`, false));
};

const getUsers = ({ usersByIds, usersToSelect, value }) => {
  return uniq(
    concat(
      searchInnactiveUsersIds({ value, usersByIds }),
      usersToSelect.map(o => o.id),
      ...value
    )
  );
};

const SpecificUsersSelect = props => {
  const {
    value = defaultValue, // * usersIds form value
    usersToSelect, // * filtered users to display
    hideUserName,
    updateFilters,
    keyName,
    items,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(!isEmpty(value));
  }, [value]);

  const usersByIds = useSelector(state => state.usersResource.byIds, shallowEqual);

  const { Text } = Typography;

  const usersToSelectWithInnactiveUsers = useMemo(
    () => getUsers({ usersByIds, usersToSelect, value }),
    [usersByIds, usersToSelect, value]
  );
  const optionsHandler = () => {
    const arrayOfIds = items.map(unit => unit.id);
    setChecked(!checked);
    updateFilters({ [keyName]: checked ? [] : arrayOfIds });
  };

  return (
    <SSelect
      value={value}
      {...otherProps}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider type="horizontal" style={{ margin: 0 }} />
          <CustomOption className="ant-select-item ant-select-item-option" onClick={optionsHandler}>
            <SText strong>{checked ? 'Убрать все' : 'Выбрать все'}</SText>
          </CustomOption>
        </>
      )}
    >
      {usersToSelectWithInnactiveUsers.map(userId => {
        const userToDisplay = get(usersByIds, userId);

        return (
          <Option key={userId} value={userId} label={getUserName({ user: usersByIds[userId] })}>
            <UserPreview
              user={usersByIds[userId]}
              disabled
              style={{ height: 14 }}
              hidden={hideUserName}
            />
            {!userToDisplay?.active && <Text strong>{t('constants.userStatuses.disabled')}</Text>}
          </Option>
        );
      })}
    </SSelect>
  );
};

export default SpecificUsersSelect;
