import { get, put, destroy, post } from 'redux-bees';

const commentTemplatesEndpoints = apiPath => ({
  getCommentTemplates: { method: get, path: `${apiPath}/template_comments` },
  createCommentTemplate: { method: post, path: `${apiPath}/template_comments` },
  deleteCommentTemplate: { method: destroy, path: `${apiPath}/template_comments/:id` },
  updateCommentTemplate: { method: put, path: `${apiPath}/template_comments/:id` }
});

export default commentTemplatesEndpoints;
