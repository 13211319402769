import { get } from 'redux-bees';

const salePipelinesEndpoints = apiPath => ({
  getSalePipelines: { method: get, path: `${apiPath}/sales_pipelines` }
  //   updateRole: { method: put, path: `${apiPath}/organization/roles/:id` },
  //   createRole: { method: post, path: `${apiPath}/organization/roles` },
  //   deleteRole: { method: destroy, path: `${apiPath}/organization/roles/:id` }
});

export default salePipelinesEndpoints;
