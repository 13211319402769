import { Popover, Tooltip } from 'antd';
import SButton from 'components/Standard/SButton';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(SButton)`
  :active {
    color: var(--black_4) !important;
  }
  :focus {
    color: var(--black_4) !important;
    &:hover {
      color: var(--primary) !important;
    }
  }
  :hover {
    color: var(--primary);
  }
  & {
    color: ${({ active }) => (active ? 'var(--primary)' : 'inherit')};
  }
`;

export const IconButton = styled(({ popover, tooltip, button, children }) => {
  const Button = (
    <StyledButton type="link" color="#82858c" {...button}>
      {children}
    </StyledButton>
  );

  if (tooltip) {
    return (
      <Tooltip arrowPointAtCenter {...{ color: '#000', ...tooltip }}>
        {Button}
      </Tooltip>
    );
  }

  if (popover) {
    return <Popover {...popover}>{Button}</Popover>;
  }
  return Button;
})`
  && {
    &:hover {
      color: #839df2;
    }
  }
`;
