const ru = {
  name: 'ru',
  options: {
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    shortMonths: [
      'Янв',
      'Фев',
      'Мар',
      'Апр',
      'Май',
      'Июн',
      'Июл',
      'Авг',
      'Сен',
      'Окт',
      'Ноя',
      'Дек'
    ],
    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    toolbar: {
      download: 'Загрузить SVG',
      selection: 'Выбор',
      selectionZoom: 'Приблизить период',
      zoomIn: 'Приблизить',
      zoomOut: 'Отдалить',
      pan: 'Перемещение',
      reset: 'Сбросить приближение'
    }
  }
};

export default ru;
