import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  dataset: {}
};

export const setEditingDataset = createAction('uiDatasetModal/editing');
export const clearFields = createAction('uiDatasetModal/clearFields');
export const uiDatasetModalReducer = createReducer(defaultState, {
  [setEditingDataset]: (state, { payload }) => ({ ...state, dataset: payload }),
  [clearFields]: (state, { payload }) => ({
    ...state,
    dataset: payload.dataset
  })
});

export default uiDatasetModalReducer;
