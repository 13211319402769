export default {
  backgroundJob: {
    title: 'Se está instalando la aplicación y sincronizando la base de datos',
    description: 'Puede tardar unos minutos',
    messages: { error: 'Error, pruebe de nuevo' },
    status: {
      process: 'En gestión'
    }
  }
};
