import createListReducer from 'core/redux/lists/listReducer';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';

const defaultState = {
  defaultState: {
    search: undefined
  },

  page: {
    size: '10',
    number: '1'
  },
  filters: {
    withInactive: 'true'
  }
};

const additionalReducer = {
  setSearch: (state, { payload }) => ({
    ...state,
    search: payload,
    page: { ...state.page, number: '1' }
  })
};

const { actions, reducer, operations } = createListReducer({
  name: 'userAccountBindings',
  loader: userAccountBindingsResource.operations.load,
  defaultState,
  additionalReducer,
  rebuildQuery: true,
  keepResults: true,
  keepResultsUnique: true
});

const userAccountBindingsListReducer = reducer;

export { actions, userAccountBindingsListReducer, operations };
