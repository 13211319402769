/* eslint-disable no-param-reassign */
import { isEqual, isPlainObject } from 'lodash/fp';
import reduce from 'lodash/reduce';

// * compares two objects and returns oject with different values
const objectsDifference = (object, base, omit = []) => {
  return reduce(
    object,
    (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isPlainObject(value) && isPlainObject(base[key]) && !omit.includes(key)
            ? objectsDifference(value, base[key], omit)
            : value;
      }

      return result;
    },
    {}
  );
};

export default objectsDifference;
