import { get, orderBy } from 'lodash';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';

const prepareData = ({
  checklistColorZoneData,
  previousChecklistColorZoneData,
  reviewsCountData,
  previousReviewsCountData,
  averageScoreData,
  previousPeriodAverageScoreData,
  users,
  ratingFlagsData
}) => {
  return orderBy(
    users.reduce((result, user) => {
      if (!get(reviewsCountData, user.id)) {
        return result;
      }

      const widgetData = {
        key: user.id,
        name: user.name,
        lastName: user.lastName,

        [ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS]: get(
          averageScoreData,
          [user.id, 'value'],
          'N/A'
        ),

        [`${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}_previous`]: get(
          previousPeriodAverageScoreData,
          [user.id, 'value'],
          'N/A'
        ),

        [ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS]: get(
          reviewsCountData,
          [user.id, 'value'],
          0
        ),

        greenZonePercentage:
          (get(checklistColorZoneData, [user.id, 'high'], 0) /
            get(reviewsCountData, [user.id, 'value'], 0)) *
          100,

        greenZonePercentagePrevious:
          (get(previousChecklistColorZoneData, [user.id, 'high'], 0) /
            get(previousReviewsCountData, [user.id, 'value'], 0)) *
          100,

        [ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS]: {
          id: user.id,
          low: get(checklistColorZoneData, [user.id, 'low'], 0),
          medium: get(checklistColorZoneData, [user.id, 'medium'], 0),
          high: get(checklistColorZoneData, [user.id, 'high'], 0)
        },

        [ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS]: {
          without_flag: get(ratingFlagsData, [user.id, 'without_flag'], 0),
          red: get(ratingFlagsData, [user.id, 'red'], 0),
          yellow: get(ratingFlagsData, [user.id, 'yellow'], 0),
          green: get(ratingFlagsData, [user.id, 'green'], 0)
        }
      };

      return [...result, widgetData];
    }, []),
    'lastName'
  );
};

export default prepareData;
