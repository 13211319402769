import { get, put, post, destroy } from 'redux-bees';

const unitDashboardPageEndpoints = apiPath => ({
  getUnitsDashboard: { method: get, path: `${apiPath}/dashboards/pages/scores_by_units` },
  getUnitsDashboardPart: {
    method: get,
    path: `${apiPath}/dashboards/pages/scores_by_units/widgets`
  },
  getUnitsDashboardAnalytics: {
    method: get,
    path: `${apiPath}/analytics/dashboards/pages/scores_by_units/widgets`
  },
  updateUnitsDashboard: { method: put, path: `${apiPath}/dashboards/pages/scores_by_units` },
  createUnitDashboardPageWidget: {
    method: post,
    path: `${apiPath}/dashboards/pages/scores_by_units/widgets`
  },
  deleteUnitDashboardPageWidgetById: {
    method: destroy,
    path: `${apiPath}/dashboards/pages/scores_by_units/widgets/:id`
  }
});

export default unitDashboardPageEndpoints;
