import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import { Col, Empty, Form, Input, Row, Select, Typography } from 'antd';
import AiIcon from 'assets/images/ai-icon.svg';
import { isCustomFieldVisible } from 'core/utils/isCustomFieldVisible';
import { CUSTOM_FIELDS_TYPES } from '../../core/utils/constants';
import SlicedTextWithTooltip from '../SlicedTextWithTooltip';

const { Text } = Typography;

const CustomFieldsModal = ({
  customFields,
  customFieldsByKeys,
  isEditMode,
  setIsEditMode,
  fieldValues,
  setFieldValues,
  length = 40
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const initialValues = {};
    Object.keys(customFields).forEach(item => {
      initialValues[item] = {
        value: customFields[item] || '',
        type: get(customFieldsByKeys, [item, 'fieldType']),
        id: get(customFieldsByKeys, [item, 'id'])
      };
    });
    setFieldValues(initialValues);
  }, [customFields, customFieldsByKeys, setFieldValues]);

  const handleChange = (item, value, isTypeChange = false) => {
    setFieldValues(prev => ({
      ...prev,
      [item]: isTypeChange ? { ...prev[item], type: value } : { ...prev[item], value }
    }));
    if (!isEditMode) {
      setIsEditMode(true);
    }
  };

  const customFieldsOptions = Object.values(CUSTOM_FIELDS_TYPES).filter(
    type => type !== CUSTOM_FIELDS_TYPES.SELECT && type !== CUSTOM_FIELDS_TYPES.ENUM
  );

  if (isEmpty(customFields))
    return (
      <Row justify="center" align="middle">
        <Col>
          <Empty />
        </Col>
      </Row>
    );

  return (
    <Form layout="horizontal">
      <Row gutter={[16, 16]}>
        {Object.keys(customFields).map(
          item =>
            isCustomFieldVisible(customFieldsByKeys[item]) && (
              <Col span={24} key={item}>
                <Form.Item labelAlign="left">
                  <Row align="middle">
                    <Col span={10} flex="auto">
                      {get(customFieldsByKeys, `${item}.usedForReviews`, false) === true && (
                        <img
                          src={AiIcon}
                          alt="ai-icon"
                          style={{ marginRight: '5px', paddingBottom: '2px' }}
                        />
                      )}
                      <Text strong>
                        <SlicedTextWithTooltip
                          value={get(customFieldsByKeys, `${item}.name`, `${item}`)}
                          length={length}
                          template="... "
                        />
                      </Text>
                    </Col>
                    <Col span={14} flex="auto" style={{ display: 'flex' }}>
                      <Input
                        style={{ width: '100%' }}
                        value={get(fieldValues, [item, 'value'], '')}
                        onChange={e => handleChange(item, e.target.value)}
                      />
                      <Select
                        style={{ width: '120px', marginLeft: '8px' }}
                        placeholder={t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldType'
                        )}
                        value={get(fieldValues, [item, 'type'], '')}
                        onChange={value => handleChange(item, value, true)}
                      >
                        {customFieldsOptions.map(type => (
                          <Select.Option value={type} key={type}>
                            {type}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            )
        )}
      </Row>
    </Form>
  );
};

export default CustomFieldsModal;
