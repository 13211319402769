import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Typography } from 'antd';
import SCol from 'components/Standard/SCol';
import { getStandardCommentsKeyByRate } from 'core/utils/standardComments';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  createQuestionStandardComment,
  deleteQuestionStandardComment,
  updateQuestionStandardComment
} from 'redux/ui/questionModal/reducer';

const { TextArea } = Input;
const { Text } = Typography;

const StandardComments = ({
  ratingValues,
  standardComments,
  updateComment,
  createComment,
  deleteComment
}) => {
  const { t } = useTranslation();

  const renderCommentTextArea = ({ commentKey, rate, id }) => {
    return (
      <>
        <Col span={22}>
          <TextArea
            rows={1}
            value={standardComments[commentKey][id]}
            onChange={e =>
              updateComment({
                rate,
                id,
                text: e.target.value
              })
            }
          />
        </Col>
        <SCol display="flex" justifyContent="flex-end" span={2}>
          <Button
            icon={<DeleteOutlined />}
            type="danger"
            onClick={() => deleteComment({ rate, id })}
          />
        </SCol>
      </>
    );
  };

  return (
    <Row>
      <Col span={24}>
        {ratingValues.map(rate => {
          const commentKey = getStandardCommentsKeyByRate(rate);
          return (
            <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
              <Col span={10}>
                <Text strong>{`Балл - ${rate}`}</Text>
              </Col>
              <Col span={14}>
                <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
                  {standardComments[commentKey] &&
                    Object.keys(standardComments[commentKey]).map(id =>
                      renderCommentTextArea({ commentKey, rate, id })
                    )}
                </Row>
                <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
                  <Col span={24}>
                    <Button block type="dashed" onClick={() => createComment({ rate })}>
                      {t('components.questionModal.buttons.addComment')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateComment: updateQuestionStandardComment,
  createComment: createQuestionStandardComment,
  deleteComment: deleteQuestionStandardComment
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardComments);
