import React from 'react';
import { orderBy, every } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { Typography, Tooltip } from 'antd';
import WidgetTable from 'components/Dashboard/SmallWidgetTable';
import { useTranslation } from 'react-i18next';
import CommentsBar from 'components/Dashboard/CommentsBar';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { stringify } from 'core/utils/queryString';
import GetUserPreview from 'components/UserPreview/GetUserPreview';
import { getFilters } from '../../../utils';

const UsersTable = ({ tableData, viewMode, filters }) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const usersByIds = useSelector(state => state.usersResource.byIds, shallowEqual);

  const prepareData = viewMode => {
    switch (viewMode) {
      case WIDGET_VIEW_MODES.OPERATORS.value:
        return tableData.reduce((acc, item) => {
          if (every([item], { without_flag: 0, green: 0, red: 0, yellow: 0 })) return acc;

          const data = {
            id: item.id,
            key: item.id,
            lastName: usersByIds[item.id]?.lastName,
            name: item?.name,
            comments: {
              without_flag: item.without_flag,
              red: item.red,
              yellow: item.yellow,
              green: item.green
            }
          };
          return [...acc, data];
        }, []);

      case WIDGET_VIEW_MODES.UNITS.value:
        return tableData.reduce((acc, item) => {
          if (every([item], { without_flag: 0, green: 0, red: 0, yellow: 0 })) return acc;

          const data = {
            id: item.id,
            key: item.id,
            lastName: item.name,
            name: item.name,
            comments: {
              without_flag: item.without_flag,
              red: item.red,
              yellow: item.yellow,
              green: item.green
            }
          };
          return [...acc, data];
        }, []);

      default:
        return [];
    }
  };

  const clickBarHandler = ({ commentsRatingFlags, userId, history, event }) => {
    const isNewTab = event.ctrlKey || event.metaKey;
    const link = `/user/${userId}/comments${stringify({
      filters: {
        ...getFilters(filters),
        commentsRatingFlags
      }
    })}`;

    return isNewTab ? window.open(link) : history.push(link);
  };

  const columns = [
    {
      title: <Text>{t('dashboardPage.flagsAndComments.table.columns.name')}</Text>,
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (text, record) =>
        viewMode === WIDGET_VIEW_MODES.OPERATORS.value ? (
          <GetUserPreview
            userId={text}
            altName={record.name}
            url={`/user/${record.key}/charts${stringify({
              filters: { ...getFilters(filters) }
            })}`}
          />
        ) : (
          <Tooltip key={record.id} title={record.name}>
            <Text>{record.name}</Text>
          </Tooltip>
        ),
      width: 270,
      sorter: (a, b) => a.lastName.localeCompare(b.lastName)
    },
    {
      title: <Text>{t('dashboardPage.flagsAndComments.table.columns.commentsCount')}</Text>,
      dataIndex: 'comments',
      key: 'comments',
      render: (text, record) => (
        <CommentsBar
          comments={record.comments}
          textColor="#000000"
          onClickHandler={data => {
            if (viewMode !== WIDGET_VIEW_MODES.OPERATORS.value) return;
            clickBarHandler({
              commentsRatingFlags:
                data.nodeEvent.id === 'without_flag' ? 'empty' : data.nodeEvent.id,
              userId: record.id,
              history: data.history,
              event: data.e
            });
          }}
        />
      )
    }
  ];

  return (
    <WidgetTable
      dataSource={orderBy(prepareData(viewMode), 'lastName')}
      columns={columns}
      pagination={false}
      size="small"
      scroll={{ y: 462 }}
      showSorterTooltip={false}
    />
  );
};

export default UsersTable;
