export const updateResource = async ({ resource, dispatch, type }) => {
  const resourceType = type || resource.type;
  await dispatch({
    type: `${resourceType}/updateByIdSucceed`,
    payload: resource
  });
};

export const updateResourceAfterDelete = async ({ resourceElementId, dispatch, type }) => {
  const resourceType = type;
  await dispatch({
    type: `${resourceType}/deleteByIdSucceed`,
    payload: { id: resourceElementId }
  });
};
