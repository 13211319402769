import createListReducer from 'core/redux/lists/listReducer';
import { unitsResource } from 'redux/resources/units';

const defaultState = {
  page: {
    size: '10',
    number: '1'
  }
};

const additionalReducer = {
  setSearch: (state, { payload }) => ({
    ...state,
    search: payload,
    page: { ...state.page, number: '1' }
  })
};

const { actions, reducer, operations } = createListReducer({
  name: 'units',
  defaultState,
  loader: unitsResource.operations.load,
  additionalReducer,
  rebuildQuery: true,
  keepResults: true
});

const unitsListReducer = reducer;

export { actions, unitsListReducer, operations };
