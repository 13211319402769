export default {
  userAnalyticsPage: {
    analyticsTabs: {
      analytics: 'Analitica',
      rates: 'Valutazioni',
      flagsAndComments: 'Commenti e bandiere'
    },
    widgets: {
      checklistTooltip: 'Analisi del modulo di valutazione',
      questionTooltip: 'Analisi per criterio',
      reviewsCount: {
        name: 'Valutazioni totali',
        description:
          'Il numero totale di valutazioni sul modulo di valutazione per il periodo selezionato'
      },
      averageScore: {
        name: 'Punteggio medio',
        description:
          'Punteggio medio per il modulo di valutazione, calcolato da tutti i voti per il periodo selezionato. Per "Tutte le forme di valutazione" viene calcolato come media aritmetica'
      },
      lowPercentage: {
        name: 'Critico',
        description: 'Numero di valutazioni nella zona rossa'
      },
      mediumPercentage: {
        name: 'Normale',
        description: 'Il numero di valutazioni nella zona gialla'
      },
      highPercentage: {
        name: 'Eccellente',
        description: 'Il numero di valutazioni nella zona verde'
      },
      flags: {
        name: 'Bandiere',
        description: 'Numero di bandiere, aggiunti ai voti per il modulo di valutazione selezionato'
      }
    },
    userReviewsTable: {
      columns: {
        clientInteractionStartedAt: 'Data e ora',
        clientPhoneNumber: 'Numero del telefono',
        clientInteractionDirection: 'Direzione',
        clientInteractionDuration: 'Dur',
        checklistScore: 'Valutazione',
        comments: 'Bandiere e commenti',
        checklistDefinition: 'Modulo di valutazione',
        tasks: 'Tag',
        createdAt: 'Data di valutazione',
        reviewerLastName: 'Autore della valutazione'
      },
      reviewsCount: 'Giudizi:',
      colorZoneSelect: { placeholder: 'Zone di valutazione' }
    },
    reviewsWithComments: {
      flagsAndCommentsCount: 'Bandiere e commenti totali:',
      flagsAndCommentsPlaceholder: 'Bandiere e commenti',
      loading: {
        load: 'Caricamento commenti...😤',
        empty: 'Spiacenti, nessun commento trovato 😟',
        all: "E' tutto! 😊"
      }
    },
    reviewPreview: {
      date: 'Data:',
      type: 'Tipo:',
      value: 'Valutazione:'
    },
    analyticsChartsPanel: { empty: 'Nessuna valutazione trovata per questi filtri 😓' },
    mainControlsPanel: {
      checklistDefinitionsEmpty: 'Nessun modulo di valutazione trovato',
      questionValues: 'Valutazioni per tutti i criteri'
    }
  }
};
