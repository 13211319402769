import ClientInteractionDrawer from 'components/ClientInteractionDrawer';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { isEqual } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { appellationsResource } from 'redux/resources/appellations';
import { getCurrentUser } from 'redux/selectors/users';
import { Actions } from './components/Actions';
import AppealInfo from './components/AppealInfo';
import { AppealStatus } from './components/AppealStatus';
import ParticipantsCard from './components/ParticipantsCard';

const AppealPage = () => {
  const mounted = React.useRef(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: appealId } = useParams();
  const [loading, setLoading] = useState(false);
  const appeal = useSelector(state => state.appellationsResource.byIds[appealId], isEqual);
  const user = useSelector(getCurrentUser, isEqual);

  const loadAppeal = async () => {
    mounted.current && setLoading(true);
    try {
      await dispatch(
        appellationsResource.operations.loadById({
          id: appealId,
          include:
            'comments.uploaded-files,actions,actions.question,actions.creator,actions.watcher,watchers,appellant,objections,assigned-to,review.reviewer,review.custom_communication,review.text_communication,review.phone_call,review.client_interaction.operator,review.client_interaction,review.checklist.questions,review.checklist.checklist_definition.question_groups.question_to_group_bindings.question,review.comments.uploaded-files,appellation-comments.uploaded-files'
        })
      );
    } catch (error) {
      console.log(error);
    }
    mounted.current && setLoading(false);
  };

  useEffect(() => {
    if (appealId) {
      loadAppeal().then(r => r);
    }
  }, [appealId]);

  useEffect(() => {
    // Will set it to true on mount ...
    mounted.current = true;
    return () => {
      // ... and to false on unmount
      mounted.current = false;
    };
  }, []);

  return (
    <SRow
      padding="16px 8px 16px 8px"
      gutter={[16, 0]}
      style={{ margin: 0 }}
      data-testid="appeal-page"
    >
      <Helmet>
        <title>{t('pagesMeta.appealsPage.title')}</title>
      </Helmet>
      <SCol span={19}>
        <SRow gutter={[0, 16]} style={{ marginBottom: '-8px' }}>
          <SCol span={24}>
            <AppealInfo loading={loading} appeal={appeal} />
          </SCol>

          <SCol span={24}>
            <AppealStatus loading={loading} appeal={appeal} />
          </SCol>

          <SCol span={24} marginBottom="123px">
            <Actions loading={loading} appeal={appeal} />
          </SCol>
        </SRow>
      </SCol>

      <SCol span={5}>
        <ParticipantsCard loading={loading} user={user} appeal={appeal} />
      </SCol>

      <ClientInteractionDrawer appealId={appealId} shouldLoad padding="0" />
    </SRow>
  );
};

export default AppealPage;
