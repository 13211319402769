export default {
  backgroundJob: {
    title: 'The application installation and database synchronization are in progress',
    description: 'It may take a few minutes',
    messages: { error: 'An error has occurred. Try again' },
    status: {
      process: 'In process'
    }
  }
};
