export default {
  clientInteractionsPage: {
    clientInteractionsTable: {
      deactivatedUserLinkToCommunicationModal: {
        title: 'Ufff... Este usuario no está activo 😢',
        description: 'Para pasar a la evaluación, es necesario activar el usuario. ',
        button: 'Pasar a los ajustes',
        ok: 'Entendido',
        cancel: 'Cerrar'
      },
      notFieldsFilled: {
        title: 'No se han completado todos los campos obligatorios',
        description: 'Por favor, rellene todos los campos obligatorios',
        ok: 'Es comprensible'
      },
      emptyTables: 'No hay tabla de comunicación',
      buttons: { createTable: 'Generar' }
    },
    clientInteractionMeta: { searchResult: 'Comunicaciones encontradas: ' },
    tableFilters: {
      tableConfigurations: { title: 'Configuraciones de filtros' },
      salesPipelines: {
        salesPipelinesPlaceholder: 'Embudo de comunicación',
        salePipelineStatusesPlaceholder: 'Etapas del embudo de comunicación'
      },
      search: {
        clientPhoneNumberPlaceholder: 'Introduzca número de teléfono',
        placeholder: 'Buscar',
        search: 'Buscar campos',
        result: {
          standartResult: 'Resultado de la búsqueda:',
          bigResult: '20 o más',
          emptyResult: 'Resultado de la búsqueda: no se ha encontrado'
        },
        searchOptions: {
          clientId: 'ID clienta',
          operatorId: 'ID del operador',
          clientPhoneNumber: 'Teléfono del cliente',
          companyName: 'Nombre de la compañía',
          clientName: 'Nombre del cliente',
          email: 'Correo electrónico'
        }
      },
      buttons: {
        applyFilters: 'Utilizar los filtros',
        resetFilters: 'Eliminar los filtros',
        saveFilters: 'Guardar la configuración'
      },
      messages: {
        tableSuccessfullyUpdated: 'La configuración se ha actualizado',
        updateTableFailed: 'No se actualizó la configuración'
      },
      shareConfirm: {
        title:
          'Los cambios realizados en esta tabla serán accesibles a todos los usuarios que tienen acceso a ella',
        description: 'Continuar?',
        ok: 'Si',
        cancel: 'No'
      },
      tableGeneralFilters: {
        title: 'Información general',
        form: {
          communicationsTypes: 'Tipo de comunicación',
          communicationsTypesShort: 'Tipos',
          directionPlaceholder: 'Dirección de la comunicación',
          direction: 'Dirección: ',
          datesRange: 'Diapasón de fechas',
          responseTime: 'Tiempo de respuesta:',
          communicationPartsCount: 'Cantidad de mensajes',
          duration: 'Duración',
          unit: 'Departamento',
          units: 'Departamentos:',
          levels: 'Niveles',
          operators: 'Operadores',
          nps: 'NPS',
          reviewStatus: 'Estatus de la evaluación',
          status: 'Estatus del contacto',
          integrations: 'Integraciones'
        }
      },
      tableCategoryName: 'Поля из карточек CRM',
      tableCategoryNameCustomFields: 'Campos personalizados',
      tableReviewsFilters: {
        title: 'Información sobre la evaluación',
        infoTooltip: 'Estos filtros están activos solo para las comunicaciones evaluadas',
        form: {
          reviewsCount: 'Cantidad de evaluaciones:',
          checklistsPlaceholder: 'Listas a chequear',
          reviewResult: 'Resultados de la evaluación:',
          reviewAuthor: 'Autores de la evaluación',
          reviewAuthorShort: 'Verificador',
          datesRange: 'Diapasón de fechas',
          commentsRatingFlags: 'Banderas y comentarios',
          tasksDefinitions: 'Etiquetas',
          questions: 'Criterios',
          questionResult: 'Resultado del criterio'
        }
      },
      customFieldFilters: { title: 'Información Adicional' }
    },
    conflicts: {
      title: 'Los ajustes establecidos no están disponibles',
      description1:
        'Debido a un cambio en la configuración del perfil, ha perdido el acceso a la configuración del filtro seleccionado.',
      description2:
        'Para cambiar la configuración de los filtros, haga clic en "Restablecer filtros" y establezca la nueva configuración. Para recuperar el acceso a la configuración del filtro seleccionado, póngase en contacto con el administrador.'
    },
    exportModal: {
      ok: 'Si',
      cancel: 'No',
      title: 'Exportación de la lista de comunicaciones',
      content:
        'Después de la confirmación, se iniciará el proceso de exportación, seleccione el método para obtener el archivo.',
      contentBreak: '¿Estás seguro de que quieres interrumpir el proceso de exportación?',
      cancelWait: 'Esperar',
      cancel2: 'Cancelación',
      download: 'Descargar',
      mail: 'Correo'
    },
    importModal: {
      title: 'Importar llamadas',
      content:
        'La importación se ha agregado correctamente a la cola para su procesamiento. Se enviará un mensaje en el que se indique que las comunicaciones han terminado de descargarse.',
      errors: {
        title: 'Se produjo un error al importar el archivo'
      }
    },
    drawer: {
      title: 'Ajuste de las tablas',
      columns: {
        title: 'Columnas',
        confirmDeleteColumn: {
          title: '¿Está seguro que quiere borrar esta columna?',
          ok: 'Si',
          cancel: 'No'
        },
        buttons: {
          viewAddColumnPanel: 'Añadir una columna',
          hidePanel: 'Ocultar'
        }
      },
      tables: {
        title: 'Configuración de la tabla',
        empty: 'No se ha formado ninguna configuración',
        tableCountMax: 'Se han formado la cantidad máxima de tablas',
        selectTablePlaceholder: 'Elija una tabla activa',
        shareTable: {
          title: 'Ajustes de acceso',
          messages: {
            tableSuccessfullyShared: 'Ajustes de acceso actualizados',
            tableSharedFailed: 'No se logró actualizar los ajustes de acceso'
          },
          sharedUserSelectPlaceholder: 'Introduzca el nombre del empleado',
          buttons: { apply: 'Aceptar' }
        },
        strings: {
          copyString: '(copia)',
          openAsccess: '(acceso abierto)',
          author: 'autor',
          newTableString: 'Nueva configuración'
        },
        messages: {
          tableSuccessfullyCreated: 'Configuración creada',
          tableSuccessfullyCopied: 'Configuración copiada',
          tableSuccessfullyUpdated: 'Configuración actualizada',
          tableSuccessfullyDeleted: 'Configuración eliminada',
          tableSuccessfullyExported: 'La tabla se ha exportado',
          tableSuccessfullyExportMessage1: 'La lista de comunicaciones llegará correo',
          tableSuccessfullyExportMessage2: 'en unos minutos',
          copyTableFailed: 'No se pudo copiar la configuración',
          createTableFailed: 'No se pudo crear la configuración',
          updateTableFailed: 'No se pudo actualizar la configuración',
          deleteTableFailed: 'No se pudo borrar la configuración',
          exportTableFailed: 'No se pudo exportar la lista de comunicaciones'
        },
        confirmDelete: {
          title: 'Está seguro que quiere borrar esta configuración?',
          description: 'Después de borrar la configuración, no se podrán retomar los ajustes',
          ok: 'Eliminar',
          cancel: 'Cancelar'
        },
        menu: {
          addConfig: 'Añadir una Cofiguración',
          editTable: 'Renombrar configuración',
          editTableColumns: 'Editar columnas',
          copyTable: 'Hacer una copia',
          shareTable: 'Compartir',
          export: 'Exportar a Excel',
          exportToTxt: 'Exportar a Txt',
          deleteTable: 'Eliminar'
        },
        buttons: { addConfig: 'Añadir una Cofiguración' }
      }
    }
  }
};
