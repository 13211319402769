import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelections } from 'ahooks';
import {
  connectHelpDeskEddyTicketFieldById,
  disconnectHelpDeskEddyTicketFieldById,
  getHelpDeskEddyTicketFields
} from 'redux/entities/helpDeskEddyIntegration/operations';
import SCard from 'components/Standard/SCard';
import { Skeleton } from 'antd';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import { SListItem } from 'components/Standard/SList';
import { FixedSizeList } from 'react-window';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { values, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Search from 'components/Inputs/Search';

const renderItem = ({ search, field, style, isSelected, handleToggle, t }) => {
  if (!field.name.toLowerCase().includes(search.toLowerCase())) {
    return null;
  }
  return (
    <SListItem style={style} borderBottom="1px solid var(--gray-border)">
      <SCol span={24} style={{ padding: '0' }}>
        <SRow type="flex" justify="space-between" align="middle">
          <SCol span={1}>
            <Checkbox checked={isSelected(field.id)} onClick={() => handleToggle(field)} />
          </SCol>
          <SCol span={9}>
            <SText {...(isEmpty(field.name) ? { type: 'secondary' } : {})}>
              {field.name ||
                t('integrationsSettingsPage.integrationPage.integrationFieldsList.empty')}
            </SText>
          </SCol>
          <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
            <SRow type="flex" style={{ maxWidth: '100%' }}>
              <SText className="truncated">{field.ticketFieldType}</SText>
            </SRow>
          </SCol>
        </SRow>
      </SCol>
    </SListItem>
  );
};

const HDEFields = ({ integrationId }) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selected, isSelected, toggle, setSelected } = useSelections([], []);
  const [search, setSearch] = useState('');
  const handleToggle = async field => {
    try {
      await dispatch(
        isSelected(field.id)
          ? disconnectHelpDeskEddyTicketFieldById({
              id: integrationId,
              ticketFieldId: field.id
            })
          : connectHelpDeskEddyTicketFieldById({
              id: integrationId,
              ticketFieldId: field.id
            })
      );
      toggle(field.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const loadFields = async () => {
      setLoading(true);
      const responseFields = await dispatch(getHelpDeskEddyTicketFields({ id: integrationId }));

      const fields = values(responseFields);

      setFields(fields);
      setSelected(fields.filter(field => field.connected).map(field => field.id));
    };

    if (integrationId) {
      loadFields().then(() => setLoading(false));
    }
  }, [integrationId]);

  if (loading)
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  const fieldsSort = (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA.includes(search) && !nameB.includes(search)) {
      return -1;
    }

    if (!nameA.includes(search) && nameB.includes(search)) {
      return 1;
    }

    return 0;
  };
  fields.sort(fieldsSort);
  return (
    <SCard bordered shadowed>
      <SRow gutter={[0, 16]} style={{ marginBottom: '-16px' }}>
        <SCol span={24}>
          <SRow align="middle" justify="space-between">
            <SCol>
              <SRow align="middle" gutter={[16, 0]}>
                <SCol>
                  <Search onSearch={setSearch} />
                </SCol>
                <SCol>
                  <SText type="secondary">{`${t('general.selected')}: ${selected.length}`}</SText>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
        </SCol>
        <SCol span={24}>
          <SListItem borderBottom="1px solid var(--gray-border)">
            <SCol span={24} style={{ padding: '0' }}>
              <SRow type="flex" justify="space-between" align="middle">
                <SCol span={1} />
                <SCol span={9}>
                  <SText strong>
                    {t('integrationsSettingsPage.integrationPage.integrationFieldsList.name')}
                  </SText>
                </SCol>
                <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                  <SText strong>
                    {t('integrationsSettingsPage.integrationPage.integrationFieldsList.type')}
                  </SText>
                </SCol>
              </SRow>
            </SCol>
          </SListItem>

          <FixedSizeList itemSize={56} itemCount={fields.length} height={500}>
            {({ index, style }) =>
              renderItem({ search, field: fields[index], style, isSelected, handleToggle, t })
            }
          </FixedSizeList>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default HDEFields;
