const QUESTION_ANALYTICS = {
  LOAD_STARTED: 'QUESTION_ANALYTICS/LOAD_STARTED',
  LOAD_SUCCESS: 'QUESTION_ANALYTICS/LOAD_SUCCESS',
  LOAD_FAILED: 'QUESTION_ANALYTICS/LOAD_FAILED'
};

const QUESTION_ANALYTICS_HISTORY = {
  LOAD_STARTED: 'QUESTION_ANALYTICS_HISTORY/LOAD_STARTED',
  LOAD_SUCCESS: 'QUESTION_ANALYTICS_HISTORY/LOAD_SUCCESS',
  LOAD_FAILED: 'QUESTION_ANALYTICS_HISTORY/LOAD_FAILED'
};

export { QUESTION_ANALYTICS, QUESTION_ANALYTICS_HISTORY };
