import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  isSidebarCollapsed: false
};

export const collapseSidebar = createAction('uiLayout/collapseSidebar');
export const expandSidebar = createAction('uiLayout/expandSidebar');
export const toggleSidebar = createAction('uiLayout/toggleSidebar');

export const layoutReducer = createReducer(defaultState, {
  [collapseSidebar]: state => ({ ...state, isSidebarCollapsed: true }),
  [expandSidebar]: state => ({ ...state, isSidebarCollapsed: false }),
  [toggleSidebar]: state => ({ ...state, isSidebarCollapsed: !state.isSidebarCollapsed })
});
