import createListReducer from 'core/redux/lists/listReducer';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';

const workPlanTaskConfigurationsParams = {
  name: 'workPlanTaskConfigurations',
  defaultState: {},
  rebuildQuery: true,
  loader: workPlanTaskConfigurationsResource.operations.load
};

const additionalReducer = {
  onConfigurationDelete: (state, { payload }) => {
    const { id } = payload;
    return state.ids.includes(id)
      ? { ...state, ids: [...state.ids.filter(configurationId => configurationId !== id)] }
      : state;
  }
};

const { actions, reducer, operations } = createListReducer({
  ...workPlanTaskConfigurationsParams,
  additionalReducer
});

const workPlanTaskConfigurationsListReducer = reducer;

export { actions, workPlanTaskConfigurationsListReducer, operations };
