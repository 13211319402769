import api from 'core/api';
import decamelize from 'decamelize-keys-deep';
import { processJsonApiObject } from 'core/jsonapi';
import loadIncludedObjectsToRedux from 'core/redux/resources/loadIncludedObjects';
import { loadDashboardAnalyticsSucceed } from 'redux/entities/analytics/dashboard/reducer';
import { get, keyBy } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import * as actions from '../reducer';

export const loadAppellationsDashboard = () => async (dispatch, getState) => {
  dispatch(actions.loadingDashboard());
  try {
    const dashboardResponse = await api.getAppellationsDashboard();
    const analyticsResponse = await api.getAppellationsDashboardAnalytics();
    await dispatch(checklistDefinitionsResource.operations.load({ pagination: false }));
    const dashboard = processJsonApiObject(dashboardResponse.body.data);
    const analytics = analyticsResponse.body;

    loadIncludedObjectsToRedux({
      objects: dashboardResponse.body.included,
      dispatch
    });

    const loadedWidgetsIds = Object.keys(analyticsResponse.body) || [];

    loadedWidgetsIds.map(widgetId => {
      loadIncludedObjectsToRedux({
        objects: analyticsResponse.body[widgetId]?.users,
        dispatch
      });
    });

    const state = await getState();

    dispatch(loadDashboardAnalyticsSucceed(analytics));
    dispatch(
      actions.dashboardLoaded({
        ...dashboard,
        widgetsByTypes: camelcaseKeys(
          keyBy(
            get(dashboard, 'widgetsIds', []).map(id => state.dashboardWidgetsResource.byIds[id]),
            'type'
          )
        )
      })
    );
  } catch (error) {
    console.log(error);
  }
};

export const updateAppellationsDashboardPageFilters = ({ filters }) => async (
  dispatch,
  getState
) => {
  // TODO: это появилось тут из-за defaultSortOrder
  dispatch(actions.setLoadingWidgetId('filters'));
  try {
    const updatedDashboardResponse = await api.updateAppellationsDashboard(decamelize({ filters }));
    const updatedAnalyticsResponse = await api.getAppellationsDashboardAnalytics();
    const dashboard = processJsonApiObject(updatedDashboardResponse.body.data);
    const analytics = updatedAnalyticsResponse.body;

    const loadedWidgetsIds = Object.keys(updatedAnalyticsResponse.body) || [];

    loadedWidgetsIds.map(widgetId => {
      loadIncludedObjectsToRedux({
        objects: updatedAnalyticsResponse.body[widgetId]?.users,
        dispatch
      });
    });

    loadIncludedObjectsToRedux({
      objects: updatedDashboardResponse.body.included,
      dispatch
    });

    const state = await getState();

    dispatch(loadDashboardAnalyticsSucceed(analytics));
    dispatch(
      actions.dashboardLoaded({
        ...dashboard,
        widgetsByTypes: camelcaseKeys(
          keyBy(
            get(dashboard, 'widgetsIds', []).map(id => state.dashboardWidgetsResource.byIds[id]),
            'type'
          )
        )
      })
    );
    dispatch(actions.setLoadingWidgetId());
  } catch (error) {
    console.log(error);
  }
};
