import React from 'react';
import { Empty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { SCol, SRow } from '../../components/Standard';

const EmptyForUserPage = () => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <SRow padding="16px" width="100%" justify="center">
      <SCol span={24}>
        <Empty description={<Text type="secondary">{t('general.empty')}</Text>} />
      </SCol>
    </SRow>
  );
};

export default EmptyForUserPage;
