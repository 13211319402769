import { destroy, get, put, post } from 'redux-bees';

const usersEndpoints = apiPath => ({
  getUsers: { method: get, path: `${apiPath}/users` },
  createUser: { method: post, path: `${apiPath}/users` },
  getUser: { method: get, path: `${apiPath}/users/:id` },
  updateUser: { method: put, path: `${apiPath}/users/:id` },
  deleteUser: { method: destroy, path: `${apiPath}/users/:id` },
  inviteUser: { method: post, path: `${apiPath}/users/:id/invite` }
});

export default usersEndpoints;
