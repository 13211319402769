import createListReducer from 'core/redux/lists/listReducer';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import decamilize from 'decamelize';

const parseSorter = sorter => {
  if (!sorter.order) return '';
  return `${sorter.order === 'descend' ? '-' : ''}${decamilize(sorter.columnKey, '_')}`;
};

const additionalReducer = {
  updateSort: (state, { payload }) => {
    return { ...state, sort: parseSorter(payload) };
  }
};

export const listParams = {
  name: 'checklistDefinitionsList',
  loader: checklistDefinitionsResource.operations.load,
  additionalReducer,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const checklistDefinitionsListReducer = reducer;

export { actions, checklistDefinitionsListReducer, operations };
