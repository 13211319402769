import { get, put, destroy, post } from 'redux-bees';

const questionGroupsEndpoints = apiPath => ({
  createChecklistQuestionGroup: { method: post, path: `${apiPath}/checklist/question_groups` },
  getChecklistQuestionGroups: { method: get, path: `${apiPath}/checklist/question_groups` },
  updateChecklistQuestionGroup: { method: put, path: `${apiPath}/checklist/question_groups/:id` },
  deleteChecklistQuestionGroup: {
    method: destroy,
    path: `${apiPath}/checklist/question_groups/:id`
  }
});

export default questionGroupsEndpoints;
