import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';

const ChecklistDefinitionStatusTag = ({ status = 'draft' }) => {
  const { t } = useTranslation();
  if (!status) {
    return <Tag>{t('components.checklistDefinitionStatusTag.unistalledTag')}</Tag>;
  }

  const { color, text } = CHECKLIST_DEFINITION_STATUS[status.toUpperCase()];
  return (
    <Tag color={color} style={{ margin: 0 }}>
      {t(text)}
    </Tag>
  );
};

export default ChecklistDefinitionStatusTag;
