import { createAction, createReducer } from '@reduxjs/toolkit';
import { CHECKLIST_MANAGER_STATES } from 'core/utils/constants';
import { isEmpty, isNil, omit, pickBy } from 'lodash';

const defaultState = {
  loading: false,
  currentChecklist: {},
  questionIdDescriptionModal: undefined,
  questionIdToAnswerValue: {},
  questionIdToStandardComment: {},
  addingCommentsToQuestionId: null,
  appealId: null,
  questionIdToAppealObjection: {},
  questionIdToAppealComment: {},
  checklistManagerState: CHECKLIST_MANAGER_STATES.INITIAL,
  previousChecklistManagerState: CHECKLIST_MANAGER_STATES.INITIAL,
  addingCommentsMassAction: []
};

export const clearCurrentChecklist = createAction('uiChecklistManager/clearCurrentChecklist');

export const setChecklistManagerState = createAction('uiChecklistManager/setChecklistManagerState');

export const updateAnswer = createAction('uiChecklistManager/Answer/Update');

export const updateComment = createAction('uiChecklistManager/Comment/Update');

export const updateCurrentChecklist = createAction('uiChecklistManager/Checklist/Update');

export const setReviewState = createAction('uiChecklistManager/setReviewState');

export const setNewChecklistState = createAction('uiChecklistManager/setNewChecklistState');

export const setLoading = createAction('uiChecklistManager/setLoading');

export const setInitialState = createAction('uiChecklistManager/setInitialState');

export const setQuestionIdDescriptionModal = createAction(
  'uiChecklistManager/setQuestionIdDescriptionModal'
);

export const updateQuestionStandardComments = createAction(
  'uiChecklistManager/updateQuestionStandardComment'
);

export const setAddingCommentsToQuestionId = createAction(
  'uiChecklistManager/setAddingCommentsToQuestionId'
);

export const setAppealId = createAction('uiChecklistManager/setAppealId');

export const setQuestionIdToAppealObjection = createAction(
  'uiChecklistManager/setQuestionIdToAppealObjection'
);

export const setQuestionIdsToAppealObjections = createAction(
  'uiChecklistManager/setQuestionIdsToAppealObjections'
);

export const setQuestionIdToAppealComment = createAction(
  'uiChecklistManager/setQuestionIdToAppealComment'
);

export const setQuestionIdsToAppealComments = createAction(
  'uiChecklistManager/setQuestionIdsToAppealComments'
);

export const setAddingCommentsMassAction = createAction(
  'uiChecklistManager/setAddingCommentsMassAction'
);

export const removeAddingCommentsMassAction = createAction(
  'uiChecklistManager/removeAddingCommentsMassAction'
);

export const clearAddingCommentsMassAction = createAction(
  'uiChecklistManager/clearAddingCommentsMassAction'
);

export const checklistManagerReducer = createReducer(defaultState, {
  [setReviewState]: (state, { payload }) => {
    const { checklist, questionIdToAnswerValue, questionIdToStandardComment } = payload;
    return {
      ...state,
      loading: false,
      currentChecklist: checklist,
      questionIdToAnswerValue: questionIdToAnswerValue || state.questionIdToAnswerValue,
      questionIdToStandardComment: questionIdToStandardComment || state.questionIdToStandardComment,
      checklistManagerState: isEmpty(checklist)
        ? CHECKLIST_MANAGER_STATES.INITIAL
        : CHECKLIST_MANAGER_STATES.SAVED,
      previousChecklistManagerState: isEmpty(checklist)
        ? CHECKLIST_MANAGER_STATES.INITIAL
        : CHECKLIST_MANAGER_STATES.SAVED
    };
  },
  [setNewChecklistState]: (state, { payload }) => {
    const { checklist, questionIdToAnswerValue } = payload;
    return {
      ...state,
      loading: false,
      currentChecklist: checklist,
      questionIdToAnswerValue: questionIdToAnswerValue || state.questionIdToAnswerValue,
      checklistManagerState: CHECKLIST_MANAGER_STATES.EDITING
    };
  },
  [clearCurrentChecklist]: state => {
    return {
      ...state,
      questionIdToAnswerValue: {},
      checklistComment: '',
      currentChecklist: {}
    };
  },
  [updateAnswer]: (state, { payload }) => {
    const questionIdToAnswerValue = pickBy(
      {
        ...state.questionIdToAnswerValue,
        [payload.questionId]: payload.value
      },
      value => !isNil(value)
    );
    return {
      ...state,
      questionIdToAnswerValue
    };
  },
  [setLoading]: (state, { payload }) => ({ ...state, loading: payload }),
  [updateCurrentChecklist]: (state, { payload }) => ({
    ...state,
    currentChecklist: { ...state.currentChecklist, ...payload }
  }),
  [setChecklistManagerState]: (state, { payload }) => ({
    ...state,
    checklistManagerState: payload
  }),
  [setQuestionIdDescriptionModal]: (state, { payload }) => ({
    ...state,
    questionIdDescriptionModal: payload
  }),
  [updateQuestionStandardComments]: (state, { payload }) => ({
    ...state,
    questionIdToStandardComment: {
      ...state.questionIdToStandardComment,
      [payload.questionId]: payload.comments
    }
  }),
  [setAddingCommentsToQuestionId]: (state, { payload }) => ({
    ...state,
    addingCommentsToQuestionId: payload
  }),
  [setAppealId]: (state, { payload }) => ({ ...state, appealId: payload }),
  [setQuestionIdToAppealObjection]: (state, { payload }) =>
    payload.objection
      ? {
          ...state,
          questionIdToAppealObjection: {
            ...state.questionIdToAppealObjection,
            [payload.questionId]: payload.objection
          }
        }
      : {
          ...state,
          questionIdToAppealObjection: omit(state.questionIdToAppealObjection, payload.questionId)
        },
  [setQuestionIdToAppealComment]: (state, { payload }) =>
    payload?.comment
      ? {
          ...state,
          questionIdToAppealComment: {
            ...state.questionIdToAppealComment,
            [payload.questionId]: payload.comment
          }
        }
      : {
          ...state,
          questionIdToAppealComment: omit(state.questionIdToAppealComment, payload.questionId)
        },
  [setQuestionIdsToAppealComments]: (state, { payload }) => ({
    ...state,
    questionIdToAppealComment: payload
  }),
  [setQuestionIdsToAppealObjections]: (state, { payload }) => ({
    ...state,
    questionIdToAppealObjection: payload
  }),
  [setInitialState]: () => defaultState,
  [setAddingCommentsMassAction]: (state, { payload }) => {
    const alreadyAdded = state.addingCommentsMassAction.find(item => item === payload);
    if (alreadyAdded) {
      return state;
    }
    return {
      ...state,
      addingCommentsMassAction: [...state.addingCommentsMassAction, payload]
    };
  },
  [removeAddingCommentsMassAction]: (state, { payload }) => {
    const filteredComments = state.addingCommentsMassAction.filter(item => item !== payload);
    return {
      ...state,
      addingCommentsMassAction: filteredComments
    };
  },
  [clearAddingCommentsMassAction]: state => ({
    ...state,
    addingCommentsMassAction: []
  })
});
