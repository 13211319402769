import React from 'react';
import { Tag, Tooltip } from 'antd';
import truncateString from 'core/utils/truncateString';
import { transformToDarkerColor } from 'core/utils/colors';

const CustomTag = ({ color, text, truncate = 30 }) => {
  const truncatedText = truncateString(text, truncate);
  return (
    <Tooltip title={truncatedText !== text && text}>
      <Tag color={transformToDarkerColor(color)} style={{ marginRight: 4 }}>
        {truncatedText}
      </Tag>
    </Tooltip>
  );
};
export default CustomTag;
