import { orderBy, find, get } from 'lodash';
import { beatifyFloat } from 'core/utils/convertFloat';

export default ({ widgetValue, currentChecklistBindings, questionGroupsByIds }) => {
  const findQuestionBindind = ({ questionId }) => find(currentChecklistBindings, { questionId });

  const data = widgetValue.map(question => ({
    ...question,
    value: beatifyFloat(question.value),
    position:
      get(findQuestionBindind({ questionId: question.id }), 'position', 0) +
      get(
        questionGroupsByIds[
          get(findQuestionBindind({ questionId: question.id }), 'questionGroupId')
        ],
        'position',
        0
      )
  }));

  return orderBy(data, 'position', 'desc');
};
