import React from 'react';
import { Row } from 'antd';
import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components';

const ValueBar = ({ data, maxValue, color, ratingMode }) => {
  return (
    <StyledRow>
      <ResponsiveBar
        data={data}
        borderRadius={4}
        keys={['value']}
        indexBy="id"
        margin={{ top: -4, right: 0, bottom: -4, left: 0 }}
        layout="horizontal"
        colors={[color]}
        tooltip={tooltip =>
          `${tooltip.data.name}: ${tooltip.data.value}${ratingMode === 'percentage' ? '%' : ''}`
        }
        label={d => `${d.value}${ratingMode === 'percentage' ? '%' : ''}`}
        labelTextColor="#4A4A4A"
        labelSkipWidth={12}
        maxValue={maxValue}
        labelSkipHeight={12}
        animate={false}
        motionStiffness={0}
        motionDamping={0}
        enableGridY={false}
      />
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  &.ant-row {
    height: 32px !important;
    background-color: white;
  }
`;

export default ValueBar;
