import { Col, Empty, Modal, Row, Spin } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import { CHECKLIST_DEFINITION_STATUS, RATING_MODES } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { compact, find, get, isEmpty, isEqual } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getChecklistDefinitionBindings,
  getChecklistDefinitionQuestionGroups,
  getQuestionsByIds
} from 'redux/selectors/checklistItems/checklistItems';
import {
  getChecklistsDefinitionsByIds,
  getChecklistsDefinitionsByStatuses
} from 'redux/selectors/checklists';
import { useDispatch, useSelector } from 'react-redux';
import { resetConditions, updateTaskDefinition } from 'redux/ui/taskDefinition/reducer';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import { SelectChecklistCol, StyledDivider } from '../styled';
import ChecklistDefinitionItem from './ChecklistDefinitionItem';
import QuestionGroup from './QuestionGroup';

const { confirm } = Modal;

const ChecklistItems = () => {
  const dispatch = useDispatch();

  const checklistDefinitionId = useSelector(state => state.uiTaskDefinition.checklistDefinitionId);

  const conditions = useSelector(state => state.uiTaskDefinition.conditions, isEqual);

  const checklistDefinitions = useSelector(
    state =>
      getChecklistsDefinitionsByStatuses(state, {
        statuses: [CHECKLIST_DEFINITION_STATUS.PUBLISHED.value]
      }),
    isEqual
  );

  const checklistDefinitionsByIds = useSelector(
    state => getChecklistsDefinitionsByIds(state),
    isEqual
  );

  const loadingChecklistDefinition = useSelector(
    state => state.checklistDefinitionsResource.loading
  );

  const questionsByIds = useSelector(state => getQuestionsByIds(state), isEqual);

  const currentChecklist = find(checklistDefinitions, { id: checklistDefinitionId }) || {};

  const currentChecklistQuestionGroups = useSelector(
    state => getChecklistDefinitionQuestionGroups(state, currentChecklist),
    isEqual
  );

  const currentChecklistBindings = useSelector(
    state => compact(getChecklistDefinitionBindings(state, currentChecklist)),
    isEqual
  );

  const selectChecklistDefinitionId = useCallback(
    value => {
      dispatch(resetConditions());
      dispatch(updateTaskDefinition({ checklistDefinitionId: value }));
    },
    [dispatch]
  );

  const { t } = useTranslation();

  const changeChecklistDefinitionId = useCallback(
    value => {
      confirm({
        title: t('triggersSettingsPage.editTaskDefinition.changeChecklistDefinitionConfirm.title'),
        content: t(
          'triggersSettingsPage.editTaskDefinition.changeChecklistDefinitionConfirm.description'
        ),
        okText: t('triggersSettingsPage.editTaskDefinition.changeChecklistDefinitionConfirm.ok'),
        okType: 'danger',
        cancelText: t(
          'triggersSettingsPage.editTaskDefinition.changeChecklistDefinitionConfirm.cancel'
        ),
        onOk: () => {
          dispatch(resetConditions());
          dispatch(updateTaskDefinition({ checklistDefinitionId: value }));
        }
      });
    },
    [dispatch]
  );

  const availableChecklists = ({ checklistDefinitionId }) => {
    const availableChecklistsIds = checklistDefinitions.map(checklist => checklist.id);
    const availableChecklists = availableChecklistsIds.includes(checklistDefinitionId)
      ? availableChecklistsIds
      : [...availableChecklistsIds, checklistDefinitionId];

    return checklistDefinitionId
      ? availableChecklists.map(checklistId => get(checklistDefinitionsByIds, checklistId, {}))
      : checklistDefinitions;
  };

  const isPercentageChecklist = currentChecklist?.ratingMode === RATING_MODES.percentage.value;

  const onChange = value =>
    !isEmpty(conditions) ? changeChecklistDefinitionId(value) : selectChecklistDefinitionId(value);

  return (
    <Spin spinning={loadingChecklistDefinition}>
      <Row gutter={[4]}>
        <Col>
          <SelectChecklistCol>
            <span>{t('triggersSettingsPage.editTaskDefinition.form.checklistDefinition')}</span>
          </SelectChecklistCol>
          <ChecklistDefinitionsSelect
            onChange={onChange}
            placeholder={t('general.checklistDefinitionPlaceholder')}
            checklistDefinitions={availableChecklists({ checklistDefinitionId })}
            value={checklistDefinitionId}
            showSearch
            style={{ width: 350 }}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'searchValue' })
            }
          />
          <StyledDivider />
        </Col>
        {isEmpty(currentChecklist) ? (
          <SCard bordered={false} shadowed={false} marginTop="16px">
            <Empty
              description={t('triggersSettingsPage.editTaskDefinition.empty.checklistDefinition')}
            />
          </SCard>
        ) : (
          <SCol span={24} marginTop="8px">
            <ChecklistDefinitionItem
              name={currentChecklist.name}
              min={isPercentageChecklist ? 0 : currentChecklist.min}
              max={isPercentageChecklist ? 100 : currentChecklist.max}
              color="var(--gray_4)"
              checklistDefinitionItemId={checklistDefinitionId}
              type="checklist_definition"
              checklistDefinitionId={checklistDefinitionId}
            />
            {currentChecklist.isGroupable ? (
              currentChecklistQuestionGroups.map(questionGroup => (
                <QuestionGroup
                  key={questionGroup.id}
                  currentChecklist={currentChecklist}
                  questionGroupId={questionGroup.id}
                  isPercentageChecklist={isPercentageChecklist}
                />
              ))
            ) : (
              <>
                {currentChecklistBindings.map(binding => (
                  <ChecklistDefinitionItem
                    name={get(questionsByIds, `${binding.questionId}.name`)}
                    min={
                      isPercentageChecklist ? 0 : get(questionsByIds, `${binding.questionId}.min`)
                    }
                    max={
                      isPercentageChecklist ? 100 : get(questionsByIds, `${binding.questionId}.max`)
                    }
                    color="white"
                    key={get(questionsByIds, `${binding.questionId}.id`)}
                    checklistDefinitionItemId={get(questionsByIds, `${binding.questionId}.id`)}
                    type="question"
                    checklistDefinitionId={checklistDefinitionId}
                  />
                ))}
              </>
            )}
          </SCol>
        )}
      </Row>
    </Spin>
  );
};

export default ChecklistItems;
