import { Trash2, MoreVertical } from 'react-feather';
import { Button, Col, Dropdown, Empty, Menu, message, Modal, Row, Typography } from 'antd';
import ClientInteractionLink from 'components/ClientInteractionLink';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import UserPreview from 'components/UserPreview';
import { isEmpty, reduce } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions, operations } from 'redux/lists/foldersListReducer';
import { foldersResource } from 'redux/resources/folders';
import { folderItemsResource } from 'redux/resources/items';
import { getFolderItemsWithClientInteractionsByIds } from 'redux/selectors/folders';
import Icon from 'components/Icon';

const { Text } = Typography;

const Folders = ({
  filters,
  clearList,
  setFilters,
  folders = [],
  loadingFolders,
  loadFolders,
  itemsByIds,
  deleteItem,
  deleteFolder,
  timeRange = {},
  clientInteractionsByIds
}) => {
  const { folderSymbolicTimeRange, folderTimeFrom, folderTimeTo } = filters;
  const { timeFrom, timeTo, symbolicTimeRange } = timeRange;
  const { t } = useTranslation();

  useEffect(() => {
    clearList();
    return clearList;
  }, []);

  useEffect(() => {
    loadFolders({
      include: 'items.client_interaction.operator',
      pagination: false,
      filters: {
        folderSymbolicTimeRange,
        folderTimeFrom,
        folderTimeTo
      }
    });
  }, [folderSymbolicTimeRange, folderTimeFrom, folderTimeTo]);

  useEffect(() => {
    clearList();
    setFilters({
      folderTimeFrom: timeFrom,
      folderTimeTo: timeTo,
      folderSymbolicTimeRange: symbolicTimeRange
    });
  }, [timeFrom, timeTo, symbolicTimeRange]);

  const handleItemMenuClick = (e, { id, folderId }) => {
    const handlers = {
      delete: async id => {
        Modal.confirm({
          title: t('calibrationsPage.folders.confirmDeleteFolderItem.title'),
          content: t('calibrationsPage.folders.confirmDeleteFolderItem.description'),
          onOk: async () => {
            const result = await deleteItem({ folderId, id });
            if (result) {
              message.success(t('calibrationsPage.folders.messages.fileSuccessfullyDeleted'));
            }
          },
          okText: t('calibrationsPage.folders.confirmDeleteFolderItem.ok'),
          cancelText: t('calibrationsPage.folders.confirmDeleteFolderItem.cancel')
        });
      }
    };

    return handlers[e.key](id);
  };

  const handleFolderMenuClick = (e, id) => {
    const handlers = {
      delete: async id => {
        Modal.confirm({
          title: t('calibrationsPage.folders.confirmDeleteFolder.title'),
          content: t('calibrationsPage.folders.confirmDeleteFolder.description'),
          onOk: async () => {
            const result = await deleteFolder({ id });
            if (result) {
              message.success(t('calibrationsPage.folders.messages.groupSuccessfullyDeleted'));
            }
          },
          okText: t('calibrationsPage.folders.confirmDeleteFolder.ok'),
          cancelText: t('calibrationsPage.folders.confirmDeleteFolder.cancel')
        });
      }
    };

    return handlers[e.key](id);
  };

  const itemMenu = ({ id, folderId }) => {
    return (
      <Menu onClick={e => handleItemMenuClick(e, { id, folderId })}>
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('calibrationsPage.folders.buttons.deleteFile')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const folderMenu = ({ id }) => {
    return (
      <Menu onClick={e => handleFolderMenuClick(e, id)}>
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('calibrationsPage.folders.buttons.deleteFolder')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const renderFolder = ({ name, itemsIds = [], id }) => {
    return (
      <Col span={24} key={id}>
        <SCard
          bodyPadding="0px"
          headPadding="0px 16px"
          title={
            <Row type="flex" justify="space-between">
              <Col>
                <span>{name}</span>
              </Col>
              <Col>
                <Dropdown overlay={folderMenu({ id })} trigger={['click']} placement="bottomLeft">
                  <Button icon={<Icon icon={MoreVertical} />} />
                </Dropdown>
              </Col>
            </Row>
          }
        >
          <STable
            dataSource={itemsIds.map(id => itemsByIds[id]).filter(Boolean)}
            pagination={false}
            rowKey="id"
            columns={[
              {
                title: t('calibrationsPage.folders.table.columns.clientInteraction'),
                key: 'clientInteractionId',
                dataIndex: 'clientInteractionId',
                ellipsis: true,
                width: 60,
                render: clientInteractionId => {
                  const communication = clientInteractionsByIds[clientInteractionId];
                  return (
                    <ClientInteractionLink
                      id={clientInteractionId}
                      isReview={false}
                      communication={communication}
                      useDrawer={false}
                    />
                  );
                }
              },
              {
                title: t('calibrationsPage.folders.table.columns.operator'),
                key: 'operator',
                dataIndex: 'clientInteraction.operatorId',
                ellipsis: true,
                render: (_, record) => (
                  <UserPreview userId={record?.clientInteraction?.operatorId} disabled />
                )
              },
              {
                title: t('calibrationsPage.folders.table.columns.name'),
                key: 'name',
                ellipsis: true,
                dataIndex: 'name'
              },
              {
                title: t('calibrationsPage.folders.table.columns.startedAt'),
                key: 'startedAt',
                dataIndex: 'clientInteraction.startedAt',
                ellipsis: true,
                render: text => moment(text).format('DD/MM/YYYY HH:mm')
              },
              {
                title: t('calibrationsPage.folders.table.columns.phoneNumber'),
                key: 'phoneNumber',
                dataIndex: 'phoneNumber',
                ellipsis: true,
                render: (text, record) => (
                  <Row type="flex" align="middle" justify="space-between">
                    <Text>{text}</Text>
                    <Dropdown
                      overlay={itemMenu({ ...record, folderId: id })}
                      trigger={['click']}
                      placement="bottomLeft"
                    >
                      <Button icon={<Icon icon={MoreVertical} />} />
                    </Dropdown>
                  </Row>
                )
              }
            ]}
          />
        </SCard>
      </Col>
    );
  };

  if (loadingFolders) {
    return (
      <Row type="flex" justify="center" gutter={[16, 16]}>
        <Col>
          <Text type="secondary">{t('calibrationsPage.folders.loading')}</Text>
        </Col>
      </Row>
    );
  }

  if (!loadingFolders && isEmpty(folders)) {
    return (
      <Row type="flex" justify="center" gutter={[16, 16]}>
        <Col>
          <Empty
            description={<Text type="secondary">{t('calibrationsPage.folders.empty')}</Text>}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row type="flex" gutter={[8, 8]} style={{ margin: '-4px' }}>
      {folders.map(renderFolder)}
    </Row>
  );
};

const mapStateToProps = state => {
  const { byIds, loading } = state.foldersResource;
  const { timeRange } = state.uiCalibrationsPage;
  const { ids, filters } = state.foldersList;

  const folders = reduce(
    ids,
    (result, id) => {
      const folder = byIds[id];

      return folder ? [...result, folder] : result;
    },
    []
  );

  return {
    filters,
    timeRange,
    folders,
    foldersByIds: byIds,
    itemsByIds: getFolderItemsWithClientInteractionsByIds(state),
    loadingFolders: loading,
    clientInteractionsByIds: state.clientInteractionsResource.byIds
  };
};

const mapDispatchToProps = {
  loadFolders: operations.load,
  setFilters: actions.setFilters,
  clearList: actions.clearList,
  deleteFolder: foldersResource.operations.deleteById,
  deleteItem: folderItemsResource.operations.delete
};

export default connect(mapStateToProps, mapDispatchToProps)(Folders);
