import { requireSignInAndPermission, requireSignIn, requireOrganizationSetting } from 'core/store';
import { PERMISSIONS } from 'core/utils/constants';
import Reviews from 'pages/BitrixIntegration/Reviews/Reviews';
import ClientInteractions from 'pages/BitrixIntegration/ClientInteractions';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import ChecklistsAndQuestionsPage from 'pages/ChecklistsAndQuestionsPage';
import ChecklistPage from 'pages/ChecklistEditor/ChecklistEditorPage';
import UserAnalyticsPage from 'pages/UserPage';
import UploadCallsPage from 'pages/UploadCallsPage';
import LibraryPage from 'pages/LibraryPage/LibraryPage';
import RootPage from 'pages/RootPage/RootPage';
import TaskDefinitionsPage from 'pages/SettingsPages/TaskDefinitions/TaskDefinitions';
import EditTaskDefinitionCardPage from 'pages/SettingsPages/TaskDefinitions/EditTaskDefinition/EditTaskDefinition';
import ClientInteractionsPage from 'pages/ClientInteractionsPage';
import CallPage from 'pages/ClientInteractionPage/CallPage';
import TextCommunicationPage from 'pages/ClientInteractionPage/TextCommunicationPage';
import ReviewPage from 'pages/ClientInteractionPage/ReviewPage';
import CrmEntityPage from 'pages/ClientInteractionPage/CrmEntityPage';
import CustomCommunicationReviewPage from 'pages/CustomCommunicationReviewPage';
import TeamPage from 'pages/DashboardPages/TeamPage/TeamPage';
import CommunicationPage from 'pages/AmoIntegration/CommunicationPage';
import ChecklistSettingsPage from 'pages/ChecklistSettingsPage';
import SetupOrganizationPage from 'pages/AuthPages/SetupOrganizationPage';
import Calibrations from 'pages/Calibrations';
import CalibrationDetails from 'pages/Calibrations/CalibrationDetails';
import CalibrationResult from 'pages/CalibrationResult/CalibrationResult';
import WorkPlanTaskConfigurations from 'pages/WorkPlanTaskConfigurations/WorkPlanTaskConfigurations/WorkPlanTaskConfigurations';
import WorkPlanTaskConfigurationEditor from 'pages/WorkPlanTaskConfigurationEditor';
import WorkPlanTasks from 'pages/WorkPlanTaskConfigurations/WorkPlanTasks/WorkPlanTasks';
import WorkPlanTaskAssignments from 'pages/WorkPlanTaskConfigurations/WorkPlanTaskAssignments/WorkPlanTaskAssignments';
import WorkPlanTaskItems from 'pages/WorkPlanTaskConfigurations/WorkPlanTaskItems/WorkPlanTaskItems';

import AppealPage from 'pages/AppealPage';
import AppealsPage from 'pages/Appeals/Appeals';
import AppealsSettingsPage from 'pages/Appeals/Settings';
import AppealsAnalyticsPage from 'pages/DashboardPages/AppealsAnalyticsPage/Analytics';
import UnitsAnalyticsPage from 'pages/DashboardPages/UnitsAnalyticsPage/Analytics';
import AutoFailScoresPage from 'pages/DashboardPages/AutofailScoresPage/Analytics';
import UnitsByTypeAnalyticsPage from 'pages/DashboardPages/UnitsByTypeAnalyticsPage/Analytics';

// * app menu
export const ProtectedDashboardPage = requireSignInAndPermission({
  ComponentToRender: DashboardPage,
  accessPermission: PERMISSIONS.CAN_SEE_DASHBOARDS
});

export const ProtectedInteractionsPage = requireSignInAndPermission({
  ComponentToRender: ClientInteractionsPage,
  accessPermission: PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS
});

export const ProtectedReviewDetailsPage = requireSignIn(ReviewPage);
export const ProtectedCrmEntityDetailsPage = requireSignIn(CrmEntityPage);

// ! bitrix pages
export const ProtectedClientInteractionsPage = requireSignInAndPermission({
  ComponentToRender: ClientInteractions,
  accessPermission: PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS
});

export const ProtectedBitrixReviewsPage = requireSignInAndPermission({
  ComponentToRender: Reviews,
  accessPermission: PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS
});
// ! end bitrix pages

export const ProtectedCallDetailsPage = requireSignInAndPermission({
  ComponentToRender: CallPage,
  accessPermission: PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS
});

export const ProtectedTextCommunicationPage = requireSignInAndPermission({
  ComponentToRender: TextCommunicationPage,
  accessPermission: PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS
});

export const ProtectedTextConversationDetailsPage = requireSignInAndPermission({
  ComponentToRender: TextCommunicationPage,
  accessPermission: PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS
});

export const ProtectedLibraryPage = requireSignInAndPermission({
  ComponentToRender: LibraryPage,
  accessPermission: PERMISSIONS.CAN_ACCESS_LIBRARY
});

export const ProtectedCustomCommunicationReviewPage = requireSignInAndPermission({
  ComponentToRender: CustomCommunicationReviewPage,
  accessPermission: PERMISSIONS.CAN_MAKE_REVIEW
});

export const ProtectedTeamPage = requireSignInAndPermission({
  ComponentToRender: TeamPage,
  accessPermission: PERMISSIONS.CAN_ACCESS_TEAM_PAGE
});

export const ProtectedAppealsPage = requireSignInAndPermission({
  ComponentToRender: AppealsPage,
  accessPermission: PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS
});

export const ProtectedAppealsSettingsPage = requireSignInAndPermission({
  ComponentToRender: AppealsSettingsPage,
  accessPermission: PERMISSIONS.CAN_ADMINISTRATE_APPELLATIONS
});

export const ProtectedAppealsAnalyticsPage = requireSignInAndPermission({
  ComponentToRender: AppealsAnalyticsPage,
  accessPermission: PERMISSIONS.CAN_ACCESS_APPELLATIONS_ANALYTICS_PAGE
});

export const ProtectedUnitsAnalyticsPage = requireSignInAndPermission({
  ComponentToRender: UnitsAnalyticsPage,
  accessPermission: PERMISSIONS.CAN_SEE_DASHBOARDS
});

export const ProtectedAutoFailScoresPage = requireSignInAndPermission({
  ComponentToRender: AutoFailScoresPage,
  accessPermission: PERMISSIONS.CAN_SEE_DASHBOARDS
});

export const ProtectedUnitsByTypeAnalyticsPage = requireSignInAndPermission({
  ComponentToRender: requireOrganizationSetting(
    UnitsByTypeAnalyticsPage,
    'attributes.user.organization.settings.analytics.scores-by-units-by-communication-type-enabled'
  ),
  accessPermission: PERMISSIONS.CAN_SEE_DASHBOARDS
});

export const ProtectedAppealPage = requireSignInAndPermission({
  ComponentToRender: AppealPage,
  accessPermissions: [
    PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS,
    PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_OPERATORS_APPELLATIONS_TAB
  ]
});

// * app menu in subMenuItem
export const ProtectedChecklistsAndQuestionsPage = requireSignInAndPermission({
  ComponentToRender: ChecklistsAndQuestionsPage,
  accessPermission: PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS
});

export const ProtectedChecklistPage = requireSignInAndPermission({
  ComponentToRender: ChecklistPage,
  accessPermission: PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS
});

export const ProtectedChecklistSettingsPage = requireSignInAndPermission({
  ComponentToRender: ChecklistSettingsPage,
  accessPermission: PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS
});

export const ProtectedTaskDefinitionsPage = requireSignInAndPermission({
  ComponentToRender: TaskDefinitionsPage,
  accessPermission: PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS
});

export const ProtectedEditTaskDefinitionCardPage = requireSignInAndPermission({
  ComponentToRender: EditTaskDefinitionCardPage,
  accessPermission: PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS
});

export const ProtectedCalibrationsPage = requireSignInAndPermission({
  ComponentToRender: Calibrations,
  accessPermission: PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS
});

export const ProtectedCalibrationSettingsPage = requireSignInAndPermission({
  ComponentToRender: CalibrationDetails,
  accessPermission: PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS
});

export const ProtectedCalibrationResultPage = requireSignInAndPermission({
  ComponentToRender: CalibrationResult,
  accessPermission: PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS
});

export const ProtectedWorkPlanTaskConfigurationsPage = requireSignInAndPermission({
  ComponentToRender: WorkPlanTaskConfigurations,
  accessPermission: PERMISSIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS
});

export const ProtectedWorkPlanTaskConfigurationEditorPage = requireSignInAndPermission({
  ComponentToRender: WorkPlanTaskConfigurationEditor,
  accessPermission: PERMISSIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS
});

export const ProtectedWorkPlanTasksPage = requireSignInAndPermission({
  ComponentToRender: WorkPlanTasks,
  accessPermission: PERMISSIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS
});

export const ProtectedWorkPlanTaskAssignmentsPage = requireSignInAndPermission({
  ComponentToRender: WorkPlanTaskAssignments,
  accessPermission: PERMISSIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS
});

export const ProtectedWorkPlanTaskItemsPage = requireSignInAndPermission({
  ComponentToRender: WorkPlanTaskItems,
  accessPermission: PERMISSIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS
});

export const ProtectedUploadCallsPage = requireSignInAndPermission({
  ComponentToRender: requireOrganizationSetting(
    UploadCallsPage,
    'attributes.user.organization.settings.phone-call-uploads.enable'
  ),
  accessPermission: PERMISSIONS.CAN_MANAGE_INTEGRATIONS
});

// * user menu
export const ProtectedUserAnalyticsPage = requireSignIn(UserAnalyticsPage);

// * other
export const ProtectedRootPage = requireSignIn(RootPage);

export const ProtectedAmoCommunicationPage = requireSignIn(CommunicationPage);

export const ProtectedSetupOrganizationPage = requireSignIn(SetupOrganizationPage);
