import Editor from 'components/Comments/Editor';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SButton from 'components/Standard/SButton';
import { setEditingCommentId } from 'redux/ui/clientInteractionPage/reducer';
import { isEmpty, isEqual } from 'lodash';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { InlineCommentText } from 'components/Comments/InlineCommentText';
import { removeCommentsToSave } from 'redux/ui/checklistEditor/reducer';
import { getTranscriptionWithParts } from 'redux/selectors/phoneCallTranscriptions';
import { setTranscriptionAnchors } from 'core/utils/transcriptionAnchors';

const CommentText = ({
  commentState,
  setCommentState,
  isEditing,
  setEditing,
  onUpdate,
  setInitialCommentState,
  disabled,
  onDeleteComment,
  communication
}) => {
  const mounted = React.useRef(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialCommentState] = useState(commentState);
  const commentText = commentState?.text;

  const transcription = useSelector(
    state => getTranscriptionWithParts(state, communication?.phoneCallTranscriptionId),
    isEqual
  );

  const transcriptionParts = transcription?.parts || [];

  useEffect(() => {
    // Will set it to true on mount ...
    mounted.current = true;
    return () => {
      // ... and to false on unmount
      mounted.current = false;
    };
  }, []);

  const onDeleteFile = async id => {
    await setCommentState({
      ...commentState,
      uploadedFiles: commentState?.uploadedFiles.filter(({ id: fileId }) => fileId !== id)
    });
    await onUpdate({
      id: commentState?.id,
      uploadedFilesIds: commentState?.uploadedFiles
        .filter(({ id: fileId }) => fileId !== id)
        .map(({ id }) => id)
    });
  };

  const confirmUpdate = async id => {
    await onUpdate({
      id,
      text: commentText,
      metadata: {
        ...commentState?.metadata,
        ...(commentState?.metadata?.ratingFlag && {
          ratingFlag: commentState?.metadata?.ratingFlag
        }),
        ...(commentState?.metadata?.questionId && {
          questionId: commentState?.metadata?.questionId
        })
      },
      hidden: commentState?.hidden,
      uploadedFilesIds: commentState?.uploadedFiles?.map(file => file?.id)
    });
    setEditing(!isEditing);
    setCommentState({ ...commentState });
    dispatch(setEditingCommentId(null));
    dispatch(removeCommentsToSave(id || commentState?.id));
    mounted.current && setInitialCommentState(initialCommentState);
  };

  const handleCancelClick = id => {
    dispatch(setEditingCommentId(id));
    dispatch(removeCommentsToSave(id || commentState?.id));
    setEditing(!isEditing);
    setCommentState(initialCommentState);
  };

  const handleAnchorClick = event => {
    if (event.target.tagName === 'A' && event.target.href.includes('#')) {
      event.preventDefault(); // предотвращаем стандартное поведение
      const targetId = event.target.getAttribute('href').substring(1);
      const element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
      }
    }
  };

  return (
    <StyledRow>
      {!isEditing && (
        <SRow gutter={[0, 8]} style={{ margin: '-4px' }}>
          <SCol span={24} padding="4px">
            <InlineCommentText
              onClick={handleAnchorClick}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  setTranscriptionAnchors(commentText, transcriptionParts),
                  {
                    ALLOWED_ATTR: ['target', 'href']
                  }
                )
              }}
            />
          </SCol>
          <SCol span={24}>
            {commentState?.uploadedFiles?.map(file => (
              <UploadedFile
                disabled={disabled}
                key={file?.id}
                uploadedFile={file}
                allFiles={commentState?.uploadedFiles}
                onDelete={onDeleteFile}
                onDeleteComment={onDeleteComment}
                commentState={commentState}
              />
            ))}
          </SCol>
        </SRow>
      )}
      {isEditing && (
        <SRow>
          <SCol width="100%">
            <Editor
              commentState={commentState}
              setCommentState={setCommentState}
              showTemplates
              showFlags
              showAttachments
              allowHidden
              actionsComponent={[
                <SButton key="cancel" onClick={() => handleCancelClick(null)} marginRight="6px">
                  {t('components.commentList.comment.commentEditButton.buttons.cancel')}
                </SButton>,
                <SButton
                  key="save"
                  type="primary"
                  onClick={() => confirmUpdate(commentState?.id)}
                  disabled={isEmpty(commentText)}
                  className="BraftEditor-actions"
                >
                  {t('components.commentsPanel.buttons.send')}
                </SButton>
              ]}
            />
          </SCol>
        </SRow>
      )}
    </StyledRow>
  );
};

const StyledRow = styled(SRow)`
  &.ant-row {
    display: block;
  }
  &.ant-row > span > p {
    margin: 0px;
  }
`;

export default CommentText;
