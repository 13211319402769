import { LoadingOutlined } from '@ant-design/icons';
import { Plus } from 'react-feather';
import { Divider, Input, message, Select } from 'antd';
import { isEmpty, some } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { foldersResource } from 'redux/resources/folders';
import { folderItemsResource } from 'redux/resources/items';
import * as actions from 'redux/ui/addToCalibrationModal/reducer';
import styled from 'styled-components';
import Icon from './Icon';
import SModal from './Standard/SModal';
import SButton from './Standard/SButton';

const { Option } = Select;

const AddToCalibrationModal = ({
  closeModal,
  visible,
  loading,
  clientInteraction,
  folders = [],
  setFolderId,
  currentFolderId,
  setNewItemName,
  newItemName,
  newFolderName,
  setNewFolderName,
  createFolder,
  createItem,
  loadFolders,
  foldersLoading,
  createStarted
}) => {
  const selectRef = useRef(null);
  const [folderDropdownOpen, setFolderDropdownOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) loadFolders();

    if (!visible) {
      setFolderDropdownOpen(false);
    }
  }, [visible]);

  const selectWrapperClickHandler = e => {
    e.stopPropagation();
    const path = e.nativeEvent.path || e.nativeEvent.composedPath();

    // открываем на все нажатия кроме элемента
    const shouldСlose = some(
      path,
      ({ className }) =>
        typeof className === 'string' && className.includes('ant-select-dropdown-menu-item')
    );

    setFolderDropdownOpen(!shouldСlose);
  };

  const onSubmit = async () => {
    const item = await createItem({
      name: newItemName,
      folderId: currentFolderId,
      clientInteractionId: clientInteraction.id
    });

    if (item) {
      message.success(t('components.addToCalibrationModal.messages.fileSuccessfullyAddedToGroup'));
      closeModal();
    } else {
      message.error(t('components.addToCalibrationModal.messages.addFileToGroupFailed'));
    }
  };

  const onCreateFolder = async () => {
    const folder = await createFolder({ name: newFolderName });

    if (folder) {
      message.success(
        t('components.addToCalibrationModal.messages.communicationGroupSuccessfullyCreated')
      );
      setNewFolderName('');
      const list = document.querySelector('.folders-dropdown .rc-virtual-list-holder');
      list.scrollTop = list.scrollHeight;
    } else {
      message.error(t('components.addToCalibrationModal.messages.communicationGroupCreateFailed'));
    }
  };

  const onChangeFolder = id => {
    setFolderId(id);
    setFolderDropdownOpen(false);
  };

  return (
    <SModal
      visible={visible}
      title={t('components.addToCalibrationModal.title')}
      destroyOnClose
      onCancel={closeModal}
      onOk={onSubmit}
      okText={t('general.continue')}
      cancelText={t('general.cancel')}
      confirmLoading={createStarted}
      size="small"
      bodyStyle={{ padding: '24px' }}
      okButtonProps={{ disabled: isEmpty(currentFolderId) || !newItemName, loading: createStarted }}
    >
      <div onClick={() => setFolderDropdownOpen(false)}>
        <StyledSpan margin="0 0 8px 0">
          {t('components.addToCalibrationModal.form.fileName')}
        </StyledSpan>
        <Input
          placeholder={t('components.addToCalibrationModal.form.callName')}
          value={newItemName || undefined}
          onChange={e => setNewItemName(e.target.value)}
        />
        <StyledSpan margin="16px 0 8px 0">
          {t('components.addToCalibrationModal.form.folderName')}
        </StyledSpan>
        <div style={{ display: 'flex' }} onClick={selectWrapperClickHandler}>
          <Select
            loading={loading}
            style={{ width: '100%' }}
            placeholder={t('components.addToCalibrationModal.form.folderNamePlaceholder')}
            onChange={onChangeFolder}
            value={currentFolderId || undefined}
            ref={selectRef}
            open={folderDropdownOpen}
            dropdownClassName="folders-dropdown"
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />

                <div
                  style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}
                  className="folder-input"
                >
                  <Input
                    placeholder={t('components.addToCalibrationModal.form.folderNamePlaceholder')}
                    value={newFolderName}
                    onChange={e => setNewFolderName(e.target.value)}
                  />

                  <SButton
                    onClick={onCreateFolder}
                    type="link"
                    icon={foldersLoading ? <LoadingOutlined /> : <Icon icon={Plus} />}
                    disabled={foldersLoading || isEmpty(newFolderName)}
                  >
                    {t('components.addToCalibrationModal.form.buttons.addGroup')}
                  </SButton>
                </div>
              </div>
            )}
          >
            {folders.map(({ id, name }) => (
              <Option key={id} value={id} label={name}>
                {name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </SModal>
  );
};

const StyledSpan = styled.span`
  display: block;
  margin: ${props => props.margin};
  font-weight: 500;
`;

const mapStateToProps = state => {
  const {
    newItemName,
    newFolderName,
    currentFolderId,
    showModal: visible
  } = state.uiAddToCalibrationModal;

  const { byIds, createStarted: foldersLoading } = state.foldersResource;
  const { createStarted } = state.folderItemsResource;

  const folders = Object.values(byIds);

  return {
    newItemName,
    newFolderName,
    currentFolderId,
    visible,
    folders,
    foldersLoading,
    createStarted
  };
};

const mapDispatchToProps = {
  closeModal: actions.closeAddToCalibrationModal,
  setNewItemName: actions.setNewItemName,
  setNewFolderName: actions.setNewFolderName,
  setFolderId: actions.setFolderId,
  createFolder: foldersResource.operations.create,
  createItem: folderItemsResource.operations.create,
  loadFolders: foldersResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCalibrationModal);
