import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadSessionsData } from 'redux/ui/calibrationSessionSettingsModal/operations';
import SessionSettingsModal from './CalibrationSessionSettingsModal';
import CalibrationsFilters from './CalibrationsFilters';
import CalibrationsList from './tabs/CalibrationsList';
import Folders from './tabs/Folders';

const tabToComponent = {
  folders: Folders
};

const Calibrations = ({ tab, loadSessionsData }) => {
  useEffect(() => {
    loadSessionsData();
  }, []);
  const { t } = useTranslation();

  const Tab = tabToComponent[tab] || CalibrationsList;

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.calibrationsSessionsPages.calibrationsSessions.title')}</title>
      </Helmet>
      <SCol span={24}>
        <CalibrationsFilters tab={tab} />
      </SCol>
      <SCol span={24} padding="16px">
        <Tab tab={tab} />
      </SCol>
      <SessionSettingsModal />
    </SRow>
  );
};

const mapDispatchToProps = {
  loadSessionsData
};

export default withRouter(connect(null, mapDispatchToProps)(Calibrations));
