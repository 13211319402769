import loadIncludedObjectsToRedux from 'core/redux/resources/loadIncludedObjects';
import { dashboardWidgetsResource } from 'redux/resources/dashboardWidgets';
import { setLoadingWidgetId } from 'redux/entities/dashboardPages/reducer';
import * as actions from './reducer';
import { getDashboardWidgetAnalytics } from '../analytics/dashboard/operations';

export const updateDashboardPageWidget = ({ ...props }) => async dispatch => {
  dispatch(actions.setLoadingWidgetId('filters'));
  try {
    dispatch(setLoadingWidgetId(props?.id));
    await dispatch(
      dashboardWidgetsResource.operations.updateById({
        ...props,
        widgetNames: []
      })
    );
    const updatedAnalytics = await dispatch(getDashboardWidgetAnalytics({ ...props }));
    loadIncludedObjectsToRedux({
      objects: updatedAnalytics[props?.id]?.users,
      dispatch
    });

    dispatch(setLoadingWidgetId());
    return updatedAnalytics;
  } catch (error) {
    console.log(error);
  }
};
