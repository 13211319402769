import { get, keyBy } from 'lodash';

export const prepareTableRows = ({
  members,
  checklistItems,
  totalDifference,
  questionsDifferenceByIds
}) => {
  const checklistItemsByIds = keyBy(checklistItems, 'id');

  // ! объеденить с reduce в CalibrationResultTable
  const mappedMembersByAnswers = questionId =>
    members.reduce(
      (acc, item) => {
        const data = {
          answers: {
            ...acc.answers,
            [item.id]: {
              value: get(item.checklist, ['questionsWithAnswersByIds', questionId, 'value'], null),
              question: get(checklistItemsByIds, questionId, {}),
              comments: item.comments.filter(
                comment => comment.metadata && comment.metadata.questionId === questionId
              )
            }
          },
          comments: {
            ...acc.comments,
            [item.id]: item.comments
          },
          result: {
            ...acc.result,
            [item.id]: item.checklist.score
          }
        };

        return data;
      },
      { answers: {}, comment: {}, result: {} }
    );

  // ! подумать над тем как объеденить это с mappedMembersByAnswers
  const mappedChecklistItems = checklistItems.map(item => ({
    key: item.id,
    name: item.name,
    type: item.type,
    coincidence: questionsDifferenceByIds[item.id],
    ...mappedMembersByAnswers(item.id).answers
  }));

  return [
    {
      key: 'header',
      ...mappedMembersByAnswers().comments
    },

    ...mappedChecklistItems,

    {
      key: 'footer',
      name: 'calibrationResultPage.table.columns.result',
      coincidence: totalDifference,
      ...mappedMembersByAnswers().result
    }
  ];
};
