import React from 'react';

const Standard = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.6587 4.82871L8.33337 0.475675C8.14603 0.36374 7.8547 0.36374 7.6667 0.475675L0.341368 4.82871C0.242702 4.88721 0.186035 4.96967 0.186035 5.05581V7.12868C0.186035 7.25213 0.301368 7.36453 0.479368 7.41382C0.658035 7.4631 0.864702 7.44099 1.0067 7.3567L8.00003 2.96082L14.9934 7.3567C15.0854 7.41105 15.2047 7.43961 15.3254 7.43961C15.3914 7.43961 15.458 7.43132 15.5207 7.41382C15.6987 7.36453 15.814 7.25213 15.814 7.12868V5.05581C15.814 4.96967 15.7574 4.88721 15.6587 4.82871V4.82871Z"
          fill="#597EF7"
        />
        <path
          d="M15.5 7.83109C15.376 7.83109 15.254 7.79931 15.16 7.73896L8 3.14547L0.84 7.73896C0.694 7.83247 0.482667 7.85735 0.3 7.80253C0.118 7.74725 0 7.62288 0 7.48561V5.18242C0 5.0866 0.0573333 4.9954 0.158667 4.92999L7.65867 0.0932794C7.85067 -0.0310931 8.14867 -0.0310931 8.34067 0.0932794L15.8407 4.92999C15.9427 4.9954 16 5.0866 16 5.18242V7.48561C16 7.62288 15.882 7.74725 15.7 7.80253C15.636 7.82142 15.568 7.83109 15.5 7.83109V7.83109ZM8 2.32876C8.122 2.32876 8.244 2.35916 8.34 2.42089L15 6.69331V5.33258L8 0.818325L1 5.33258V6.69331L7.66 2.42089C7.756 2.35916 7.878 2.32876 8 2.32876V2.32876Z"
          fill="white"
        />
        <path
          d="M15.6587 8.75877L8.33337 4.40573C8.14603 4.29379 7.8547 4.29379 7.6667 4.40573L0.341368 8.75877C0.242702 8.81727 0.186035 8.89972 0.186035 8.98586V11.0587C0.186035 11.1822 0.301368 11.2946 0.479368 11.3439C0.658035 11.3932 0.864702 11.371 1.0067 11.2868L8.00003 6.89088L14.9934 11.2868C15.0854 11.3411 15.2047 11.3697 15.3254 11.3697C15.3914 11.3697 15.458 11.3614 15.5207 11.3439C15.6987 11.2946 15.814 11.1822 15.814 11.0587V8.98586C15.814 8.89972 15.7574 8.81727 15.6587 8.75877V8.75877Z"
          fill="#597EF7"
        />
        <path
          d="M15.5 11.7611C15.376 11.7611 15.254 11.7294 15.16 11.669L8 7.07553L0.84 11.669C0.694 11.7625 0.482667 11.7874 0.3 11.7326C0.118 11.6773 0 11.5529 0 11.4157V9.11247C0 9.01666 0.0573333 8.92545 0.158667 8.86004L7.65867 4.02333C7.85067 3.89896 8.14867 3.89896 8.34067 4.02333L15.8407 8.86004C15.9427 8.92545 16 9.01666 16 9.11247V11.4157C16 11.5529 15.882 11.6773 15.7 11.7326C15.636 11.7515 15.568 11.7611 15.5 11.7611V11.7611ZM8 6.25881C8.122 6.25881 8.244 6.28922 8.34 6.35094L15 10.6234V9.26264L8 4.74838L1 9.26264V10.6234L7.66 6.35094C7.756 6.28922 7.878 6.25881 8 6.25881V6.25881Z"
          fill="white"
        />
        <path
          d="M15.6587 12.667L8.33337 8.31393C8.14603 8.202 7.8547 8.202 7.6667 8.31393L0.341368 12.667C0.242702 12.7255 0.186035 12.8079 0.186035 12.8941V14.9669C0.186035 15.0904 0.301368 15.2028 0.479368 15.2521C0.658035 15.3014 0.864702 15.2793 1.0067 15.195L8.00003 10.7991L14.9934 15.195C15.0854 15.2493 15.2047 15.2779 15.3254 15.2779C15.3914 15.2779 15.458 15.2696 15.5207 15.2521C15.6987 15.2028 15.814 15.0904 15.814 14.9669V12.8941C15.814 12.8079 15.7574 12.7255 15.6587 12.667V12.667Z"
          fill="#597EF7"
        />
        <path
          d="M15.5 15.6695C15.376 15.6695 15.254 15.6377 15.16 15.5773L8 10.9839L0.84 15.5773C0.694 15.6709 0.482667 15.6957 0.3 15.6409C0.118 15.5856 0 15.4613 0 15.324V13.0208C0 12.925 0.0573333 12.8338 0.158667 12.7684L7.65867 7.93166C7.85067 7.80729 8.14867 7.80729 8.34067 7.93166L15.8407 12.7684C15.9427 12.8338 16 12.925 16 13.0208V15.324C16 15.4613 15.882 15.5856 15.7 15.6409C15.636 15.6598 15.568 15.6695 15.5 15.6695V15.6695ZM8 10.1671C8.122 10.1671 8.244 10.1975 8.34 10.2593L15 14.5317V13.171L8 8.6567L1 13.171V14.5317L7.66 10.2593C7.756 10.1975 7.878 10.1671 8 10.1671V10.1671Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Standard;
