import ClientInteractionLink from 'components/ClientInteractionLink';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import UserPreview from 'components/UserPreview';
import { isEqual } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getClientInteractionsByIds } from 'redux/selectors/clientInteractions';
import moment from 'moment';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from 'redux/selectors/users';

const AppealInfo = ({ appeal, loading }) => {
  const { t } = useTranslation();
  const review = useSelector(state => state.reviewsResource.byIds[appeal?.reviewId], isEqual);
  const communication = useSelector(
    state => getClientInteractionsByIds(state)[review?.clientInteractionId],
    isEqual
  );
  const currentUser = useSelector(state => getCurrentUser(state));

  const operatorId = useSelector(
    state => state.clientInteractionsResource.byIds[review?.clientInteractionId]?.operatorId
  );

  return (
    <SCard shadowed bordered loading={!appeal || !communication || loading}>
      <SRow gutter={[0, 16]} style={{ marginBottom: '-8px' }}>
        <SCol span={24}>
          <SRow gutter={[0, 16]} style={{ marginBottom: '-8px' }}>
            <SCol span={1}>
              <SRow height="100%" align="middle">
                <SCol span={24}>
                  <ClientInteractionLink
                    isReview
                    useDrawer
                    id={appeal?.reviewId}
                    communication={communication}
                    style={{ width: '20px', height: '20px' }}
                  />
                </SCol>
                <SCol span={24} height="30px" />
              </SRow>
            </SCol>
            <SCol span={22}>
              <SRow gutter={[0, 16]} style={{ marginBottom: '-8px' }}>
                <SCol span={24}>
                  <SRow gutter={[20, 0]} align="middle">
                    <SCol display="flex" alignItems="center">
                      <SText fontWeight="600" paddingRight="4px">
                        {`${t('appealsPage.appealInfo.operator')}:`}
                      </SText>
                      <UserPreview userId={operatorId} showAvatar disabled size="extraSmall" />
                    </SCol>

                    <SCol display="flex" alignItems="center">
                      <SText fontWeight="600" paddingRight="4px">
                        {`${t('general.date')}:`}
                      </SText>
                      <SText>{moment(communication?.createdAt).format('DD/MM/YYYY')}</SText>
                    </SCol>

                    <SCol display="flex" alignItems="center">
                      <SText fontWeight="600" paddingRight="4px">
                        {`${t('general.direction')}:`}
                      </SText>
                      <SText>{t(CALL_DIRECTION_LITERALS[communication?.direction])}</SText>
                    </SCol>

                    <SCol display="flex" alignItems="center">
                      <SText fontWeight="600" paddingRight="4px">
                        {`${t('general.number')}:`}
                      </SText>
                      <SText>{communication?.clientPhoneNumber || ''}</SText>
                    </SCol>

                    <SCol display="flex" alignItems="center">
                      <SText fontWeight="600" paddingRight="4px">
                        {`${t('general.time')}:`}
                      </SText>
                      <SText>{moment(communication?.createdAt).format('HH:mm')}</SText>
                    </SCol>
                  </SRow>
                </SCol>

                <SCol span={24}>
                  <SRow gutter={[20, 0]} align="middle">
                    <SCol display="flex" alignItems="center">
                      <SText fontWeight="600" paddingRight="4px">
                        {`${t('appealsPage.appealInfo.reviewCreate')}:`}
                      </SText>
                      <UserPreview
                        userId={appeal?.reviewerId}
                        showAvatar
                        disabled
                        size="extraSmall"
                        hidden={appeal?.reviewerId !== currentUser?.id}
                      />
                    </SCol>

                    <SCol display="flex" alignItems="center">
                      <SText fontWeight="600" paddingRight="4px">
                        {`${t('general.reviewDate')}:`}
                      </SText>
                      <SText>{moment(review?.createdAt).format('DD/MM/YYYY')}</SText>
                    </SCol>

                    <SCol display="flex" alignItems="center">
                      <SText fontWeight="600" paddingRight="4px">
                        {`${t('general.time')}:`}
                      </SText>
                      <SText>{moment(review?.createdAt).format('HH:mm')}</SText>
                    </SCol>
                  </SRow>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default AppealInfo;
