import { Col } from 'antd';
import SCard from 'components/Standard/SCard';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { getChecklistDefinitionQuestionGroups } from 'redux/selectors/checklistItems/checklistItems';
import { setAnswersHistoryQuestionId } from 'redux/ui/userAnalyticsPage/reducer';
import SCol from 'components/Standard/SCol';
import SRow from '../../components/Standard/SRow';
import QuestionsAnalytics from './QuestionsAnalytics';

const AllQuestionGroups = ({ widgets, loadingWidgets, checklistDefinition, userId, state }) => {
  if (loadingWidgets) {
    return <SCard loading={loadingWidgets} />;
  }

  const { isGroupable } = checklistDefinition;
  const questionGroups = getChecklistDefinitionQuestionGroups(state, checklistDefinition);

  return (
    <SCol span={24} marginTop="-16px">
      {Object.values(questionGroups).map(questionGroup => (
        <SRow key={questionGroup.id} gutter={[16, 16]}>
          <Col span={24}>
            <QuestionsAnalytics
              widgets={widgets}
              key={questionGroup.id}
              loading={loadingWidgets}
              questionGroup={questionGroup}
              isGroupable={isGroupable}
              reviewsCount={get(widgets, [
                ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
                userId,
                'value'
              ])}
              allQuestionsScores
            />
          </Col>
        </SRow>
      ))}
    </SCol>
  );
};

const mapStateToProps = state => {
  return {
    state
  };
};

const mapDispatchToProps = {
  setAnswersHistoryQuestionId
};

export default connect(mapStateToProps, mapDispatchToProps)(AllQuestionGroups);
