import createListReducer from 'core/redux/lists/listReducer';
import { reviewsResource } from 'redux/resources/reviews';

export const reviewsListParams = {
  name: 'bitrixReviews',
  rebuildQuery: true,
  loader: reviewsResource.operations.load,
  keepResults: true
};

const { actions, reducer, operations } = createListReducer(reviewsListParams);

const bitrixReviewsListReducer = reducer;

export { actions, bitrixReviewsListReducer, operations };
