export default {
  registerPage: {
    form: {
      title: 'Sign up',
      description: 'You can start using qolio through integration with your CRM',
      or: 'or',
      buttons: {
        login: 'Login',
        bitrix: 'Continue with Bitrix24',
        amo: 'Continue with AmoCRM',
        email: 'Continue with email',
        bitrixPopover: {
          title: 'Enter your Bitrix24 app URL',
          wrongUrl: 'Wrong Bitrix24 URL',
          continue: 'Continue'
        }
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Efficiency',
          description:
            'All tools for quality control and evaluation of communications on one platform'
        },
        motivation: {
          title: 'Motivation',
          description: 'Involve, develop and motivate your team'
        },
        simplicity: {
          title: 'Simplicity',
          description:
            'Get rid of spreadsheets and outdated tools for evaluation and quality control'
        },
        analytics: {
          title: 'Analytics',
          description:
            'Analyze quality metrics and find new opportunities for the growth of your team'
        }
      }
    }
  }
};
