// collection, member

// export const createEntityReducer = ({ name, api }) => {};
// dependency tree, since we need initialized actions to populate dependencies
import { createResourceOperations } from './createResourceOperations';
import { createResourceReducer } from './createResourceReducer';

export const createReduxResource = ({
  name,
  additionalReducer = {},
  reactors = {},
  customOperations = {}
}) => {
  const slice = createResourceReducer({ name, additionalReducer });

  const operations = createResourceOperations({
    name,
    actions: slice.actions,
    reactors,
    customOperations
  });

  return {
    ...slice,
    operations
  };
};
