import { Trash2, MoreVertical, PlayCircle, Edit } from 'react-feather'; //
import { Dropdown, Menu, Modal, Tooltip, Typography } from 'antd';
import EditableLabel from 'components/Inputs/EditableLabel';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS } from 'core/utils/constants';
import React, { useEffect } from 'react';
import { reviewLibrariesResource } from 'redux/resources/reviewLibraries';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { reviewLibraryItemsResource } from 'redux/resources/reviewLibraryItems';
import { setEditingItem, editingItemSaved } from 'redux/ui/libraryPage/operations';
import { setCurrentInteractionId, updateEditingItem } from 'redux/ui/libraryPage/reducer';
import Icon from 'components/Icon';
import {
  ClientInteractionContainer,
  ClientInteractionContent,
  SettingButton,
  SettingContainer
} from './styled';

const { confirm } = Modal;

const ClientInteractionPreview = ({
  id,
  selectInteraction,
  isSelected,
  name,
  onSave,
  onEdit,
  onDelete,
  isEditing,
  updateEditingItem,
  updateItem
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      selectInteraction(null);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const handleClick = ({ key }) => {
    switch (key) {
      case 'edit':
        onEdit(id);
        break;
      case 'delete':
        confirm({
          title: t('libraryPage.libraryItem.deleteItemModal.title'),
          okText: t('libraryPage.libraryItem.deleteItemModal.buttons.delete'),
          cancelText: t('libraryPage.libraryItem.deleteItemModal.buttons.cancel'),
          okType: 'danger',
          onOk: async () => {
            if (isSelected) {
              selectInteraction(null);
            }

            return onDelete({ id });
          }
        });
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="edit">
        <Icon icon={Edit} />
        <span>{t('libraryPage.libraryItem.dropdown.edit')}</span>
      </Menu.Item>
      <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
        <Icon icon={Trash2} />
        <span>{t('libraryPage.libraryItem.dropdown.delete')}</span>
      </Menu.Item>
    </Menu>
  );

  const ConditionalSettingContainer = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MANAGE_LIBRARY] },
    SettingContainer
  );

  return (
    <ClientInteractionContainer onClick={() => selectInteraction(id)} isSelected={isSelected}>
      <Icon icon={PlayCircle} size="20px" />
      <ClientInteractionContent>
        {isEditing ? (
          <EditableLabel
            initialState="edit"
            value={name}
            onSave={(name, { final }) => {
              if (name.length > 45) {
                return;
              }
              updateEditingItem({ name });
              if (final) {
                updateItem({ id, name });
                onSave();
              }
            }}
          />
        ) : (
          <Tooltip placement="topLeft" title={name}>
              <Typography.Text
                style={{
                  color: 'black',
                  maxWidth: '20vw',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'}}
              >
                {name}
              </Typography.Text>
            </Tooltip> || t('libraryPage.libraryItem.emptyName')
        )}
      </ClientInteractionContent>
      <ConditionalSettingContainer onClick={e => e.stopPropagation()}>
        <Dropdown overlay={menu} trigger={['click']}>
          <SettingButton
            icon={<Icon icon={MoreVertical} />}
            background={isSelected && '#EAEAEA '}
          />
        </Dropdown>
      </ConditionalSettingContainer>
    </ClientInteractionContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { editingItem, editingItemId } = state.uiLibraryPage;
  const isEditing = editingItemId === ownProps.id;
  const { name, createdAt, reviewId } = isEditing
    ? editingItem
    : state.reviewLibraryItemsResource.byIds[ownProps.id];

  return {
    clientInteraction: state.reviewsResource.byIds[reviewId],
    name,
    isSelected: ownProps.id === state.uiLibraryPage.currentClientInteractionId,
    isEditing,
    createdAt
  };
};

const mapDispatchToProps = {
  selectInteraction: setCurrentInteractionId,
  onDelete: reviewLibraryItemsResource.operations.deleteById,
  loadLibraries: reviewLibrariesResource.operations.load,
  updateItem: reviewLibraryItemsResource.operations.updateById,
  onEdit: setEditingItem,
  onSave: editingItemSaved,
  updateEditingItem
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInteractionPreview);
