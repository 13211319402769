import createListReducer from 'core/redux/lists/listReducer';
import { commentTemplatesResource } from 'redux/resources/commentTemplates';

const defaultState = {
  page: {
    size: '10',
    number: '1'
  }
};

const additionalReducer = {
  onCommentTemplateCreate: (state, { payload }) => {
    const { id } = payload;
    return state.ids.includes(id) ? state : { ...state, ids: [id, ...state.ids] };
  },
  onCommentTemplateDelete: (state, { payload }) => {
    const { id } = payload;
    return state.ids.includes(id)
      ? { ...state, ids: [...state.ids.filter(templateId => templateId !== id)] }
      : state;
  }
};

export const listParams = {
  name: 'commentTemplatesList',
  loader: commentTemplatesResource.operations.load,
  keepResults: true
};

const { actions, reducer, operations } = createListReducer({
  ...listParams,
  defaultState,
  additionalReducer
});

const commentTemplatesListReducer = reducer;

export { actions, commentTemplatesListReducer, operations };
