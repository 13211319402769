import styled from 'styled-components';
import { Typography } from 'antd';
import {
  color,
  space,
  layout,
  flexbox,
  background,
  shadow,
  position,
  typography
} from 'styled-system';

const { Text, Title, Paragraph } = Typography;

export default styled(Text)`
&.ant-typography {
    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
    ${typography}
    ${color}
  }
`;

export const STitle = styled(Title)`
&.ant-typography {
    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
    ${typography}   
    ${color}
  }
`;

export const SParagraph = styled(Paragraph)`
&.ant-typography {
    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
    ${typography}
    ${color}
  }
`;
