import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle, isEmpty, isEqual, uniq } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Skeleton, Empty, Row, Col } from 'antd';
import { loadInteractions } from 'redux/ui/bitrixIntegration/operations';
import { actions } from 'redux/lists/bitrixClientInteractionsList';
import { useTranslation } from 'react-i18next';
import EmptyDescriptionWithLink from 'components/EmptyDescriptionWithLink';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import ClientInteractionPreview from './component/ClientInteractionPreview';
import { ColumnName } from '../styled';

const ClientInteractions = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.bitrixClientInteractionsList.loading);
  const hasNextPage = useSelector(state => {
    const { page, totalPages } = state.bitrixClientInteractionsList;
    return totalPages > page.number;
  });
  const interactions = useSelector(state => {
    const ids = uniq(state.bitrixClientInteractionsList.ids);
    return ids.map(id => state.clientInteractionsResource.byIds[id]);
  }, isEqual);

  useEffect(() => {
    dispatch(actions.clearList());
    dispatch(loadInteractions());

    return () => {
      dispatch(actions.clearList());
    };
  }, []);
  const { t } = useTranslation();

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      dispatch(loadInteractions());
    }
  }, 250);

  if (loading) {
    return (
      <SCard>
        <Skeleton active paragraph={false} />
      </SCard>
    );
  }

  if (isEmpty(interactions) && !loading) {
    return (
      <Empty
        style={{ margin: '12px' }}
        description={
          <EmptyDescriptionWithLink
            linkText={t('bitrixIntegrationPage.clientInteractions.empty.linkText')}
            link="https://intercom.help/qolio/ru/articles/5422028-проверка-коммуникаций"
            description={t('bitrixIntegrationPage.clientInteractions.empty.description')}
          />
        }
      />
    );
  }

  return (
    <>
      <Row type="flex" align="middle" justify="space-between" gutter={[16, 16]}>
        <Col span={1} />
        <Col span={5}>
          <ColumnName>{t('bitrixIntegrationPage.clientInteractions.columns.operator')}</ColumnName>
        </Col>
        <Col span={3}>
          <ColumnName>{t('bitrixIntegrationPage.clientInteractions.columns.direction')}</ColumnName>
        </Col>
        <SCol display="flex" justifyContent="center" span={5}>
          <ColumnName>{t('bitrixIntegrationPage.clientInteractions.columns.date')}</ColumnName>
        </SCol>
        <SCol display="flex" justifyContent="center" span={3}>
          <ColumnName>{t('bitrixIntegrationPage.clientInteractions.columns.duration')}</ColumnName>
        </SCol>
        <SCol display="flex" justifyContent="center" span={3}>
          <ColumnName>{t('bitrixIntegrationPage.clientInteractions.columns.status')}</ColumnName>
        </SCol>
        <Col span={1} onClick={e => e.stopPropagation()} />
      </Row>
      <PerfectScrollbar
        style={{ maxHeight: 'calc(100vh - 56px)' }}
        options={{ suppressScrollX: true }}
        onScrollY={onScrollY}
      >
        {interactions.map(interaction => (
          <ClientInteractionPreview key={interaction.id} interaction={interaction} />
        ))}
      </PerfectScrollbar>
    </>
  );
};

export default ClientInteractions;
