import { createSelector } from '@reduxjs/toolkit';
import { getCurrentUser } from './users';

export const getWorkPlanTasksByIds = state => state.workPlanTasksResource.byIds;
export const getWorkPlanTaskConfigurationsByIds = state =>
  state.workPlanTaskConfigurationsResource.byIds;
export const getWorkPlanTaskAssignmentsByIds = state => state.workPlanTaskAssignmentsResource.byIds;

export const getCurrentUserAssignments = createSelector(
  [
    getCurrentUser,
    getWorkPlanTasksByIds,
    getWorkPlanTaskConfigurationsByIds,
    getWorkPlanTaskAssignmentsByIds
  ],
  (currentUser, tasksByIds, taskConfigurationsByIds, assignmentsByIds) =>
    Object.values(assignmentsByIds).reduce((acc, item) => {
      if (item.reviewerId !== currentUser.id) return acc;
      const taskItem = tasksByIds[item.taskId];
      const taskConfiguration = taskConfigurationsByIds[taskItem?.taskConfigurationId];
      if (!taskConfiguration?.name) return acc;

      const data = {
        ...item,
        configurationName: taskConfiguration?.name
      };
      return [...acc, data];
    }, [])
);

export const getCurrentUserAssignmentsByTaskStatus = createSelector(
  getCurrentUserAssignments,
  getWorkPlanTasksByIds,
  (_, { status }) => status,
  (assignments, tasksByIds, status) => {
    return assignments.reduce((acc, assignment) => {
      const task = tasksByIds[assignment?.taskId];
      if (task?.status !== status) return acc;
      return [...acc, { ...assignment, taskTimeFrom: task?.timeFrom }];
    }, []);
  }
);
