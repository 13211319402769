export default {
  loginPage: {
    form: {
      title: 'Вход',
      unRegistered: 'Не зарегистрированы?',
      fields: {
        emailPlaceholder: 'Email',
        phonePlaceholder: 'Телефон',
        passwordPlaceholder: 'Пароль',
        enterConfimationPasswordPlaceholder: 'Повторите пароль',
        firstNamePlaceholder: 'Имя',
        lastNamePlaceholder: 'Фамилия',
        orgNamePlaceholder: 'Название организации',
        WebsitePlaceholder: 'Адрес сайта',
        demoPlaceholder: 'Создать с демо данными'
      },
      messages: {
        agreeWith: 'Я согласен(на) с ',
        and: ' и ',
        offer: 'договором публичной оферты',
        privacy: 'политикой конфиденциальности',
        errorUrl: 'Некорректный адрес',
        errorPhone: 'Некорректный номер телефона',
        errorReg: 'Ошибка при регистрации',
        successReg: 'Вы успешно зарегистрировались!',
        emailAlreadyRegistered: 'Пользователь с таким email уже зарегистрирован',
        enterEmail: 'Введите email',
        enterPhone: 'Введите номер телефона',
        enterFirstName: 'Введите имя',
        enterLastName: 'Введите фамилию',
        shouldAccept: 'Я согласен(на) с договором публичной оферты и политикой конфиденциальности',
        enterPassword: 'Введите пароль',
        confirmPassword: 'Подтвердите пароль',
        enterOrgName: 'Введите название организации',
        enterWebsite: 'Введите адрес сайта',
        wrongEmail: 'Некорректный email',
        minLengthPassword: 'Минимум 6 символов'
      },
      buttons: {
        login: 'Войти',
        register: 'Зарегистрировать кабинет',
        forgotPassword: 'Забыли пароль?',
        registration: 'Регистрация',
        goToSite: 'Перейти на сайт',
        registrationlink: 'Пройти регистрацию'
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Эффективность',
          description:
            'Все инструменты для контроля качества и оценки коммуникаций собраны на одной платформе'
        },
        motivation: {
          title: 'Мотивация',
          description: 'Вовлекайте, развивайте и мотивируйте сотрудников'
        },
        simplicity: {
          title: 'Простота',
          description:
            'Откажитесь от электронных таблиц и устаревших инструментов для оценки и контроля качества'
        },
        analytics: {
          title: 'Аналитика',
          description: 'Анализируйте показатели качества и находите новые возможности для роста'
        }
      }
    }
  }
};
