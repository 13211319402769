export default {
  registerPage: {
    form: {
      title: 'Registro',
      description:
        'Usted puede comenzar a utilizar Qolio con la ayuida de la integración con su CRM',
      or: 'o',
      buttons: {
        login: 'Entrar',
        bitrix: 'Continuar a través de Britix24',
        amo: 'Continuar a través de AmoCRM',
        email: 'Recibir acceso a traves del correo electrónico',
        bitrixPopover: {
          title: 'Señale la dirección de su portal',
          wrongUrl: 'La dirección del portal es incorrecta',
          continue: 'Continuar'
        }
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Eficiencia',
          description:
            'Todos los instrumentos para el control de calidad y la evaluación de la comunicación reunidos en una misma plataforma'
        },
        motivation: {
          title: 'Motivación',
          description: 'Involucre, desarrolle y motive a sus empleados'
        },
        simplicity: {
          title: 'Sencillez',
          description:
            'Elimine las hojas de cálculo electrónicas y los instrumentos absoletos para la evaluación y el control de la calidad'
        },
        analytics: {
          title: 'Analítica',
          description:
            'Analice las métricas de calidad y encuentre nuevas posibilidades de crecimiento'
        }
      }
    }
  }
};
