export default {
  teamPage: {
    unitTable: {
      empty: 'No data',
      infoIcons: {
        info: 'This table shows analytics based on the scores from a department',
        scoreInfo: 'Average score among all scores on a selected scorecard',
        scoreInfoPrevious:
          'The average score among all grades, according to the selected form of assessment relative to the previous period ',
        reviewsCountInfo: 'Number of all checks on selected scorecards',
        greenZoneCountInfo: 'Percentage of checks in the green zone on selected scorecards',
        greenZoneCountInfoPrevious:
          'The percentage of checks that fell into the green zone according to the selected assessment forms relative to the previous period',
        allScoresInfo: 'All scores by color zones on selected scorecards',
        previousResultInfo: 'The result is relative to the previous period'
      },
      columns: {
        employee: 'Employee',
        unit: 'Unit',
        score: 'Score',
        reviewsCount: 'Checks',
        greenZoneCount: 'Excellent',
        allScores: 'All evaluations',
        flags: 'Flags',
        previous: 'The previous period'
      },
      differenceArrowScoreLiteral: 'points'
    },
    exportTable: {
      columns: {
        employee: 'Employee',
        unit: 'Unit',
        averageScore: 'Average score',
        averageScorePrevious: 'Average score relative to the previous period',
        totalCountReviews: 'Total count reviews',
        greenZonePercentage: 'Percentage of calls in the green zone',
        greenZonePercentagePrevious:
          'Percentage of calls in the green zone relative to the previous period',
        grayColorZone: 'Gray zone (not rated)',
        redColorZone: 'Ratings in the red zone',
        yellowColorZone: 'Ratings in the yellow zone',
        greenColorZone: 'Ratings in the green zone',
        countCommentsWithoutFlag: 'Number of comments without a flag',
        redComments: 'Red comments',
        yellowComments: 'Yellow comments',
        greenComments: 'Green comments'
      }
    },
    buttons: {
      exportTeamPageAnalytics: 'Export to Excel',
      units: 'Units',
      employees: 'Employees'
    },
    pageFilters: {
      exportModal: {
        title: 'Analytics exported',
        description1: 'The export result will come to',
        description2: 'in a few minutes'
      },
      messages: { exportTableFailed: 'Failed to export analytics' },
      filters: {
        unitPlaceholder: 'Select unit',
        unitPlaceholderMax: 'Selected',
        unitPlaceholderMax2: 'units'
      }
    }
  }
};
