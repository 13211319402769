import { Col, Row, Select } from 'antd';
import { SYNC_DATA_PERIODS } from 'core/utils/constants';
import React from 'react';
import { connect } from 'react-redux';
import { setSyncFrom } from 'redux/ui/registerFromIntegration/reducer';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SyncData = React.memo(({ syncFrom, setSyncFrom }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Select
          style={{ width: '100%' }}
          placeholder="Выберите период синхронизации"
          onChange={setSyncFrom}
          value={syncFrom}
        >
          {Object.values(SYNC_DATA_PERIODS).map(period => (
            <Option value={period.key}>{t(period.name)}</Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
});

const mapStateToProps = state => ({
  syncFrom: state.uiRegisterFromIntegration.syncFrom
});

const mapDispatchToProps = {
  setSyncFrom
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncData);
