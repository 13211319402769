import React, { useEffect, useCallback } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SText, { STitle } from 'components/Standard/SText';
import moment from 'moment';
import { beatifyFloat } from 'core/utils/convertFloat';
import UserPreview from 'components/UserPreview';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, get, find } from 'lodash';
import { operations, actions } from 'redux/lists/clientInteractionsList';
import ClientInteractionLink from 'components/ClientInteractionLink';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer';
import CommentsPreview from 'components/Comments/CommentsPreview';
import { TagsDisplay } from 'components/Tags/TagsDisplay';
import { workPlanTaskAssignmentsResource } from 'redux/resources/workPlanTaskAssignments';
import { isEmpty } from 'validate.js';
import { Empty } from 'antd';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import TaskItemsInfo from './TaskItemsInfo';

const WorkPlanTaskItems = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { task_id: currentTaskId, assignment_id: currentAssignmentId } = useParams();

  const { ids, totalCount, page, sort, filters, loading } = useSelector(
    state => state.clientInteractionsList,
    isEqual
  );

  const { updatePage, setSorting, setFilters } = actions;

  const taskItemsByIds = useSelector(state => state.workPlanTaskItemsResource.byIds, isEqual);
  const taskItemsByClientinteractionsIds = Object.values(taskItemsByIds).reduce((acc, item) => {
    const data = {
      [item.clientInteractionId]: {
        ...item
      }
    };
    return { ...acc, ...data };
  }, {});
  const workPlanTasksByIds = useSelector(state => state.workPlanTasksResource.byIds, isEqual);
  const reviewsByIds = useSelector(state => state.reviewsResource.byIds, isEqual);
  const commentsByIds = useSelector(state => state.commentsResource.byIds, isEqual);
  const tasksByIds = useSelector(state => state.tasksResource.byIds, isEqual);
  const taskDefinitionsByIds = useSelector(state => state.taskDefinitionsResource.byIds, isEqual);
  const checklistsByIds = useSelector(state => state.checklistsResource.byIds, isEqual);
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const clientInteractionsByIds = useSelector(
    state => state.clientInteractionsResource.byIds,
    isEqual
  );

  useEffect(() => {
    dispatch(
      setFilters({
        taskAssignmentsIds: [currentAssignmentId]
      })
    );
  }, []);

  const clientInteractions = ids.map(item => clientInteractionsByIds[item]);

  const currentTask = workPlanTasksByIds[currentTaskId];

  useEffect(() => {
    dispatch(
      workPlanTaskAssignmentsResource.operations.load({
        filters: {
          taskId: [currentTaskId]
        },
        include: 'reviewer.unit,task.task_configuration,task_items'
      })
    );
    dispatch(
      operations.load({
        page,
        filters: { taskAssignmentsIds: [currentAssignmentId] },
        sort,
        include:
          'operator.unit,reviews.checklist,reviews.comments,reviews.tasks.task_definition,reviews.checklist.checklist_definition'
      })
    );
  }, [page, sort, filters]);

  useEffect(() => {
    dispatch(
      workPlanTaskAssignmentsResource.operations.load({
        filters: {
          taskId: [currentTaskId]
        },
        include: 'reviewer.unit,task.task_configuration,task_items'
      })
    );
  }, [Object.keys(reviewsByIds).length]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  const tableColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      render: (text, record) => {
        const taskItem = taskItemsByClientinteractionsIds[record.id];
        return (
          <ClientInteractionLink
            id={taskItem?.reviewId || text}
            isReview={!!taskItem?.reviewId}
            communication={record}
          />
        );
      }
    },

    {
      title: t('general.dateAndTime'),
      dataIndex: 'startedAt',
      key: 'startedAt',
      ellipsis: true,
      sorter: true,
      width: 200,
      render: text => <SText>{moment(text).format('DD/MM/YYYY HH:mm')}</SText>
    },

    {
      title: t('workPlanTasks.reviewedOperator'),
      dataIndex: 'operatorId',
      key: 'operatorId',
      ellipsis: true,
      width: 350,
      render: text => <UserPreview userId={text} disabled showAvatar showUnit />
    },

    {
      title: t('general.score'),
      dataIndex: 'left',
      key: 'left',
      align: 'left',
      ellipsis: true,
      width: 100,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        const checklist = checklistsByIds[review?.checklistId];
        const score = checklist?.score;
        return <SText>{isEmpty(score) && score !== 0 ? null : beatifyFloat(score)}</SText>;
      }
    },

    {
      title: t('general.flagsAndComments'),
      dataIndex: 'flagsAndComments',
      key: 'flagsAndComments',
      align: 'left',
      width: 300,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        const checklist = checklistsByIds[review?.checklistId];
        const comments = get(review, 'commentsIds', []).map(item => commentsByIds[item]);

        return (
          <CommentsPreview
            comments={comments}
            checklistData={{
              checklist: {
                comment: checklist?.comment
              }
            }}
          />
        );
      }
    },

    {
      title: t('general.tags'),
      dataIndex: 'countData',
      key: 'countData',
      width: 200,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        const tasks = get(review, 'tasksIds', []).map(item => {
          const task = tasksByIds[item];
          return {
            ...task,
            taskDefinition: taskDefinitionsByIds[task?.taskDefinitionId]
          };
        });

        return <TagsDisplay tasks={tasks} truncate={16} />;
      }
    },
    {
      title: t('general.checklistDefinition'),
      dataIndex: 'checklistDefinition',
      key: 'checklistDefinition',
      width: 350,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        const checklist = checklistsByIds[review?.checklistId];
        const checklistDefinition = checklistDefinitionsByIds[checklist?.checklistDefinitionId];
        return <SText>{checklistDefinition?.name}</SText>;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.reviewCreatedAt'),
      dataIndex: 'reviewCreatedAt',
      key: 'reviewCreatedAt',
      ellipsis: true,
      width: 170,
      sorter: true,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        return <SText>{review ? moment(review?.createdAt).format('DD/MM/YYYY HH:mm') : '-'}</SText>;
      }
    }
  ];

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <TaskItemsInfo />
          </SCol>
          <SCol span={24}>
            <SRow type="flex" margin="16px">
              <SCol span={24}>
                {isEmpty(clientInteractions) ? (
                  <SCard loading={loading} bordered shadowed>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <>
                          <STitle level={3} type="secondary">
                            {t('workPlanTasks.communicationsNotFound')}
                          </STitle>
                          {currentTask?.status ===
                          WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.pending.value ? (
                            <SText type="secondary">
                              {`${t('workPlanTasks.nextCommunicationDate')} ${moment(
                                currentTask?.timeFrom
                              ).format('DD/MM/YYYY, HH:mm')}`}
                            </SText>
                          ) : (
                            <SText type="secondary">{t('workPlanTasks.checkCommunication')}</SText>
                          )}
                        </>
                      }
                    />
                  </SCard>
                ) : (
                  <SCard bordered shadowed>
                    <STable
                      dataSource={clientInteractions}
                      columns={tableColumns}
                      loading={loading}
                      border
                      pagination={{
                        pageSize: parseInt(page.size),
                        current: parseInt(page.number),
                        total: totalCount,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '25', '50'],
                        size: 'small'
                      }}
                      onChange={handleTableChange}
                      fixSorterColumns
                      scroll={{ y: 'calc(100vh - 245px)', x: 'max-content' }}
                      size="small"
                    />
                  </SCard>
                )}

                <ClientInteractionDrawer shouldLoad />
              </SCol>
            </SRow>
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTaskItems;
