import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  paymentPlan: 'profi',
  paymentSymbolicPeriod: 'MONTH',
  paymentCurrency: 'RUB',
  paymentUsersCount: 0,
  paymentSumm: 0,
  isVisiblePaymentRequestModal: false,
  isCheckedPolicy: true
};

export const updatePaymentDetails = createAction('uiPaymentSettings/updatePaymentDetails');
export const toogleVisiblePaymentRequestModal = createAction(
  'uiPaymentSettings/toogleVisiblePaymentRequestModal'
);

export const paymentSettingsReducer = createReducer(defaultState, {
  [updatePaymentDetails]: (state, { payload }) => ({ ...state, ...payload }),
  [toogleVisiblePaymentRequestModal]: state => ({
    ...state,
    isVisiblePaymentRequestModal: !state.isVisiblePaymentRequestModal
  })
});
